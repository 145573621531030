import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";


// Customizable Area Start

export const configJSON = require("./config");




// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabIndex:number
  tabIndexString:string
  AccountApiResponse : any
  ClientAccountDetail : any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ClientAccountDetailControllerweb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  clientAccountDetailApiCallId: any;

  //Properties from config

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.clientAccountDetailApiCallId = ''

    this.state = {
      tabIndex:0,
      tabIndexString:'0',
      ClientAccountDetail:{},
      AccountApiResponse:null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

  
    runEngine.debugLog("API Message Recived", message);


    if (responseJson && responseJson.data) {
      if (apiRequestCallId === this.clientAccountDetailApiCallId) {
        this.setState({ AccountApiResponse: responseJson.data.attributes, ClientAccountDetail:responseJson.data.attributes });
      }
   } 

    // Customizable Area End
  }

  
  async componentDidMount() {
    super.componentDidMount();
    this.getUserDetail();
    this.getAccountDetails();
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  

  // Customizable Area Start
  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({tabIndex:newValue,tabIndexString:newValue.toString()})
  }
  
  getUserDetail = ()=>{
    let detail:any = localStorage.getItem('userDetail')
    if(detail){
      let userDetailObj  = JSON.parse(detail)
      this.getClientAccountDetail(userDetailObj.account.id)
    }
  
}

  getAccountDetails = async () => {
    const token = await localStorage.getItem("token");
    let userDetails: any = await localStorage.getItem("userDetail");
    userDetails = JSON.parse(userDetails);
    const userRole = userDetails?.account_role;
    if (token && (userRole === "Super Admin" || userRole === "Admin")) {
      const clientId = this.props.navigation.history.location?.state?.id;
      if (clientId) {
        this.getClientAccountDetail(clientId);
      }
    }
  }

  getClientAccountDetail = (clientId:number) =>{
    let temp = this.getTokenForClientAccountDetail();

    const header = {
      "Content-Type": configJSON.contentTypeApiGetClientAccountDetail,
      token: temp,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.clientAccountDetailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetClientAccountDetail + this.getAccountId(clientId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetClientAccountDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true

  }

  getAccountId = (clientId: number) => {
    const clientDetails: any = sessionStorage.getItem("clientDetails");
    return clientDetails ? parseInt(JSON.parse(clientDetails).id) : clientId;
  }

  downloadImage = async(url:string,nameOfDownload:string) => { 
      let response = await fetch(url);
      let blobImage = await response.blob();
      let href = URL.createObjectURL(blobImage);
      let anchorElement = document.createElement('a');
      anchorElement.href = href;
      anchorElement.download = nameOfDownload;
      document.body.appendChild(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
  }

  getTokenForClientAccountDetail = () => {
    const clientDetails: any = sessionStorage.getItem("clientDetails");
    const token = localStorage.getItem("token");
    return (JSON.parse(clientDetails) || {}).token || token;
  }

  handleBeforeUnload = () => {
    localStorage.removeItem("clientDetails");
  }

  componentWillUnmount(): any {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }
  // Customizable Area End
}
