import React from "react";

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Typography,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, } from "@material-ui/core/styles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import Strings from "../../../components/src/Strings copy";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});


// Customizable Area End

import CategoriesController, {
    Props,
} from "./CategoriesController.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
import Navbar from "../../settings2/src/Navbar.web";
import { CheckBox, Close, Delete, breadCrum, notAllCheck, plusImg, searchIcon, unCheckedBox } from "./assets";
import { successImg, yesImg } from "../../adminconsole2/src/assets";
import { Alert } from "@material-ui/lab";

export default class Categories extends CategoriesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start

            <div id="element" className="main-wrapper">
                <NavigationMenu navigation={this.props.navigation} id={""} />
                <div className="dashboard_container_wrapper">
                    <div className="dashboard_navbar_container">
                        <Navbar navigation={this.props.navigation} id={""} />
                    </div>
                    <Box style={webStyle.second_section}>
                        <Box style={webStyle.form_div}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "32px", alignItems: "center" }}>
                                <Typography style={webStyle.categories_text}>
                                    {Strings.categoriesHeading}
                                </Typography>
                                {this.state.alertOpen && (
                                    <Alert severity="error" icon={false}
                                        style={{
                                            width: '45%',
                                            justifyContent: 'center',
                                            borderRadius: '12px',
                                            fontFamily: 'Barlow Semi Condensed',
                                            color: '#DC2626',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            backgroundColor: '#FEDCE0'
                                        }}>{Strings.noSelections}</Alert>)}
                                {this.state.editSuccesMsg && (
                                    <Alert severity="success" icon={false}
                                        style={{
                                            width: '45%',
                                            justifyContent: 'center',
                                            borderRadius: '12px',
                                            fontFamily: 'Barlow Semi Condensed',
                                            color: '#288700',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            backgroundColor: '#DCFBCF'
                                        }}>{Strings.changesSaved}</Alert>)}
                                <button
                                    data-test-id="addNewBtn"
                                    style={webStyle.addnewcategoies_button}
                                    onClick={this.handleAddNewCategoryModal}
                                >
                                    <img src={plusImg} style={{ width: '24px', height: '24px' }} />
                                    <p style={webStyle.addnewcategoies_text} className="category-add-button">{Strings.addCategory}</p>
                                </button>
                            </div>
                            <Dialog
                                open={this.state.open}
                                onClose={this.handleCloseDilog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                fullWidth
                                maxWidth="xs"
                                PaperProps={{
                                    style: {
                                        overflow: 'hidden',
                                        borderRadius: '12px',
                                        width: '640px',
                                        height: '272px',
                                        maxWidth: 'none',
                                        display: 'flex',
                                        marginTop: '-220px',
                                        boxShadow: 'none',
                                    },
                                }}
                            >
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={this.handleCloseDilog}
                                    aria-label="close"
                                    style={{
                                        position: 'absolute',
                                        top: '16px',
                                        right: '33px',
                                        padding: '8px',
                                    }}
                                >
                                    <img src={Close} />
                                </IconButton>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description" >
                                        <Typography
                                            style={{
                                                fontFamily: 'Barlow Semi Condensed',
                                                fontSize: '16px',
                                                fontWeight: 700,
                                                color: '#1D1F2C',
                                                marginTop: '15px'
                                            }}>
                                            {Strings.addCategory}
                                        </Typography>
                                        <Typography style={{
                                            fontFamily: 'Barlow Semi Condensed',
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: '#777980',
                                            marginTop: '25px'
                                        }} >
                                            {Strings.name}
                                        </Typography>
                                        <input style={
                                            {
                                                width: '100%',
                                                height: '40px',
                                                border: '1px solid #E0E2E7',
                                                padding: '8px 12px',
                                                borderRadius: '8px',
                                                fontFamily: 'Barlow Semi Condensed',
                                                marginTop: '10px',
                                            }}
                                            data-test-id="categoryInput"
                                            value={this.state.categoryValue}
                                            onChange={(e) => this.setState({
                                                categoryValue: e.target.value
                                            })}
                                        />
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', bottom: 30, right: 30 }}>
                                            <Button style={{
                                                width: '61px',
                                                height: '40px',
                                                padding: '10px 14px',
                                                borderRadius: '8px',
                                                backgroundColor: '#32569A',
                                                color: 'white',
                                                textTransform: 'none',
                                                fontSize: '14px',
                                                fontWeight: 600,
                                                fontFamily: 'Barlow Semi Condensed'

                                            }}
                                                onClick={this.handleAddCategory}
                                            >{Strings.save}</Button>
                                        </div>
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: "24px" }}>
                                <button
                                    data-test-id="deleteAllBtn"
                                    style={webStyle.deleteAll_button}
                                    onClick={this.handleDeleteToggle}
                                >
                                    <img src={Delete} style={{ width: '24px', height: '24px', color: 'white' }} />
                                    <p style={webStyle.addnewcategoies_text} className="category-add-button">{Strings.deleteAll}</p>
                                </button>
                                <div style={{ position: 'relative', marginLeft: "16px" }}>
                                    <input
                                        data-test-id="searchInput"
                                        placeholder={Strings.searchText}
                                        value={this.state.searchValue}
                                        onChange={this.handleSearchInputChange}
                                        style={webStyle.inputSearch}
                                    />
                                    <div style={{ position: 'absolute', top: '50%', left: '5px', transform: 'translateY(-50%)' }}>
                                        <img src={searchIcon} alt="Search Icon" style={{ width: '20px', height: '20px' }} />
                                    </div>
                                </div>
                            </div>
                            <TableContainer component={Paper} style={{ borderRadius: '12px' }}>
                                <Table aria-label="simple table" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" style={{ color: '#1C3045' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }} >
                                                    {this.state.datas.length === 0 ? <img src={unCheckedBox} style={{ marginRight: '20px' }} onClick={this.toggleSelectAll} /> : this.checkAllCheckBox() ?
                                                        <img src={notAllCheck} style={{ marginRight: '20px' }} onClick={this.toggleSelectAll} /> : <img src={unCheckedBox} style={{ marginRight: '20px' }} onClick={this.toggleSelectAll} />}
                                                    <Typography style={{ fontFamily: 'Barlow Semi Condensed', fontSize: '16px', fontWeight: 600 }}>{Strings.name}</Typography>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right" style={{ fontFamily: 'Barlow Semi Condensed', fontSize: '16px', fontWeight: 600, color: '#1C3045', paddingRight: '50px' }}>{Strings.action}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.filterData().sort((a, b) => a.id - b.id).map((item: any, index: any) => {
                                            return (
                                                <TableRow key={item.id}
                                                >
                                                    <TableCell align="left">
                                                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}
                                                            data-test-id={`MapMennu-${index}`}
                                                            onClick={() => this.toggleCheckBox(item.id)}>
                                                            {item.selected ? <img src={CheckBox} style={{ marginRight: '20px' }} /> : <img src={unCheckedBox} style={{ marginRight: '20px' }} />}

                                                            {item && item.attributes && item.attributes.name}

                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="right">

                                                        <img src={breadCrum}
                                                            data-test-id={`MapMenu-${index}`}
                                                            style={{ marginRight: '60px', cursor: 'pointer' }}
                                                            onClick={(event: any) => this.deleteItemToggle(item.id, item, event)} />
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Dialog
                                open={this.state.editOpen}
                                onClose={this.handleCloseDilog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                fullWidth
                                maxWidth="xs"
                                PaperProps={{
                                    style: {
                                        overflow: 'hidden',
                                        borderRadius: '12px',
                                        width: '640px',
                                        height: '224px',
                                        maxWidth: 'none',
                                        display: 'flex',
                                        marginTop: '-260px',
                                        boxShadow: 'none',
                                    },
                                }}
                            >
                                <IconButton
                                    edge="end"
                                    data-test-id="crossBtn"
                                    color="inherit"
                                    onClick={this.handleEditClose}
                                    aria-label="close"
                                    style={{
                                        position: 'absolute',
                                        top: '16px',
                                        right: '33px',
                                        padding: '8px',
                                    }}
                                >
                                    <img src={Close} />
                                </IconButton>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description" >
                                        <Typography
                                            style={{
                                                fontFamily: 'Barlow Semi Condensed',
                                                fontSize: '16px',
                                                fontWeight: 700,
                                                color: '#1D1F2C',
                                                marginTop: '15px'
                                            }}>
                                            {Strings.editCategory}
                                        </Typography>
                                        <Typography style={{
                                            fontFamily: 'Barlow Semi Condensed',
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            color: '#777980',
                                            marginTop: '25px'
                                        }} >
                                            {Strings.name}
                                        </Typography>
                                        <input style={
                                            {
                                                width: '590px',
                                                height: '40px',
                                                border: '1px solid #E0E2E7',
                                                padding: '8px 12px',
                                                borderRadius: '8px',
                                                marginTop: '10px'
                                            }}
                                            data-test-id="editInput"
                                            value={this.state.editValue}
                                            onChange={(e) => this.setState({
                                                editValue: e.target.value
                                            })}
                                        />
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', bottom: 20, right: 30 }}>
                                            <Button
                                                data-test-id="editSaveBtn"
                                                style={{
                                                    width: '61px',
                                                    height: '40px',
                                                    padding: '10px 14px',
                                                    borderRadius: '8px',
                                                    backgroundColor: '#32569A',
                                                    color: 'white',
                                                    textTransform: 'none',
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                    fontFamily: 'Barlow Semi Condensed'

                                                }}
                                                onClick={this.editWasteTypeCategory}
                                            >{Strings.save}</Button>
                                        </div>


                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>

                            <Menu
                                id="account-menu"
                                open={this.state.openPopUp}
                                onClose={this.cancelDelete}
                                getContentAnchorEl={null}
                                anchorEl={this.state.anchorEl}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                MenuListProps={{
                                    style: {
                                        width: '160px',
                                        borderRadius: '10px',
                                    },
                                }}
                                PaperProps={{
                                    style: {
                                        overflow: 'visible',
                                        border: '1px solid #E2E8F0',
                                        width: '192px',
                                        height: '82px',
                                        borderRadius: '8px',
                                        transformOrigin: 'top right',
                                        marginTop: '4px',
                                        transform: 'translateX(7px)',
                                        boxShadow: 'none',
                                    },
                                }}
                            >
                                  <MenuItem style={webStyle.menu_text} onClick={() => this.handleEditOpen()}>
                                        {Strings.edit}
                                  </MenuItem>
                                  <MenuItem style={webStyle.menu_text} onClick={this.handleDeleteToggle}>
                                       {Strings.delete}
                                  </MenuItem>
                             </Menu>

                            <Dialog
                                open={this.state.sucessOpen}
                                onClose={this.handleCloseDilog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                fullWidth
                                maxWidth="xs"
                                PaperProps={{
                                    style: {
                                        overflow: 'hidden',
                                        borderRadius: '12px',
                                        width: '640px',
                                        height: '308px',
                                        maxWidth: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        boxShadow: 'none'
                                    },
                                }}
                            >
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={this.handleCloseSuccessDilog}
                                    aria-label="close"
                                    style={{
                                        position: 'absolute',
                                        top: '16px',
                                        right: '16px',
                                        padding: '8px',
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description" >
                                        <div>
                                            <img src={successImg} alt="Success Image" style={{ position: 'absolute', top: 100, left: 260 }} />
                                            <img src={yesImg} alt="Yes Image" style={{ position: 'absolute', top: 100, left: 269 }} />
                                            <p style={{
                                                textAlign: 'center',
                                                marginTop: '190px',
                                                color: 'black',
                                                fontFamily: 'Barlow Semi Condensed',
                                                fontSize: '16',
                                                fontWeight: 500
                                            }}>{Strings.added}</p>
                                        </div>
                                    </DialogContentText>
                                </DialogContent>
                            </Dialog>

                            <Dialog
                                open={this.state.openDelete}
                                onClose={this.handleCloseDilog}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                fullWidth
                                maxWidth="xs"
                                PaperProps={{
                                    style: {
                                        overflow: 'hidden',
                                        borderRadius: '8px 8px 32px 8px',
                                        width: '600px',
                                        height: '220px',
                                        maxWidth: 'none',
                                        marginTop: '150px',
                                        boxShadow: 'none'
                                    },
                                }}
                            >
                                <Typography
                                    style={{
                                        fontSize: '16px',
                                        fontFamily: 'Barlow Semi Condensed',
                                        color: '#0F172A',
                                        fontWeight: 600,
                                        marginLeft: '45px',
                                        marginTop: '50px'
                                    }}
                                >
                                    {Strings.confirmDelete}
                                </Typography>
                                <IconButton
                                    data-test-id="closeBtn"
                                    edge="end"
                                    color="inherit"
                                    onClick={() => { this.setState({ openDelete: false, selected: [] }) }}
                                    aria-label="close"
                                    style={{
                                        position: 'absolute',
                                        top: '16px',
                                        right: '16px',
                                        padding: '8px',
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <DialogContent style={{ overflowY: 'hidden' }}>
                                    <DialogContentText id="alert-dialog-description" >
                                        <Typography style={{ fontSize: '16px', fontFamily: 'Barlow Semi Condensed', color: '#0F172A', fontWeight: 400, marginLeft: '20px' }}>
                                            {Strings.deleteWarning}
                                        </Typography>
                                    </DialogContentText>
                                </DialogContent>
                                <Divider />
                                <DialogActions>
                                    <Button
                                        data-test-id="cancleBtn"
                                        variant="outlined"
                                        style={webStyle.cancel_btn}
                                        onClick={() => this.setState({ openDelete: false, selected: [] })}>
                                        {Strings.cancel}
                                    </Button>
                                    <Button variant="contained" style={webStyle.delete_btn} onClick={this.deleteAllCategory}>
                                        {Strings.delete}
                                    </Button>
                                </DialogActions>
                            </Dialog>

                        </Box>

                    </Box>
                </div>
            </div>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    second_section: {
        padding: '32px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "#F6F7FA",
    } as const,
    inputSearch: {
        width: '200px',
        height: '40px',
        paddingLeft: '30px',
        border: '1px solid #E0E2E7',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Barlow Semi Condensed',
    },
    navigationMenu: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    } as const,
    categories_text: {
        fontFamily: 'Barlow Semi Condensed',
        fontSize: '24px',
        fontWeight: 700,
        color: '#1C3045'
    },
    form_div: {
        display: 'flex',
        flexDirection: 'column',
    } as const,
    wrapper_div: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px'
    } as const,
    text_style: {
        fontFamily: 'Barlow Semi Condensed',
        fontWeight: 500,
        fontSize: '16px',
        color: 'rgba(28, 48, 69, 0.7)'
    } as const,
    addnewcategoies_button: {
        backgroundColor: '#32569A',
        borderRadius: '8px',
        padding: '10px 16px',
        alignItems: "center",
        gap: "8px",
        display: "flex"
    } as const,
    addnewcategoies_text: {
        color: '#FFFFFF',
        textTransform: 'none',
        fontFamily: 'Barlow Semi Condensed',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: "24px"
    } as const,
    deleteAll_button: {
        backgroundColor: '#DC2626',
        borderRadius: '8px',
        padding: '10px 16px',
        height: '40px',
        alignItems: "center",
        gap: "8px",
        display: "flex"
    } as const,
    delete_btn: {
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: 700,
        textTransform: "none",
        marginRight: '30px',
        backgroundColor: '#DC2626',
        color: '#FFFFFF'
    } as const,
    cancel_btn: {
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: 700,
        textTransform: "none",
        margin: '20px',
        backgroundColor: '#FFFFFF',
        color: '#32569A',
        borderColor: '#32569A'
    } as const,
    menu_text: {
        fontSiz: '16px',
        fontWeight: 500,
        fontFamily: 'Barlow Semi Condensed',
        color: '#32569A',
        width: '121%',
        borderRadius: '10px'
    }

};

// Customizable Area End
