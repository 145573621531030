import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  checkedRememberMe: boolean;
  showPassword: boolean;
  statusLogin:boolean;
  rememberMe:boolean;
  language : string | null;
  dropDownOpen:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallIds: any = "";
  forValidationApiCallId: string = "";
  emailRegs: RegExp;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      dropDownOpen:false,
      password: "",
      checkedRememberMe: false,
      showPassword:false,
      statusLogin:false,
      rememberMe:false,
      language : localStorage.getItem('language') ? localStorage.getItem('language') : 'english'
    };

    this.emailRegs = /\w+/;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApis();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const rememberUser=localStorage.getItem("rememberUser")
    if(rememberUser){
        this.setState({email:rememberUser})
    }
    this.checkUserLoginOrNot()
    // Customizable Area End
  }

  // Customizable Area Start
//   
  handleShowPassword=()=>{
    this.setState({
      showPassword:!this.state.showPassword
    })
  }

  openDashboardPage =(accountRole:string)=>{
    if(accountRole === 'Client'){
      localStorage.removeItem("clientDetails");
      sessionStorage.removeItem("clientDetails");
     window.location.href = "DashboardClient";
    }else if(accountRole === "Super Admin" || accountRole === "Admin"){
     window.location.href = 'Dashboard'
    }
  }

  checkUserLoginOrNot = ()=>{
    let detail:any = localStorage.getItem('userDetail')
    console.log('detail:$$$$$$$$$$ ', detail);
      try{
        let userDetailObj = JSON.parse(detail)
        if(userDetailObj.token && userDetailObj.account_role){
              this.openDashboardPage(userDetailObj.account_role)
          }
      }catch(e){
        let language= localStorage.getItem('language')
        localStorage.clear();
        if(language){
        localStorage.setItem('language',language)
        }
      }
  }

  dropDownClickAway=()=>{
    this.setState({dropDownOpen:false})
  }

  openDropDown=()=>{
    this.setState({dropDownOpen:!this.state.dropDownOpen})
  }

  handlelanguage(event : any)
  {
    localStorage.setItem('language', event.target.value);
    window.location.reload()
  }

  // Web Event Handling

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
         this.setState({rememberMe: false})
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
    if (apiRequestCallId === this.forValidationApiCallId && responseJson?.data?.length) {
        const regexData = responseJson.data[0];
        if (regexData?.email_validation_regexp) {
            this.emailRegs = new RegExp(regexData.email_validation_regexp);
        }
    }
    
    if (apiRequestCallId === this.apiEmailLoginCallIds) {
        const token = responseJson?.meta?.token;
        if (token) {
            localStorage.setItem("token", token);
            localStorage.setItem("language", responseJson?.meta?.language)
            localStorage.setItem("userDetail", JSON.stringify(responseJson?.meta));
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.saveLoggedInUserDatas(responseJson);
            this.sendLoginSuccessMessages();
            this.openDashboardPage(responseJson?.meta?.account_role)
        } else {
            this.sendLoginFailMessages();
            this.setState({statusLogin:true})
        }
    }
    }
    // Customizable Area End
  }

  sendLoginFailMessages() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessages() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));
    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

    saveLoggedInUserDatas(responsJson: any) { 

    if (responsJson && responsJson.meta && responsJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify( responsJson )
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responsJson.meta.token
      );

      this.send(msg);
    }
  }
  openInfoPages (){
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

   goToForgotPassword=()=> {
    this.props.navigation.navigate("ForgotPassword");
  }


  doEmailLogIn=()=> {
    if(this.state.rememberMe===true){
      localStorage.setItem("rememberUser",this.state.email)
  }
  else{
      localStorage.removeItem("rememberUser")
  }
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailRegs.test(this.state.email)
    ) {
    this.setState({statusLogin:true})
    }

   else if (this.state.password === null || this.state.password.length === 0) {
        this.setState({statusLogin:true})
    }
 else {
   
  const attrs = {
    email: this.state.email,
    password: this.state.password,
  };
  const data = {
    type: "email_account",
    attributes: attrs,
  };
    
    
    const httpBody = {
      data: data,
    };
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEmailLoginCallIds = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "login/login"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
    }
  }

  callGetValidationApis() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.forValidationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
