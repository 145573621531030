import React from "react";

// Customizable Area Start
import { logout, naqaa, closeIcon, MenuIcon } from "./assets";
import {
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import Strings from "./Strings";
import "./navigationStyle.css"
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  navList: [] = configJSON.navMenuForAdmin;



  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <div className="sidebar_wrapper hide-tab-element" ref={this.navigationMenuRef} id="toogle" onClick={this.toggle}>
          <div className="sidebar">
            <div className="logo" >
              <div style={{display:'flex',alignItems:'center'}}>
              <img src={MenuIcon} alt="menu_icon" className='menu-logo hide-desk-element' data-test-id="toggle-btn" onClick={this.toggle} />
              <img src={naqaa} alt="logo" className='img-logo'></img>
              </div>
            {/* language */}
              <div className='show_mob_element language_container'>
                <FormControl>
                  <Select
                    disableUnderline
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    value={this.state.currentLanguage}
                    inputProps={{ 'aria-label': 'Without label', }}
                    style={{ marginLeft: '16px', fontFamily: 'Barlow Semi Condensed', fontSize: '14px', fontWeight: 400 }}
                  >
                    <MenuItem value={"english"} data-test-id="menuselect1" onClick={() => this.setLanguageOption('english')} style={webStyle.menu_item_select} >{Strings.english}</MenuItem>
                    <MenuItem value={"arabic"} data-test-id="menuselect2" onClick={() => this.setLanguageOption('arabic')} style={webStyle.menu_item_select}>{Strings.arabic}</MenuItem>
                  </Select>
                </FormControl>
              </div>

            </div>
            <hr className="hide-desk-element horigentle_nav_line" />
            <nav className="navigation">
              <ul>
                {this.state.navigationMenuArray.map((menuIem: { icon: string, title: string, navigationName: string }, index: number) => {
                  return <li key={index} className={`${this.activePath(menuIem.navigationName) && 'selectedMenu'} MenuItem`} onClick={()=>this.goNavigationPage(menuIem.navigationName)}>
                    <div className={`list-item`} ><span className="list-padding"><span className="list-img"><img src={this.state.navigationImageArray[menuIem.icon]}></img></span> <span className="list-text"> {menuIem.title} </span></span></div>
                    <div className="active_border hide-mob-element"></div>
                    </li>
                })}

                {(this.state.userRole === "Client" || this.isViewAsClient()) && <li data-test-id="logOutBtn" onClick={this.ShowSignOutPopup} id="list-item" style={{ height: '3.25rem', cursor: 'pointer' }} ><div className="list-item" ><span className="list-padding"><span className="list-img"><img src={logout}></img></span> <span className="list-text logout-text"> {Strings.signOut} </span></span></div></li>}
              </ul>
            </nav>
          </div>

          </div>


        {this.state.signOutModalFlag &&
          <div className="sign_out_popup_wrapper" data-test-id="logoutPopupBox">
            <div className="sign_out_popup_container">
              <div className="sign_out_modal_close"><img src={closeIcon} alt="cross" data-test-id="closeModalImg" onClick={this.HideSignOutPopup} /></div>
              <p className="sign_out_text_message">{Strings.logWarning}</p>
              <div className="sign_out_button_container">
                <button data-test-id="closeModal" className="sign_out_cancel_button" onClick={this.HideSignOutPopup}>{Strings.cancel}</button>
                <button data-test-id="logOut" className="sign_out_logout_button" onClick={this.onLogoutClick}>{Strings.logout}</button>
              </div>
            </div>
          </div>
        }

      </>


    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
   menu_item_select: {
    fontFamily: 'Barlow Semi Condensed',
    fontWeight: 400,
    fontSize: '14px',
    color: '#000000'
  }
}

// Customizable Area End
