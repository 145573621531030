import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import * as Yup from 'yup';
import moment from "moment";
import { SQS } from "./DashboardController.web";
import Strings from "./Strings";

interface SF {
    cityNames: string[],
    date: string,
    custom_start_date: string,
    custom_end_date: string
}

export interface SP {
    title: string,
    value: string
}

let initialFormValues = {
    cityNames: [],
    date: "",
    custom_start_date: moment().subtract(1, 'days').format('DD-MM-yyyy'),
    custom_end_date: moment().format('DD-MM-yyyy'),
}

export interface Props {
    searchQueryState: SQS,
    setSearchQueryState: (arg: SQS, callApi: boolean) => void,
    showFilterModal: () => void,
    hideFilterModal: () => void,
    cityList: Array<{ city: string }>
    testId?: string
}

interface S {
    expandedList: string | boolean
    date: SP[]
    initialValues: SF
}
interface SS {}

export default class ServiceMaintainanceFilterController extends BlockComponent<Props, S, SS> {
    formRef: any
    formSchema: any

    constructor(props: Props) {
        super(props);
        
        this.receive = this.receive.bind(this);

        this.formSchema = Yup.object().shape({
            date: Yup.string(),
            custom_end_date: Yup
                .string()
                .when('date', {
                    is: 'custom_date',
                    then: Yup.string().required(),
                    otherwise: Yup.string(),
                }),
            cityNames: Yup.array().of(Yup.string()),
            custom_start_date: Yup
                .string()
                .when('date', {
                    is: 'custom_date',
                    then: Yup.string().required(),
                    otherwise: Yup.string(),
                }),
        })

        this.state = {
            expandedList: 'panel2',
            date: [
                { title: Strings.lastMonth, value: 'last_month' },
                { title: Strings.thisMonth, value: 'this_month' },
                { title: Strings.custom, value: 'custom_date' },
            ],
            initialValues: initialFormValues
        };
        this.formRef = React.createRef();
    }

    setCustomStartDate = (formValues: SF, setFieldValue: any) => (e: any) => {
        const startDate = moment(e.target.value)
        const endDate = formValues.custom_end_date
        if (startDate.diff(moment(endDate, 'DD-MM-yyyy'), 'days') > 0) {
            setFieldValue('custom_end_date', startDate.add(1, 'days').format('DD-MM-yyyy'))
        }
        setFieldValue('custom_start_date', moment(e.target.value).format('DD-MM-yyyy'))
    }

    handleExpandedList = (panel: string) => (event: any, isExpanded: boolean) => {
        this.setState({ expandedList: isExpanded ? panel : false })
    };

    setDateValue = (value: string, formValues: SF, setFieldValue: any) => () => {
        if (value !== formValues.date) {
            setFieldValue('date', value);
        } else {
            setFieldValue('date', '');
        }
    }

    setFormValue = () => {
        let { searchQueryState } = this.props;
        this.setState({
            initialValues: {
                cityNames: searchQueryState.citys,
                custom_start_date: searchQueryState.custom_start_date || this.state.initialValues.custom_start_date,
                custom_end_date: searchQueryState.custom_end_date || this.state.initialValues.custom_end_date,
                date: searchQueryState.date,
            }
        })
    }

    setCustomEndDate = (formValues: SF, setFieldValue: any) => (e: any) => {
        setFieldValue('custom_end_date', moment(e.target.value).format('DD-MM-yyyy'))
    }

    setCityNames = (city: string, formValues: SF, setFieldValue: any) => () => {
        let cityValues = formValues.cityNames
        if (cityValues.includes(city)) {
            const index = cityValues.indexOf(city);
            cityValues.splice(index, 1);
            setFieldValue('cityNames', [...cityValues])
        } else {
            setFieldValue('cityNames', [...cityValues, city])
        }
    }
    
    clearFormValue = (resetForm: any) => () => {
        resetForm({
            values: { ...initialFormValues },
            isSubmitting: false
        })
        this.handleSubmit(initialFormValues)
    }

    handleSubmit = (value: SF) => {
        this.props.setSearchQueryState(
            {
                ...this.props.searchQueryState,
                citys: value?.cityNames,
                custom_start_date: value?.custom_start_date,
                custom_end_date: value?.custom_end_date,
                date: value?.date,
                page: 1,
                activePaginationStep: 1
            }, true)
        this.props.hideFilterModal()
    }

    async componentDidMount(): Promise<void> {
        this.setFormValue()
    }
}
