import React from "react";

//Customizable Area Start
import { forgotPasswordStrings } from "./assets";
import ForgotPasswordWebController, {
  Props,
} from "./ForgotPasswordWebController";
import CustomForgotResetPassword from "./CustomForgotResetPassword.web";
import Timer from "./Timer";
import "./ForgotPassword.css";
import Strings from "./Strings";

//Customizable Area End

export default class ForgotPassword extends ForgotPasswordWebController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  onResetButtonPress = () => this.onResetPress(this.state.savedEmailValue);

  backToLogin = () => {
    let language=localStorage.getItem('language')
    if (this.state.error === null || this.state.timerRemaining<0)
      return (
        <div className="below-login-button">
          <p className="login-button-text">
            {Strings.backTo}
            <button
              data-testId={"backToLogin"}
              onClick={this.goToLogin}
              className={"login-button"}
            >
              {Strings.login}
            </button>
          </p>
        </div>
      );

    return (
      <div className={"below-login-button"}>
        <p className="login-button-text" dir={language==='arabic'?"rtl":"ltr"}>
          {Strings.didntReceiveTheLink}
          <button
            data-testid="resendLink"
            disabled={this.state.timerRemaining !== 0}
            onClick={this.onResetButtonPress}
            className={`login-button ${this.state.timerRemaining !== 0 &&
              "button-disabled"}`}
          >
            {Strings.resendAgain}
          </button>
        </p>
       {this.state.timerRemaining!==0&& <Timer
          timeRemaining={this.state.timerRemaining}
          setTimeRemaining={this.setTimeRemaining}
        />}
      </div>
    );
  };

  render() {
    return (
      <div
        data-testId="forgotPasswordContainer"
        className="forgotPasswordcontainer"
      >
        <CustomForgotResetPassword
          data-testid={"customForgotPassword"}
          headerText={forgotPasswordStrings.headerText}
          subHeaderText={forgotPasswordStrings.subHeaderText}
          buttonText={forgotPasswordStrings.buttonText}
          promptMessage={this.state.propmtMessage}
          onSubmitButtonPress={this.onResetPress}
          showBackButton={this.state.error !== null}
          onBackButtonPress={this.goToLogin}
          showPrompt={this.state.error}
          belowSubmitButtonUI={this.backToLogin}
          validateField={this.state.emailValidate}
          inputArray={[
            {
              name: Strings.emailAddress,
              placeholder: Strings.naqaaMail,
              value: this.state.emailValue,
              type: "email",
              error: this.state.emailValidate,
              onChange: this.onChangeText,
            },
          ]}
        />
      </div>
    );
  }
}

const styles = {
  submitButton: {
    marginTop: "2.37rem",
  },
  errorMessage: {
    width: "100%",
    marginLeft: 5,
  },
  marginBottom0: {
    marginBottom: 0,
  },
};
