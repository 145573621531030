Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"

exports.englishLanguage = { 
  categoriesHeading : 'Categories',
  noSelections : 'No selections',
  changesSaved : 'Your changes have been saved!',
  addCategory : ' Add new category',
  name : 'Name',
  save : 'Save',
  action : 'Action',
  confirmDelete : 'Are you sure you want to delete?',
  deleteWarning : 'Once deleted, it can’t be undone.',
  cancel : 'Cancel',
  delete : 'Delete',
  deleteAll : 'Delete All',
  editCategory : 'Edit category',
  added : 'New Category has been successfully added!',
  searchText : 'Search Category',
  filters : 'Filters',
  sort : 'Sort'
  }


exports.arabicLanguage = { 
  categoriesHeading : 'الفئات',
  noSelections : 'No selections',
  changesSaved : 'تم حفظ التغييرات بنجاح',
  addCategory : ' إضافة فئة جديدة',
  name : 'الاسم',
  save : 'حفظ',
  action : 'الإجراء',
  confirmDelete : 'هل أنت متأكد من حذف الفئة',
  deleteWarning : 'لا يمكن إستعادة الفئة بعد حذفها',
  cancel : 'Cancel',
  delete : 'حذف',
  deleteAll : 'حذف الكل',
  editCategory : 'تعدّيل الفئة',
  added : 'تمت إضافة الفئة بنجاح!',
  searchText : 'إبحث عنا لفئات ',
  filters : 'تصفية',
  sort : 'ترتيب'
  }


// Customizable Area End