import React from "react";
import "./WasteFilter.css"
import WasteFilterController, {
  Props, SP
} from "./WasteFilterController.web";
import { withStyles } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { calendarIcon, checkIcon, uncheckIcon } from "./assets";
import { Form, Formik } from "formik";
import moment from "moment";
import Strings from "./Strings";


export default class WasteFilter extends WasteFilterController {

  constructor(props: Props) {

    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (

      <React.Fragment>

        <Formik
          data-test-id="formik"
          initialValues={this.state.initialValues}
          enableReinitialize={true}
          onSubmit={(values) => { this.handleSubmit(values) }}
          validationSchema={this.filterformSchema}
          innerRef={this.filterformRef}
        >

          {(props) => {
            const { values, setFieldValue, resetForm } = props;
            return (

              <Form translate={undefined} className="formContainer">

                <div className="filter_modal_wrapper">
                  <div className="client_filter_menu_clear_wrapper">
                    <p data-test-id="reset-form" onClick={this.clearFormValue(resetForm)}>{Strings.clearAll}</p>
                  </div>

                  <div className="client_filter_accordian_wrapper">
                    {/* accordian */}


                    <Accordion data-test-id="Wasteaccordian" square expanded={this.state.expandedList === 'panel1'} onChange={this.handleAcordionChange('panel1')}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon style={{ color: '#8D97A2' }} />}>
                        <p className={`client_accordian_header ${this.state.expandedList === 'panel1' && 'filter_accordian_active_header_color'}`}>{Strings.clients}</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="accordionDetails_wrapper">
                          {this.props.companyList?.map((companyObj: any, comIndex: number) => {

                            return (

                              <div className="checkbbox_wrapper" key={comIndex}>
                                <img data-test-id={`company-checkbox-${comIndex}`} src={values.clientsId.includes(companyObj[0]) ? checkIcon : uncheckIcon} alt="checkIcon" className="checkbox_img" onClick={this.setCompanyId(companyObj[0], values, setFieldValue)} />
                                <p className="checkbox_title">{companyObj[1]}</p>
                              </div>
                            )

                          })}
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion data-test-id="Wasteaccordian" square expanded={this.state.expandedList === 'panel2'} onChange={this.handleAcordionChange('panel2')}>
                      <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ExpandMoreIcon style={{ color: '#8D97A2' }} />}>
                        <p className={`client_accordian_header ${this.state.expandedList === 'panel2' && 'filter_accordian_active_header_color'}`}>{Strings.pickupDate}</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="accordionDetails_wrapper">
                          {this.state.date_pickup.map((pick_obj: SP, pickIndex: number) => {
                            return (
                              <div data-test-id={`pickUpDate${pickIndex}`} className="checkbbox_wrapper" key={pickIndex} >
                                <img data-test-id={`pickup-checkbox-${pickIndex}`}
                                  src={values.pickUpDate === pick_obj.value ? checkIcon : uncheckIcon}
                                  alt="checkIcon" className="checkbox_img"
                                  onClick={this.setPickValue(pick_obj.value, values, setFieldValue)}
                                />
                                <p className="checkbox_title">{pick_obj.title}</p>
                              </div>
                            )
                          })}
                          {/* custome date picker */}
                          {
                            values.pickUpDate === 'custom_date' &&
                            <div className="custom_date_wrapper">
                              {/* from Date */}
                              <div className="custom_date_input_wrapper">
                                <p className="custom_date_input_title">{Strings.from} </p>
                                {/* select Date from */}
                                <div className="custom_date_input_container" >
                                  <input className="input_date_hide"
                                    data-test-id="custom-start-date"
                                    id="from_date_type"
                                    type="date"
                                    value={moment(values.custom_start_date, 'DD-MM-yyyy').format('yyyy-MM-DD')}
                                    onChange={this.setCustomStartDate(values, setFieldValue)}
                                    max={moment().format('yyyy-MM-DD')}
                                  />
                                  <p className="custom_date_input_text">
                                    {this.formatDateValue(values.custom_start_date)}
                                  </p>
                                  <img src={calendarIcon} alt="calander_icon" />
                                </div>
                              </div>
                              {/* To date */}
                              <div className="custom_date_input_wrapper">
                                <p className="custom_date_input_title">{Strings.to}</p>
                                {/* Select to date */}
                                <div className="custom_date_input_container" >
                                  <input className="input_date_hide"
                                    id="from_date_type"
                                    type="date"
                                    onChange={this.setCustomEndDate(setFieldValue)}  
                                    value={moment(values.custom_end_date, 'DD-MM-yyyy').format('yyyy-MM-DD')}
                                    min={values.custom_start_date}
                                    data-test-id="custom-end-date"                                 
                                 
                                  />
                                  <p className="custom_date_input_text">
                                    {this.formatDateValue(values.custom_end_date)}
                                  </p>
                                  <img src={calendarIcon} alt="calander_icon" />
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion data-test-id="Wasteaccordian" square expanded={this.state.expandedList === 'panel3'} onChange={this.handleAcordionChange('panel3')}>
                      <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMoreIcon style={{ color: '#8D97A2' }} />}>
                        <p className={`client_accordian_header ${this.state.expandedList === 'panel3' && 'filter_accordian_active_header_color'}`}>{Strings.branch}</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="accordionDetails_wrapper">
                          {this.props.branchList?.map((branchObj: any, branchIndex: number) => {

                            return (

                              <div className="checkbbox_wrapper" key={branchIndex}>
                                <img
                                  data-test-id={`branch-checkbox-${branchIndex}`}
                                  src={values.branchesId.includes(branchObj[0]) ? checkIcon : uncheckIcon}
                                  alt="checkIcon"
                                  className="checkbox_img"
                                  onClick={this.setBranchId(branchObj[0], values, setFieldValue)}
                                />
                                <p className="checkbox_title">{branchObj[1]}</p>
                              </div>
                            )

                          })}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div className="client_filter_bottom_wraper">

                    <button data-test-id="cancle-btn"
                      className="client_filter_bottom_cancel_btn"
                      onClick={this.cancelFilterBtn}>
                      {Strings.cancel}
                    </button>
                    <button className="client_filter_bottom_apply_btn"
                      type="submit">
                      {Strings.apply}
                    </button>

                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </React.Fragment>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const Accordion = withStyles({
  root: {
    borderBottom: '1px solid rgba(141, 151, 162, 0.4)',
    boxShadow: 'none',
    // '&:not(:last-child)': {
    //   borderBottom: 0,
    // },
    '&:before': {
      display: 'none',
    },
    '&$expandedList': {
      margin: 'auto',
    },
  },
  expandedList: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    padding: '0px 7px',
    marginBottom: -1,
    minHeight: 54,
    '&$expandedList': {
      minHeight: 36,
    },
  },
  content: {
    '&$expandedList': {
      margin: '14px 0',
    },
  },
  expandedList: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails);


// Customizable Area End
