import React from "react";
import { BlockComponent } from "./../../../framework/src/BlockComponent";
import * as Yup from 'yup';
import moment from "moment";
import { SearchQueryType } from "./ReportClientController.web";
export const webConfigJSON = require("./config.js");

let initialFormValues = {
  branches: [],
  categories: [],
  sort_by: "",
  custom_start_date: moment().subtract(1, 'days').format('DD-MM-yyyy'),
  custom_end_date: moment().format('DD-MM-yyyy'),
}
export interface Props {
  testId: string
  navigation: any;
  searchQueryState: SearchQueryType,
  setSearchQueryState: (arg: any, callApi: boolean) => void,
  showFilterModal: () => void,
  branchList: any[],
  categoryList: any[]
}

export interface SP {
  title: string,
  value: string
}

interface SS { }
interface S {
  expanded: string | boolean
  initialValues: SearchQueryType
}

export default class CustomFilterController extends BlockComponent<Props, S, SS> {

  formRef: any
  formSchema: any

  constructor(props: Props) {
    super(props);
    this.state = {
      expanded: 'panel2',
      initialValues: initialFormValues
    };

    this.formSchema = Yup.object().shape({
      custom_start_date: Yup
        .string()
        .when('pickUpDate', {
          is: 'custom_date',
          then: Yup.string().required(),
          otherwise: Yup.string(),
        }),
      categoryId: Yup.array().of(Yup.string()),
      branchId: Yup.array().of(Yup.number()),
      custom_end_date: Yup
        .string()
        .when('pickUpDate', {
          is: 'custom_date',
          then: Yup.string().required(),
          otherwise: Yup.string(),
        }),
      pickUpDate: Yup.string(),
    })

    this.formRef = React.createRef();
    this.receive = this.receive.bind(this);
  }


  async componentDidMount(): Promise<void> {
    this.setFormValue()
  }

  handleAcordionChange = (panel: string) => (event: any, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel : false })
  };


  setPickValue = (value: string, formValues: SearchQueryType, setFieldValue: any) => () => {
    if (value === formValues.sort_by) {
      setFieldValue('sort_by', '');
    } else {
      setFieldValue('sort_by', value);
    }
  }

  setCustomEndDate = (setFieldValue: any) => (e: any) => {
    setFieldValue('custom_end_date', moment(e.target.value).format('DD-MM-yyyy'))
  }

  clearFormValue = (resetForm: any) => () => {
    resetForm({
      values: { ...initialFormValues },
      isSubmitting: false
    })
    this.handleSubmit(initialFormValues)
  }

  cancelFilterBtn = () => {
    this.props.showFilterModal()
  }

  setCustomStartDate = (formValues: SearchQueryType, setFieldValue: any) => (e: any) => {
    let startDate = moment(e.target.value)
    let endDate = formValues.custom_end_date
    if (startDate.diff(moment(endDate, 'DD-MM-yyyy'), 'days') > 0) {
      setFieldValue('custom_end_date', startDate.add(1, 'days').format('DD-MM-yyyy'))
    }
    setFieldValue('custom_start_date', moment(e.target.value).format('DD-MM-yyyy'))
  }

  setFormValue = () => {
    let { searchQueryState } = this.props;
    this.setState({
      initialValues: {
        branches: searchQueryState.branches,
        categories: searchQueryState.categories,
        sort_by: searchQueryState.sort_by,
        custom_start_date: searchQueryState.custom_start_date || this.state.initialValues.custom_start_date,
        custom_end_date: searchQueryState.custom_end_date || this.state.initialValues.custom_end_date,
      }
    })
  }


  setCategoryName = (category: string, formValues: SearchQueryType, setFieldValue: any) => () => {
    let categoriesValue = formValues.categories
    if (categoriesValue.includes(category)) {
      const index = categoriesValue.indexOf(category);
      categoriesValue.splice(index, 1);
      setFieldValue('categories', [...categoriesValue])
    } else {
      setFieldValue('categories', [...categoriesValue, category])
    }
  }

  setBranchId = (id: number, formValues: SearchQueryType, setFieldValue: any) => () => {
    let branchValue = formValues.branches
    if (branchValue.includes(id)) {
      const index = branchValue.indexOf(id);
      branchValue.splice(index, 1);
      setFieldValue('branches', [...branchValue])
    } else {
      setFieldValue('branches', [...branchValue, id])
    }
  }

  handleSubmit = (value: SearchQueryType) => {
    this.props.setSearchQueryState(
      {
        ...this.props.searchQueryState,
        custom_start_date: value.custom_start_date,
        branches: value.branches,
        sort_by: value.sort_by,
        custom_end_date: value.custom_end_date,
        categories: value.categories,
      }, true)
    this.props.showFilterModal()
  }

  formatDateValue = (date: string) => {
    if(date === "Invalid date") {
      return "DD/MM/YYYY";
    }
    const [day, month, year] = date.split("-")
    return `${day}/${month}/${year}`;
  }

  getPlaceholderStyle = (date: string) => {
    if(date === "Invalid date") {
      return {
        color: "#8D97A2"
      }
    }
    return {};
  }

}
