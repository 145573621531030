export const userProfileImg = require("../assets/user-profile.png");
export const camera = require("../assets/camera.svg");
export const userPic = require("../assets/userAvtar.png");
export const eyeSlash = require("../assets/eye-slash.svg")
export const ticked = require("../assets/tick-circle.png");
export const unticked = require("../assets/unticked.png");
export const eye = require("../assets/visible.png");
export const userAlter = require("../assets/userAlter.svg");


export const constantPasswordValidation = [
    "Your password must contain 8 characters",
    "Your password must contain one special character",
    "Your password must contain one numeric digit",
    "Your password must contain one uppercase and lowercase character",
  ];



