import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from 'react'
import { runEngine } from "../../../framework/src/RunEngine";
import { arrow, clock, crossIcon, dot, emptyNotification, frame, right } from './assets'
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import Strings from "./Strings";

const navStyle = { 
  marginLeft : {
    left : '-10rem'
  },
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    anchorEl: HTMLElement | null;
    open:boolean;
    userRole : string,
    path:string,
    userObj:any,
    showPopover : boolean;
    notificationList : any[];
    selectedNotificationType : string;
    payload : any
    unreadCount : number;
    loaded : boolean;
    markReadOnly : boolean
    currentLanguage:'english'|'arabic';
    languageOpen:boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class NavbarController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
      getDataCallId : string = '';
      markNotificationCallId : string = '';
      getTokenId : string = '';
      setLanguageOptionsAPIId:any;
      getClientDetailsId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            anchorEl: null,
            open:false,
            userRole : '',
            path:'',
            userObj:{},
            showPopover : false,
            notificationList : [],
            selectedNotificationType : "",
            unreadCount : 0,
            payload : {},
            loaded : false,
            markReadOnly : false,
            currentLanguage:'english',
            languageOpen:false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End

    }
    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.setTokenToSessionStorage();
        let language:any = localStorage.getItem('language');
        if(language){
        this.setState({currentLanguage: language});
        }
        else{
          localStorage.setItem('language','english')
          this.setState({currentLanguage : 'english'})
        }
        if(this.isViewAsClient()) {
          this.getClientAccountDetails();
          this.fetchNotifications();
        }
        else {
          this.getUserDetail();   
          this.fetchNotifications();
          this.getToken();
        }
      }

      setTokenToSessionStorage() {
        const data = localStorage.getItem("clientDetails");
        if(data) {
          sessionStorage.setItem("clientDetails", data);
        }
      }

      toggle(type : string)
      {
        if(type == 'open')
        {
          this.fetchNotifications();
            this.setState({showPopover : true})
        }
        else{
            this.setState({
                showPopover : false
            })
        }
      }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

   if (
      this.getDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
     if(successMessage && successMessage.data)
     {
      this.setState({
        notificationList : successMessage.data, unreadCount : successMessage.meta.unread_notification_count,
        loaded : true
      })
     }
    }
    else if (
      this.markNotificationCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      if(successMessage && successMessage.data)
      {
    let payload = this.state.payload ? this.state.payload : null;
    this.setState({unreadCount : successMessage.meta.unread_notification_count})
    this.state.markReadOnly ? this.setState({markReadOnly : false}) : this.navigateTo(this.state.selectedNotificationType,payload);
      }
    }
    if( this.getTokenId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))&& successMessage)
      {
          if(successMessage.errors)
          {
            localStorage.clear();
            this.screenNavigate('EmailAccountLoginBlock')
          }
          else{
            this.setUserDetail(successMessage.meta)
            this.fetchNotifications();
          }
          
      }
    if(
      this.getClientDetailsId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage)) &&
      successMessage
    ) {
      const userObject = this.getClientUserObject(successMessage.meta);
      this.setState({
        userRole: userObject.account_role,
        userObj : {...userObject},
        path:window.location.pathname.split('/')[1]
      });
    }

      if (
        this.setLanguageOptionsAPIId !== null &&
        this.setLanguageOptionsAPIId === dataMessage
      ) {
        this.handleAPIResponse(
          successMessage,
          errorMessage,
          this.setLanguageSuccessCallback
        );
      }

  }

  setLanguageOption=async (option:string)=>{
    if(option!==this.state.currentLanguage){
      localStorage.setItem("language",option)
      let token =localStorage.getItem('token')
      let language=option==='english'?"English":"Arabic"
      let data={
        language
      }
      const header = {
        "Content-Type": configJSON.apiContentType,
        token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.setLanguageOptionsAPIId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.setLanguageEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      )

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.markAsReadMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage); 
    }
  }

  handleAPIResponse(
    successMessage: any,
    errorMessage: any,
    callback: Function
  ) {
    if (errorMessage) {
      console.log("error", errorMessage);
    } else if (successMessage) {
      if (!successMessage.errors) {
        callback(successMessage);
      }
    }
  }
  setLanguageSuccessCallback = (successMessage: any) => {
    window.location.reload()
  };

  

  notificationClick(event : any)
  {
    this.setState({selectedNotificationType : event.attributes.notification_type , payload : event.attributes.payload})
    if(!event.attributes.is_read)
    {
      event.attributes.is_read = true;
    this.markNotificationAsRead(event.id)
    return;
    }
    this.navigateTo(event.attributes.notification_type,event.attributes.payload)
  }

   
  screenNavigate(screen : string)
  {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screen);  // name of the screen where we want to navigate
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { email:"Hello" });  // from here we can passing params
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }


  navigateTo(notificationType : string,payload? : any)
  {
    switch(notificationType)
    {
      case "new_pick_up_request":
        // this.props.navigation.navigate('PickupRequest')
        this.screenNavigate('PickupRequest');
        break;

      case "new_waste_bill_created":
        // this.props.navigation.navigate('ReportClient')
        this.screenNavigate('ReportClient');
        break;

      case "contract_due_end_month_admin" :
        // this.props.navigation.navigate('ClientListing')
       this.screenNavigate('ClientListing')
        break;

      case "new_file_uploaded_for_client":
        // this.props.navigation.navigate('ClientAccountDetail')
            this.screenNavigate('ClientAccountDetail')
        break;

      case "due_payment_reminder":
       this.screenNavigate('ClientListing')
        break;

      case "contract_due_end_client":
        this.screenNavigate('ClientAccountDetail')
        break;

      case "contract_due_end_week_admin" :
        this.screenNavigate('ClientListing')
        break;
    }
  }

      

    handleClick = (event: any) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };


     handleClickOpen = () => {
        this.setState({open:true});
      };
       handleCloseDilog = () => {
        this.setState({open:false});

      };

       
  agoTime(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " " + Strings.years;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " " + Strings.months;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " " + Strings.day;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " " + Strings.hour;
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " " + Strings.minute;
    }
    return Math.floor(seconds) + " " + Strings.seconds;
  }

      getRowBottom(item:any,index : number)
      {
        const row =     <div className='row-bottom'>
          <div onClick={() => this.markNotificationAsRead(item.id,'markRead')} data-testid={`mark-read`}>
        <img src={right}></img>
      <span style={{marginLeft : '0.44rem'}} > {Strings.markAsRead} </span>
      </div>
      </div>

      return item.attributes.is_read ? null : row;
      }

      getDot()
      {
        const ele =    <img src={dot} className="notification-dot"></img>
        return this.state.unreadCount > 0 ? ele : null
      }

      getNotificationList()
      {
        return <div style={this.state.userRole == 'Client' ? navStyle.marginLeft : {}}>
        <div className='popover-header'>
          <div className='popover-heading-text'>
          {Strings.notification} ({this.state.unreadCount})
          </div>
          <div className='popover-close' data-testid="close-icon" onClick={() => this.toggle('close')}>
            <img src={crossIcon}></img>
          </div>
          </div>
          <div className='not-list-container'>
            {this.state.notificationList.map((item,index) => (

            
          <div className='not-row' data-testid={`notif-${index}`} style={item.attributes.is_read ? {} : {background : '#EBEDF1'}} onClick={()=> {this.notificationClick(item)}}>
            <div className='time-section'>
              <img src={clock}></img>
              <span className='time-dot' style={{display:'flex'}}>
              <p className='time-text' style={{fontFamily : '"Barlow Semi Condensed",sans-serif'}}>
              {this.agoTime(item.attributes.created_at)} {Strings.ago}
              </p>
              {item.attributes.is_read ? null : <img src={dot}></img>}
              </span>
            </div>
            <div className='not-content-sub-heading' style={{marginBottom : '1rem'}}>{item.attributes.contents}</div>
        {this.getRowBottom(item,index)}
          </div>
            ))
      }
     
          <div className='not-list-bottom'>
            <div data-testid="view-btn" style={{display:'flex', alignItems: 'center'}} onClick={() => this.navigate()}>
          {Strings.viewAll}
          <img src={arrow} style={{marginLeft : '1rem'}}></img>
          </div>
          </div>
        </div>
        </div>
      }

      getScreenForUI()
      {
        if(this.state.notificationList.length > 0)
        {
          return this.getNotificationList()
        }
        else{
          return this.getEmptyImageScreen()
        }
      }

      getUserProfileIamge(){
        if(this.state.userObj?.profile_picture ){
          return <img src={this.state.userObj?.profile_picture} id="login-image" style={{ width: '100%', height: '100%' }} alt="profile" />
        }else{
          return <img src={frame} id="login-image" alt="defaultProfile" style={{ filter: 'brightness(0) invert(1)', width: '24px', height: '24px' }} />
        }
        }
      

      getEmptyImageScreen()
      {
        return <div  style={this.state.userRole == 'Client' ? navStyle.marginLeft : {}}>
        <div className='popover-header'>
          <div className='popover-heading-text'>
          {Strings.notification} ({this.state.unreadCount})
          </div>
          <div className='popover-close' data-testid="close-icon" onClick={() => this.toggle('close')}>
            <img src={crossIcon}></img>
          </div>
          </div>
          <div className='not-list-container' style={{alignItems : 'center'}}>
            <img src={emptyNotification} style= {{height : '9rem', width : '11.14288rem'}} />
            <p className="not-image-text" style={{marginTop : '0.75rem', marginBottom : '0.75rem'}}>
      {Strings.weWillInformYouWhenThereIsANotification}
      </p>
          </div>
          <div className='not-list-bottom'>
            <div data-testid="view-btn" style={{display:'flex', alignItems: 'center'}} onClick={() => this.navigate()}>
          {Strings.viewAll}
          <img src={arrow} style={{marginLeft : '1rem'}}></img>
          </div>
          </div>
          </div>
      }


  getNotifications(token: any) {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}?limit=3`
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  fetchNotifications() {
    let token;
    const detail: any = localStorage.getItem("userDetail");
    const user: any = JSON.parse(detail);
    const path = this.props.navigation.history.location.pathname.slice(1);
    if(
      (user.account_role === "Super Admin" || user.account_role === "Admin") &&
      configJSON.viewAsClientRoutes.includes(path)
    ) {
      const data: any = sessionStorage.getItem("clientDetails");
      const clientDetails = JSON.parse(data);
      token = clientDetails.token;
    } else {
      token = localStorage.getItem("token");
    }
    this.getNotifications(token);
  }


  markNotificationAsRead(id: number,markRead? : string) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if(markRead)
    {
      this.setState({
        markReadOnly : true
      })
    }

    let token = localStorage.getItem('token')

    this.markNotificationCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

    // Customizable Area End


    // Customizable Area Start
  handleLogout=()=>{
    this.handleCloseDilog();
    let language = localStorage.getItem('language')
    localStorage.clear();
    if(language)
    {
    localStorage.setItem('language', language)
    this.props.navigation.navigate("EmailAccountLoginBlock"); 
    }   
     return true
  }
    // Customizable Area End


    // Customizable Area Start
    navigateAccount = ()=>{
      if (this.state.userRole === "Client") {
        this.props.navigation.navigate('EducationalUserProfileClient');
      } else {
        this.props.navigation.navigate('EducationalUserProfile');
      }
    }

    getUserDetail = ()=>{
        let detail:any = localStorage.getItem('userDetail')
        try{
          let userDetailObj = JSON.parse(detail)
          if(userDetailObj.token && userDetailObj.account_role){
                this.setState({ userRole:userDetailObj.account_role, userObj : userDetailObj,path:window.location.pathname.split('/')[1]})
            }
        }catch(e){
        }
    }

    navigate()
    {
        this.setState({showPopover : false})
        if(this.state.userRole === "Client") {
          this.screenNavigate('NotificationsClient');
        } else {
          this.screenNavigate('Notifications');
        }
    }

  setUserDetail(userObject : any)
  {
    this.setState({ userRole:userObject.account_role, userObj : userObject,path:window.location.pathname.split('/')[1]})

  }
  getToken() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getTokenId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTokenEndpoint
    );

    let token = localStorage.getItem('token');

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }

  getClientAccountDetails = () => {
    const clientDetails: any = sessionStorage.getItem("clientDetails");
    const token = JSON.parse(clientDetails).token;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClientDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTokenEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  isViewAsClient = () => {
    const detail: any = localStorage.getItem("userDetail");
    const path = this.props.navigation.history.location.pathname.slice(1);
    const clientDetails = sessionStorage.getItem("clientDetails");
    return !!clientDetails &&
      configJSON.viewAsClientRoutes.includes(path) &&
      (JSON.parse(detail).account_role === "Super Admin" || JSON.parse(detail).account_role === "Admin") 
  }

  getTokenForNavbar = () => {
    const clientDetails: any = sessionStorage.getItem("clientDetails");
    const token = localStorage.getItem("token");
    return (JSON.parse(clientDetails) || {}).token || token;
  }

  getClientUserObject = (response: any) => {
    const clientObj = {
      account: {...response.account},
      profile_picture: response.profile_picture,
      account_role: response.account_role
    };
    return clientObj;
  }


  showNavigationMenu = ()=>{
    let element = document.getElementById("toogle");
    element?.classList.toggle("hide-tab-element");
  }

  onLanguageToggle=()=>{
    this.setState({languageOpen:!this.state.languageOpen})
  }

  onLanguageClose=()=>{
    this.setState({languageOpen:false})
  }

    // Customizable Area End













}
