import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  emailValue: string;
  phoneValue: string;
  savedEmailValue: string;
  token: any;
  emailValidate: boolean;
  error: boolean | null;
  propmtMessage: string;
  showTimer: boolean;
  timerRemaining: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
export interface ForgotPasswordProps {
  navigation: any;
}
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  // isChangePassword: boolean;
  forgotPasswordId: any;

  //Properties from config

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    this.state = {
      emailValue: "",
      phoneValue: "",
      savedEmailValue: "",
      token: "",
      emailValidate: true,
      error: null,
      showTimer: false,
      propmtMessage: "",
      timerRemaining: 30,
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();

    if (window.location.href.includes("?")) {
      let error: string = window.location.href.split("?")[1];

      if (error.length > 0) {
        let errorMessage = error.slice(
          error.indexOf("=") + 1,
          error.lastIndexOf("&")
        );
        let email = error.slice(error.lastIndexOf("=") + 1);

        errorMessage = errorMessage.replace(/\%20/g, " ");

        if (errorMessage.length > 0) {
          this.setState({
            error: false,
            propmtMessage: errorMessage,
            savedEmailValue: email,
            emailValue: email,
          });
        }
      }
    }
  }

  async receive(from: string, message: Message) {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (
      this.forgotPasswordId !== null &&
      this.forgotPasswordId === dataMessage
    ) {
      this.handleAPIResponse(
        successMessage,
        errorMessage,
        this.forgotSuccessCallback
      );
    }
  }

  goToLogin = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  // Customizable Area Start

  emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  onChangeText = (e: any) => {
    const { value } = e.target;
    this.setState({ emailValue: value });
    this.validateEmail(value);
  };

  validateEmail: (text: string) => void = (text) => {
    this.setState({ emailValidate: !this.emailRegex.test(text) });
  };

  onResetPress = (email = this.state.emailValue) => {
    if (
      this.state.emailValue !== this.state.savedEmailValue ||
      this.state.timerRemaining === 0
    ) {
      let language = localStorage.getItem("language");
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType,
        locale: language === "arabic" ? "ar" : "en",
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.forgotPasswordId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.forgotPasswordApiEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      const data = {
        email,
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleAPIResponse(
    successMessage: any,
    errorMessage: any,
    callback: Function
  ) {
    if (errorMessage) {
      console.log("error", errorMessage);
    } else if (successMessage) {
      if (successMessage.errors) {
        this.setState({
          error: false,
          timerRemaining: -1,
          savedEmailValue: "",
          propmtMessage: successMessage.errors[0]?.account,
        });
      } else {
        callback(successMessage);
      }
    }
  }
  forgotSuccessCallback = (successMessage: any) => {
    this.setState({
      timerRemaining: 30,
      savedEmailValue: this.state.emailValue,
      error: true,
      propmtMessage: successMessage?.message,
    });
  };

  setTimeRemaining = () => {
    if (this.state.timerRemaining > 0) {
      this.setState({ timerRemaining: this.state.timerRemaining - 1 });
    }
  };

  // Customizable Area End
}
