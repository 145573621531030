import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import Strings from './Strings'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  data: any[];
  selectedData: any;
  token: any;
  notificationList : any[],
  markedIds : any[],
  allSelected : boolean,
  selectedNotificationType : string,
  payload : any;
  loaded : boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  markReadCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage), 
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      data: [],
      selectedData: null,

  notificationList : [],
      token: "",
      allSelected : false,
      markedIds : [],
      selectedNotificationType : '',
      payload : {},
      loaded : false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getNotifications();
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const successMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
   if (
      this.getDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
     if(successMessage && successMessage.data)
     {
      this.setState({
        notificationList : successMessage.data
      , loaded : true})
     }
     else if (
      this.markReadCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      if(successMessage && successMessage.message)
      {
        this.markReadCallId = "";
      }
    }
    } else if (
      this.markAsReadCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      if(successMessage && successMessage.data)
      {
    let payload = this.state.payload ? this.state.payload : null;
    this.navigate(this.state.selectedNotificationType,payload);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  
  adapterNavigate(screen : string)
  {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screen);  // name of the screen where we want to navigate
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { email:"Hello" });  // from here we can passing params
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  itemClick(event : any)
  {
    this.setState({selectedNotificationType : event.attributes.notification_type , payload : event.attributes.payload})
    if(!event.attributes.is_read)
    {
      event.attributes.is_read = true;
    this.markAsRead(event.id)
    return;
    }
    this.navigate(event.attributes.notification_type,event.attributes.payload)
  }

  navigate(type : string,payload? : any)
  {
    switch(type)
    {
      case "new_pick_up_request":
        // this.props.navigation.navigate('PickupRequest')
        this.adapterNavigate('PickupRequest');
        break;

      case "new_waste_bill_created":
        // this.props.navigation.navigate('ReportClient')
        this.adapterNavigate('ReportClient');
        break;

      case "contract_due_end_month_admin" :
        // this.props.navigation.navigate('ClientListing')
       this.adapterNavigate('ClientListing')
        break;

      case "new_file_uploaded_for_client":
        // this.props.navigation.navigate('ClientAccountDetail')
            this.adapterNavigate('ClientAccountDetail')
        break;

      case "due_payment_reminder":
       this.adapterNavigate('ClientListing')
        break;

      case "contract_due_end_client":
        this.adapterNavigate('ClientAccountDetail')
        break;

      case "contract_due_end_week_admin" :
        this.adapterNavigate('ClientListing')
        break;
    }
  }


  checkBoxHandle(event : any, item : any)
  {
    let obList = this.state.notificationList;
    for(let i=0; i < obList.length; i++)
    {
      if(obList[i].id == item.id)
      {
          obList[i].isSelected = event.target.checked;
      }
    }
    this.setState({notificationList : obList})
  }

  markRead()
  {
    let ids : any = [];
    let filteredList = this.state.notificationList.filter(x => x.isSelected)
    if(filteredList.length > 0)
    {
    filteredList.map(item => { 
      let {id} = item;
      item.isSelected = false;
      item.attributes.is_read = true
      ids.push(id)
    })
    this.setState({markedIds : ids}, () => { 
      this.markAllRead()
    })
  }
  }

  globalCheckbox(event : any)
  {
    this.setState({allSelected : event.target.checked})
    let notificationList = this.state.notificationList;
    notificationList.map((item : any) => { 
      item.isSelected = event.target.checked
    })

    this.setState({
      notificationList : notificationList
    })
  }

  iconBellProps = {
    source: imgBell,
  };

  getNotifications() {
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getDataCallId = getDataMsg.messageId;

    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPoint
    );

    let token = this.getTokenForNotifications();

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  }


  markAllRead() {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const body = {
      ids : this.state.markedIds
    }

    this.markReadCallId = markAsReadMsg.messageId;
    let token = localStorage.getItem('token');

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.markAllReadEndPoint}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let token = localStorage.getItem('token')

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: number) {
    console.log(id);
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
  }
  
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + "" + Strings.years;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + Strings.months;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + Strings.day;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " " + Strings.hour;
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " " + Strings.minutes;
    }
    return Math.floor(seconds) + " " + Strings.seconds;
  }
  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  getTokenForNotifications = () => {
    const data: any = sessionStorage.getItem("clientDetails");
    const path = this.props.navigation.history.location.pathname.slice(1);

    if(data && path === "NotificationsClient") {
      const clientDetails: any = JSON.parse(data);
      return clientDetails.token;
    } else {
      return localStorage.getItem("token");
    }
  }

  handleBeforeUnload = () => {
    localStorage.removeItem("clientDetails");
  }

  componentWillUnmount(): any {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }
  // Customizable Area End
}
