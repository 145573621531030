import LocalizedStrings from "react-localization";
const configJSON = require("./config");

const Str = new LocalizedStrings({
  english: configJSON.english,
  arabic: configJSON.arabic,
});

let curr = localStorage.getItem("language");
if (curr) {
  Str.setLanguage(curr);
} else {
  Str.setLanguage("english");
}

export default Str;
