import React from "react";

//Customizable Area Start
import { resetSuccessfulStrings } from "./assets";
import ResetPasswordController, { Props } from "./ResetPasswordController";
import CustomForgotResetPassword from "./CustomForgotResetPassword.web";
import  './ForgotPassword.css'
//Customizable Area End

export default class ResetSuccessful extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {

    return (
      <div className="forgotPasswordcontainer">
        <CustomForgotResetPassword
          data-testid={'customForgotReset'}
          subHeaderText={resetSuccessfulStrings.subHeaderText}
          buttonText={resetSuccessfulStrings.buttonText}
          subHeaderImg={resetSuccessfulStrings.subHeaderImg}
          onSubmitButtonPress={this.onBackToLogin}
          showPrompt={null}
          subHeaderCustomStyle="reset-successful-subheader-container"
          subHeaderTextCustonStyle="reset-successful-subheader-text"
          customContainerStyle="custom-reset-successful-container"
        />
      </div>
    );
  }
}
