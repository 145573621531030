import React from "react";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Navbar from "../../settings2/src/Navbar.web";
import WasteBillController, {
  Props
} from "./WasteBillController.web";
  // Customizable Area Start

  import {trashIcon,searchIcon,filterIcon,sortIcon, plusIcon } from './assets'
  import {
  
    Button,
    Box,
    Tabs,
    Tab,
    ClickAwayListener
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';

import './WasteBill.css';

import { Alert, TabContext, TabPanel } from "@material-ui/lab";
import WasteTable from "./WasteTable.web";
import WasteFilter from "./WasteFilter.web";
import WasteListSort from "./WasteListSort.web";
import Strings from "./Strings";

    // Customizable Area End

export default class WasteBill extends WasteBillController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    window.scrollTo(0,0);
    return (
  
      <div id="element" className="main-wrapper">
        <NavigationMenu navigation={this.props.navigation} id={""} />
        <div className="dashboard_container_wrapper">
          <div className="dashboard_navbar_container">
            <Navbar navigation={this.props.navigation} id={""} />
          </div>
          {this.state.showNoSelectionError && window.innerWidth<=414 && (
                                    <Alert severity="error" icon={false} 
                                    style={{
                                        width: "90%",
                                        justifyContent:'center',
                                        borderRadius:'12px',
                                        fontFamily:'Barlow Semi Condensed',
                                        color:'#DC2626',
                                        fontSize:'16px',
                                        fontWeight:400,
                                        backgroundColor:'#FEDCE0'
                                    }}>{Strings.noSelections}</Alert> )}  
          <div className="waste-top-container">
            <div className="waste-header" style={{alignItems : 'baseline'}}>
              <p className="header-title">
               {Strings.wastebill}
              </p>

              <div style={{ display: 'flex', justifyContent: 'space-between',width: '28.125rem', alignItems : 'baseline'}}>
                                {this.state.showNoSelectionError && window.innerWidth>414 && (
                                    <Alert severity="error" icon={false} 
                                    style={{
                                        width: 'inherit',
                                        justifyContent:'center',
                                        borderRadius:'12px',
                                        fontFamily:'Barlow Semi Condensed',
                                        color:'#DC2626',
                                        fontSize:'16px',
                                        fontWeight:400,
                                        backgroundColor:'#FEDCE0'
                                    }}>{Strings.noSelections}</Alert> )}                                
                            </div>
              <div data-test-id="AddNewWasteBill" style={webStyle.addNew}  className="header_add_client_wrapper"  onClick={() => this.navigateTo('AddNewWasteBill')}>
                                <img className="add_client_icon" src={plusIcon} alt="add_icon" />
                                <p className="add_client_text">{Strings.addNew}</p>
                            </div>
              {/* <Button style={webStyle.addNew} data-test-id="AddNewWasteBill" onClick={() => this.navigateTo('AddNewWasteBill')}><AddIcon/> &nbsp;   {'Add New'} </Button> */}
            </div>
            <Box mt={5}>
              <div className="listing_body_container_waste">
                
                <div className='clientlisitning-sub-header-container'>

                  <Tabs value={this.state.wasteIndex} data-test-id="Wastelistingtabs"
                   onChange={this.handleTabChange} 
                   classes={{ root: 'wastebill_listing_tabs_root', indicator: 'listing_tabs_indicator', scroller: 'listing_tabs_scroller' }}>
                    <Tab data-test-id="tabs-detail" label={Strings.impact} classes={{ root: 'listing_tab_root', selected: 'listing_tab_selected' }} />
                  </Tabs>

                  <div className="wastbill-listing-subheader-right-container">
                    {/* delete */}
                    <button className="wastebill-listing-delete-btn" onClick={() =>this.onDeleteAll()}> <img src={trashIcon} alt="delete icon"  /><p>{Strings.deleteAll}</p></button>
                    {/* search */}
                    {
                     <div className="search_container">
                      <img src={searchIcon} alt="searcIcon"  onClick={this.showSearchBar}/>
                      <input type="text" placeholder={Strings.searchClient} onChange={this.handleChangeSearch} value={this.state.searchQueryState.searchText}/>
                    </div>
                    }
                    {/* filter icon */}
                    <div className="wastebill_listing_filter_container">
                      <button className="wastebill_listing_filter_button" data-test-id="filter_button" 
                      onClick={this.showFilterModal}
                      >
                        <img src={filterIcon} alt="filterIcon" />
                        <p>{Strings.filters}</p>
                      </button>
                      {this.state.showFilterModal &&
                       <ClickAwayListener onClickAway={this.handleClickAwayFilter}>
                        <div className="filter_modal_container">
                          <WasteFilter navigation={this.props.navigation} 
                            searchQueryState={this.state.searchQueryState}
                            setSearchQueryState={this.setSearchQueryState}
                            showFilterModal={this.showFilterModal}
                            companyList={this.state.companyList}
                            branchList={this.state.branchList}
                           />
                        </div>
                        </ClickAwayListener>
                      }
                    </div>
                    <div className="wastebill_listing_sort_container">
                      <button className="wastebill_listing_sort_button" onClick={this.showSortModal}>
                        <img src={sortIcon} alt="sortIcon" />
                        <p>{Strings.sort}</p>
                      </button>
                      {this.state.showSortModal &&
                        <ClickAwayListener onClickAway={this.handleClickAwaySort}>
                          <div className="wastebill_sort_modal_container">
                            <WasteListSort                           
                              querySearchState={this.state.searchQueryState}
                              setSearchQueryState={this.setSearchQueryState} 
                              navigation={this.props.navigation}                            />
                          </div>
                        </ClickAwayListener>
                      }
                    </div>
                  </div>
                </div>
                {this.state.showSearchBar&&<div className="alternate-search-container alternate-searchbar">
                  <img src={searchIcon} alt="searcIcon" />
                  <input type="search" placeholder={Strings.searchClient} onChange={this.handleChangeSearch} value={this.state.searchQueryState.searchText}/>
                </div>}
                <TabContext value={this.state.tabIndexString}>
                  <TabPanel value={'0'} className='dashboard_table_wrapper' classes={{ root: 'listing_tabpanel_root wastebill_tabpanel_root' }}>
                    <WasteTable
                    datatestId="wasteTable"
                      navigation={this.props.navigation}
                      loader={this.state.apiloader}
                      clientListigData={this.state.billdata}
                      searchQueryState={this.state.searchQueryState}
                      setSearchQueryState={this.setSearchQueryState}
                      onDelete={this.onDelete} OnSelectItems={this.OnSelectItems}
                      uncheckIcon={this.state.selecetItem}
                      onHeaderCheck={this.setUncheckIcon}
                      handleDeletePopupOpen={this.handleDeletePopupOpen}
                      deleteModalPopup={this.state.deleteModalPopup}
                      handleClosePopup={this.handleClosePopup}
                      />
                     
                  </TabPanel>
                </TabContext>
              </div>
            </Box>
            
          </div>
        </div>
      </div>
      
    );
    // Customizable Area End
  }
}

// Customizable Area Start


const webStyle = {
  addNew:{
    minWidth: "120px",
    height: "40px",
    padding: "10px, 16px",
    borderRadius: '8px',
    backgroundColor: '#32569A',
    color:'#ffff',
    fontFamily: "Barlow Semi Condensed",
  },
  delall:{
    width: "120px",
    height: "40px",
    padding: "10px, 16px",
    borderRadius: '8px',
    backgroundColor: 'red',
    color:'#ffff',
    fontFamily: "Barlow Semi Condensed",
  },
  categories_text: {
    fontFamily: 'Barlow Semi Condensed',
    fontSize: '24px',
    fontWeight: 700,
    color: '#1C3045'
},
}

// Customizable Area End
