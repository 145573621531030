Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.apiContentType = "application/json"
exports.markAllReadEndPoint = "notifications/mark_all_read"
exports.endPoint = "/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.getTokenEndpoint="account_block/verify_token";
exports.setLanguageEndpoint = "language_options/languages/set_user_language";

exports.englishLanguage={
  weAreHappyToHelpManageYourWaste:"We’re happy to help manage your waste.",
  welcomeBack:"Welcome Back",
  english:"English",
  arabic:"Arabic",
  account:"Account",
  signOut:"Sign out",
  areYouSureYouWantToLogOut:"Are you sure you want to logout?",
  yourUnsavedDataWillBeLost:"Your unsaved data will be lost!",
  cancel:"Cancel",
  logout:"Logout",
  minute:"min",
  hour:"hours",
  notification:"Notification",
  markAsRead:"Mark as Read",
  ago:'ago',
  day:"days",
  months:"months",
  years:"years",
  seconds:"seconds",
  viewAll:"View all",
  weWillInformYouWhenThereIsANotification:"We will inform you when there is a notification!"
}

exports.arabicLanguage={
  weAreHappyToHelpManageYourWaste:"سعداء بمساعدتك على إدارة النفايات الخاصة بك.",
  welcomeBack:"أهلًا بعودتك",
  arabic:"العربية",
  english:"الإنجليزية",
  account:"الحساب",
  signOut:"تسجيل خروج",
  areYouSureYouWantToLogOut:"هل أنت متأكد من تسجيل الخروج",
  yourUnsavedDataWillBeLost:"ستفقد البيانات الغير محفوظة",
  logout:"تسجيل خروج",
  cancel:"إلغاء",
  minute:"دقيقة",
  notification:"الإشعارات",
  markAsRead:"مقروء",
  ago:"قبل",
  months:"ساعات",
  hour:"ساعة",
  day:"أيام",
  years:"سنين",
  seconds:"ثواني",
  viewAll:"الكل",
  weWillInformYouWhenThereIsANotification:"سنبلغك عند وجود أي إشعارات"
}

exports.viewAsClientRoutes = [
  "DashboardClient",
  "ReportClient",
  "ClientAccountDetail",
  "PickupClientListing",
  "ContactusClient",
  "EducationalUserProfileClient",
  "ClientAccountDetail",
  "NotificationsClient"
];
// Customizable Area End