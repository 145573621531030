// Customizable Area Start
export const History = require("../assets/History.png");
export const Close = require("../assets/Close.png");
export const location = require("../assets/location.png");
export const imgLocation = require("../assets/location.png");
export const imgLogo = require("../assets/logo.png");
export const imgsplash = require("../assets/splash.png");
export const imgambarsar = require("../assets/ambarsar.png");
export const imgsurat = require("../assets/surat.png");
export const Maplogo = require("../assets/Map.png");
export const Plus = require("../assets/Plus.png");
export const startpin = require("../assets/Start_pin.png");
export const destinationPin = require("../assets/destination_pin.png");
export const tickCircle = require("../assets/tick_circle.svg");
export const trash = require("../assets/trash.svg");
export const filter = require("../assets/filter.png");
export const sort = require("../assets/sort.png");
export const checkIcon = require("../assets/check_icon.svg");
export const uncheckIcon = require("../assets/uncheck_icon.svg");
export const checkRadioIcon = require("../assets/checkRadioIcon.svg");
export const uncheckRadioIcon = require("../assets/uncheckRadioIcon.svg");
export const arrowRightIcon = require("../assets/arrow-right.png");
export const caretLeft = require("../assets/caretLeft.png");
export const caretRight = require("../assets/caretRight.png");
export const moreIcon = require("../assets/more_vert.svg");
export const checkbox = require("../assets/untick.png");
export const minusIcon = require("../assets/minus.png");
export const crossIcon = require("../assets/fi-sr-cross.svg");
export const calendar = require("../assets/calendar.svg");
export const close = require("../assets/close.svg");
export const noResult = require("../assets/no_result.svg");


// Customizable Area End
