import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { createRequest, handleResponseDataObject } from "./requestHelper";
import Strings from './Strings'
export enum TimeChoice {
  YEAR,
  MONTH,
  CONTRACT_YEAR
}
export const listLabelMonth = ['Jan', 'Feb', 'Mar', 'Apr', 'May', "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export const listValueImpact = [
  "tree_saved",
  "kilo_watt_of_energy_saved",
  "co2_eq_reduction",
  "landfill_space_saved",
  "water_saved",
  "reduction_petroleum_use"
]

export const listDropdown = [
  Strings.treeSaved,
  Strings.co2EqReduction,
  Strings.waterSaved,
  Strings.energySaved,
  Strings.landfillSpaceSaved,
  Strings.reducedPetroleunUse,
]
export interface SQS {
  page: number,
  per_page: number,
  total_page: number
  total_count: number,
  activePaginationStep: number,
  date: string,
  citys: string[],
  custom_start_date: string,
  custom_end_date: string,
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  listServices: Array<any>
  listServicesMain: Array<any>
  chosenTimeCollection: TimeChoice
  chosenTimeEnvironment: TimeChoice
  listDataEnvironment: Array<number>
  listLabelEnvironment: Array<string>
  maxValueBarEnvironment: number
  listDataCollectionMonth: Array<number>
  listDataCollectionYear: Array<number>
  maxValueBarCollectionYear: number
  maxValueBarCollectionMonth: number
  listLabelCollectionMonth: Array<string>
  listLabelCollectionYear: Array<string>
  isOpen: boolean
  isOpenViewMore: boolean
  typeIndex: number
  totalWasteNet: number
  totalClient: number
  totalNewClient: number
  totalNewClientPercentage: number
  searchQueryState: SQS
  cityList: Array<any>
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTotalNetWasteApi: string = "";
  getRecycleCollectionMonthlyApi: string = "";
  getRecycleCollectionYearlyApi: string = "";
  getTotalClientApi: string = ""
  getRecycleEnvironmentApi: string = ""
  getServiceMaintainceApi: string = ""
  getcityListApi: string = ""
  postDeleteAllApi: string = ""
  getServiceMaintainceRecallApi: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      listServicesMain: [],
      listServices: [],
      chosenTimeCollection: TimeChoice.YEAR,
      chosenTimeEnvironment: TimeChoice.YEAR,
      listDataEnvironment: [],
      listLabelEnvironment: [],
      maxValueBarEnvironment: 0,
      listDataCollectionMonth: [],
      listDataCollectionYear: [],
      listLabelCollectionMonth: [],
      listLabelCollectionYear: [],
      maxValueBarCollectionMonth: 0,
      maxValueBarCollectionYear: 0,
      totalWasteNet: 0,
      totalClient: 0,
      totalNewClient: 0,
      totalNewClientPercentage: 0,
      isOpen: false,
      isOpenViewMore: false,
      typeIndex: 0,
      searchQueryState: {
        page: 1,
        per_page: 10,
        total_page: 1,
        total_count: 0,
        activePaginationStep: 1,
        date: '',
        citys: [],
        custom_start_date: "",
        custom_end_date: '',
      },
      cityList: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    this.getTotalNetWaste()
    this.getTotalClient()
    this.getRecycleCollectionMonthly()
    this.getRecycleCollectionYearly()
    this.getRecyclingEnvironment()
    this.getServiceMaintaince(false)
    this.getCityList()
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      // if (responseJson && responseJson.errors) {
      // }

      if (responseJson && !responseJson.errors) {
        let dataList: any
        switch (apiRequestCallId) {
          case this.getTotalNetWasteApi:
            this.setState({ totalWasteNet: responseJson.total_recyclable_net_weight })
            break;
          case this.getRecycleCollectionMonthlyApi:
            dataList = handleResponseDataObject({ inputData: responseJson.recycling_collection, timeChoice: TimeChoice.MONTH, isLineChart: true })
            this.setState({
              listDataCollectionMonth: dataList.data,
              listLabelCollectionMonth: dataList.label,
              maxValueBarCollectionMonth: Math.max(dataList),
            })
            break;
          case this.getRecycleCollectionYearlyApi:
            dataList = handleResponseDataObject({ inputData: responseJson.recycling_collection, timeChoice: TimeChoice.YEAR, isLineChart: false })
            this.setState({
              listDataCollectionYear: dataList.data,
              listLabelCollectionYear: dataList.label,
              maxValueBarCollectionYear: Math.max(dataList),
            })
            break;
          case this.getTotalClientApi:
            this.setState({
              totalClient: responseJson.total_account_details?.total_account_count ?? 0,
              totalNewClient: responseJson.total_account_details?.user_increase_last_month ?? 0,
              totalNewClientPercentage: responseJson.total_account_details?.percentage_increase_last_month ?? 0,
            })
            break;
          case this.getRecycleEnvironmentApi:
            dataList = handleResponseDataObject({ inputData: responseJson, timeChoice: this.state.chosenTimeEnvironment, isLineChart: false })
            this.setState({
              listDataEnvironment: dataList.data,
              listLabelEnvironment: dataList.label,
              maxValueBarEnvironment: Math.max(dataList),
            })
            break;
          case this.getServiceMaintainceApi:
            this.setState({ listServices: responseJson.data, listServicesMain: responseJson.data.length > 4 ? responseJson.data.slice(0, 4) : responseJson.data })
            break
          case this.getServiceMaintainceRecallApi:
            this.setState({listServicesMain: responseJson.data.length > 4 ? responseJson.data.slice(0, 4) : responseJson.data, listServices: responseJson.data })
            break
          case this.getcityListApi:
            this.setState({ cityList: responseJson.data })
            break
          case this.postDeleteAllApi:
            this.getServiceMaintaince(true)
            break
        }
      }
    }


    // Customizable Area End
  }
  // Customizable Area Start
  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  selectOption = (value: number) => {
    this.setState({
      typeIndex: value,
      isOpen: false,
    });
  };

  showViewMore() {
    this.setState({ isOpenViewMore: true, })
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
  }

  setListingServices=(list:any)=>{
    this.setState({listServices:[...list]})
  }

  hideViewMore() {
    this.setState({ isOpenViewMore: false });
    document.body.style.overflow = 'unset';
  }

  async getCityList() {
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getcityListApi = request.messageId;
    let token = await localStorage.getItem("token");

    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: webConfigJSON.dashboardGetcityListUrl
    });
  }

  async getTotalNetWaste() {
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getTotalNetWasteApi = request.messageId;
    let token = await localStorage.getItem("token");

    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: webConfigJSON.dashboardGetTotalRecycleWasteUrl
    });
  }

  async getRecycleCollectionMonthly() {
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getRecycleCollectionMonthlyApi = request.messageId;
    let token = await localStorage.getItem("token");

    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: `${webConfigJSON.dashboardGetRecycleCollectionUrl}?recycling_collection_filter=monthly`
    });
  }

  async getRecycleCollectionYearly() {
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getRecycleCollectionYearlyApi = request.messageId;
    let token = await localStorage.getItem("token");

    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: `${webConfigJSON.dashboardGetRecycleCollectionUrl}?recycling_collection_filter=yearly`
    });
  }

  async getTotalClient() {
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getTotalClientApi = request.messageId;
    let token = await localStorage.getItem("token");

    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: `${webConfigJSON.dashboardGetTotalClientUrl}`
    });
  }

  async getRecyclingEnvironment() {
    const timeChoice = this.state.chosenTimeEnvironment === TimeChoice.YEAR ? "yearly" : "monthly"
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getRecycleEnvironmentApi = request.messageId;
    let token = await localStorage.getItem("token");

    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: `${webConfigJSON.dashboardGetRecycleEnvironmentUrl}?recycling_environmental_impact_filter=${timeChoice}&key=${listValueImpact[this.state.typeIndex]}`
    });
  }

  async postDeleteAll(listChosenId: Array<string>) {
    let token = await localStorage.getItem("token");
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.postDeleteAllApi = request.messageId;

    createRequest({
      token,
      requestMsg: request,
      method: "POST",
      endPoint: `${webConfigJSON.dashboardDeleteUrl}`,
      header: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: {
          ids: [...listChosenId]
        }
      })
    });
  }

  async getServiceMaintaince(recall: boolean) {
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    if (recall) {
      this.getServiceMaintainceRecallApi = request.messageId;
    } else {
      this.getServiceMaintainceApi = request.messageId;
    }
    let token = await localStorage.getItem("token");

    let { searchQueryState } = this.state
    let endPoint: string = `${webConfigJSON.dashboardGetServiceMaintainceUrl}?page=${searchQueryState.page}&per_page=${searchQueryState.per_page}`
    if (searchQueryState.citys.length > 0) {
      for (const iterator of searchQueryState.citys) {
        endPoint += `&filter[city][]=${iterator}`
      }
    }
    if (searchQueryState.date !== "") {
      if (searchQueryState.date === "last_month") {
        endPoint += `&filter[time_period][name]=last_month`
      }
      if (searchQueryState.date === "this_month") {
        endPoint += `&filter[time_period][name]=this_month`
      }
      if (searchQueryState.date === "custom_date") {
        endPoint += `&filter[time_period][name]=custom`
        endPoint += `&filter[time_period][from]=${this.handleDate(searchQueryState.custom_start_date)}`
        endPoint += `&filter[time_period][to]=${this.handleDate(searchQueryState.custom_end_date)}`
      }
    }
    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: endPoint
    });
  }

  setSearchQueryState = (queryState: SQS, callApi: boolean) => {
    this.setState({ searchQueryState: { ...queryState } }, () => {
      if (callApi) {
        this.getServiceMaintaince(true)
      }
    })
  }

  handleDate(date: string) {
    const newDate = date.split("-").reverse().join("-");
    return newDate
  }

  componentDidUpdate(prevProps: any, prevState: S) {
    if (prevState.typeIndex !== this.state.typeIndex || prevState.chosenTimeEnvironment !== this.state.chosenTimeEnvironment) {
      this.getRecyclingEnvironment()
    }
  }
  closeDropDown=()=>{
    this.setState({isOpen:false})
  }
  // Customizable Area End
}
