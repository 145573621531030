import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  headerText?: string;
  subHeaderText?: string;
  buttonText: string;
  promptMessage?:string;
  onSubmitButtonPress?: () => void;
  inputArray?: any;
  showPrompt?: boolean | null;
  validateField?: boolean;
  belowSubmitButtonUI?: any;
  subHeaderImg?: any;
  showBackButton?:boolean;
  onBackButtonPress?:()=>void;
  subHeaderCustomStyle?:string;
  subHeaderTextCustonStyle?:string;
  customContainerStyle?:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
export interface ForgotPasswordProps {
  navigation: any;
}
// Customizable Area End

export default class CustomForgotResetController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // isChangePassword: boolean

  //Properties from config
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    }
  
}
