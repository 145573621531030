import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { emailRegex, nameRegex, passwordRegex } from "./assets";

// Customizable Area Start

export type Selected = "Client Details" | "Contract" | "Report" | "Send Invite";

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  setShowSuccessModal: (arg: boolean) => void;
  setError: (arg: any) => void;
  scrollToTop: () => void;
  editId?: number;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  first_name: string;
  last_name: string;
  emailAddress: string;
  create_password: string;
  confirm_password: string;
  first_name_validate: boolean | null;
  last_name_validate: boolean | null;
  email_validate: boolean | null;
  create_password_validate: boolean | null;
  confirm_password_validate: boolean | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SendInviteController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  sendInviteId: any;
  getSendInviteData: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      token: "",
      first_name: "",
      last_name: "",
      emailAddress: "",
      create_password: "",
      confirm_password: "",
      first_name_validate: null,
      last_name_validate: null,
      email_validate: null,
      create_password_validate: null,
      confirm_password_validate: null,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (this.sendInviteId !== null && this.sendInviteId === dataMessage) {
      this.handleClientAPIResponse(
        successMessage,
        errorMessage,
        this.clientSuccessCallback
      );
    } else if (
      this.getSendInviteData !== null &&
      this.getSendInviteData === dataMessage
    ) {
      this.handleClientAPIResponse(
        successMessage,
        errorMessage,
        this.storeSendInviteData
      );
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let token = await localStorage.getItem("token");
    if (token) {
      this.setState({ token });
      if (this.props.editId && this.props.editId !== -1) {
        this.getUserDetails();
      }
    }
  }

  getUserDetails = (editId = this.props.editId) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSendInviteData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountCreationUpdate}${editId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onChangeInputField = (event: any) => {
    const { name, value } = event.target;
    let newValue = { ...this.state, [name]: value };
    this.setState({ ...newValue });
    this.validateInput(name, value);
  };

  validateInput = (name: string, value: string) => {
    switch (name) {
      case "first_name":
        this.setState({ first_name_validate: !nameRegex.test(value) });
        return;
      case "last_name":
        this.setState({ last_name_validate: !nameRegex.test(value) });
        return;
      case "emailAddress":
        this.setState({ email_validate: !emailRegex.test(value) });
        return;
      case "create_password":
        this.setState({
          create_password_validate: !passwordRegex.test(value),
          confirm_password_validate:
            this.state.confirm_password === ""
              ? null
              : value != this.state.confirm_password,
        });
        return;
      case "confirm_password":
        this.setState({
          confirm_password_validate:
            value === "" ? null : value != this.state.create_password,
        });
        return;
    }
  };

  handleDiscardChanges = () => {
    let initialState = {
      first_name: "",
      last_name: "",
      emailAddress: "",
      create_password: "",
      confirm_password: "",
      first_name_validate: null,
      last_name_validate: null,
      email_validate: null,
      create_password_validate: null,
      confirm_password_validate: null,
    };
    this.setState({ ...initialState });
  };

  handlleSaveChange = async () => {
    const {
      first_name,
      last_name,
      emailAddress,
      create_password,
      confirm_password,
      first_name_validate,
      last_name_validate,
      email_validate,
      create_password_validate,
      confirm_password_validate,
    } = this.state;
    let tempState = {
      email_validate: email_validate === null ? true : email_validate,
      first_name_validate:
        first_name_validate === null ? true : first_name_validate,
      last_name_validate:
        last_name_validate === null ? true : last_name_validate,
      create_password_validate:
        create_password_validate === null ? true : create_password_validate,
      confirm_password_validate:
        confirm_password_validate === null ? true : confirm_password_validate,
    };
    this.state = { ...this.state, ...tempState };

    let fieldValidate = false;
    let passwordValidate = false,
    confirmPasswordValidate = false;
    if (this.props.editId === -1) {
      fieldValidate =
        !this.state.first_name_validate &&
        !this.state.last_name_validate &&
        !this.state.email_validate &&
        !this.state.create_password_validate &&
        !this.state.confirm_password_validate;
    } else {
      if (
        this.state.create_password !== "" ||
        this.state.confirm_password !== ""
      ) {
        passwordValidate = Boolean(this.state.create_password_validate);
        confirmPasswordValidate = Boolean(this.state.confirm_password_validate);
      }
      fieldValidate =
        !this.state.first_name_validate &&
        !this.state.last_name_validate &&
        !this.state.email_validate &&
        !passwordValidate &&
        !confirmPasswordValidate;
    }

    // when there is no validation error
    if (fieldValidate) {
      let data={}
      if(this.props.editId===-1 || !passwordValidate || !confirmPasswordValidate){
      data = {
        account: {
          step: "Step4",
          email: this.state.emailAddress,
          password: this.state.create_password,
          password_confirmation: this.state.confirm_password,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          activation_email_sent: true,
        },
      };
    }
    else{
      data = {
        account:{
          step: "Step4",
          email:this.state.emailAddress,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          activation_email_sent:false
        }
      }
    }
      let token = await localStorage.getItem("token");

      if (token) {
        const header = {
          "Content-Type": configJSON.contentTypeApiUpdateUser,
          token,
        };

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.sendInviteId = requestMessage.messageId;
        let id;
        if (this.props.editId === -1) {
          id = await localStorage.getItem("clientAccountId");
        } else {
          id = this.props.editId;
        }

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.accountCreationUpdate}${id}`
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiUpdateUserType
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(data)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
      } else {
        this.props.navigation.navigate("EmailAccountLoginBlock");
      }
    } else if (
      first_name.length === 0 ||
      last_name.length === 0 ||
      emailAddress.length === 0 ||
      create_password.length === 0 ||
      confirm_password.length === 0
    ) {
      this.props.setError({
        status: true,
        message: "Client details not filled!",
      });
    } else {
      this.props.setError({
        status: true,
        message: "Incorrect Details",
      });
    }
  };

  handleClientAPIResponse(
    successMessage: any,
    errorMessage: any,
    callback: Function
  ) {
    if (errorMessage) {
    } else if (successMessage) {
      if (successMessage.errors) {
        this.props.setError({
          status: true,
          message: successMessage.errors[0],
        });
      } else {
        callback(successMessage);
      }
    }
  }

  clientSuccessCallback = (successMessage: any) => {
    this.props?.scrollToTop();
    this.props.setShowSuccessModal(true);
    this.props.setError({ status: null, message: "" });
    setTimeout(() => {
      this.props.navigation.navigate("ClientListing");
    }, 2000);
  };

  storeSendInviteData = (successMessage: any) => {
    const { email, first_name, last_name } = successMessage.data.attributes;
    this.setState({
      emailAddress: email,
      first_name,
      last_name,
      email_validate: false,
      first_name_validate: false,
      last_name_validate: false,
    });
  };
  // Customizable Area End
}
