import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RefObject, createRef } from "react";
import moment from "moment";
import Strings from "./Strings";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
interface Filters {
  time: any[];
  branches: any[];
  city: any[];
  selectedTime: any;
  selectedCity: any;
  selectedBranch: any;
}

interface Error {
  status: boolean | null;
  message: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  token: string;
  showFilterModal: boolean;
  search: string;
  error: Error;
  sortList: any[];
  selectedSort: any;
  showSortModal: boolean;
  filters: Filters;
  selectedFilterBranch: any;
  selectedFilterTime: any;
  selectedFilterCity: any;
  showFilterType: "Time Period" | "Branches" | "City" | "";
  page: number;
  currentPageCount: number;
  pageBatch: number;
  totalPages: number;
  totalItems: number;
  listData: any[];
  menuIndex: number;
  showDeleteModal: boolean;
  deletingId: any;
  showReasonPopup: boolean;
  reason: string;
  showSearchBar:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class PickupClientListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  scrollRef: RefObject<HTMLDivElement>;
  sortModalRef: RefObject<HTMLDivElement>;
  sortButtonRef: RefObject<HTMLButtonElement>;
  filterModalRef: RefObject<HTMLDivElement>;
  filterButtonRef: RefObject<HTMLButtonElement>;
  debounceTimer: any;
  actionButtonRef: RefObject<HTMLImageElement>;
  actionModalRef: RefObject<HTMLDivElement>;
  getListDataApiId: any;
  getBranchesAPIId: any;
  getCitiesAPIId: any;
  deletePickupApiId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      showFilterModal: false,
      error: { status: null, message: "" },
      search: "",
      menuIndex: -1,
      sortList: [
        { desc: "pick_up_date_desc", before: Strings.newest, after: Strings.oldest },
        { desc: "pick_up_date_asc", before: Strings.oldest, after: Strings.newest },
        { desc: "branch_name_asc", before: Strings.ascendingA, after: Strings.z },
        { desc: "branch_name_desc", before: Strings.descendingZ, after: Strings.a },
      ],
      selectedSort: {
        desc: "pick_up_date_desc",
        before: Strings.newest,
        after: Strings.oldest,
      },
      showSortModal: false,
      showSearchBar:false,
      filters: {
        branches: [],
        time: [
          {
            id: "last_7_days",
            attributes: { name: Strings.last7Days },
          },
          {
            id: "last_30_days",
            attributes: { name: Strings.last30Days },
          },
          {
            id: "current_contract_period",
            attributes: { name: Strings.currentContractPeriod },
          },
        ],
        city: [],
        selectedTime: {
          id: "",
          type: "time_period",
          attributes: { name: "All" },
        },
        selectedBranch: [],
        selectedCity: [],
      },
      selectedFilterBranch: [],
      selectedFilterTime: {
        id: "",
        type: "time_period",
        attributes: { title: "", name: "", from: "", to: "" },
      },
      selectedFilterCity: [],
      showFilterType: "Time Period",
      page: 1,
      currentPageCount: 1,
      pageBatch: 1,
      totalPages: 1,
      totalItems: 0,
      listData: [],
      showDeleteModal: false,
      deletingId: "",
      showReasonPopup: false,
      reason: "",
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.debounceTimer = "";
    this.scrollRef = createRef();
    this.sortModalRef = createRef();
    this.sortButtonRef = createRef();
    this.filterModalRef = createRef();
    this.filterButtonRef = createRef();
    this.actionButtonRef = createRef();
    this.actionModalRef = createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (
      this.getListDataApiId !== null &&
      this.getListDataApiId === dataMessage
    ) {
      this.handleApiResponse(
        successMessage,
        errorMessage,
        this.getListingCallback
      );
    } else if (
      this.getBranchesAPIId !== null &&
      this.getBranchesAPIId === dataMessage
    ) {
      this.handleApiResponse(
        successMessage,
        errorMessage,
        this.getClientBranchesCallback
      );
    } else if (
      this.getCitiesAPIId !== null &&
      this.getCitiesAPIId === dataMessage
    ) {
      this.handleApiResponse(
        successMessage,
        errorMessage,
        this.getClientCitiesCallback
      );
    } else if (
      this.deletePickupApiId !== null &&
      this.deletePickupApiId === dataMessage
    ) {
      this.handleApiResponse(
        successMessage,
        errorMessage,
        this.deletePickupRequestCallback
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let token = await this.getTokenForPickupRequestList();
    if (token) {
      this.setState({ token });
      document.addEventListener("mousedown", this.handleClickOutside);
      this.getListing(token);
      this.getInitialBranches(token);
      this.getCities(token);
    }
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  getTokenForPickupRequestList = async () => {
    const clientDetails: any = await sessionStorage.getItem("clientDetails");
    const token = await localStorage.getItem("token");
    return (JSON.parse(clientDetails) || {}).token || token;
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (
      prevState.page !== this.state.page ||
      JSON.stringify(prevState.selectedFilterBranch) !==
        JSON.stringify(this.state.selectedFilterBranch) ||
      JSON.stringify(prevState.selectedFilterCity) !==
        JSON.stringify(this.state.selectedFilterCity) ||
      JSON.stringify(prevState.selectedFilterTime) !==
        JSON.stringify(this.state.selectedFilterTime) ||
      JSON.stringify(prevState.selectedSort) !==
        JSON.stringify(this.state.selectedSort)
    ) {
      this.getListing(this.state.token);
    }
  }

  componentWillUnmount(): any {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  navigateTo = (screen: string, params: any = {}) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screen); // name of the screen where we want to navigate
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), params); // from here we can passing params
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  handleClickOutside = (event: any) => {
    if (
      this.filterModalRef.current &&
      !this.filterModalRef.current.contains(event.target) &&
      this.filterButtonRef.current &&
      !this.filterButtonRef.current.contains(event.target)
    ) {
      this.setState({ showFilterModal: false });
    }
    if (
      this.sortButtonRef.current &&
      !this.sortButtonRef.current.contains(event.target) &&
      this.sortModalRef.current &&
      !this.sortModalRef.current.contains(event.target)
    ) {
      this.setState({ showSortModal: false });
    }
    if (
      this.actionButtonRef.current &&
      !this.actionButtonRef.current.contains(event.target) &&
      this.actionModalRef.current &&
      !this.actionModalRef.current.contains(event.target)
    ) {
      this.setState({ menuIndex: -1 });
    }
  };

  getListing = (token: string) => {
    let filters = "";
    this.state.selectedFilterBranch.map((item: any) => {
      filters =
        filters +
        `${
          item.id !== ""
            ? `${filters === "" ? "&" : ""}filter[branch_id][]=${item.id}`
            : ""
        }`;
    });

    this.state.selectedFilterCity.map((item: any) => {
      filters =
        filters +
        `${
          item.city !== ""
            ? `${filters === "" ? "&" : ""}filter[city][]=${item.city}`
            : ""
        }`;
    });

    let timeFilter = "";
    if (this.state.selectedFilterTime.id) {
      timeFilter =
        this.state.selectedFilterTime.id === "custom"
          ? `&filter[time_period][name]=${this.state.selectedFilterTime.id}&filter[time_period][from]=${this.state.selectedFilterTime.attributes.from}&filter[time_period][to]=${this.state.selectedFilterTime.attributes.to}`
          : `&filter[time_period][name]=${this.state.selectedFilterTime.id}`;
    }

    let finalURL = `${configJSON.pickupRequestListingClient}?page=${this.state.page}&per_page=10&search_term=${this.state.search}&sort=${this.state.selectedSort.desc}${filters}${timeFilter}`;

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getListDataApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      finalURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getInitialBranches = (token: string) => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBranchesAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBranchNames
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCities = (token: string) => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCitiesAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCityNames
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  formatdate = (date: string) => {
    let formated = moment(date).format("DD/MM/YYYY");
    return formated.toString();
  };

  formateListDate(date: string) {
    let formated = moment(date).format("DD MMM YYYY");
    return formated.toString();
  }

  onSeeMoreClick = (reason: string) => {
    this.scrollRef.current?.scrollIntoView();
    this.setState({
      reason,
      showReasonPopup: true,
    });
  };

  onSeeMoreCloseClick = () => {
    this.setState({
      reason: "",
      showReasonPopup: false,
    });
  };

  onFilterDateChange = (event: any) => {
    const { name, value } = event.target;

    let formattedDate = moment(value).isValid() ? this.formatdate(value) : "";
    let temp: S = JSON.parse(JSON.stringify(this.state));
    temp.filters.selectedTime = {
      ...temp.filters.selectedTime,
      attributes: {
        ...temp.filters.selectedTime.attributes,
        [name]: formattedDate,
      },
    };
    this.setState(JSON.parse(JSON.stringify(temp)));
  };

  onDeleteModalOpen = (id: number = -1, status: string) => {
    this.scrollRef.current?.scrollIntoView();
    if (status === Strings.pending || status === Strings.rejected) {
      if (id !== -1) {
        this.setState({ showDeleteModal: true, deletingId: id, menuIndex: -1 });
      }
    } else {
      this.setState({
        error: {
          status: true,
          message: Strings.pleaseContactNaqaaSolutions,
        },
        menuIndex: -1,
      });
    }
  };

  onModalCloseClick = () => {
    this.setState({ showDeleteModal: false, deletingId: "", menuIndex: -1 });
  };

  onModalDeleteClick = () => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePickupApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pickupRequestListingClient}/${this.state.deletingId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onPageClick = (item: number) => {
    if (this.state.page !== item) {
      this.setState({ page: item, menuIndex: -1 });
    }
  };

  onPreviousPageClick = () => {
    if (this.state.pageBatch !== 1)
      this.setState({ pageBatch: this.state.pageBatch - 1, menuIndex: -1 });
  };

  onNextPageClick = () => {
    if (this.state.pageBatch < Math.ceil(this.state.totalPages / 5))
      this.setState({ pageBatch: this.state.pageBatch + 1, menuIndex: -1 });
  };

  onNewPickupClick = () => {
    this.navigateTo("AddPickupRequestClient");
  };

  onChangeSearch = (e: any) => {
    const { value } = e.target;
    this.setState({ search: value });

    // Clear the previous debounce timer
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    // Set a new debounce timer
    this.debounceTimer = setTimeout(() => {
      this.getListing(this.state.token);
    }, 1000);
  };

  onFilterRoleClick = (filterType: "Time Period" | "Branches" | "City") => {
    if (filterType !== this.state.showFilterType) {
      this.setState({ showFilterType: filterType });
    } else {
      this.setState({ showFilterType: "" });
    }
  };

  onFilterClick = () => {
    this.setState({
      showFilterModal: !this.state.showFilterModal,
      showSortModal: false,
      menuIndex: -1,
    });
  };

  onCancelFilterClick = () => {
    this.setState({
      filters: {
        ...this.state.filters,
        selectedBranch: this.state.selectedFilterBranch,
        selectedTime: this.state.selectedFilterTime,
        selectedCity: this.state.selectedFilterCity,
      },
      showFilterModal: false,
    });
  };

  onFilterClearClick = () => {
    this.setState({
      selectedFilterBranch:[],
      selectedFilterTime:{ id: "", type: "role", attributes: { name: "All" } },
      selectedFilterCity:[],
      showFilterModal:false,
      filters: {
        ...this.state.filters,
        selectedBranch: [],
        selectedTime: { id: "", type: "role", attributes: { name: "All" } },
        selectedCity: [],
      },
    });
  };

  onApplyFilterClick = () => {
    this.setState({
      selectedFilterBranch: this.state.filters.selectedBranch,
      selectedFilterTime: this.state.filters.selectedTime,
      selectedFilterCity: this.state.filters.selectedCity,
      page: 1,
      pageBatch: 1,
      showFilterModal: false,
    });
  };

  onFilterRoleItemClick = (item: any) => {
    let index = this.state.filters.selectedBranch.findIndex(
      (element: any) => JSON.stringify(item) === JSON.stringify(element)
    );
    if (index === -1) {
      this.setState({
        filters: {
          ...this.state.filters,
          selectedBranch: [...this.state.filters.selectedBranch, item],
        },
      });
    } else {
      let temp: S = JSON.parse(JSON.stringify(this.state));
      temp.filters.selectedBranch.splice(index, 1);
      this.setState(JSON.parse(JSON.stringify(temp)));
    }
  };

  onFilterCityItemClick = (item: any) => {
    let index = this.state.filters.selectedCity.findIndex(
      (element: any) => JSON.stringify(item) === JSON.stringify(element)
    );
    if (index === -1) {
      this.setState({
        filters: {
          ...this.state.filters,
          selectedCity: [...this.state.filters.selectedCity, item],
        },
      });
    } else {
      let temp: S = JSON.parse(JSON.stringify(this.state));
      temp.filters.selectedCity.splice(index, 1);
      this.setState(JSON.parse(JSON.stringify(temp)));
    }
  };

  onFilterTimeItemClick = (item: any) => {
    if (
      JSON.stringify(this.state.filters.selectedBranch) !== JSON.stringify(item)
    ) {
      this.setState({
        filters: {
          ...this.state.filters,
          selectedTime: item,
        },
      });
    }
  };

  onSortItemClick = (item: any) => {
    if (JSON.stringify(item) !== JSON.stringify(this.state.selectedSort)) {
      this.setState({
        selectedSort: item,
        page: 1,
        showSortModal: false,
        pageBatch: 1,
        menuIndex: -1,
      });
    }
  };
  onSortClick = () => {
    this.setState({
      showFilterModal: false,
      showSortModal: !this.state.showSortModal,
      filters: {
        ...this.state.filters,
        selectedBranch: this.state.selectedFilterBranch,
        selectedTime: this.state.selectedFilterTime,
        selectedCity: this.state.selectedFilterCity,
      },
      menuIndex: -1,
    });
  };

  onEditPickupRequest = (id: number, status: string) => {
    if (status === "Pending" || status === "قيد الإنتظار") {
      if (id !== -1) {
        this.props.navigation.history.push("AddPickupRequestClient", { id });
      }
    } else {
      this.setState({
        error: {
          status: true,
          message: Strings.pleaseContactNaqaaSolutions,
        },
        menuIndex: -1,
      });
    }
  };

  onActionClick = (index: number) => {
    if (this.state.menuIndex !== index) {
      this.setState({ menuIndex: index });
    } else {
      this.setState({ menuIndex: -1 });
    }
  };

  handleApiResponse = (successMsg: any, errorMsg: any, callback: Function) => {
    if (errorMsg) {
      console.log("error---------", errorMsg);
    } else if (successMsg) {
      if (successMsg.errors) {
        if (successMsg.errors[0].token) {
          this.navigateTo("EmailAccountLoginBlock");
        } else {
          this.setState({
            error: { status: false, message: successMsg.errors[0] },
          });
        }
      } else {
        callback(successMsg);
      }
    }
  };

  getListingCallback = (successMessage: any) => {
    if (successMessage.data) {
      this.setState({
        listData: successMessage.data,
        totalItems: successMessage.meta.pagy.count,
        totalPages: successMessage.meta.pagy.pages,
        currentPageCount:
          (this.state.page - 1) * 10 + successMessage.meta.pagy.in,
      });
    }
  };

  getClientBranchesCallback = (successMessage: any) => {
    if (successMessage.data) {
      this.setState({
        filters: {
          ...this.state.filters,
          branches: successMessage.data,
        },
      });
    }
  };

  getClientCitiesCallback = (successMessage: any) => {
    if (successMessage.data) {
      this.setState({
        filters: {
          ...this.state.filters,
          city: successMessage.data,
        },
      });
    }
  };

  deletePickupRequestCallback = (successMessage: any) => {
    if (successMessage.message) {
      if(this.state.currentPageCount===1){
        this.setState({page:this.state.page-1})
      }else{
        this.getListing(this.state.token);
      }
      this.setState({
        deletingId: "",
        showDeleteModal: false,
        menuIndex: -1,
        page: 1,
        error: {
          status: false,
          message: successMessage.message,
        },
      });
    }
  };

  shouldDisableNewPickupRequestButton = () => {
    const clientDetails = sessionStorage.getItem("clientDetails");
    return !!clientDetails;
  }
  
  searchIconPress=()=>{
    this.setState({showSearchBar:!this.state.showSearchBar})
  }

  handleBeforeUnload = () => {
    localStorage.removeItem("clientDetails");
  }
  // Customizable Area End
}
