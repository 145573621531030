import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import { RefObject, createRef } from "react";
// Customizable Area Start
interface Error {
  status: boolean | null;
  message: string;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  branchNames: any[];
  branch_name: any;
  pickupdate: string;
  comment: string;
  error: Error;
  showSuccessModal: boolean;
  editId: number;
  // Customizable Area End
}
interface SS {}

export default class AddPickupRequestClientController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getBranchAPIId: any;
  createPickupRequestApiId: any;
  getPickupDetailsAPIId: any;
  scrollRef: RefObject<HTMLDivElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      branchNames: [],
      token: "",
      branch_name: { id: "", attributes: { name: "", address: "" } },
      comment: "",
      pickupdate: "",
      error: { status: null, message: "" },
      showSuccessModal: false,
      editId: -1,
    };
    this.scrollRef = createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    let token = await localStorage.getItem("token");
    if (token) {
      this.setState({ token });
      this.getBranches(token);
      let editId = this.props.navigation?.history.location?.state?.id;
      if (editId) {
        this.setState({ editId: parseInt(editId) });
        this.getPickupDetails(editId, token);
      }
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (this.getBranchAPIId !== null && this.getBranchAPIId === dataMessage) {
      this.handleAPIResponse(
        successMessage,
        errorMessage,
        this.getBranchesCallback
      );
    } else if (
      this.createPickupRequestApiId !== null &&
      this.createPickupRequestApiId === dataMessage
    ) {
      this.handleAPIResponse(
        successMessage,
        errorMessage,
        this.createPickupSuccessCallback
      );
    } else if (
      this.getPickupDetailsAPIId !== null &&
      this.getPickupDetailsAPIId === dataMessage
    ) {
      this.handleAPIResponse(
        successMessage,
        errorMessage,
        this.getPickupDetailsCallback
      );
    }
    // Customizable Area End
  }
  // Customizable Area Start

  onModalCrossPress = () => {
    this.setState({ showSuccessModal: false });
    this.props.navigation.navigate("PickupClientListing");
  };

  formateDate(date: string) {
    let formated = moment(date).format("DD/MM/YYYY");
    return formated.toString();
  }

  onChangeInput = (event: any) => {
    const { name, value } = event.target;

    if (name === "pickupdate") {
      this.setState({
        pickupdate: moment(event.target.value).isValid()
          ? this.formateDate(event.target.value)
          : "",
      });
    } else {
      let temp = { ...this.state, [name]: value };
      this.setState({ ...temp });
    }
  };

  setCurrentBranch = (name: string, value: any) => {
    this.setState({ branch_name: value });
  };

  getPickupDetails = (editId: any, token = this.state.token) => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPickupDetailsAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createPickupRequest}/${editId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBranches = (token: string) => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBranchAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBranchNames
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAPIResponse = (successMsg: any, errorMsg: any, callback: Function) => {
    if (errorMsg) {
      console.log("error---------", errorMsg);
    } else if (successMsg) {
      if (successMsg.errors) {
        if (successMsg.errors[0].token) {
          this.props.navigation.navigate("EmailAccountLoginBlock");
        } else {
          this.setState({
            error: { status: false, message: successMsg.errors[0] },
          });
        }
      } else {
        callback(successMsg);
      }
    }
  };

  getBranchesCallback = (successMessage: any) => {
    if (successMessage.data) {
      this.setState({ branchNames: successMessage.data });
    }
  };

  handleSubmit = () => {
    if (this.state.branch_name.id && this.state.pickupdate) {
      let data = {
        data: {
          branch_name_id: this.state.branch_name.id,
          pick_up_date: this.state.pickupdate,
          comment: this.state.comment,
        },
      };

      const header = {
        "Content-Type": configJSON.getUserListApiContentType,
        token: this.state.token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createPickupRequestApiId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.state.editId === -1
          ? configJSON.createPickupRequest
          : `${configJSON.createPickupRequest}/${this.state.editId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        this.state.editId === -1
          ? configJSON.addUserApiMethod
          : configJSON.editUserApiMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.scrollRef.current?.scrollIntoView();
      this.setState({
        error: { status: true, message: "Pickup details not filled" },
      });
    }
  };

  createPickupSuccessCallback = (successMessage: any) => {
    this.scrollRef.current?.scrollIntoView();
    this.setState({ showSuccessModal: true });
  };

  getPickupDetailsCallback = (successMessage: any) => {
    if (successMessage.data) {
      const { branch, comment, pick_up_date } = successMessage.data.attributes;
      this.setState({
        branch_name: branch,
        comment,
        pickupdate: moment(pick_up_date).isValid()
          ? this.formateDate(pick_up_date)
          : "",
      });
    }
  };
  // Customizable Area End
}
