// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.userName = "User name"
exports.location = "Location"
exports.url = "Location"
exports.educationList = "/profile/educational_qualifications"
exports.projectList = "/profile/projects"
exports.awardList = "/profile/awards"
exports.patentList = "/profile/publication_patents"

exports.educationalTitle = "Educational Qualification";
exports.projectLabel = "Project";
exports.awardsLabel = "Awards";
exports.publicationPatentLabel = "Publication Patents";
exports.showMore = "Show More";
exports.noProjectText = "No project...";
exports.noQualiText = "No qualifications";
exports.noAwards = "No Award...";
exports.noPublicationPatents = "No Publication Patents...";
exports.closeText = "Close";

exports.getEducationContentType = "application/json";
exports.updateContent = "multipart/form-data"
exports.getEducationApiMethod = "GET";
exports.updateMethod = "PUT"
exports.getEducationApiEndPoint = "profile/educational_qualifications";
exports.profileEndPoint = "profile"
exports.profileUpdateEndPoint = "profile/account"

exports.getProjectContextType = "application/json";
exports.getProjectApiMethod = "GET";
exports.getProjectApiEndPoint = "profile/projects";

exports.getAwardsContextType = "application/json";
exports.getAwardsApiMethod = "GET";
exports.getAwardsApiEndPoint = "profile/awards";

exports.getPatentsContextType = "application/json";
exports.getPatentsApiMethod = "GET";
exports.getPatentsApiEndPoint = "profile/publication_patents";
exports.paswordContains8Character= /.{8,}/;
exports.passwordContainsSpecialCharacter=/[!@#$%^&*()\-_+={}|\/;:'"<>,.?\[\]]/
exports.passwordContainsDigit= /\d/;
exports.passwordContainsUpperLowerCharacter= /^(?=.*[a-z])(?=.*[A-Z]).+$/

exports.englishLanguage = {
  accountHeading : 'Account',
  changePassHead : 'Change Password',
  editProfile : ' Edit Profile',
  personalInformationHeading : 'Personal Information',
  editPicture : 'Edit Picture',
  lastName : 'Last Name',
  firstName : 'First Name',
  role : 'Role',
  email : 'Email',
  currentPass : 'Current Password',
  newPass : 'New Password',
  confirmPass : 'Confirm Password',
  infoHeader : "Your password has been changed successfully!",
  save : 'Save',
  discard : 'Discard Changes',
  errorPass : 'Your password doesn’t match!',
  yourChangesHaveBeenSaved:"Your changes have been saved!",
  charcterLength : 'Your password must contain 8 characters',
  specialCharacter : 'Your password must contain one special character',
  numericDigit : 'Your password must contain one numeric digit',
  lowerAndUpperCaseCharacter : 'Your password must contain one uppercase and lowercase character',
  newPasswordCantBeOld:"Old password cannot be same as old password"
}


exports.arabicLanguage = {
  accountHeading : 'الحساب',
  changePassHead : 'تغيير كلمة المرور',
  editProfile : 'تعدّيل',
  newPasswordCantBeOld:"كلمة المرور القديمة لا يمكن أن تكون نفس كلمة المرور القديمة",
  personalInformationHeading : 'البيانات الشخصية',
  editPicture : 'تعدّيل الصورة',
  lastName : 'الاسم الأخير',
  firstName : 'الاسم الأول',
  yourChangesHaveBeenSaved:"تم حفظ التغييرات بنجاح",
  role : 'المنصب',
  email : 'البريد الإلكتروني',
  currentPass : 'كلمة السر الحالية',
  newPass : 'كلمة المرور لجديدة',
  confirmPass : 'تأكيد كلمة المرور',
  infoHeader : "تم تغيير كلمة المرور بنجاح",
  save : 'حفظ',
  discard : 'تجاهل التغييرات',
  errorPass : 'كلمة المرور غير متطابقة',
  charcterLength : 'يجب أن تتكون كلمة المرور من 8 خانات',
  specialCharacter : 'يجب أن تحتوي كلمة المرور على حرف خاص واحد',
  numericDigit : 'يجب أن تحتوي كلمة المرورعلى رقم واحد',
  lowerAndUpperCaseCharacter : 'يجب أن تحتوي كلمة السر على حرف كبير و حرف صغير'
}
// Customizable Area End