import LocalizedStrings from 'react-localization'
const configJSON = require("./config");

class StringLocalized {
    constructor() {
      this.initFunction();
    }
  
    strings = new LocalizedStrings({
      english: configJSON.englishLanguage,
      arabic: configJSON.arabicLanguage,
    });
  
    initFunction =  () => {
      try{
      let currLang = localStorage.getItem("language");
      if (currLang) {
        this.strings.setLanguage(currLang);
      } else {
        this.strings.setLanguage("english");
      }
    }
    catch(error){
      console.log('errors',error)
    }
    };
  }
  
  export default new StringLocalized().strings;