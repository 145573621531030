import React from 'react'
import "./activeClientTable.css"
import { Skeleton } from '@material-ui/lab'

function ClientTableSkeletonLoader() {
  return (
    <div data-testid={`skeleton-component`}>
    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((count) => {
      return (
        <div className="client_table_tr_container" key={count}  data-testid="detail-box" >
          <div className="client_table_header_field_container header_field_name_container" data-testid={`skeleton-animation-${count}`} >
            <Skeleton variant="rect" className="client_table_name_filed_checkbox_img" />
            <Skeleton variant="text" className='skelton_text' />
          </div>
          <div className="client_table_header_field_container header_field_email_container">
            <Skeleton variant="text" className='skelton_text' />
          </div>
          <div className="client_table_header_field_container header_field_lastpickup_container">
            <Skeleton variant="text" className='skelton_text' />
          </div>
          <div className="client_table_header_field_container header_field_city_container">
            <Skeleton variant="text" className='skelton_text' />
          </div>
          <div className="client_table_header_field_container header_field_action_container">
            <Skeleton variant="text" className='skelton_text' />
          </div>
        </div>
      )
    })}
    </div>
  )
}

export default ClientTableSkeletonLoader