import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import * as Yup from 'yup';
import moment from "moment";
import { SQS } from "./WasteBillController.web";
import Strings from "./Strings";


// Customizable Area Start
interface SF {
  clientsId: number[],
  cityNames: string[],
  pickUpDate: string,
  custom_start_date: string,
  custom_end_date:string
}

export interface SP {
  title :string,
  value : string
}
interface SF {
  clientsId: number[],
  branchesId: number[],
  cityNames: string[],
  pickUpDate: string,
  custom_start_date: string,
  custom_end_date:string,
}
let initialFormValues ={
  clientsId: [],
  branchesId: [],
  cityNames: [],
  pickUpDate: "",
  custom_start_date: moment().subtract(1,'days').format('DD-MM-yyyy'),
  custom_end_date: moment().format('DD-MM-yyyy'),
}
// Customizable Area End



export interface Props {
  navigation: any;
  // Customizable Area Start
  searchQueryState:SQS,
  setSearchQueryState : (arg:SQS,callApi:boolean)=>void,
  showFilterModal : ()=>void,
  companyList: any[],
  branchList: any[],

  // Customizable Area End
}
interface S {
  // Customizable Area Start
  expandedList : string | boolean
  date_pickup:SP[]
  initialValues :SF
  // Customizable Area End
}
interface SS {}

export default class WasteFilterController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  filterformRef : any
  filterformSchema: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start
   

    this.state = {
      expandedList : 'panel2',

      date_pickup: [

        {title : Strings.today,value:'today'},
        {title : Strings.thisWeek,value:'this_week'},
        {title : Strings.lastWeek,value:'last_week'},
        {title : Strings.thisMonth,value:'this_month'},
        {title : Strings.lastMonth,value:'last_month'},
        {title : Strings.custom,value:'custom_date'},

      ],
      initialValues:initialFormValues
    };
    this.filterformSchema = Yup.object().shape({
     
      
      custom_start_date : Yup
      .string()
       .when('pickUpDate', {
        is: 'custom_date',
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      custom_end_date : Yup
      .string()
       .when('pickUpDate', {
        is: 'custom_date',
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
      pickUpDate : Yup.string(),
      clientsId: Yup.array().of(Yup.number()),
      cityNames: Yup.array().of(Yup.string()),
    })
    this.filterformRef = React.createRef();

    
    // Customizable Area End
  }


  // Customizable Area Start

  

   handleAcordionChange = (panel: string) => (event: any, isExpanded: boolean) => {
    this.setState({expandedList : isExpanded ? panel : false})  
    };
    setCustomStartDate=(formValues:SF,setFieldValue:any) =>(e:any)=>{
      let startDate = moment(e.target.value)
      let endDate = formValues.custom_end_date
      if(startDate.diff(moment(endDate,'DD-MM-yyyy'), 'days')>0){
        setFieldValue('custom_end_date',startDate.add(1, 'days').format('DD-MM-yyyy'))
      }
      setFieldValue('custom_start_date',moment(e.target.value).format('DD-MM-yyyy'))
    }

    async componentDidMount(): Promise<void> {
      this.setFormValue()
    }
    setPickValue=(value:string,formValues:SF,setFieldValue:any)=>()=>{
      if(value === formValues.pickUpDate){
        setFieldValue('pickUpDate', '');
      }else{
        setFieldValue('pickUpDate', value);
      }
    }    

   

   
    setFormValue = ()=>{
      let {searchQueryState} = this.props;
      this.setState({initialValues:{
        clientsId: searchQueryState.clients,
        branchesId: searchQueryState.branches,
        cityNames: searchQueryState.citys,
        pickUpDate: searchQueryState.pick_up_date,
        custom_start_date: searchQueryState.custom_start_date || this.state.initialValues.custom_start_date,
        custom_end_date: searchQueryState.custom_end_date || this.state.initialValues.custom_end_date,
      }})
    }
   

   

    setCustomEndDate =(setFieldValue:any) =>(e:any)=>{
      setFieldValue('custom_end_date',moment(e.target.value).format('DD-MM-yyyy'))
    }
    
    clearFormValue = (resetForm:any)=>()=>{
      resetForm({
        values: {...initialFormValues},
        isSubmitting: false
      })
      this.handleSubmit(initialFormValues)
    }
    setCompanyId = (id:number,formValues:SF,setFieldValue:any)=>()=>{    
      let clientsValue= formValues.clientsId
      if(clientsValue.includes(id)){
        const index = clientsValue.indexOf(id);
        clientsValue.splice(index, 1);
        setFieldValue('clientsId',[...clientsValue])
      }else{
        setFieldValue('clientsId',[...clientsValue,id])
      }
    }
    setBranchId = (id: number, formValues: SF, setFieldValue: any) => () => {
      let branchesValue = formValues.branchesId;
      if(branchesValue.includes(id)) {
        const index = branchesValue.indexOf(id);
        branchesValue.splice(index, 1);
        setFieldValue('branchesId', [...branchesValue]);
      } else {
        setFieldValue('branchesId', [...branchesValue, id]);
      }
    }
    cancelFilterBtn = ()=>{
      this.props.showFilterModal()
    }
    


    handleSubmit = (value: SF) => {
      this.props.setSearchQueryState(
        {...this.props.searchQueryState,
          clients: value?.clientsId,
          branches: value?.branchesId,
          citys: value?.cityNames,
          custom_start_date : value?.custom_start_date,
          custom_end_date : value?.custom_end_date,
          pick_up_date : value?.pickUpDate,
          page : 1,
          activePagiantionStep : 1
        },true)
        this.props.showFilterModal()
  }

  formatDateValue = (date: string) => {
    if(date === "Invalid date") {
      return date;
    }
    const [day, month, year] = date.split("-")
    return `${day}/${month}/${year}`;
  }
  // Customizable Area End
}
