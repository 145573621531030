import React from "react";

// Customizable Area Start
import {
  Button,
  Typography,
  IconButton,
  Checkbox,
  Link,
  FormControl,
  Select,
  MenuItem,
  TextField,
  withStyles,
  ClickAwayListener,
} from "@material-ui/core";
import Strings from './Strings'
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { downArrow, eye, eyeSlash, globalImg, illustration, naqaalogo } from "./assets"
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';

// Customizable Area End
// Customizable Area Start
import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController.web";
import "./EmailAccountLogin.css"

// Customizable Area End
// Customizable Area Start
const webStyle = {
  menu_item: {
    color: "#32569A",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    borderRadius: "8px",
    display: "flex",
    height: "36px",
    padding: "6px 49px 6px 16px",
    alignItems: "center",
    alignSelf: "stretch",
    "&:hover": {
      backgroundColor: "#E1F0E6"
    }
  },
  main_div: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row" as const,
    margin: "0",
    padding: "0",
    height: "100vh",
    boxSizing: "border-box" as const,
    overflow: "hidden",
    // flexWrap: "wrap",
  },
  wel_text:{
    marginTop:'10px',
    fontWeight: 600,
    // fontFamily: 'Barlow semi condensed',
    fontFamily: 'Barlow Semi Condensed',
    fontSize:'16px',
    color:'#364F65'
  } as const,
  login_text:{
    display: "flex",
    justifyContent: "flex-start",
    fontWeight:600,
    fontSize:'36px',
    color:'#203B54',
    fontFamily: 'Barlow Semi Condensed',
    marginTop: '10px'
  } as const,
  email_text:{
    marginTop:'10px',
    fontWeight:500,
    fontSize:'16px',
    color:'#364F65',
    fontFamily:'Barlow Semi Condensed'
  } as const ,
  password_text:{
    marginTop:'15px',
    fontWeight:500,
    fontSize:'16px',
    color:'#364F65',
    fontFamily:'Barlow Semi Condensed'
  } as const,
  text_field:{
    marginTop:'10px',
    borderRadius:'8px',
  } as const,
  drop_down: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '20px',
    marginTop: '8%'
  } as const,
  rem_text:{
    // marginTop:'20px',
    fontWeight:400,
    fontSize:'14px',
    color:'#1C3045',
    fontFamily:'Barlow Semi Condensed',
    marginTop : '4px'
  },
  second_section: {
    backgroundColor: '#f0f0f0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    overflow: 'hidden',
  } as const,
  
  enviro_image: {
    width: '100%',
    height: '100%',
    position: 'relative',
  } as const,
  enviro_text: {
    fontFamily:'Barlow Semi Condensed',
    fontSize: '40px',
    fontWeight: 700,
    textAlign: 'center',
    color: '#1C3045', 
    padding: '20px',
    zIndex:1,
  } as const,
  forgot_font:{
    fontFamily:'Barlow Semi Condensed',
    fontWeight:600,
    fontSize:'14px'
  },
  alert_style:{
    display:'flex', 
    justifyContent:'center',
    color:'#DC2626',
    backgroundColor:'#FEDCE0',
    fontFamily:'Barlow Semi Condensed',
    fontWeight:500,
    fontSize:'16px',
    borderRadius:'8px'
  }
}
const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#BFCC33',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#BFCC33',
        borderWidth: '1px'
      },
    },
  },
})(TextField);

const styles = {
  menuItem: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#E1F0E6',
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: '#E1F0E6',
      },
    },
  },
};
// Customizable Area End

export class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start

      // Required for all blocks

      <div className="login_block_wrapper">

        <div className="dropdown_wrapper">
          <img src={globalImg} width='24px' height='24px' style={{ marginTop: '4px'}} />
        <ClickAwayListener onClickAway={this.dropDownClickAway}>
          <FormControl>
            <Select
              disableUnderline
              IconComponent={() => (
                <img src={downArrow}
                  style={{ color: '#292D32', width: '24px', height: '24px', cursor:'pointer' }}
                />
              )}
              open={this.state.dropDownOpen}
              onClick={this.openDropDown}
              classes={{root:"mui-select-root"}}
              defaultValue={this.state.language}
              inputProps={{ 'aria-label': 'Without label', }}
              style={{ fontFamily: "Barlow Semi Condensed", backgroundColor: 'transparent' }}
              data-testid="language-dropdown"
              onChange={(event) => this.handlelanguage(event)}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    display: 'flex',
                    paddingLeft: '4px',
                    paddingRight: '4px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                    flexShrink: 0,
                    borderRadius: '8px',
                    border: '1px solid #E2E8F0',
                    background: '#FFF',
                    boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
                    marginLeft: "-2rem",
                    marginTop: "0.7rem"
                  }
                }
              }}
            >
              <MenuItem
                style={{...webStyle.menu_item, marginBottom: "4px"}}
                value={"english"}
                className={this.props.classes.menuItem}
              >
                {Strings.english}
              </MenuItem>
              <MenuItem
                style={{...webStyle.menu_item, marginTop: "4px"}}
                value={"arabic"}
                className={this.props.classes.menuItem}
              >
                {Strings.arabic}
              </MenuItem>
            </Select>
          </FormControl>
          </ClickAwayListener>
        </div>


        {/* container 1 */}
        <div className="form_block_wrapper">
          {/* logo */}
          <div className="logo_wrapper">
            <img src={naqaalogo} className="logo_img" />
          </div>

          <div className="form_container">
            <p className="welcome_text">{Strings.welcomeText}</p>

            <p className="fomr_header_text">{Strings.loginText}</p>

            {this.state.statusLogin && <div data-test-id="error_div" className="error_wrapper">
              <p className="error_text"> {Strings.incorrectUserCred}</p>
            </div>}

            {/* email field */}
            <p className={`field_title ${this.state.statusLogin && 'error_bottom_margin'}`}>{Strings.emailLabel} </p>

            <input type="email" data-test-id="emailInput" placeholder={Strings.emailPlaceholder}
              className="field_input"
              value={this.state.email}
              onChange={(e: any) => {
                this.setState({ email: e.target.value })
              }} />


            {/* password field */}
            <p className={`field_title ${this.state.statusLogin && 'error_bottom_margin'}`}>{Strings.passLabel} </p>

            <div className="password_field_input">
              <input type={this.state.showPassword ? 'text' : 'password'} data-test-id="passwordInput" placeholder={Strings.passPlaceholder}
                className="field_input"
                value={this.state.password}
                onChange={(e: any) => {
                  this.setState({ password: e.target.value })
                }} />
              <div className="password_type_button">
                <IconButton
                  data-test-id="showPassword"
                  aria-label="toggle password visibility"
                  onClick={this.handleShowPassword}
                >
                  {
                    this.state.showPassword
                      ? <img src={eye} alt="eye" className="password-eye-icon" />
                      : <img src={eyeSlash} alt="eyeSlash" className="password-eye-icon" />
                  }
                </IconButton>
              </div>
            </div>

            {/* remember container */}

            <div className="remember_wrapepr">
              {/* remeber */}
              <div className="remember_container">
                <div className="checkobox_container">
                  <Checkbox
                    data-test-id="checkbtn"
                    style={{ color: "#34D399" }}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    checked={this.state.rememberMe}
                    onChange={() => this.setState({ rememberMe: !this.state.rememberMe })}
                  />
                </div>
                <p className="remember_text">{Strings.rememberMe}</p>
              </div>

              {/* forgot password */}
              <p onClick={this.goToForgotPassword} className="forgot_text">{Strings.forgetPassword}</p>
            </div>
            {/* terms and condition */}
            <div style={{ display: "flex" }}>
              <p className="terms_condition_text">{Strings.tandcText}
                <a href="/TermsConditions" className="terms_condition_link">  <u>{Strings.termsConditions}</u></a>
              </p>
            </div>

            {/* submit button */}
            <button
              data-test-id="loginbtn"
              onClick={this.doEmailLogIn}
              style={{
                marginTop: '20px',
                borderRadius: "8px",
                backgroundColor: "#32569A",
                width: "100%",
                color: "#fff",
                fontFamily: "Barlow Semi Condensed",
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "24px",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              {Strings.loginText}
            </button>
          </div>


        </div>

        {/* container 2 */}
        <div className="image_block_wrapper">
          <img
            src={illustration}
            alt="Environment"
            className="backgorund_image"
          />
          <div style={webStyle.enviro_text} className="login-environment-text">
           {this.state.language == 'english' ? <> {Strings.betterEnv + ','} <br /> {Strings.betterTom} </>: 
           Strings.betterEnv + " " + Strings.betterTom}
          </div>
        </div>
      </div>


      // Customizable Area End
    );
  }
}

export default withStyles(styles)(EmailAccountLoginBlock);