export const upArrow = require("../assets/upArrow.png");
export const downArrow = require("../assets/downArrow.png");
export const trashIcon = require("../assets/trash_icon.svg");
export const searchIcon = require("../assets/search_icon.svg");
export const filterIcon = require("../assets/filter_icon.svg");
export const sortIcon = require("../assets/sort_icon.svg");
export const checkIcon = require("../assets/check_icon.svg");
export const actionIcon = require("../assets/action.png");
export const uncheckIcon = require("../assets/unCheck.png");
export const calendar = require("../assets/calendar-1.svg");
export const fileUpload = require("../assets/fileupload.svg");
export const attachment = require("../assets/attachmentIcon.svg");
export const cross = require("../assets/cross.svg");
export const paginationLeftIcon = require("../assets/pagination_left_icon.svg");
export const paginationRightIcon = require("../assets/pagination_right_icon.svg");
export const calendarIcon = require("../assets/calendar.svg");
export const successTick = require("../assets/successTick.svg");
export const download = require("../assets/download.svg");
export const editPencil = require("../assets/editIcon.svg");
export const checkRadioImage = require("../assets/checRadioIcon.svg");
export const unCheckRadioImage = require("../assets/uncheckRadioIcon.svg");
export const arrowRightICon = require("../assets/arrow-right.png");
export const circleFill = require("../assets/circle-fill.png");
export const tickIcon = require("../assets/tick.png");
export const arrowLeft = require("../assets/arrowLeft.svg");
export const vectorDown = require("../assets/Vector.png");
export const vectorUp = require("../assets/vectorup.png");
export const addIcon = require("../assets/add.png");
export const noResult = require("../assets/noResults.svg");
export const noResultBackground = require("../assets/noResultBackground.png");
export const untickIcon = require("../assets/untick.png");
export const caretLeft = require("../assets/caretLeft.png");
export const caretRight = require("../assets/caretRight.png");
export const noResults = require('../assets/noResults.svg');
export const plusIcon = require('../assets/plus.svg');

