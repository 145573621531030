Object.defineProperty(exports, "__esModule", {
    value: true
  });

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";

exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.phoneNumberIsNotValid = "Phone number is not valid";
exports.phoneNumberIsRequired = "Phone number is required";
exports.otpCodeIsRequired = "Phone number is required";
exports.pleaseEnterAPassword = "Please enter a password";
exports.passwordMustBeAtLeast2Characters = "Password must be at least 2 characters";
exports.pleaseConfirmYourPassword = "Please confirm your password";
exports.passwordsMustMatch = "Passwords must match";
exports.invalidEmailAddress = "Invalid email address";
exports.invalidPassword = "Invalid password";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.goToOtpAfterPhoneValidationErrorBody = "Please select country code";

exports.labelTextIsAccountRecovery = "Account Recovery";
exports.secondLabelText = "Please choose what type of account you signed up with."
exports.thirdLabelText = "To Reset your password, please enter the email associated with your account.";
exports.forthLabelText = "We sent a confirmation code to the following email:";
exports.fifthLabelText = "To Reset your password, please enter the phone number associated with your account."
exports.sixthLabelText = "We sent a confirmation code to the following phone:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Email";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Next";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType= "phone-pad"
exports.secondInputPlaceholder = "Mobile"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Enter OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "SMS (Phone) Account";
exports.buttonTitleIsEmailAccount = "Email Account";

exports.labelTextIsPleaseEnterYourNewPassword = "Please enter your new password.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Your password has been successfully changed";

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Re-Type Password";

exports.buttonTitleIsOk = "Ok"
exports.buttonColorForOkButton = "#6200EE"; 

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Select Country";
// Customizable Area Start
exports.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
exports.passwordRegex = /^(?=.*[!@#$%^&*()\-=_+[\]{}|;:'",.<>?/])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
exports.forgotPasswordApiEndpoint = "/forgot_password/reset_password_link";
exports.resetPasswordApiEndpoint = "/forgot_password/reset_password";
exports.paswordContains8Character= /.{8,}/;
exports.passwordContainsSpecialCharacter=/[!@#$%^&*()\-_+={}|\/;:'"<>,.?\[\]]/
exports.passwordContainsDigit= /\d/;
exports.passwordContainsUpperLowerCharacter= /^(?=.*[a-z])(?=.*[A-Z]).+$/

exports.englishLanguage = {
  forgotPassword:"Forgot Password?",
  forgotPasswordSubHeader:"Don’t Worry! Please enter your email id and we will send you the reset link.",
  resetPassword:'Reset Password',
  resetPasswordSubheader:"Your new password should not match with your old password.",
  backToLogin:"Back to Login",
  passwordResetSuccessful:"Your password has been reset successfully!",
  passwordMustBe8Character:"Your password must contain 8 characters",
  passwordContainSpecialCharacter:"Your password must contain one special character",
  passwordContainNumeric:"Your password must contain one numeric digit",
  passwordContainUpperAndLowerCharacter:"Your password must contain one uppercase and lowercase character",
  emailAddress:"Email address",
  naqaaMail:"naqasolutions@gmail.co",
  resendAgain:'Resend again',
  didntReceiveTheLink:"Didn’t receive the link? ",
  backTo:"Back to ",
  login:"Login",
  passwordResendLinkSend:"A password reset link has been sent successfully!",
  newPassword:'New Password',
  confirmPassword:"Confirm Password",
  enterNewPassword:"Enter  new password",
  yourPasswordDoesntMatch:"Your password doesn’t match!"
}
exports.arabicLanguage = {
  forgotPassword:"نسيت كلمة المرور",
  forgotPasswordSubHeader:"أدخل البريد الإلكتروني لنرسل لك رابط إعادة تعيين كلمة المرور البريد الإلكتروني",
  resetPassword:'إعادة تعيين كلمة المرور',
  resetPasswordSubheader:"كلمة المرور الجديدة يجب أن لا تتطابق مع كلمة المرور السابقة",
  backToLogin:"العودة إلى تسجيل الدخول",
  passwordResetSuccessful:"تمت إعادة تعيين كلمة المرور بنجاح",
  passwordMustBe8Character:"يجب أن تتكون كلمة المرور من 8 خانات",
  passwordContainSpecialCharacter:"يجب أن تحتوي كلمة المرور على حرف خاص واحد",
  passwordContainNumeric:"يجب أن تحتوي كلمة المرورعلى رقم واحد",
  passwordContainUpperAndLowerCharacter:"يجب أن تحتوي كلمة السر على حرف كبير و حرف صغير",
  emailAddress:"البريد الإلكتروني",
  naqaaMail:"naqasolutions@gmail.co",
  resendAgain:"إعادة إرسال الرابط",
  didntReceiveTheLink:" لم يصلك الرابط؟",
  backTo:"العودة إلى تسجيل ",
  login:"الدخول",
  passwordResendLinkSend:"تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني بنجاح !",
  newPassword:'كلمة المرور لجديدة',
  confirmPassword:"تأكيد كلمة المرور",
  enterNewPassword:"أدخل كلمة مرور الديدة",
  yourPasswordDoesntMatch:"كلمة المرور غير متطابقة"
}
// Customizable Area End