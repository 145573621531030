import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isOpen: boolean;
  successDialogClose: boolean;
  selectedValue: any;
  datas:any[];
  selected:string[];
  open: boolean;
  categoryValue: string;
  sucessOpen: boolean;
  alertOpen: boolean;
  openDelete: boolean;
  openPopUp: boolean;
  clickedBreadcrumb: any;
  isSelected: any;
  searchValue: any;
  editOpen: boolean;
  editValue: string;
  editSuccesMsg: boolean;
  anchorEl:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  CategoriesId: any;
  caterogyCallId: any;
  catergoryAddCallId: any;
  deleteAllCatergoryCallId: any;
  editCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      
      isOpen:false,
      selectedValue:'',
      datas:[],
      selected: [],
      open: false,
      successDialogClose: false,
      categoryValue:"",
      sucessOpen: false,
      alertOpen:false,
      openDelete: false,
      openPopUp:false,
      clickedBreadcrumb: null,
      isSelected: null,
      searchValue:"",
      editOpen: false,
      editValue: '',
      editSuccesMsg:false,
      anchorEl: null,
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(apiRequestCallId===this.caterogyCallId){
        if(responseJson.data){
          const dataWithSelectedField= responseJson.data.map((item: any)=> ({
            ...item,selected: false
          }))
        this.setState({
          datas:dataWithSelectedField
        })
      }else{
        this.setState({
          datas:[]
        })
      }
      }
      // add catergories

      if(apiRequestCallId===this.catergoryAddCallId){
        this.setState({
               sucessOpen: true
           })
          this.setState({open: false})
          this.categorys()
      }
      // delete all response handle
      if(apiRequestCallId===this.deleteAllCatergoryCallId){
        this.categorys();
        this.setState({
          selected:[]
        })
      }

      if(apiRequestCallId===this.editCallId){
        this.categorys();
        if(responseJson.data.id){
          this.setState({
            editSuccesMsg: true
          });
          setTimeout(()=>{
            this.setState({
              editSuccesMsg: false
            })
          },5000)
        }
      }
    }
    // Customizable Area End
    // Customizable Area End
  }
  // Customizable Area Start
  
  async componentDidMount() {
    this.categorys();
  }

  handleSearchInputChange = (event :any) => {
    const searchValue = event.target.value;
    this.setState({searchValue: searchValue})
}

checkAllCheckBox=()=>{
  return this.state.datas.every((item: any)=>
  item.selected
  )
}
toggleSelectAll=()=>{
  const updatedDatas=this.state.datas.map((item: any)=>({...item, selected: !this.checkAllCheckBox()}));
  this.setState({datas: updatedDatas})

}
checkForNotAllSelect = () =>{
  return !this.checkAllCheckBox() && this.state.datas.some((item:any)=> item.selected)
}

toggleCheckBox=((id: any)=>{
const updatedDatas=this.state.datas.map((data :any)=>
data.id === id ? {...data,selected: !data.selected} : data
)
this.setState({
  datas: updatedDatas
})
});
handleEditClose = () => {
  const selectedItem = this.state.datas.find((item) => item.selected);
  if (selectedItem) {
    this.toggleCheckBox(selectedItem.id);
  }

  this.setState({
    editOpen: false,
  });
};

deleteItemToggle = (id: any, editCategoryValue: any, event?: any)=> {
  const itemToDelete = this.state.datas.find((item: any) => item.id === id);
  if (!itemToDelete?.selected) {
    this.toggleCheckBox(id);
  }
  this.setState({
    isSelected: itemToDelete,
    openPopUp: true,
    anchorEl: event?.currentTarget, 
    editValue: editCategoryValue?.attributes?.name,
  });
};
cancelDelete= ()=>{
  this.setState({
    openPopUp: false
  })
  if(this.state.isSelected){
    this.toggleCheckBox(this.state.isSelected.id)
    this.setState({
      isSelected: null
    })
    
  }
}

filterData = () => {
  const { datas, searchValue } = this.state;
  if (searchValue.trim() === "") {
      return datas; 
  } else {
      const lowerCaseSearchValue = searchValue.toLowerCase();
      return datas.filter((item : any) =>
          item.attributes.name.toLowerCase().includes(lowerCaseSearchValue)
      );
  }
};

  handleCloseDilog = () => {
    this.setState({open:false});
  };
  handleCloseSuccessDilog =() =>{
    this.setState({
        sucessOpen: false
    });
    
  }
  handleAddNewCategoryModal = ()=>{
    this.setState({
        open: true
    })
  }
 
  
  handleAddCategory=()=>{

    if (this.state.categoryValue.trim() === "") {
      return;
  }
  const categoryNameExists = this.state.datas.some((item:any) => item.attributes.name === this.state.categoryValue);

  if (categoryNameExists) {
    this.setState({
      categoryValue: "", 
    });
    return;
  }
    this.setState({
      categoryValue:''
    })
      const httpBody = {
        name: this.state.categoryValue,
      };
      const header = {
        "Content-Type" : "application/json",
        token:localStorage.getItem('token')
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.catergoryAddCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "account_block/waste_type_categories"
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
      }

      handleDeleteToggle =()=>{
        const seletedItems=this.state.datas.filter((item: any)=>item.selected)
        if (seletedItems.length === 0) {
          this.setState({openDelete: false});
           this.setState({
            alertOpen:true
           });
           setTimeout(()=>{
            this.setState({alertOpen: false})
           },5000)
            return;
        } else {
          this.setState({openDelete: true, openPopUp: false})
        }
      }

      deleteAllCategory=()=>{
        this.setState({
          openDelete: false
        })
        const deletedItem= this.state.datas.filter((item: any)=>item.selected)
        const delItem= deletedItem.map((item: any)=>Number(item.id))

        
        const httpBody =  {
          "waste_type_categories_ids": delItem
        
      };
        const header = {
          "Content-Type" : "application/json",
          token:localStorage.getItem('token')
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteAllCatergoryCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "account_block/waste_type_categories/destroy_all"
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "DELETE"
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
        return true;
        }
    
  

  handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = this.state.datas.map((n) => n);
      this.setState({selected: newSelecteds});
      return;
    }
    this.setState({selected:[]});
  };


  handleClick = (event: any, name: string) => {
    const selectedIndex = this.state.selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1),
      );
    }

    this.setState({selected: newSelected});
  };

  categorys = async () => {
    let token = localStorage.getItem("token");

    const headers = {
      token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.caterogyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/waste_type_categories`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
     
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // edit api integration
  handleEditOpen=()=>{
    this.setState({
      editOpen: true,
      openPopUp: false
    })
  }
 
  

  editWasteTypeCategory = async () => {
    const editItemData= this.state.datas.find((item: any)=>item.selected)

    const httpBody = {
      "name": this.state.editValue
  };
    
    const headers = {
    "Content-Type" : "application/json",
    token:localStorage.getItem('token')
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/waste_type_categories/${Number(editItemData.id)}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
     
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    const updatedDatas = this.state.datas.map((item) =>
    item.id === editItemData.id ? { ...item, name: this.state.editValue } : item
  );
 
  this.setState({
    datas: updatedDatas,
    editOpen: false,
  });
    return true;
  }

  // Customizable Area End
}
