import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { TimeChoice, listLabelMonth } from "./DashboardController.web";

export const createRequest = (request: {
  requestMsg: Message;
  endPoint: string;
  method: string;
  token?: string | null;
  body?: string;
  header?: any;
}) => {
  const {
    requestMsg: apiMessage,
    endPoint: apiEndPoint,
    method: apiMethod,
    token: apiToken,
    body: bodyRequest,
    header,
  } = request;

  const convertHeader = {
    Token: apiToken ?? undefined,
    ...header,
  };

  apiMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiEndPoint,
  );

  apiMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(convertHeader),
  );

  apiMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    apiMethod,
  );

  bodyRequest &&
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      bodyRequest,
    );

  runEngine.sendMessage(apiMessage.id, apiMessage);
}

export const handleResponseDataObject = ({ inputData, timeChoice, isLineChart }: { inputData: any, timeChoice: TimeChoice, isLineChart: boolean }) => {
  const arrayDataLabel: Array<any> = []
  let arrayDataData: Array<any> = []

  if (timeChoice === TimeChoice.MONTH) {
    listLabelMonth.forEach((item: string) => {
      arrayDataLabel.push(item)
      arrayDataData.push(0)
    })

    // Only display first 3 characters of month
    const arrayObject: Array<any> = []
    for (const [key, value] of Object.entries(inputData)) {
      arrayObject.push({
        month: key.slice(0, 3),
        value: value
      })
    }
    // fill data for month that have data
    for (const iterator of arrayObject) {
      const indexMonth = listLabelMonth.findIndex((item) => item === iterator.month)

      const newDataArray = [...arrayDataData]
      newDataArray[indexMonth] = iterator.value
      arrayDataData = [...newDataArray]
    }
  } else if (timeChoice === TimeChoice.YEAR) {
    for (const [key, value] of Object.entries(inputData)) {
      arrayDataLabel.push(key)
      arrayDataData.push(value)
    }
  } else {
    const arrayObject: Array<any> = []
    // Only display first 3 characters of month
    for (const [key, value] of Object.entries(inputData)) {
      arrayObject.push({
        year: Number(key),
        value: value
      })
    }

    // re order data
    if (arrayObject.length > 1) {
      arrayObject.sort(function (a: any, b: any) {
        return listLabelMonth.indexOf(a.year)
          - listLabelMonth.indexOf(b.year);
      });
    }

    for (const iterator of arrayObject) {
      arrayDataLabel.push(iterator.year)
      arrayDataData.push(iterator.value)
    }

    //if there is only 1 year in data, add 1 more year before it for line chart
    if (arrayDataLabel.length === 1 && isLineChart) {
      arrayDataLabel.unshift((Number(arrayDataLabel[0]) - 1).toString())
      arrayDataData.unshift(0)
    }
  }
  return {
    label: arrayDataLabel,
    data: arrayDataData
  }
}
