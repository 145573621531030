export const imgPasswordVisible = require("../assets/visible.png");
export const imgPasswordInVisible = require("../assets/eye-slash.png");
export const fileImg = require("../assets/image.png");


export const deleteIcon =require('../assets/delete.svg')
export const calendar =require('../assets/calendar-1.svg')
export const calendarIcon =require('../assets/calendar.svg')
export const trashCan=require('../assets/trash.png');
export const addIcon=require('../assets/add.png');
export const tickIcon=require('../assets/tick.png');
export const arrow =require('../assets/arrow.svg')
export const key =require('../assets/key.svg')
export const plusIcon =require('../assets/plus.svg')
export const tickDisable =require('../assets/tick_disable.svg')
export const untickDisable =require('../assets/untick_disable.svg')
export const DropIcon =require('../assets/dropIcon.svg')
export const attachmentIcon =require('../assets/attachment_icon.svg')
export const documentDownloadIcon = require('../assets/documentDownloadIcon.svg')
export const trashIcon = require('../assets/trash_icon.svg')
export const searchIcon = require('../assets/search_icon.svg')
export const filterIcon = require('../assets/filter_icon.svg')
export const sortIcon = require('../assets/sort_icon.svg')
export const AcordianIcon = require('../assets/accordian_icon.svg')
export const unChcekIcon = require('../assets/uncheck_icon.svg')
export const unChcekBoxIcon = require('../assets/unCheckbox.svg')
export const checkallICon = require('../assets/checkall.svg')
export const chcekIcon = require('../assets/check_icon.svg')
export const actionIcon = require('../assets/action.png')
export const paginationLeftIcon = require('../assets/pagination_left_icon.svg')
export const paginationRightIcon = require('../assets/pagination_right_icon.svg')
export const checkRadioImage = require('../assets/checRadioIcon.svg')
export const unCheckRadioImage = require('../assets/uncheckRadioIcon.svg')
export const closeIcon = require('../assets/close_icon.svg');
export const arrowRightICon = require('../assets/arrow-right.png')
export const noResultsIcon = require('../assets/noResults.svg')

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const mobileRegex = /^(?:\+\d{1,3}\s?)?\d{3,14}$/;
export const nameRegex = /^[A-Za-z\s'-]+$/;
export const passwordRegex = /^(?=.*[!@#$%^&*()\-=_+[\]{}|;:'",.<>?/])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
export const tick =require('../assets/tick-icon.svg')
export const numberRegex = /^[0-9]*$/

export const fileIcons = require('../assets/Vector.png');


