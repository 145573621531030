Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.markAllReadEndPoint = "notifications/mark_all_read"
exports.endPoint = "/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"

exports.englishLanguage = {
  notificationHeading : 'Notifications',
  markRead : 'Mark All as Read',
  ago:'ago',
  day:"days",
  months:"months",
  minutes : 'minutes',
  years:"years",
  seconds:"seconds",
  hour:"hours",
  noNotification : 'We will inform you when there is a notification!'
}

exports.arabicLanguage = {
  notificationHeading : 'الإشعارات',
  markRead : 'مقروء',
  noNotification : 'سنبلغك عند وجود أي إشعارات',
  ago:"قبل",
  months:"ساعات",
  hour:"ساعة",
  day:"أيام",
  years:"سنين",
  seconds:"ثواني",
  minutes : 'دقائق'
}
// Customizable Area End