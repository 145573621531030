import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  newPassword: string;
  confirmPassword: string;
  newPasswordValidate: boolean;
  confirmPassValidate: boolean;
  showNewPass: boolean;
  showConfirmPass: boolean;
  token: any;
  samePassError:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;

  // Customizable Area End
}

// Customizable Area Start
export interface ForgotPasswordProps {
  navigation: any;
}
// Customizable Area End

export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestPhoneOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  otpToken: any;
  // isChangePassword: boolean;
  resetPasswordId: any;

  //Properties from config
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    this.state = {
      newPassword: "",
      confirmPassword: "",
      newPasswordValidate: true,
      confirmPassValidate: true,
      showNewPass: false,
      showConfirmPass: false,
      token: "",
      samePassError:'',
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  async receive(from: string, message: Message) {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (this.resetPasswordId !== null && this.resetPasswordId === dataMessage) {
      this.handleAPIResponse(
        successMessage,
        errorMessage,
        this.resetSuccessCallback
      );
    }
  }

  // Customizable Area Start

  onNewPassChange = (e: any) => {
    const { value } = e.target;
    this.setState({ newPassword: value,samePassError:'' });
    this.validateNewPassword(value);
    this.validateConfirmPassword(this.state.confirmPassword, value);
  };

  onConfirmPassChange = (e: any) => {
    const { value } = e.target;
    this.setState({ confirmPassword: value });
    this.validateConfirmPassword(value);
  };

  validateConfirmPassword = (
    text: string,
    newPassword = this.state.newPassword
  ) => {
    this.setState({
      confirmPassValidate: text !== newPassword,
    });
  };

  validateNewPassword: (text: string) => void = (text) => {
    this.setState({
      newPasswordValidate: !(configJSON.passwordRegex.test(text) && !text.includes(' ')),
    });
  };

  onBackToLogin = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  onResetPress = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resetPasswordApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let token = "";

    let message: string = window.location.href.split("?")[1];

      if (message?.length > 0) {
         token = message.slice(
          message.indexOf("=") + 1,
          message.lastIndexOf("&")
        );
      }

    const data = {
      token: token,
      new_password: this.state.newPassword,
      confirm_password: this.state.confirmPassword,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAPIResponse(
    successMessage: any,
    errorMessage: any,
    callback: Function
  ) {
    if (successMessage) {
      if (successMessage.errors) {
        this.setState({newPasswordValidate:true,samePassError:successMessage.errors[0].password})
      } else {
        callback(successMessage);
      }
    }
  }
  resetSuccessCallback = (successMessage: any) => {
    this.props.navigation.navigate("ResetSuccessful");
  };
  // Customizable Area End
}
