import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import { SQS } from "./ClientListingController.web";
// Customizable Area Start
// Customizable Area End

export const webConfigJSON = require("../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  clientListigData : any[]
  loader : boolean,
  searchQueryState:SQS,
  setSearchQueryState : (arg:SQS,callApi:boolean)=>void
  selectUserDelete : number[]
  setSelectUserForDelete : (ids:number[])=>void
  handleDelete : (ids:number[])=>void
  handleViewAsClient: (clientId: string) => void
  
  // Customizable Area End
}
interface S {
  // Customizable Area Start
    menuIndex : number | null
    deletClientId : number | null
    deleteModalFlag : boolean
  // Customizable Area End
}
interface SS {}

export default class ActiveClientTableController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    // Customizable Area Start
   

    this.state = {
      menuIndex : null,
      deletClientId : null,
      deleteModalFlag : false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    
    
    // Customizable Area Start
    // Customizable Area End
  }

  

  async receive(from: string, message: Message) {
    // Customizable Area Start

   
    // Customizable Area End
  }
  // Customizable Area Start
  onActionClick = (e:any,index: number) => {
    if (this.state.menuIndex !== index) this.setState({ menuIndex: index });
    else this.setState({ menuIndex: null });
    document.addEventListener("click", this.handleClickOutside);
  };

  handleClickOutside(event:any) {
    if(event.target.className !== "client_listing_action_img"){
    this.setState({ menuIndex: null });
    }
    document.removeEventListener("click", this.handleClickOutside);
    }

  viewAsClient = (clientId: string) => () => {
    this.props.handleViewAsClient(clientId);
  }

  viewClient = (clientId: string) => () => {
    this.props.navigation.history.push('ClientAccountDetail', {id: clientId});
  }

  editClient = (clientId : string) =>()=>{
    this.props.navigation.history.push('AccountCreation',{id:clientId})
  }

  downPage = ()=>{
    if(this.props.searchQueryState.activePagiantionStep > 1){
      this.props.setSearchQueryState({...this.props.searchQueryState,activePagiantionStep : this.props.searchQueryState.activePagiantionStep - 1 },false)
    }
  }

  upPage = ()=>{
    if(Math.ceil(this.props.searchQueryState.total_page/5) > this.props.searchQueryState.activePagiantionStep){
      this.props.setSearchQueryState({...this.props.searchQueryState,activePagiantionStep : this.props.searchQueryState.activePagiantionStep + 1 },false)
    }
  }

  pageChange = (setPage:number) => ()=>{
    this.props.setSearchQueryState({...this.props.searchQueryState,page : setPage },true)
  }

  selectAll = ()=>{
    let {clientListigData, setSelectUserForDelete, selectUserDelete} = this.props
    if(selectUserDelete.length){
      setSelectUserForDelete([])  
    }else{
      let ids = clientListigData.map((clientData)=>{
        return +clientData.id
      })
      setSelectUserForDelete(ids)
    }
  }

  selectClient=(id:number)=>()=>{
    let {setSelectUserForDelete, selectUserDelete} = this.props
    if(selectUserDelete.includes(id)){
      let selectUser= [...selectUserDelete]
      let index = selectUser.indexOf(id)
      selectUser.splice(index,1)
      setSelectUserForDelete(selectUser)
    }else{
      setSelectUserForDelete([...selectUserDelete,id])
    }
  }

  deleteClient =(id:number)=>()=>{
    this.setState({deletClientId : id,deleteModalFlag : true})
    let element: any = document.querySelector('#root div');
    if(element){
      element.scrollTo({
        top: 0,
        behavior: "smooth"
      })
      element.style.overflow = 'hidden'
    }
    
  }

  handleDeleteClient = ()=>{
    if(this.state.deletClientId){
      this.props.handleDelete([this.state.deletClientId])
      this.hideDeleteModalFlag()
    }
  }

  hideDeleteModalFlag = ()=>{
    this.setState({deletClientId : null,deleteModalFlag : false})
    let element: any = document.querySelector('#root div');
    if(element){element.style.overflow = 'unset'}
  }

  clearFilter = ()=>{
    this.props.setSearchQueryState(
      {
        ...this.props.searchQueryState,pick_up_date : '',
        clients : [],
        citys : [],
      },true)
  }

  // Customizable Area End
}
