import LocalizedStrings from 'react-localization';
const configJSON = require("./config");
class CategoryString {
    constructor() {
      this.initFunction();
    }
  
    Strings = new LocalizedStrings({
      english: configJSON.englishLanguage,
      arabic: configJSON.arabicLanguage,
    });
  
    initFunction =  () => {
      try{
      let lang =  localStorage.getItem("language");
      if (lang) {
        this.Strings.setLanguage(lang);
      } else {
        this.Strings.setLanguage("english");
      }
    }
    catch(error){
      console.log('errors',error)
    }
    };
  }
  
  export default new CategoryString().Strings;