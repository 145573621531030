// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.updateAPIMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "FormApprovalWorkflow";
exports.labelBodyText = "FormApprovalWorkflow Body";
exports.CreateIdApi='bx_block_formapprovalworkflow/formapprovalworkflows/create_customer_trip'
exports.btnExampleTitle = "CLICK ME";
exports.cancelAPI = "bx_block_formapprovalworkflow/formapprovalworkflows/cancel_trip"
exports.getBranchesList = "admin/branch_names";
exports.getClientsList = "account_block/account_company_names";
exports.getPickupRequestData = "formapprovalworkflow/pick_up_requests";
exports.actionAPIEndpoint = "formapprovalworkflow/pick_up_requests";
exports.acceptAllEndpoint = "formapprovalworkflow/pick_up_requests/accept_all";
exports.declineAllEndpoint = "formapprovalworkflow/pick_up_requests/decline_all";

exports.englishLanguage = {
  pickupHeading : 'Pickup Request',
  pending : 'Pending',
  accpeted : 'Accepted',
  declined : 'Declined',
  acceptAll : 'Accept all',
  declineAll : 'Decline all',
  search : 'Search client',
  filters : 'Filters',
  sort : 'Sort',
  name : 'Name',
  address : 'Address',
  branch : 'Branch',
  city : 'City',
  pickupdate : 'Pick Up Date',
  action : 'Action',
  reason : 'Reason',
  reasonForreSchedule : 'Reason For Reschedule',
  clearAll : 'Clear All',
  clients : 'Clients',
  thisMonth : 'This month',
  thisWeek : 'This week',
  accept : 'Accept',
  reschedule : 'Re-schedule',
  reschedulePickupHeading : 'Re-schedule Pick Up Request',
  clientName : 'Client Name',
  chooseDate : 'Choose Date',
  enterComments : 'Enter your comments.',
  save : 'Save',
  declineWarning : 'Are you sure you want to decline?',
  reasonForDecline : 'Reason for decline',
  decline : 'Decline',
  declineAllWarning : 'Are you sure you want to decline them all?',
  nothingToShow : 'Nothing to show yet!',
  newest : 'Newest',
  ascending : 'Ascending',
  descending : 'Descending',
  lastSevenDays : 'Last 7 days',
  lastThirtyDays : 'Last 30 days',
  showing:"Showing",
  From:"From"
}
exports.arabicLanguage = {  
  branch : 'الفرع',
  city : 'المدينة',
  pickupdate : 'تاريخ التفريغ',
  action : 'الإجراء',
  reason : 'الأسباب',
  reasonForreSchedule : 'أسباب إعادة الجدولة',
  clearAll : 'مسح الكل',
  clients : 'العملاء',
  thisMonth : 'هذا الشهر',
  thisWeek : 'هذا الأسبوع',
  accept : 'قبول',
  decline : 'رفض',
  reschedule : 'إعادة جدولة',
  reschedulePickupHeading : 'إعادة جدولة طلب التفريغ',
  clientName : 'العميل',
  showing:"عرض",
  From:"من",
  chooseDate : 'التاريخ',
  enterComments : 'أدخل الأسباب',
  save : 'حفظ',
  declineWarning : 'هل أنت متأكد من رفض الطلب؟ ',
  reasonForDecline : 'أسباب الرفض',
  declineAllWarning : 'هل أنت متأكد أنك تريد رفض الكل؟',
  nothingToShow : 'لا توجد بيانات للآن',
  declineAll :'الكل  رفض',
  pickupHeading : 'طلبات التفريغ',
  pending : 'قيد الإنتظار',
  accpeted : 'مقبولة',
  declined : 'مرفوضة',
  acceptAll : 'قبول الكل',
  search : 'البحث عن عميل',
  filters : 'تصفية',
  sort : 'ترتيب',
  name : 'الاسم',
  address : 'العنوان',
  newest : 'أحدث',
  ascending : 'تصاعدي',
  descending : 'تنازلي',
  lastSevenDays : 'آخر 7 أيام',
  lastThirtyDays : 'آخر 30 يومًا'
}

// Customizable Area End
