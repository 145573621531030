export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const addIcon = require("../assets/add.png");
export const deleteIcon = require("../assets/delete.png");
export const filterIcon = require("../assets/filter.png");
export const sortIcon = require("../assets/sort.png");
export const searchIcon = require("../assets/search.png");
export const tickIcon = require("../assets/tick.png");
export const untickIcon = require("../assets/untick.png");
export const minusIcon = require("../assets/minus.png");
export const actionIcon = require("../assets/action.png");
export const caretLeft = require("../assets/caretLeft.png");
export const caretRight = require("../assets/caretRight.png");

export const eyeImg= require("../assets/eye-slash.png");
export const vectorDown=require("../assets/Vector.png");
export const vectorUp=require("../assets/vectorup.png");
export const circleFill = require("../assets/circle-fill.png");
export const arrowRight = require("../assets/arrow-right.png");
export const crossIcon = require("../assets/cross.png");
export const successImg=require("../assets/success.png");
export const yesImg=require("../assets/yes.png");
