import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import Strings from "./Strings";

// Customizable Area Start

export type Selected = "Client Details" | "Contract" | "Report" | "Send Invite";

interface ContactPerson {
  contact_person_id?: any;
  name: string;
  email: string;
  mobile: string;
  nameValidate: boolean | null;
  emailValidate: boolean | null;
  mobileValidate: boolean | null;
  _destroy?: boolean;
}
interface BranchName {
  branch_id?: any;
  name: string;
  address: string;
  city: string;
  nameValidate: boolean | null;
  addressValidate: boolean | null;
  cityValidate: boolean | null;
  _destroy?: boolean;
}
interface Error {
  status: boolean | null;
  message: string;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  setCurrentScreen: (arg: any) => void;
  editId?: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  userActive: boolean;
  companyName: string;
  emailAddress: string;
  mobileNumber: string;
  address: string;
  city: string;
  companyValidate: boolean | null;
  mobileValidate: boolean | null;
  emailValidate: boolean | null;
  nameValidate: boolean | null;
  cityValidate: boolean | null;
  addressValidate: boolean | null;
  contactPersons: Array<ContactPerson>;
  branchNames: Array<BranchName>;
  error: Error;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ClientDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  clientDetailsId: any;
  getClientDetails: any;
  haveUnsavedChanges: boolean = false;
  editDetails: any = {};
  //Properties from config

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      token: "",
      ...this.initialState,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (this.clientDetailsId !== null && this.clientDetailsId === dataMessage) {
      this.handleClientAPIResponse(
        successMessage,
        errorMessage,
        this.clientSuccessCallback
      );
    } else if (
      this.getClientDetails !== null &&
      this.getClientDetails === dataMessage
    ) {
      this.handleClientAPIResponse(
        successMessage,
        errorMessage,
        this.storeClientDetails
      );
    }

    // Customizable Area End
  }

  emptyBranchDetails = {
    name: "",
    address: "",
    city: "",
    nameValidate: null,
    addressValidate: null,
    cityValidate: null,
  };
  emptyContactDetails = {
    name: "",
    email: "",
    mobile: "",
    nameValidate: null,
    emailValidate: null,
    mobileValidate: null,
  };

  async componentDidMount() {
    super.componentDidMount();
    let token = await localStorage.getItem("token");
    if (token) {
      window.addEventListener("beforeunload", this.handleUnsaveChanges);
      this.setState({ token });
      if (this.props.editId && this.props.editId !== -1) {
        console.log("%%%%%%%%%%%%%%%%%%%%");
        this.getUserDetails();
      }
    }
  }

  emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  mobileRegex = /^\d+$/;
  nameRegex = /^[a-z0-9 ,.'-]+$/i;

  // Customizable Area Start

  async componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleUnsaveChanges);
  }

  handleUnsaveChanges = (e: any) => {
    if (this.haveUnsavedChanges) {
      e.preventDefault();
      e.returnValue =
        "You have unsaved changes. Are you sure you want to leave?";
    }
  };

  getUserDetails = (editId = this.props.editId) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClientDetails = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountCreationUpdate}${editId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  initialState = {
    error: { status: null, message: "" },
    userActive: true,
    companyName: "",
    emailAddress: "",
    mobileNumber: "",
    address: "",
    city: "",
    emailValidate: null,
    addressValidate: null,
    mobileValidate: null,
    cityValidate: null,
    companyValidate: null,
    nameValidate: null,
    contactPersons: [
      {
        name: "",
        email: "",
        mobile: "",
        nameValidate: null,
        emailValidate: null,
        mobileValidate: null,
      },
    ],
    branchNames: [
      {
        name: "",
        address: "",
        city: "",
        nameValidate: null,
        addressValidate: null,
        cityValidate: null,
      },
    ],
  };

  onChangeEmailAddress = (event: any) => {
    this.haveUnsavedChanges = true;
    const { value } = event.target;
    this.setState({
      emailAddress: value,
      emailValidate: !this.emailRegex.test(value),
    });
  };
  onChangeMobile = (event: any) => {
    this.haveUnsavedChanges = true;
    const { value } = event.target;
    if (value === "" || this.mobileRegex.test(value)) {
      this.setState({
        mobileNumber: value,
        mobileValidate: !this.mobileRegex.test(value),
      });
    }
  };
  onChangeAddress = (event: any) => {
    this.haveUnsavedChanges = true;
    const { value } = event.target;
    let validate = value.length > 3;
    this.setState({ address: value, addressValidate: !validate });
  };
  onChangeCity = (event: any) => {
    this.haveUnsavedChanges = true;
    const { value } = event.target;
    let validate = this.nameRegex.test(value);
    this.setState({ city: value, cityValidate: !validate });
  };
  onChangeCompanyName = (event: any) => {
    this.haveUnsavedChanges = true;
    const { value } = event.target;
    let validate = value.length > 2;
    this.setState({ companyName: value, companyValidate: !validate });
  };

  onActivePress = () => {
    if (!this.state.userActive) {
      this.haveUnsavedChanges = true;
      this.setState({ userActive: true });
    }
  };
  onInactivePress = () => {
    if (this.state.userActive) {
      this.haveUnsavedChanges = true;
      this.setState({ userActive: false });
    }
  };

  handleContactsDelete = (index: number) => {
    this.haveUnsavedChanges = true;
    if (this.state.contactPersons[index].contact_person_id) {
      this.state.contactPersons[index]._destroy = true;
      let tempData: any = this.state.contactPersons.splice(index, 1);
      this.state.contactPersons.push(tempData[0]);
      let tempState = { ...this.state };
      this.setState({ ...tempState });
    } else {
      this.state.contactPersons.splice(index, 1);

      if (this.state.contactPersons.length === 0) {
        this.state.contactPersons.push(this.emptyContactDetails);
        this.setState({
          error: { status: true, message: "Minimum one input required!" },
        });
      }
      if (
        JSON.stringify(this.state.contactPersons) !==
        JSON.stringify(this.emptyContactDetails)
      ) {
        this.setState({ contactPersons: [...this.state.contactPersons] });
      }
    }
    let newContact = this.state.contactPersons.filter(
      (element) => !element._destroy
    );
    if (newContact.length === 0) {
      this.setState({
        contactPersons: [this.emptyContactDetails],
        error: { status: true, message: "Minimum one input required!" },
      });
    }
  };

  getContactValidate = (name: string, value: string) => {
    switch (name) {
      case "name":
        return { nameValidate: !this.nameRegex.test(value) };
      case "email":
        return { emailValidate: !this.emailRegex.test(value) };
      case "mobile":
        return { mobileValidate: !this.mobileRegex.test(value) };
    }
  };

  handleContactsPersonDetails = (e: any, index: number) => {
    this.haveUnsavedChanges = true;
    const { name, value } = e.target;
    let validate: any = this.getContactValidate(name, value);
    let newDetails = this.state.contactPersons[index];
    newDetails = { ...newDetails, [name]: value };
    this.state.contactPersons[index] = { ...newDetails, ...validate };

    this.setState({ contactPersons: [...this.state.contactPersons] });
  };

  handleAddContactPerson = () => {
    this.haveUnsavedChanges = true;
    let insertIndex = this.state.contactPersons.findIndex(
      (element) => element._destroy
    );
    if (insertIndex !== -1) {
      this.state.contactPersons.splice(
        insertIndex,
        0,
        this.emptyContactDetails
      );
    } else {
      this.state.contactPersons.push(this.emptyContactDetails);
    }

    this.setState({ contactPersons: [...this.state.contactPersons] });
  };

  handleBranchDelete = (index: number) => {
    this.haveUnsavedChanges = true;
    if (this.state.branchNames[index].branch_id) {
      this.state.branchNames[index]._destroy = true;
      let tempData: any = this.state.branchNames.splice(index, 1);
      this.state.branchNames.push(tempData[0]);
      let tempState = { ...this.state };
      this.setState({ ...tempState });
    } else {
      this.state.branchNames.splice(index, 1);

      if (this.state.branchNames.length === 0) {
        this.state.branchNames.push(this.emptyBranchDetails);
        this.setState({
          error: { status: true, message: "Minimum one input required!" },
        });
      }
      if (
        JSON.stringify(this.state.branchNames) !==
        JSON.stringify(this.emptyContactDetails)
      ) {
        this.setState({ branchNames: [...this.state.branchNames] });
      }
    }
    let newBranch = this.state.branchNames.filter(
      (element) => !element._destroy
    );
    if (newBranch.length === 0) {
      this.setState({
        branchNames: [this.emptyBranchDetails],
        error: { status: true, message: "Minimum one input required!" },
      });
    }
  };

  getBranchValidate = (name: string, value: string) => {
    switch (name) {
      case "name":
        return { nameValidate: !this.nameRegex.test(value) };
      case "address":
        return { addressValidate: value.length <= 3 };
      case "city":
        return { cityValidate: !this.nameRegex.test(value) };
    }
  };

  handleBranchDetails = (e: any, index: number) => {
    this.haveUnsavedChanges = true;
    const { name, value } = e.target;
    let validate: any = this.getBranchValidate(name, value);
    let newDetails = this.state.branchNames[index];
    newDetails = { ...newDetails, [name]: value };
    this.state.branchNames[index] = { ...newDetails, ...validate };

    this.setState({ branchNames: [...this.state.branchNames] });
  };

  handleAddBranchPerson = () => {
    this.haveUnsavedChanges = true;
    let insertIndex = this.state.branchNames.findIndex(
      (element) => element._destroy
    );
    if (insertIndex !== -1) {
      this.state.branchNames.splice(insertIndex, 0, this.emptyBranchDetails);
    } else {
      this.state.branchNames.push(this.emptyBranchDetails);
    }

    this.setState({ branchNames: [...this.state.branchNames] });
  };

  handleDiscardChanges = () => {
    this.haveUnsavedChanges = false;
    if (this.props.editId === -1) {
      this.setState({
        ...this.initialState,
        contactPersons: [this.emptyContactDetails],
        branchNames: [this.emptyBranchDetails],
      });
    } else {
      this.setState(JSON.parse(JSON.stringify({ ...this.initialState, ...this.editDetails })));
    }
  };

  handlleSaveChange = async () => {
    this.haveUnsavedChanges = false;
    let tempState = {
      emailValidate:
        this.state.emailValidate === null ? true : this.state.emailValidate,
      mobileValidate:
        this.state.mobileValidate === null ? true : this.state.mobileValidate,
      addressValidate:
        this.state.addressValidate === null ? true : this.state.addressValidate,
      companyValidate:
        this.state.companyValidate === null ? true : this.state.companyValidate,
      cityValidate:
        this.state.cityValidate === null ? true : this.state.cityValidate,
    };
    this.state = { ...this.state, ...tempState };
    this.handleBranchValidation();
    this.handleContactValidation();
    let temp = { ...this.state };
    this.setState({ ...temp });
    if (this.checkValidation()) {
      let {
        companyName,
        emailAddress,
        branchNames,
        contactPersons,
        address,
        city,
        userActive,
        mobileNumber,
      } = this.state;
      let contact_people_attributes = contactPersons.map((item: any) => {
        let temp: any = {
          name: item.name,
          email: item.email,
          mobile_number: item.mobile,
        };
        if (item.contact_person_id) {
          temp = {
            ...temp,
            id: item.contact_person_id,
            _destroy: item?._destroy,
          };
        }
        return temp;
      });
      let branch_names_attributes = branchNames.map((item: any) => {
        let temp: any = {
          name: item.name,
          address: item.address,
          city: item.city,
        };
        if (item.branch_id) {
          temp = { ...temp, id: item.branch_id, _destroy: item?._destroy };
        }
        return temp;
      });
      let data = {
        account: {
          company_name: companyName,
          client_email: emailAddress,
          full_phone_number: mobileNumber,
          address,
          activated: userActive,
          city,
          contact_persons_attributes: contact_people_attributes,
          branch_names_attributes,
          role: "Client",
          step: "Step1",
        },
      };

      let token = await localStorage.getItem("token");

      if (token) {
        if (this.props.editId === -1) {
          const header = {
            "Content-Type": configJSON.contentTypeApiUpdateUser,
            token,
          };

          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.clientDetailsId = requestMessage.messageId;

          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.clientDetailsEndpoint
          );

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.callTypeApiValidateMobileNo
          );

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
          );

          runEngine.sendMessage(requestMessage.id, requestMessage);
        } else {
          this.updateData(token, data);
        }
      } else {
        this.props.navigation.navigate("EmailAccountLoginBlock");
      }
    }
  };

  updateData = (token: string, data: any) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.clientDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountCreationUpdate}${this.props.editId}`
    );

    let updateData = { ...data };
    delete updateData.account.role;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(updateData)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  checkValidation = () => {
    const {
      emailValidate,
      mobileValidate,
      nameValidate,
      companyValidate,
      contactPersons,
      branchNames,
      cityValidate,
    } = this.state;
    let validate =
      !emailValidate &&
      !mobileValidate &&
      !nameValidate &&
      !companyValidate &&
      !cityValidate;

    const allPersonValid = contactPersons.every(
      (person) =>
        person.nameValidate || person.emailValidate || person.mobileValidate
    );
    const allBranchValid = branchNames.every(
      (person) =>
        person.nameValidate || person.addressValidate || person.cityValidate
    );

    validate = validate && !allBranchValid && !allPersonValid;

    return validate;
  };

  handleBranchValidation = () => {
    let branchValidate = this.state.branchNames.map(
      (currenItem: any, index) => {
        return Object.keys(currenItem).filter((item) => {
          switch (item) {
            case "addressValidate":
              this.state.branchNames[index].addressValidate =
                this.state.branchNames[index].addressValidate === null
                  ? true
                  : this.state.branchNames[index].addressValidate;
              return this.state.branchNames[index].addressValidate;
            case "nameValidate":
              this.state.branchNames[index].nameValidate =
                this.state.branchNames[index].nameValidate === null
                  ? true
                  : this.state.branchNames[index].nameValidate;
              return this.state.branchNames[index].nameValidate;
            case "cityValidate":
              this.state.branchNames[index].cityValidate =
                this.state.branchNames[index].cityValidate === null
                  ? true
                  : this.state.branchNames[index].cityValidate;
              return this.state.branchNames[index].cityValidate;
            default:
              return false;
          }
        });
      }
    );
    return branchValidate;
  };

  handleContactValidation = () => {
    let contactValidate = this.state.contactPersons.map(
      (currentItem: any, index) => {
        return Object.keys(currentItem).filter((item) => {
          switch (item) {
            case "emailValidate":
              this.state.contactPersons[index].emailValidate =
                this.state.contactPersons[index].emailValidate === null
                  ? true
                  : this.state.contactPersons[index].emailValidate;
              return this.state.contactPersons[index].emailValidate;
            case "nameValidate":
              this.state.contactPersons[index].nameValidate =
                this.state.contactPersons[index].nameValidate === null
                  ? true
                  : this.state.contactPersons[index].nameValidate;
              return this.state.contactPersons[index].nameValidate;
            case "mobileValidate":
              this.state.contactPersons[index].mobileValidate =
                this.state.contactPersons[index].mobileValidate === null
                  ? true
                  : this.state.contactPersons[index].mobileValidate;
              return this.state.contactPersons[index].mobileValidate;
            default:
              return false;
          }
        });
      }
    );
    return contactValidate;
  };

  handleClientAPIResponse(
    successMessage: any,
    errorMessage: any,
    callback: Function
  ) {
    if (errorMessage) {
    } else if (successMessage) {
      if (successMessage.errors) {
        if (successMessage.errors[0].token) {
          this.props.navigation.navigate("EmailAccountLoginBlock");
        } else {
          this.setState({
            error: { status: true, message: successMessage.errors[0] },
          });
        }
      } else {
        callback(successMessage);
      }
    }
  }

  clientSuccessCallback = async (successMessage: any) => {
    let id = successMessage?.data?.id;
    localStorage.setItem("clientAccountId", id);
    this.setState({
      error: { status: false, message: Strings.changesSaved },
    });
    this.props.setCurrentScreen(Strings.contract);
  };

  storeClientDetails = (successMessage: any) => {
    const {
      company_name,
      client_email,
      full_phone_number,
      activated,
      address,
      city,
      contact_persons,
      branch_names,
    } = successMessage.data.attributes;
    let branchNames =
      branch_names !== null
        ? branch_names.map((item: any) => ({
            branch_id: item.branch_id,
            _destroy: false,
            name: item.name,
            city: item.city,
            address: item.address,
            nameValidate: false,
            addressValidate: false,
            cityValidate: false,
          }))
        : [this.emptyBranchDetails];
    let contactPersons =
      contact_persons !== null
        ? contact_persons.map((item: any) => ({
            contact_person_id: item?.contact_person_id,
            _destroy: false,
            name: item.name,
            email: item.email,
            mobile: item.mobile_number,
            nameValidate: false,
            emailValidate: false,
            mobileValidate: false,
          }))
        : [this.emptyContactDetails];
    let temp = {
      userActive: activated,
      companyName: company_name,
      emailAddress: client_email,
      mobileNumber: full_phone_number,
      address: address,
      city: city,
      emailValidate: false,
      addressValidate: false,
      mobileValidate: false,
      cityValidate: false,
      companyValidate: false,
      nameValidate: false,
      contactPersons,
      branchNames,
    };
    this.editDetails ={...JSON.parse(JSON.stringify(temp))};
    this.setState({...temp});
  };

  // Customizable Area End
}
