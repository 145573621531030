import React, { createRef } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Strings from './Strings'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface Error {
  status: boolean | null;
  message: string;
}
interface Filters {
  roles: Array<any>;
  selectedRole: any;
}

interface SortSelected {
  desc: string;
  before: string;
  after: string;
}

interface SelectedFilter {
  id: string;
  type: string;
  attributes: any;
}

interface S {
  // Customizable Area Start
  token: any;
  error: Error;
  filterModal: boolean;
  sortModal: boolean;
  search: string;
  menuIndex: number;
  selected: Array<number>;
  page: number;
  currentItemCount: number;
  pageBatch: number;
  totalPages: number;
  listData: Array<any>;
  showSearchBar:boolean;
  filters: Filters;
  totalItems: number;
  showFilterRole: boolean;
  sortList: Array<any>;
  selectedSort: SortSelected;
  selectedFilter: SelectedFilter;
  deletingId: any;
  showDeleteModal: boolean;
  profileType: "Admin" | "Super Admin" | "";
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminStaffListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataAPIId: any;
  debounceSearch: any = "";
  getRolesAPIId: any;
  deleteAccountAPIId: any;
  deleteAllAccountId: any;
  debounceTimer: NodeJS.Timeout | undefined;
  filterButtonRef: any;
  filterModalRef: any;
  sortButtonRef: any;
  sortModalRef: any;
  actionButtonRef: any;
  actionModalRef: any;
  scrollRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      filterModal: false,
      sortModal: false,
      error: { status: null, message: "" },
      search: "",
      showSearchBar:false,
      menuIndex: -1,
      selected: [],
      page: 1,
      currentItemCount: 1,
      pageBatch: 1,
      totalPages: 1,
      totalItems: 0,
      listData: [],
      showFilterRole: true,
      sortList: [
        { desc: "created_at_desc", before: Strings.newest, after: "" },
        { desc: "name_asc", before: Strings.ascendingA, after: Strings.z },
        { desc: "name_desc", before: Strings.descendingZ, after: Strings.a },
      ],
      selectedSort: { desc: "created_at_desc", before: Strings.newest, after: "" },
      filters: {
        roles: [],
        selectedRole: { id: "", type: "role", attributes: { name: Strings.all } },
      },
      selectedFilter: { id: "", type: "role", attributes: { name: Strings.all } },
      showDeleteModal: false,
      deletingId: "",
      profileType: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.filterModalRef = createRef();
    this.filterButtonRef = createRef();
    this.sortButtonRef = createRef();
    this.sortModalRef = createRef();
    this.actionButtonRef = createRef();
    this.actionModalRef = createRef();
    this.scrollRef = createRef();
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    let token = await localStorage.getItem("token");
    let userDetails: any = await localStorage.getItem("userDetail");
    userDetails = JSON.parse(userDetails);
    let userRole = userDetails?.account_role;
    if (token && (userRole === "Super Admin" || userRole === "Admin")) {
      this.setState({ token: token, profileType: userRole });
      this.getRolesList();
      this.getListingData();
      document.addEventListener("mousedown", this.handleClickOutside);
    } else {
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (this.getRolesAPIId !== null && this.getRolesAPIId === dataMessage) {
      this.handleGetDataAPIResponse(
        successMessage,
        errorMessage,
        this.getRolesSuccessCallback
      );
    }
    if (this.getDataAPIId !== null && this.getDataAPIId === dataMessage) {
      this.handleGetDataAPIResponse(
        successMessage,
        errorMessage,
        this.getDataSuccessCallback
      );
    } else if (
      this.deleteAccountAPIId !== null &&
      this.deleteAccountAPIId === dataMessage
    ) {
      this.handleGetDataAPIResponse(
        successMessage,
        errorMessage,
        this.deleteAccountSuccessCallback
      );
    } else if (
      this.deleteAllAccountId !== null &&
      this.deleteAllAccountId === dataMessage
    ) {
      this.handleGetDataAPIResponse(
        successMessage,
        errorMessage,
        this.deleteAllSuccessCallback
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start

  componentWillUnmount(): any {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (
      prevState.page !== this.state.page ||
      prevState.selectedSort.desc !== this.state.selectedSort.desc ||
      prevState.selectedFilter.id !== this.state.selectedFilter.id
    ) {
      this.getListingData();
    }
  }

  handleClickOutside = (event: any) => {
    if (
      this.filterModalRef.current &&
      !this.filterModalRef.current.contains(event.target) &&
      this.filterButtonRef.current &&
      !this.filterButtonRef.current.contains(event.target)
    ) {
      this.setState({ filterModal: false });
    }
    if (
      this.sortButtonRef.current &&
      !this.sortButtonRef.current.contains(event.target) &&
      this.sortModalRef.current &&
      !this.sortModalRef.current.contains(event.target)
    ) {
      this.setState({ sortModal: false });
    }
    if (
      this.actionButtonRef.current &&
      !this.actionButtonRef.current.contains(event.target) &&
      this.actionModalRef.current &&
      !this.actionModalRef.current.contains(event.target)
    ) {
      this.setState({ menuIndex: -1 });
    }
  };

  getRolesList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRolesAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRolesList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleRegisterNewClient = () => {
    this.props.navigation.navigate("RegisterNewAdmin");
  };

  onSelectClick = (index: number) => {
    let temp = this.state.selected.indexOf(index);
    if (temp == -1) {
      this.setState({ selected: [...this.state.selected, index] });
    } else {
      this.state.selected.splice(temp, 1);
      let tempState = [...this.state.selected];
      this.setState({ selected: [...tempState] });
    }
  };

  onSelectAllClick = () => {
    if (this.state.selected.length === 0) {
      const numbers = [];
      for (let i = 0; i < this.state.listData.length; i++) {
        numbers.push(this.state.listData[i].id);
      }
      this.setState({ selected: numbers });
    } else {
      this.setState({ selected: [] });
    }
  };

  onActionClick = (index: number) => {
    if (this.state.menuIndex !== index) this.setState({ menuIndex: index });
    else this.setState({ menuIndex: -1 });
  };

  onPageClick = (item: number) => {
    if (this.state.page !== item) {
      this.setState({ page: item, selected: [], menuIndex: -1 });
    }
  };

  onPreviousPageClick = () => {
    if (this.state.pageBatch !== 1)
      this.setState({ pageBatch: this.state.pageBatch - 1, menuIndex: -1 });
  };

  onNextPageClick = () => {
    if (this.state.pageBatch < Math.ceil(this.state.totalPages / 5))
      this.setState({ pageBatch: this.state.pageBatch + 1, menuIndex: -1 });
  };

  onDeleteModalOpen = (id: number = -1) => {
    if (id === -1 && this.state.selected.length === 0) {
      return;
    } else {
      this.setState({ showDeleteModal: true, deletingId: id, menuIndex: -1 });
    }
  };

  onModalCloseClick = () => {
    this.setState({ showDeleteModal: false, deletingId: "", menuIndex: -1 });
  };

  onModalDeleteClick = () => {
    if (this.state.deletingId === -1) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
      };
      const data = {
        ids: [...this.state.selected],
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteAllAccountId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.deleteAllAPIEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ data })
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.deleteAccountAPIId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getListingData}/${this.state.deletingId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpDeleteRequest
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  getListingData = (
    page: number = this.state.page,
    searchTerm: string = this.state.search
  ) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDataAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getListingData}?page=${page}&per_page=10&search_term=${searchTerm}&filter[role_id]=${this.state.selectedFilter?.id}&sort=${this.state.selectedSort?.desc}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetDataAPIResponse = (
    successMessage: any,
    errorMessage: any,
    callback: Function
  ) => {
    if (errorMessage) {
      console.log("error-------------", errorMessage);
    } else if (successMessage) {
      if (successMessage.errors) {
        if (successMessage.errors[0].token) {
          this.props.navigation.navigate("EmailAccountLoginBlock");
        } else {
          this.setState({
            error: { status: false, message: successMessage.errors[0] },
          });
        }
      } else {
        callback(successMessage);
      }
    }
  };
  getDataSuccessCallback = (successMessage: any) => {
    // this.scrollRef.current?.scrollIntoView();
    this.setState({
      listData: [...successMessage.data],
      totalPages: successMessage.meta.pagy.pages,
      totalItems: successMessage.meta.pagy.count,
      currentItemCount: successMessage.meta.pagy.in,
      selected: [],
    });
  };

  onEditAdminClick = (id: number) => {
    if (this.state.profileType === "Super Admin") {
      this.props.navigation.history.push("EditAdminStaff", { id });
    } else {
      this.setState({
        error: {
          status: true,
          message: "You are not authorized to perform this action",
        },
      });
    }
    this.setState({ menuIndex: -1 });
  };

  onChangeSearch = (e: any) => {
    const { value } = e.target;
    this.setState({ search: value });
    const newSearchTerm = value;

    // Clear the previous debounce timer
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    // Set a new debounce timer
    this.debounceTimer = setTimeout(() => {
      this.getListingData(this.state.page, newSearchTerm);
    }, 1000);
  };

  onSortClick = () => {
    this.setState({
      filterModal: false,
      sortModal: !this.state.sortModal,
      filters: {
        ...this.state.filters,
        selectedRole: this.state.selectedFilter,
      },
      menuIndex: -1,
    });
  };

  onFilterRoleClick = () => {
    this.setState({ showFilterRole: !this.state.showFilterRole });
  };

  onFilterClick = () => {
    this.setState({
      filterModal: !this.state.filterModal,
      sortModal: false,
      menuIndex: -1,
    });
  };

  onCancelFilterClick = () => {
    this.setState({
      filters: {
        ...this.state.filters,
        selectedRole: this.state.selectedFilter,
      },
      filterModal: false,
    });
  };

  onFilterClearClick = () => {
    this.setState({
      selectedFilter: { id: "", type: "role", attributes: { name: Strings.all } },
      filterModal:false,
      filters: {
        ...this.state.filters,
        selectedRole: { id: "", type: "role", attributes: { name: Strings.all } },
      },
    });
  };

  onApplyFilterClick = () => {
    this.setState({
      selectedFilter: this.state.filters.selectedRole,
      page: 1,
      pageBatch: 1,
      filterModal: false,
    });
  };

  onFilterRoleItemClick = (item: any) => {
    if (
      JSON.stringify(this.state.filters.selectedRole) !== JSON.stringify(item)
    )
      this.setState({
        filters: {
          ...this.state.filters,
          selectedRole: item,
        },
      });
  };

  onSortItemClick = (item: any) => {
    if (JSON.stringify(item) !== JSON.stringify(this.state.selectedSort)) {
      this.setState({
        selectedSort: item,
        page: 1,
        sortModal: false,
        pageBatch: 1,
        menuIndex: -1,
      });
    }
  };

  getRolesSuccessCallback = (successMessage: any) => {
    this.setState({
      filters: {
        ...this.state.filters,
        roles: [
          ...successMessage.data,
          { id: "", type: "role", attributes: { name: Strings.all } },
        ],
      },
    });
  };

  deleteAccountSuccessCallback = (successMessage: any) => {
    if(this.state.currentItemCount===1 &&this.state.page!==1){
      this.setState({page:this.state.page-1})
    }
    else{
      this.getListingData();
    }
    this.setState({ showDeleteModal: false, deletingId: -1 });
  };

  deleteAllSuccessCallback = (successMessage: any) => {
    if(this.state.selected.length===this.state.currentItemCount &&this.state.page!==1){
      this.setState({page:this.state.page-1})
    }
    else{
      this.getListingData();
    }
    this.setState({ showDeleteModal: false, selected: []});
  };

  searchIconPress=()=>{
    this.setState({showSearchBar:!this.state.showSearchBar})
  }
  // Customizable Area End
}
