import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RefObject, createRef } from "react";
import Strings from "./Strings"

// Customizable Area Start
interface Error {
  status: boolean | null;
  message: string;
}

type Screen = string;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  editId: number;
  first_name: string;
  first_name_validate: boolean | null;
  last_name: string;
  last_name_validate: boolean | null;
  email: string;
  email_validate: boolean | null;
  full_phone_number: string;
  phone_number_validate: boolean | null;
  role_id: any;
  role_id_validate: boolean | null;
  password: string;
  password_validate: boolean | null;
  password_confirmation: string;
  password_confirmation_validate: boolean | null;
  error: Error;
  currentScreen: Screen;
  showSuccessModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EditAdminStaffController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAdminStaffDetailsAPIId: any;
  updateAdminStaffDetailsAPIId: any;
  scrollRef: RefObject<HTMLDivElement>;
  roleList: any;
  changePasswordState: any;
  editProfileState: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      editId: -1,
      first_name: "",
      first_name_validate: null,
      last_name_validate: null,
      email_validate: null,
      password_confirmation_validate: null,
      password_validate: null,
      phone_number_validate: null,
      role_id_validate: null,
      last_name: "",
      email: "",
      full_phone_number: "",
      role_id: { id: "", attributes: { name: "" } },
      password: "",
      password_confirmation: "",
      error: { status: null, message: "" },
      currentScreen: Strings.editProfile,
      showSuccessModal: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.roleList = [
      { id: 2, attributes: { name: Strings.admin } },
      { id: 1, attributes: { name: Strings.superAdmin } },
    ];
    this.scrollRef = createRef();
    this.editProfileState = {
      first_name: "",
      first_name_validate: null,
      last_name_validate: null,
      email_validate: null,
      phone_number_validate: null,
      role_id_validate: null,
      last_name: "",
      email: "",
      full_phone_number: "",
      role_id: { id: "", attributes: { name: "" } },
    };
    this.changePasswordState = {
      password: "",
      password_confirmation: "",
      password_confirmation_validate: null,
      password_validate: null,
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (
      this.getAdminStaffDetailsAPIId !== null &&
      this.getAdminStaffDetailsAPIId === dataMessage
    ) {
      this.APIResponseHandler(
        successMessage,
        errorMessage,
        this.getAdminStaffCallback
      );
    } else if (
      this.updateAdminStaffDetailsAPIId !== null &&
      this.updateAdminStaffDetailsAPIId === dataMessage
    ) {
      this.APIResponseHandler(
        successMessage,
        errorMessage,
        this.updateAdminStaffDetailsCallback
      );
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    let token = await localStorage.getItem("token");
    if (token) {
      this.setState({ token: token });
      let editId = this.props.navigation.history.location?.state?.id;
      if (editId) {
        this.setState({ editId: parseInt(editId) });
        this.getAdminStaffData(editId);
      }
    }
  }

  navigation(to: string, params: any = {}) {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), to);
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), params);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  getAdminStaffData = (editId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdminStaffDetailsAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getListingData}/${editId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  checkValidation = () => {
    const {
      currentScreen,
      first_name_validate,
      last_name_validate,
      email_validate,
      phone_number_validate,
      role_id_validate,
      password_validate,
      password_confirmation_validate,
    } = this.state;
    let temp = false;
    if (currentScreen === "Edit Profile") {
      temp =
        !first_name_validate &&
        !last_name_validate &&
        !email_validate &&
        !phone_number_validate &&
        !role_id_validate;
    } else {
      temp = !password_validate && !password_confirmation_validate;
    }
    return temp;
  };

  handlleSaveChange = () => {
    console.log("validation", this.checkValidation());
    if (this.checkValidation()) {
      let data;
      if (this.state.currentScreen === "Edit Profile") {
        data = {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          full_phone_number: this.state.full_phone_number,
          role_id: this.state.role_id.id,
        };
      } else {
        data = {
          password: this.state.password,
          password_confirmation: this.state.password_confirmation,
        };
      }
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateAdminStaffDetailsAPIId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getListingData}/${this.state.editId}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutRequest
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ data })
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  onHeaderScreenClick = (screen: Screen) => {
    this.setState({ currentScreen: screen });
  };

  validateInput = (name: string, value: string) => {
    switch (name) {
      case "first_name":
        this.setState({
          first_name_validate: !configJSON.nameRegex.test(value),
        });
        return;
      case "last_name":
        this.setState({
          last_name_validate: !configJSON.nameRegex.test(value),
        });
        return;
      case "email":
        this.setState({ email_validate: !configJSON.emailRegex.test(value) });
        return;
      case "full_phone_number":
        this.setState({
          phone_number_validate: !configJSON.mobileRegex.test(value),
        });
        return;
      case "password":
        this.setState({
          password_validate: !configJSON.passwordRegex.test(value),
          password_confirmation_validate:
            this.state.password_confirmation === ""
              ? null
              : value != this.state.password_confirmation,
        });
        return;
      case "password_confirmation":
        this.setState({
          password_confirmation_validate:
            value === "" ? null : value != this.state.password,
        });
        return;
    }
  };

  onInputChange = (event: any) => {
    const { name, value } = event.target;
    let newValue = { ...this.state, [name]: value };
    this.setState({ ...newValue });
    this.validateInput(name, value);
  };

  setCurrentDropDown = (name: string, value: string) => {
    this.setState({ role_id: value });
  };

  handleDiscardChanges = () => {
    if(this.state.currentScreen==='Edit Profile'){
      this.setState({...this.editProfileState})
    }
    else{
      this.setState({...this.changePasswordState})
    }
  };

  onModalCrossPress = () => {
    this.setState({showSuccessModal:false})
    this.navigation("AdminStaffListing");
  };

  APIResponseHandler = (
    successMessage: any,
    errorMessage: any,
    callback: Function
  ) => {
    if (errorMessage) {
      console.log("error-------------", errorMessage);
    } else if (successMessage) {
      if (successMessage.errors) {
        if (successMessage.errors[0].token) {
          localStorage.clear();
          this.props.navigation.navigate("EmailAccountLoginBlock");
        } else {
          this.setState({
            error: { status: false, message: successMessage.errors[0] },
          });
        }
      } else {
        callback(successMessage);
      }
    }
  };
  getAdminStaffCallback = (successMessage: any) => {
    console.log("succccccc", successMessage);
    if (successMessage.data) {
      const { email, full_phone_number, first_name, last_name, role } =
        successMessage.data.attributes;
      let temp = {
        email,
        email_validate: false,
        phone_number_validate: false,
        first_name_validate: false,
        last_name_validate: false,
        role_id_validate: false,
        full_phone_number,
        first_name,
        last_name,
        role_id: role,
      };
      this.editProfileState = {
        ...temp,
      };
      this.setState({ ...temp });
    }
  };

  updateAdminStaffDetailsCallback = (successMessage: any) => {
    console.log("successssssss", successMessage);
    this.setState({ showSuccessModal: true });
  };
  // Customizable Area End
}
