import React from "react";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Navbar from "../../settings2/src/Navbar.web";
import AddPickupRequestClientController, {
  Props,
} from "./AddPickupRequestClientController.web";
import InputField from "../../../components/src/InputField.web";
import DropDown from "./DropDown.web";
import { calendar } from "./assets";
import SuccessModal from "./sucessModal.web";
import Strings from "./Strings";
import moment from 'moment';

export default class AddPickupRequestClient extends AddPickupRequestClientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  getErrorBoxStyles = () => {
    if (this.state.error.status === null) {
      return "";
    } else if (!this.state.error.status) {
      return "prompt-container-success";
    } else {
      return "prompt-container-failure";
    }
  };

  getErrorInputStyles = (errors: any) => {
    if (errors !== null) {
      if (errors) {
        return "error-input";
      }
      return "success-input";
    }
    return "";
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <div
        id="element"
        className={`main-wrapper ${this.state.showSuccessModal &&
          "addwastebill-disable-scroll"}`}
        ref={this.scrollRef}
      >
        <NavigationMenu navigation={this.props.navigation} id={""} />
        {this.state.showSuccessModal && (
          <SuccessModal
            data-testid={"successModalComponent"}
            onModalCrossPress={this.onModalCrossPress}
            successText={Strings.yourPickupHasbeedSentSuccesfully}
          />
        )}
        <div className="dashboard_container_wrapper">
          <div className="dashboard_navbar_container">
            <Navbar navigation={this.props.navigation} id={""} />
          </div>
          <div className="addNewWasteBill-right-container">
            <div style={webStyles.inputsContainer}>
              <div className="contactus-header-container">
                <div className="contactus-header-top-container addwastebill-top-container">
                  <p className="contactus-header-title">
                    {this.state.editId === -1
                      ? Strings.pickupRequest
                      : Strings.editPickupRequest}
                  </p>
                  {this.state.error.status !== null && (
                    <span
                      data-testid={"prompMessage"}
                      className={`contactus-prompt-container header-prompt-container ${this.getErrorBoxStyles()}`}
                    >
                      {this.state.error.message}
                    </span>
                  )}
                </div>
              </div>
              <DropDown
                name="branch_name"
                data-testid={"branchName"}
                value={this.state.branch_name.attributes.name}
                showEndEndornment
                disable={true}
                labelName={Strings.branchName}
                placeholder={Strings.selectBranchName}
                dropDownList={this.state.branchNames}
                currentSelected={this.state.branch_name.attributes.name}
                error={
                  this.state.branch_name.attributes.name === "" ? null : false
                }
                setCurrentSelected={this.setCurrentBranch}
              />
              <InputField
                value={this.state.branch_name.attributes.address}
                name={"address"}
                data-testid={"address"}
                disable={true}
                type="text"
                labelName={Strings.address}
                placeholder={Strings.selectAddress}
                error={
                  this.state.branch_name.attributes.address !== ""
                    ? false
                    : null
                }
              />
              <div className="account-creation-input-container">
                <label className="dropdown-input-name addwastebill-input-name">
                  {Strings.pickupDaySelection}
                </label>
                <span
                  className={`addNewWasteBill-date-input ${this.getErrorInputStyles(
                    this.state.pickupdate === "" ? null : false
                  )}`}
                >
                  <input
                    type="date"
                    name="pickupdate"
                    data-testid="pickupDate"
                    className="date-opacity text-field-input"
                    value={moment(this.state.pickupdate,'DD-MM-yyyy').format('yyyy-MM-DD')}
                    onChange={this.onChangeInput}
                  />
                  <p
                    className={`addwastebill-date-text ${Boolean(
                      this.state.pickupdate
                    ) && "addwastebill-text-color"}`}
                  >
                    {this.state.pickupdate
                      ? this.state.pickupdate
                      : "DD/MM/YYYY"}
                  </p>
                  <span className="adornment">
                    <img src={calendar} />
                  </span>
                </span>
                <InputField
                  value={this.state.comment}
                  name={"comment"}
                  data-testid={"comment"}
                  type="text"
                  multiline={true}
                  onChangeText={this.onChangeInput}
                  labelName={`${Strings.comment} (${Strings.optional})`}
                  customStyles={"addpickup-comment-text-field"}
                  placeholder=
                  {Strings.enterYourComments}
                  error={this.state.comment !== "" ? false : null}
                />
              </div>
            </div>
            <div
              style={{
                width: "93%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                data-testid={"SubmitButton"}
                style={{
                  color: "#FFF",
                  fontFamily: "Barlow Semi Condensed",
                  fontSize: "1rem",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "1.5rem",
                  display: "flex",
                  width: "6.5625rem",
                  height: "2.75rem",
                  padding: "0.625rem 1rem",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.5rem",
                  backgroundColor: "#32569A",
                  borderRadius: "0.5rem",
                  alignSelf: "flex-end",
                  cursor: "pointer",
                }}
                onClick={this.handleSubmit}
              >
                {Strings.submit}
              </button>
            </div>
          </div>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  inputsContainer: { width: "79%" },
};

// Customizable Area End
