export const userProfile = require("../assets/user-profile.png");
export const naqaa = require("../assets/Naqaa.png");
export const calender = require("../assets/calendar.svg");
export const group = require("../assets/group.svg");
export const documentImg = require("../assets/document-normal.svg");
export const key = require("../assets/key.svg");
export const message = require("../assets/message-text.svg");
export const element = require("../assets/element-3.svg");
export const documenttext = require("../assets/document-text.svg");
export const profile = require("../assets/profile-2user.svg");
export const logout = require("../assets/logout.svg");
export const categories = require('../assets/categories_icon.svg');
export const cross = require('../assets/cross.png');
export const accountUserIcon = require('../assets/account_user_icon.svg');
export const closeIcon = require('../assets/close_icon.svg');
export const MenuIcon = require("../assets/menu_icon.svg")

