Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.navMenuForAdmin = [
  {icon : 'element', title : 'Home', navigationName : 'Dashboard'},
  {icon : 'profile', title : 'Clients', navigationName : 'ClientListing'},
  {icon : 'documenttext', title : 'Waste Bill', navigationName : 'WasteBill'},
  {icon : 'calender', title : 'Calendar', navigationName : 'Calendar'},
  {icon : 'group', title : 'Pickup Request', navigationName : 'PickupRequest'},
  {icon : 'message', title : 'Contact', navigationName : 'Contactus'},
  {icon : 'documentImg', title : 'Terms and Conditions', navigationName : 'TermsConditionsEdit'},
  {icon : 'key', title : 'Admin Staff', navigationName : 'AdminStaffListing'},
  {icon : 'categories', title : 'Categories', navigationName : 'Categories'},
]

exports.navMenuForClient = [
  {icon : 'element', title : 'Home', navigationName : 'DashboardClient'},
  {icon : 'accountUserIcon', title : 'Account', navigationName : 'ClientAccountDetail'},
  {icon : 'documenttext', title : 'Report', navigationName : 'ReportClient'},
  {icon : 'group', title : 'Pick Up Request', navigationName : 'PickupClientListing'},
  {icon : 'message', title : 'Contact', navigationName : 'ContactusClient'},
]
exports.RouteClient = ['DashboardClient', 'ClientAccountDetail','ReportClient', 'PickupRequestClient','ContactusClient','EducationalUserProfileClient','NotificationsClient', "PickupClientListing",'AddPickupRequestClient' ]
exports.redirectForClient ={Dashboard:'DashboardClient',PickupRequest:'PickupClientListing',Contactus:'ContactusClient',}

exports.RouteAdmin = [
  'Dashboard',
  'ClientAccountDetail',
  'AccountCreation',
  'WasteBill',
  'Calendar',
  'PickupRequest',
  'Contactus',
  'TermsConditionsEdit',
  'EditAdminStaff',
  'AdminStaffListing',
  'Categories',
  'EducationalUserProfile',
  'RegisterNewAdmin',
  'ClientListing',
  'AddNewWasteBill',
  'Notifications',
  'DashboardClient',
  'PickupClientListing',
  'ContactusClient',
  'ReportClient',
  'EducationalUserProfileClient',
  'NotificationsClient'
]

exports.redirectForAdmin ={DashboardClient:'Dashboard',PickupRequestClient:'PickupRequest',ContactusClient:'Contactus'}
exports.activeRoutesLists = {accountcreation:['ClientListing'],addnewwastebill:["WasteBill"],addpickuprequestclient:["PickupClientListing"],editadminstaff:['AdminStaffListing'],registernewadmin:['AdminStaffListing']}

exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";

exports.arabicLanguage = {
  home : 'الصفحة الرئيسية',
  clients : 'العملاء',
  wasteBill : 'تذكرة تفريغ',
  calendar : 'التقويم',
  pickupRequest : 'طلب الفريغ',
  contact : 'تواصل معنا',
  adminStaff : 'المشرفين',
  categories : 'الفئات',
  account : 'الحساب',
  report : 'التقارير',
  termsConditions : 'الشروط و الأحكام',
  pickupClient : 'طلب تفريغ',
  signOut : 'تسجيل خروج',
  logout : 'تسجيل خروج',
  cancel : 'إلغاء',
  logWarning : 'هل أنت متأكد من تسجيل الخروج؟',
  arabic:"العربية",
  english:"الإنجليزية",
}
exports.englishLanguage = {
  home : 'Home',
  clients : 'Clients',
  wasteBill : 'Waste Bill',
  calendar : 'Calendar',
  pickupRequest : 'Pickup Request',
  contact : 'Contact',
  adminStaff : 'Admin Staff',
  categories : 'Categories',
  account : 'Account',
  report : 'Report',
  termsConditions : 'Terms and Conditions',
  pickupClient : 'Pick Up Request',
  signOut : 'Sign Out',
  logout : 'Logout',
  cancel : 'Cancel',
  logWarning : 'Are you sure you want to logout?',
  english:"English",
  arabic:"Arabic",
}
// Customizable Area End