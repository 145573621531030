import React, { ChangeEvent } from "react";
import ContactusUserWebController, { Props, configJSON } from "./ContactusUserWebController";
import { callIcon, chatIcon, locationIcon, sendIcon, facebookIcon, instagramIcon, twitterIcon, youtubeIcon, linkedInIcon, tickIcon, cross } from "./assets";
import { Formik, Form } from "formik";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import "./contactusUserStyle.css"
import Navbar from "../../settings2/src/Navbar.web";
import Strings from "./Strings";

 





export default class ContactusUser extends ContactusUserWebController {

    constructor(props: Props) {
        super(props);
    }




    render() {
        let { contactInfo } = this.state


        return (
            <div id="element" className={`main-wrapper ${this.state.successModalFlag && 'disable-scroll'} `}>
       
          {this.state.successModalFlag && 
            <div className="success_modal_wrapper" data-test-id="successmodal">
                <div className="success_modal_container">
                    <div className="success_modal_close"><img src={cross} alt="cross" onClick={this.closeSuccessModal} data-test-id="closemodal" /></div>
                    <img src={tickIcon} className="success_modal_logo"  />
                    <p className="success_modal_text">
                        {configJSON.suceesMessageUserForm}
                    </p>
                </div>
                
            </div>
          }
                <NavigationMenu navigation={this.props.navigation} id={""} />
                <div className="container_wrapper">
                    <div className="navbar_container">
                        <Navbar navigation={this.props.navigation} id={""} />
                    </div>

                    <div className="body_container">

                        {/* content header start */}
                        <div className="contact_header_wrapper">
                            <div className="header_title_wrapper">
                                <h1 className="header_title_text">{Strings.contact}</h1>
                            </div>
                            {this.state.showErrorFlag.error && 
                            <div className="header_form_error_wrapper">
                                <div className="form_error_container">
                                    <p className="form_error_text">{this.state.showErrorFlag.message}</p>
                                </div>
                            </div>
                            }
                        </div>
                        {/* -------- content header end ---------- */}

                        {/* content body start */}

                        <div className="contact_body_wrapper">
                            <div className="contact_detail_wrapper">

                                <div>

                                    {/* 1 */}
                                    <div className="detail_content_header" >
                                        <img src={chatIcon} className="detail_header_icon" />
                                        <div className="detail_header_text">{contactInfo?.chat_heading}</div>
                                    </div>
                                    <div className="detail_content_body">
                                        <p className="detail_content_descripition">{contactInfo?.chat_sub_heading}</p>
                                        <p className="detail_content_descripition detail_text_color">{contactInfo?.chat_email_address}</p>
                                    </div>
                                    {/* 2 */}
                                    <div className="detail_content_header">
                                        <img src={locationIcon} className="detail_header_icon" />
                                        <div className="detail_header_text">{contactInfo?.office_heading}</div>
                                    </div>
                                    <div className="detail_content_body">
                                        <p className="detail_content_descripition">{contactInfo?.office_sub_heading}</p>
                                        <p className="detail_content_descripition detail_text_color">{contactInfo?.office_address}</p>
                                    </div>

                                    {/* 3 */}
                                    <div className="detail_content_header">
                                        <img src={callIcon} className="detail_header_icon" />
                                        <div className="detail_header_text">{contactInfo?.contact_heading}</div>
                                    </div>
                                    <div className="detail_content_body">
                                        <p className="detail_content_descripition">{contactInfo?.contact_sub_heading}</p>
                                        <p className="detail_content_descripition detail_text_color">{contactInfo?.contact_number1}</p>
                                        <p className="detail_content_descripition detail_text_color">{contactInfo?.contact_number2}</p>
                                        <p className="detail_content_descripition detail_text_color">{contactInfo?.contact_number3}</p>
                                    </div>


                                    {/*  4 */}
                                    <div className="detail_content_header">
                                        <img src={sendIcon} className="detail_header_icon" />
                                        <div className="detail_header_text">{contactInfo?.follow_heading}</div>
                                    </div>
                                    <div className="detail_content_body">
                                        <p className="detail_content_descripition">{contactInfo?.follow_sub_heading}</p>
                                        <div>
                                            {contactInfo?.facebook_url_active && <img src={facebookIcon} alt="facebookIcon" className="connect_icon" onClick={() => window.open(contactInfo?.facebook_url)} data-test-id="facebookImage" />}
                                            {contactInfo?.instagram_url_active && <img src={instagramIcon} alt="instagramIcon" className="connect_icon" onClick={() => window.open(contactInfo?.instagram_url)} data-test-id="instagramImage" />}
                                            {contactInfo?.twitter_url_active && <img src={twitterIcon} alt="twitterIcon" className="connect_icon" onClick={() => window.open(contactInfo?.twitter_url)} data-test-id="twitterImage" />}
                                            {contactInfo?.youtube_url_active && <img src={youtubeIcon} alt="youtubeIcon" className="connect_icon" onClick={() => window.open(contactInfo?.youtube_url)} data-test-id="youtubeImage" />}
                                            {contactInfo?.linkedin_url_active && <img src={linkedInIcon} alt="linkedInIcon" className="connect_icon" onClick={() => window.open(contactInfo?.linkedin_url)} data-test-id="linkdinImage" />}
                                        </div>
                                    </div>

                                    {/*  */}

                                </div>
                            </div>
                            {/*  */}
                            <div className="contact_form_wrapper">
                                <div className="contact_form_container" data-test-id={"contactform"}>
                                    <Formik
                                        data-test-id="formik"
                                        initialValues={{
                                            name: "",
                                            email: "",
                                            phone_number: "",
                                            description: ""

                                        }}

                                        onSubmit={(values)=>{this.handleSubmit(values)}}
                                        validationSchema={this.formSchema}
                                        innerRef={this.myRef}

                                    >
                                        {(props) => {
                                            const {
                                                values,
                                                touched,
                                                errors,
                                                isSubmitting,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                            } = props;
                                            return (
                                                <Form translate={undefined}>
                                                    {/* name field */}
                                                    <input
                                                        data-test-id={"nameField"}
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        placeholder={Strings.yourName}
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`${errors.name && touched.name && "error"} usercontactformInput`}
                                                    />

                                                    {/* email field */}
                                                    <input
                                                    data-test-id={"emailField"}
                                                        id="email"
                                                        name="email"
                                                        type={'text'}
                                                        placeholder={Strings.emailAddress}
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`${errors.email && touched.email && "error"} usercontactformInput`}
                                                    />

                                                    {/* phone number field */}
                                                    <input
                                                    data-test-id={"phoneField"}
                                                        id="phone_number"
                                                        name="phone_number"
                                                        type={'text'}
                                                        placeholder={Strings.phoneNumber}
                                                        value={values.phone_number}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`${errors.phone_number && touched.phone_number && "error"} usercontactformInput`}
                                                    />

                                                    {/* descripition field */}
                                                    <textarea
                                                        data-test-id={"descriptionField"}
                                                        id="description"
                                                        name="description"
                                                        placeholder={Strings.enterYourMessage}
                                                        value={values.description}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={`${errors.description && touched.description && "error"} usercontactformInput usercontainerformtextarea`}
                                                    />

                                                    <div className="submitBtnConatiner">
                                                        <button data-test-id="btnSubmit" disabled={this.state.btnDisableFlag || this.shouldDisableSubmit()} type="submit" className="userContactFormSumbitBtn">{Strings.submit}</button>
                                                    </div>


                                                </Form>
                                            )
                                        }}

                                    </Formik>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

}