Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.httpDeleteRequest = 'DELETE';
exports.httpPutRequest = 'PUT';
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "adminconsole2";
exports.labelBodyText = "adminconsole2 Body";
exports.getListingData = 'admin/admin_staffs'
exports.getRolesList = 'roles_permissions/roles?admin_roles=true'
exports.deleteAllAPIEndpoint='admin/admin_staffs/bulk_destroy'

exports.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.mobileRegex = /^(?:\+\d{1,3}\s?)?\d{3,20}$/;
exports.nameRegex = /^[A-Za-z\s'-]+$/;
exports.passwordRegex = /^(?=.*[!@#$%^&*()\-=_+[\]{}|;:'",.<>?/])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,}$/;

exports.btnExampleTitle = "CLICK ME";

exports.english={
  email:"Email",
  action:"Action",
  mobileNumber:"Mobile No.",
  role:"Role",
  all:"All",
  name:"Name",
  newest:"Newest",
  ascendingA:"Ascending A",
  z:'Z',
  a:"A",
  descendingZ:"Descending Z",
  apply:"Apply",
  cancel:"Cancel",
  clearAll:"Clear All",
  filters:"Filters",
  sort:"Sort",
  searchClient:"Search Client",
  deleteAll:"Delete All",
  adminStaff:"Active Staff",
  registerNewAdmin:"Register New Admin",
  areYouSureYouWantToDelete:"Are you sure you want to delete?",
  onceDoneCantBeUndone:"Once deleted, it can’t be undone.",
  firstName:"First Name",
  lastName:"Last Name",
  emailAddress:"Email Address",
  mobileNumberLabel:"Mobile Number",
  enterMobileNumber:"Enter mobile number",
  selectRole:"Select Role",
  enterEmailAddress:"Enter email address",
  enterLastName:"Enter last name",
  enterFirstName:"Enter first name",
  changePassword:"Change Password",
  adminCOnsole2:"randomStr",
  createPassword:"Create Password",
  createAPassword:"Create a password",
  confirmPassword:"Confirm Password",
  editProfile:"Edit Profile",
  yourInvitationHasBeenSuccessfullySent:"Your invitation is successfully sent!",
  editAdminStaff:"Edit Admin Staff",
  admin:"Admin",
  superAdmin:"Super Admin",
  oneOfTheFieldIsInvalidOrEmpty:"One of the required field is empty or contains invalid data!",
  discardChanges:"Discard Changes",
  sendInvite:"Send Invite",
  save:"Save",
  edit:"Edit",
  delete:"Delete",
  showing:"Showing",
  from:"from",
  yourInviteHasBeenSentSuccessfully:"Your invite has been sent successfully!"
}

exports.arabic={
  name:"الاسم",
  showing:"عرض",
  from:"من",
  all:"الجميع",
  email:"البريد الإلكتروني",
  action:"الإجراء",
  mobileNumber:"رقم الهاتف",
  role:"المسمى الوظيفي",
  newest:"الأحدث",
  ascendingA:"أ تصاعدي",
  z:'ي',
  a:"أ",
  descendingZ:"ي تنازلي",
  apply:"تطبيق",
  cancel:"إلغاء",
  clearAll:'مسح الكل',
  filters:'تصفية',
  sort:"ترتيب",
  searchClient:"البحث عن عميل",
  deleteAll:"حذف الكل",
  adminStaff:"المشرفين",
  registerNewAdmin:"إضافة مشرف جديد",
  areYouSureYouWantToDelete:"هل أنت متأكد من حذف طلب التفريغ؟",
  onceDoneCantBeUndone:"لا يمكن إستعادة الفئة بعد حذفها",
  firstName:"الاسم الأول",
  lastName:"الاسم الأخير",
  emailAddress:"البريد الإبكتروني",
  mobileNumberLabel:"رقم الهاتف",
  enterMobileNumber:"أدخل رقم الهاتف",
  selectRole:"حدد الدور",
  enterEmailAddress:"أدخل البريد الإلكتروني",
  enterLastName:"أدخل الاسم الأخير",
  enterFirstName:"أدخل الاسم الأول",
  yourInviteHasBeenSentSuccessfully: "!تم إرسال الدعوة للعميل بنجاح",
  changePassword:"تغيير كلمة المرور",
  createPassword:"كلمة المرور",
  createAPassword:"أنشئ كلمة مرور",
  confirmPassword:"تأكيد كلمة المرور",
  editProfile:"تعدّيل",
  admin:"مشرف",
  superAdmin:"مدير",
  yourInvitationHasBeenSuccessfullySent:"!تم إرسال الدعوة للعميل بنجاح",
  editAdminStaff:"تعدّيل المشرف",
  oneOfTheFieldIsInvalidOrEmpty:"أحد الخانات فارغة أو المدخلات غير مدعومة",
  discardChanges:"تجاهل التغييرات",
  sendInvite:"أرسل الدعوة",
  save:"حفظ",
  edit:"تعدّيل",
  delete:"حذف",
}

// Customizable Area End