import React from "react";
import "./DropDown.css";
import { upArrow, downArrow } from "./assets";

export interface InputProps {
  name: string;
  labelName?: string;
  value?: any;
  placeholder?: string;
  onChangeText?: (arg: any) => void;
  customStyles?: string; // className
  customStyle?: any; // Object styles inline
  error?: boolean | null;
  type?: string;
  errorMessage?: string;
  errorMessageStyle?: any;
  customContainerStyle?: string;
  showEndEndornment?: boolean;
  disable?: boolean;
  dropDownList: Array<any>;
  currentSelected?: string;
  setCurrentSelected: (arg: string, arg2: any) => void;
  onBlur?: any;
  disableDropdown?: boolean;
}

interface State {
  showDropDown: boolean;
}

export default class DropDown extends React.Component<InputProps, State> {
  dropDownRef: any;
  constructor(props: any) {
    super(props);
    this.state = {
      showDropDown: false,
    };
    this.dropDownRef = React.createRef();
  }

  componentDidMount(): void {
    document.addEventListener("click", this.handleDocumentClick);
  }

  componentWillUnmount(): void {
    document.removeEventListener("click", this.handleDocumentClick);
  }

  handleDocumentClick = (event: MouseEvent) => {
    if (
      this.dropDownRef.current &&
      !this.dropDownRef.current.contains(event.target as Node)
    ) {
      // The click occurred outside of the component
      this.setState({ showDropDown: false });
    }
  };

  handleClickShowPassword = (event: any) => {
    event.preventDefault();
    if (this.props.dropDownList.length !== 0 && !this.props.disableDropdown) {
      this.setState({ showDropDown: !this.state.showDropDown });
    }
  };

  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  onInputBlur = (event: any) => {
    if (!this.props.disableDropdown) {
      this.props.onBlur && this.props.onBlur(event);
      setTimeout(() => {
        if (this.state.showDropDown) {
          this.setState({ showDropDown: false });
        }
      }, 400);
    }
  };

  onInputFocus = () => {
    if (this.props.dropDownList.length !== 0 && !this.props.disableDropdown) {
      this.setState({ showDropDown: true });
    }
  };

  endAdornment = () => {
    return (
      <button
        data-testid={"iconButton"}
        aria-label="toggle password visibility"
        onClick={this.handleClickShowPassword}
        onMouseDown={this.handleMouseDownPassword}
        className="dropdown-endornment-icon"
      >
        <img
          src={this.state.showDropDown ? upArrow : downArrow}
          className="img-dropdown-icon"
        />
      </button>
    );
  };

  getErrorStyle = () => {
    if (this.props.error !== null) {
      if (this.props.error) {
        return { outline: "none", border: "1px solid rgba(220, 38, 38, 1)" };
      }
      return { outline: "none", border: "1px solid #bfcc33" };
    }
    return {};
  };

  onItemPress = (event: any, item: string) => {
    event.preventDefault();
    this.props.setCurrentSelected(this.props.name, item);
    this.setState({ showDropDown: false });
  };

  onChangeText = (event: any) => {
    let newValue = { id: "", attributes: { name: event.target.value } };
    this.props.setCurrentSelected(this.props.name, newValue);
  };

  renderDropDown = () => {
    return (
      <ul className="drop-down-container" data-testid={"dropdownMenu"}>
        {this.props.dropDownList.map((item: any, index: number) => {
          return (
            <li
              data-testid={`dropDownItem${index + 1}`}
              onClick={(event) => this.onItemPress(event, item)}
              className="drop-down-item"
              key={index}
            >
              {item?.attributes?.name}
            </li>
          );
        })}
      </ul>
    );
  };
  render() {
    const {
      name,
      value,
      placeholder,
      disable,
      disableDropdown,
      currentSelected,
      labelName = "",
      customStyles,
      customStyle, //inline Object
      customContainerStyle,
      showEndEndornment = true,
    } = this.props;
    return (
      <div className={`dropdown-outer-container ${customContainerStyle}`}>
        <p className={"dropdown-input-name"}>{labelName}</p>
        <div
          ref={this.dropDownRef}
          style={{ ...this.getErrorStyle(), ...customStyle }}
          className={`dropdown-container ${customStyles}`}
        >
          {disable ? (
            <div
              className={`${
                currentSelected !== "" ? "dropdown-placeholder-selected" : ""
              } dropdown-placeholder`}
              onClick={this.handleClickShowPassword}
              onMouseDown={this.handleMouseDownPassword}
            >
              {currentSelected !== "" ? currentSelected : placeholder}
            </div>
          ) : (
            <input
              value={value?.attributes?.name}
              data-testid={"dropDownInput"}
              placeholder={placeholder}
              name={name}
              onChange={this.onChangeText}
              onFocus={this.onInputFocus}
              onBlur={this.onInputBlur}
              className="dropdown-input-field"
              autoComplete="off"
            />
          )}
          {showEndEndornment && this.endAdornment()}
        </div>
        {this.state.showDropDown &&
          this.props.dropDownList.length !== 0 &&
          this.renderDropDown()}
      </div>
    );
  }
}
