import React from "react";
// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Navbar from "../../settings2/src/Navbar.web";
import "./Dashboard.css"
import { backgroundClientDashboard, backgroundImage, iconTrash, iconUser, iconUserPlus, imageEmpty, vectorDown } from "./assets";
import { TimeChoice, listDropdown } from "./DashboardController.web";
import ChartComponent, { ChartType, ChartComponentProps } from "./ChartComponent.web";
import ServiceMaintainancePopUp, { ListService } from "./ServiceMaintainancePopUp.web";
import { ClickAwayListener } from "@material-ui/core";
import Strings from './Strings'
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getItemListStatistic(index: number) {
    if (index === 0) return {
      icon: iconUser,
      title: Strings.totalClientText
    }
    else if (index === 1) return {
      icon: iconUserPlus,
      title: Strings.newClient
    }
    else return {
      icon: iconTrash,
      title: Strings.totalWasteNetWeight
    }
  }

  renderListStatistic() {
    return [{ statistic: this.state.totalClient }, { statistic: this.state.totalNewClient, change: `${this.state.totalNewClientPercentage > 0 ? "+" : "-"}${this.state.totalNewClientPercentage}` }, { statistic: this.state.totalWasteNet }].map((item: any, index: number) => (
      <div className="itemStatistic" key={index}>
        <div className="itemStaticInnerContainer">
          <img src={this.getItemListStatistic(index).icon} className="item_icon" />
          <div className="itemdetail_container">
            <div className="headerTextStatistic">{this.getItemListStatistic(index).title}</div>
            <div className="textItem">
              <div className="textNumber">{item.statistic?.toLocaleString()}</div>
              {!!item.change && (<div className="textChange">{`${item.change}%`}</div>)}
            </div>
          </div>
        </div>
      </div>
    ))
  }

  renderHeaderTime({ onPressYear, onPressMonth, isYear, idTestYear, idTestMonth }: { onPressYear: () => void; onPressMonth: () => void; isYear: boolean; idTestYear: string; idTestMonth: string }) {
    return (
      <div className="view_headerTime">
        <div data-test-id={idTestYear} onClick={() => onPressYear()} className={`${isYear ? 'chosenTimeText' : 'unchosenTimeText'}`} >{Strings.yearlyText}</div>
        <div style={{ width: "14px" }} />
        <div data-test-id={idTestMonth} onClick={() => onPressMonth()} className={`${!isYear ? 'chosenTimeText' : 'unchosenTimeText'}`} >{Strings.monthlyText}</div>
      </div>
    )
  }

  renderBarChart({ label, dataArray, suggestedMax, aspectRatio,typeIndex }: ChartComponentProps) {
    return <ChartComponent chartType={ChartType.BAR} label={label} dataArray={dataArray} suggestedMax={suggestedMax} aspectRatio={aspectRatio} typeIndex ={typeIndex} />
  }

  renderLineChart() {
    return <ChartComponent chartType={ChartType.LINE} aspectRatio={3} dataArray={this.state.listDataCollectionMonth} suggestedMax={this.state.maxValueBarCollectionMonth * 1.1} label={this.state.listLabelCollectionMonth} />
  }

  renderDropdown() {
    return (
      <ClickAwayListener onClickAway={this.closeDropDown}>
      <div className="viewDropdown">
        <div
          className={'viewTitleDropdown'}
          onClick={this.toggleDropdown}
          tabIndex={0}
          data-test-id="dropdown"
        >
          <p className="textTitleDropdown">{listDropdown[this.state.typeIndex]}</p>
          <img className={`iconDropdown ${this.state.isOpen?'rotateIcon':''}`} src={vectorDown} />
        </div>
        {this.state.isOpen && (
          <div style={webStyle.viewListDropdown}>
            {listDropdown.map((option: string, index: number) => (
              <li
                key={index}
                style={{
                  cursor: 'pointer',
                  padding: '5px 0',
                }}
                onClick={() => this.selectOption(index)}
                data-test-id={`dropdown_${option}`}
              >
                <p style={webStyle.textOption}>{option}</p>
              </li>
            ))}
          </div>
        )}
      </div>
      </ClickAwayListener>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    if (this.state.totalWasteNet === 0) {
      return (
        //Merge Engine DefaultContainer
        <div id="element" className="main-wrapper">
          <NavigationMenu navigation={this.props.navigation} id={""} />
          <div style={webStyle.container} className="dashboard_container_wrapper">
            <div className="dashboard_navbar_container">
              <Navbar navigation={this.props.navigation} id={""} />
            </div>
            <div className="dashboard_body_container">
              {
                this.state.totalClient !== 0 && (
                  <div className="viewStatistic">
                    {this.renderListStatistic()}
                  </div>
                )
              }
              <div style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center',marginBottom: '20px' }}>
                <img src={imageEmpty} style={webStyle.imageEmpty} />
                <div style={webStyle.emptyText}>{Strings.emptyText}</div>
              </div>
            </div>
          </div>
        </div>
        //Merge Engine End DefaultContainer
      );
    }
    return (
      //Merge Engine DefaultContainer
      <div id="element" className="main-wrapper">
        <NavigationMenu navigation={this.props.navigation} id={""} />
        <div style={webStyle.container} className="dashboard_container_wrapper">
          <div className="dashboard_navbar_container">
            <Navbar navigation={this.props.navigation} id={""} />
          </div>
          {/* body container */}
          <div className="dashboard_body_container">
            {/* card part */}
            <div className="viewStatistic">
              {this.renderListStatistic()}
            </div>
            {/* chart part  */}
            <div className='chart_part_container'>
              {/* chart Recycling collection */}
              <div className='recycle_collection_container'>
                <div className="recycle_inner_container">
                  <div className="recycle_Header">
                    <div className="view_headerText">{Strings.recyclingCollectionHeader}</div>

                    {this.renderHeaderTime({
                      onPressYear: () => { this.setState({ chosenTimeCollection: TimeChoice.YEAR }) },
                      onPressMonth: () => { this.setState({ chosenTimeCollection: TimeChoice.MONTH }) },
                      isYear: this.state.chosenTimeCollection === TimeChoice.YEAR,
                      idTestYear: "collectionYearButton",
                      idTestMonth: "collectionMonthButton",
                    })}
                  </div>
                  {/* cahrt */}
                  <div className="recycle_chart_canvas_container">
                  <div className="recycle_chart_canvas_container_inner">
                    {this.state.chosenTimeCollection === TimeChoice.YEAR ?
                      this.renderBarChart({
                        label: this.state.listLabelCollectionYear,
                        suggestedMax: this.state.maxValueBarCollectionYear * 1.2,
                        dataArray: this.state.listDataCollectionYear,
                        aspectRatio: 2.5
                      }) : this.renderLineChart()}
                  </div>
                  </div>
                  {/*  */}
                </div>
              </div>
              {/*  Service Maintainance */}
              <div className='maintainace_collection_container'>
                <div className="maintaince_inner_container">
                  <div className="maintainace_headerText">{Strings.serviceHeader}</div>
                  <div style={{ display: "flex", flexDirection: 'column' }}>
                    <ListService listServices={this.state.listServicesMain.slice(0,4)} isSlice={true} />
                  </div>
                  {
                    this.state.listServices.length > 4 && (
                      <div data-test-id="viewMore" className="viewMoreText" onClick={() => this.showViewMore()}>{Strings.viewMoreText}</div>
                    )
                  }
                </div>
              </div>


            </div>
            {/*  */}

            <div className="viewEnvironmental">
              <div className="environmental_inner_container">
              
              <div className="envir_Header">
                <div className="envir_headerText">{Strings.environmentalHeader}</div>

                <div className="envir_drop_Select_container">
                  {this.renderHeaderTime({
                    onPressYear: () => { this.setState({ chosenTimeEnvironment: TimeChoice.YEAR }) },
                    onPressMonth: () => { this.setState({ chosenTimeEnvironment: TimeChoice.MONTH }) },
                    isYear: this.state.chosenTimeEnvironment === TimeChoice.YEAR,
                    idTestYear: "environmentYearButton",
                    idTestMonth: "environmentMonthButton",
                  })}

                  {this.renderDropdown()}
                </div>

              </div>
             <div className="envir_chart_canvas_container">
              <div className="envir_chart_canvas_container_inner">

              {this.renderBarChart({
                label: this.state.listLabelEnvironment,
                suggestedMax: this.state.maxValueBarEnvironment * 1.2,
                dataArray: this.state.listDataEnvironment,
                aspectRatio: 4.5,
                typeIndex:this.state.typeIndex
              })}
              </div>
              </div>
              </div>
            </div>

          </div>
        </div>
        <ServiceMaintainancePopUp
          testId="ServiceMaintainancePopUp"
          onPressClose={() => this.hideViewMore()}
          isOpen={this.state.isOpenViewMore}
          listServices={this.state.listServices}
          cityList={this.state.cityList}
          searchQueryState={this.state.searchQueryState}
          setSearchQueryState={this.setSearchQueryState}
          onDelete={(e: Array<string>) => this.postDeleteAll(e)}
        />
      </div>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const webStyle = {
itemStatisticClient: {
    backgroundImage: `url(${backgroundClientDashboard})`,
    display: "flex",
    padding: "20px",
    aligntems: "flex-start",
    flexDirection: 'column',
    backgroundSize: "130% 100%",
    backgroundColor: "#E1F0E6",
    gap: "16px",
    width: "calc(100% - 32px)",
    marginTop: "20px",
    marginBottom: "20px",
    borderRadius: "12px"
  } as any,
  icon: {
    width: '36px',
    height: '36px',
  },
  viewStatistic: {
    flexDirection: 'row',
    width: "100%",
    justifyContent: "space-between",
    display: 'flex',
    marginTop: "40px",
    marginBottom: "20px"
  } as any,
  container: {
    backgroundColor: "#F6F7FA"
  },
  imageEmpty: {
    alignSelf: "center",
    width: "394px",
    height: "320px",
    marginTop: "80px"
  },
  emptyText: {
    color: "#000",
    fontSize: "20px",
    lineHeight: "24px",
    textAlign: 'center',
    marginTop: "20px",
    fontFamily: 'Barlow Semi Condensed',
  } as any,
itemStatistic: {
    backgroundImage: `url(${backgroundImage})`,
    display: "flex",
    padding: "20px",
    aligntems: "flex-start",
    flexDirection: 'column',
    backgroundSize: "100% 100%",
    backgroundColor: "#E1F0E6",
    gap: "16px",
    width: "28%",
    paddingTop: "20px",
    paddingBottom: "20px",
    borderRadius: "12px"
  } as any,
  textItem: {
    display: "flex",
    flexDirection: "row",
    fontFamily: 'Barlow Semi Condensed',
    alignItem: "center",
  } as any,
  textNumber: {
    color: "#1C3045",
    fontSize: "28px",
    fontWeight: "700",
    fontFamily: 'Barlow Semi Condensed',
    letterSpacing: "0.28px"
  } as any,
  textChange: {
    color: "#1C3045",
    fontSize: "12px",
    fontWeight: "700",
    lineHeight: "18px",
    letterSpacing: "0.06px",
    fontFamily: 'Barlow Semi Condensed',
    paddingLeft: "12px",
    margin: "auto 0"
  } as any,
  headerTextStatistic: {
    color: "#1C3045",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    fontFamily: 'Barlow Semi Condensed',
    letterSpacing: "0.08px"
  } as any,
  rowView: {
    display: "flex",
    flexDirection: "row",
    marginTop: "18px",
    width: "100%",
    justifyContent: "space-between"
  } as any,
  viewCollection: {
    display: "flex",
    flexDirection: "column",
    width: "62%",
    backgroundColor: "#fff",
    borderRadius: "12px",
    padding: "24px",
  } as any,
  viewService: {
    display: "flex",
    flexDirection: "column",
    width: "27.5%",
    backgroundColor: "#fff",
    borderRadius: "12px",
    padding: "24px",
  } as any,
  viewEnvironmental: {
    display: "flex",
    width: "calc(100% - 48px)",
    flexDirection: "column",
    backgroundColor: "#fff",
    borderRadius: "12px",
    padding: "24px",
    marginTop: "18px",
    marginBottom: "24px",
  } as any,
  headerText: {
    color: "#1C3045",
    fontFamily: 'Barlow Semi Condensed',
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "30px",
    letterSpacing: "0.24px",
    marginBottom: "10px"
  } as any,
  viewMoreText: {
    color: "#32569A",
    fontFamily: 'Barlow Semi Condensed',
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "24px",
    width: "100%",
    justifyContent: "flex-end",
    display: "flex",
  } as any,
  viewTime: {
    display: "flex",
    flexDirection: "row",
    padding: "2px 12px 2px 12px",
    borderRadius: "10px",
    border: "1px solid #E0E2E7"
  } as any,
  chosenTimeText: {
    color: "#1C3045",
    fontFamily: 'Barlow Semi Condensed',
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "30px",
    letterSpacing: "0.14px",
    textDecorationLine: "underline",
    textDecorationSkipInk: "none"
  } as any,
  unchosenTimeText: {
    color: "rgba(28, 48, 69, 0.50)",
    fontFamily: 'Barlow Semi Condensed',
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "30px",
    letterSpacing: "0.14px",
  } as any,
  viewHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "12px"
  } as any,
  viewDropdown: {
    position: 'relative',
    display: 'inline-block',
    minWidth: '200px',
    marginLeft: "20px"
  } as any,
  viewTitleDropdown: {
    position: 'relative',
    padding: '10px 12px',
    backgroundColor: 'transparent',
    border: '1.5px solid #E0E2E7',
    borderRadius: '8px',
    cursor: 'pointer',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  } as any,
  textTitleDropdown: {
    color: "#1C3045",
    fontWeight: 600,
    lineHeight: "18.2px",
    fontFamily: 'Barlow Semi Condensed',
    fontSize: '14px',
    alignItems: 'center'
  } as any,
  iconDropdown: {
    marginLeft: '10px',
    width: "14px",
    height: "14px",
    objectFit: "contain"
  } as any,
  viewListDropdown: {
    left: '0',
    zIndex: 1000,
    position: 'absolute',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '12px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
    marginTop: '39px',
    width: "calc(100% - 22px)"
  } as any,
  textOption: {
    color: "#1C3045",
    fontFamily: 'Barlow Semi Condensed',
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "18.2px"
  },
  viewSegment: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 32px)",
    backgroundColor: "#fff",
    borderRadius: "12px",
    padding: "24px",
    marginTop: "18px",
    marginBottom: "24px"
  } as any
}
// Customizable Area End
