import React from "react";

// Customizable Area Start
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Box, FormControl, Link, MenuItem, Select } from "@material-ui/core";
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import Strings from "../../../components/src/Strings copy";
import { global } from "./assets";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  heading : string = 'Terms and Conditions' 
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
      <div className="terms-condition-container">

        <Box sx={{ 
          display : 'flex',
          flexDirection : 'row',
          justifyContent : 'space-between',
          alignItems : 'center',
          marginBottom : '1.31rem '
        }}>
          <div style={{display : 'flex',flexDirection : 'row', alignItems : 'center'}}>
          <Link href="/" style={{color:'#1C3045'}}><span style={{ marginRight : '1.37rem'}} ><KeyboardBackspaceIcon/> </span></Link>
            <div style={header}>
              {Strings.heading}
            </div>
            </div>

            <div>
            <img src={global} style={{ paddingRight: '10px', marginTop: "4px" , height : "24px" , width: "24px"}} />
            <FormControl>
              <Select
                disableUnderline
                IconComponent={KeyboardArrowDownOutlinedIcon}
                defaultValue= {this.state.language}
                data-testid = "language-dropdown"
                onChange={(event) => this.setOption(event)}
                inputProps={{ 'aria-label': 'Without label', }}
              >
                <MenuItem value={"english"}>{Strings.english}</MenuItem>
                <MenuItem value={"arabic"}>{Strings.arabic}</MenuItem>
              </Select>
            </FormControl>
            </div>
        </Box>

        <Box id="termsConds" sx={{
          display : 'flex',
          flexWrap: 'Wrap',
          flexShrink : '1',
          maxHeight : '43.75rem',
          fontSize : '0.875rem',
          fontWeight : 400,
          lineHeight : '1.5rem',
          whiteSpace : 'pre-wrap',
          overflow: 'auto',
          fontFamily : 'Barlow Semi Condensed'
        }} >

                {this.state.termsConds}
        </Box>

      </div>
      </>
    )   
    // Customizable Area End
  }
}

// Customizable Area Start

const header = { 
  fontFamily : 'Barlow Semi Condensed',
  fontSize : '2.5rem',
  fontWeight : 600,
  lineHeight : '125%',
  letterSpacing : '0.00925rem',
  color: '#203b54'
}

// Customizable Area End
