import React from 'react';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Menu,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Divider,
  withStyles,
  ClickAwayListener,
} from "@material-ui/core";
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import NavbarController from './NavbarController.web';
import { MenuIcon, Naqaa, downArrow, frame, globalImg, notification, signout } from './assets';
import "./Navbar.web.css"
import Strings from './Strings'



const styles = {
  menuItem: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#E1F0E6',
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: '#E1F0E6',
      },
    },
  },
};

export class Navbar extends NavbarController {

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;
    const open = Boolean(anchorEl);
    return (
      <div className='main_container_navbar'>
        <div style={{ display: 'flex' }} >
          <div style={webStyle.welcomeBoxContainer} >

            <div className='hide-desk-element tab-logo-container'>
              <img src={MenuIcon} alt="logo" className='menu-logo' onClick={this.showNavigationMenu} />
              <img src={Naqaa} alt="logo" className='img-logo' />
            </div>

            {this.state.path.includes('Dashboard') &&
              <div style={{ marginLeft: '2rem', marginTop: '2rem' }} className='hide-tab-element'>
                <h1 className='welcomeText'>
                  {
                    this.state.userObj.account_role === "Client" || this.isViewAsClient()
                      ? `${Strings.welcomeBack}, ${this.state.userObj.account?.company_name}`
                      : `${Strings.welcomeBack}, ${this.state.userObj.account?.first_name}`
                  }
                </h1>
                <p className='welcomeSubText'>{Strings.weAreHappyToHelpManageYourWaste}</p>
              </div>
            }
          </div>
          <div className='main_section' >

            {/* side section */}
            <div style={{ display: 'flex' }} className='navbar-hide-mob-element'>
              <img src={globalImg} style={{ paddingRight: '10px', marginTop: "4px", width: '24px', height: '24px' }} />
              <ClickAwayListener data-testId={'clickAwayListener'} onClickAway={this.onLanguageClose}>
              <FormControl>
                <Select
                  disableUnderline
                  IconComponent={() => (
                    <img src={downArrow}
                      onClick={this.onLanguageToggle}
                      style={{ color: '#292D32', height: '24px', cursor:'pointer' }}
                    />
                  )}
                  data-testId={'languageSelect'}
                  open={this.state.languageOpen}
                  classes={{root:"navbar_select_root"}}
                  onClick={this.onLanguageToggle}
                  value={this.state.currentLanguage}
                  inputProps={{ 'aria-label': 'Without label', }}
                  style={{ marginLeft: '16px', fontFamily: 'Barlow Semi Condensed', fontSize: '14px', fontWeight: 400, width: "5rem" }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      style: {
                        display: 'flex',
                        paddingLeft: '4px',
                        paddingRight: '4px',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '8px',
                        flexShrink: 0,
                        borderRadius: '8px',
                        border: '1px solid #E2E8F0',
                        background: '#FFF',
                        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)',
                        marginLeft: "-3rem",
                        marginTop: "0.7rem"
                      }
                    }
                  }}
                >
                  <MenuItem
                    value={"english"}
                    onClick={() => this.setLanguageOption('english')}
                    style={{
                      ...webStyle.menu_item,
                      marginBottom: "4px"
                    }}
                    className={classes.menuItem}
                  >
                    {Strings.english}
                  </MenuItem>
                  <MenuItem
                    value={"arabic"}
                    onClick={() => this.setLanguageOption('arabic')}
                    style={{
                      ...webStyle.menu_item,
                      marginTop: "4px"
                    }}
                    className={classes.menuItem}
                  >
                    {Strings.arabic}
                  </MenuItem>
                </Select>
              </FormControl>
              </ClickAwayListener>
            </div>
            {/* notification */}
            <div className='notification_container'>
              <IconButton aria-label="show 11 new notifications" onClick={() => this.toggle('open')} data-testid="bell-icon" color="inherit" >
                <img src={notification} />
                {this.getDot()}
              </IconButton>

              {this.state.showPopover &&
              <div className="notification_modal_container">
                {this.getScreenForUI()}
              </div>
              }
            </div>

            <div style={{ display: 'flex', marginRight: this.state.userRole === 'Client' ? '2rem' : '0px' }} className='tab-userProfile-container'>
              <div style={{ backgroundColor: this.state.userObj?.profile_picture ? 'unset' : '#32569A' }} className='img_div hide-tab-element '>
              {this.getUserProfileIamge()}
              </div>

              <div style={{ backgroundColor: this.state.userObj?.profile_picture ? 'unset' : '#32569A' }}
                onClick={this.handleClick}
               className='img_div hide-desk-element  show-tab-profile-element '>
              {this.getUserProfileIamge()}
              </div>
              <div className='hide-tab-element'>
                <Box style={{ display: 'flex', flexDirection: 'column' }} >
                  <Typography id="full-name" style={webStyle.name_text}>{this.state.userObj.account?.full_name} </Typography>
                  <Typography style={webStyle.email_text}>{this.state.userObj.account?.email}</Typography>
                </Box>
              </div>

              <div>
                <div className='hide-tab-element'>
                  <IconButton
                    onClick={this.handleClick}
                    size="small"
                    style={{ marginLeft: 2 }}
                    aria-owns={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}

                  >
                    <img src={downArrow} style={webStyle.keyboard_arrow} />
                  </IconButton>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={this.handleClose}
                  onClick={this.handleClose}
                  MenuListProps={{
                    style: {
                      width: '160px',
                      borderRadius: '10px',
                    },
                  }}
                  classes={{paper:'menu_papaer_props'}}
                  PaperProps={{
                    style: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      width: '160px',
                      height: this.state.userRole == "Client" ? '60px' : '90px',
                      borderRadius: '10px',
                      transformOrigin: 'top right',
                      transform: 'translateX(-25px)',
                    },
                  }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                  <MenuItem data-test-id="navigateAccount" onClick={this.navigateAccount} style={webStyle.account_text} classes={{root:'menuItem_root'}}>
                    <img src={frame} style={{ width: '24px', height: '24px', marginRight: '5px' }} />
                    {Strings.account}
                  </MenuItem>
                  {this.state.userRole !== "Client" && <MenuItem onClick={this.handleClickOpen} style={webStyle.logout_text} classes={{root:'menuItem_root'}}>
                    <img src={signout} style={{ width: '24px', height: '24px' }} />
                    <span onClick={this.handleClickOpen} style={{ marginLeft: '5px' }}>{Strings.signOut}</span>
                  </MenuItem>
                  }
                </Menu>
              </div>
            </div>


          </div>

          <Dialog
            open={this.state.open}
            onClose={this.handleCloseDilog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="xs"
            PaperProps={{
              style: {
                overflow: 'hidden',
                borderRadius: '8px 8px 32px 8px',
                width: '600px',
                height: '220px',
                maxWidth: 'none',
              },
            }}
          >
            <Typography
              style={{
                fontSize: '16px',
                fontFamily: 'Barlow Semi Condensed',
                color: '#0F172A',
                fontWeight: 600,
                marginLeft: '45px',
                marginTop: '50px'
              }}
            >
              {Strings.areYouSureYouWantToLogOut}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={this.handleCloseDilog}
              aria-label="close"
              style={{
                position: 'absolute',
                top: '16px',
                right: '16px',
                padding: '8px',
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent style={{ overflowY: 'hidden' }}>
              <DialogContentText id="alert-dialog-description" >
                <Typography style={{ fontSize: '16px', fontFamily: 'Barlow Semi Condensed', color: '#0F172A', fontWeight: 400, marginLeft: '20px' }}>
                  {Strings.yourUnsavedDataWillBeLost}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button variant="outlined" style={webStyle.cancel_btn} onClick={this.handleCloseDilog}>
                {Strings.cancel}
              </Button>
              <Button variant="contained" style={webStyle.logout_btn} onClick={this.handleLogout}>
                {Strings.logout}
              </Button>
            </DialogActions>
          </Dialog>

        </div>
        {this.state.path.includes('Dashboard') &&
          <div style={{ marginLeft: '2rem' }} className='hide-desk-element tab-welcome-container'>
            <h1 className='welcomeText'>
              {
                this.state.userObj.account_role === "Client" || this.isViewAsClient()
                  ? `Welcome back, ${this.state.userObj.account?.company_name}`
                  : `Welcome back, ${this.state.userObj.account?.first_name}`
              }
            </h1>
            <p className='welcomeSubText'>We’re happy to help manage your waste.</p>
          </div>
        }
      </div>

    )
  }
}
const webStyle = {

  keyboard_arrow: {
    color: '#2C3659',
    marginLeft: '30px',
    marginRight: '20px',
    marginTop: '5px'
  },
  name_text: {
    fontFamily: 'Barlow Semi Condensed',
    fontWeight: 700,
    color: '#1C3045',
    fontSize: '16px'
  },
  email_text: {
    fontFamily: 'Barlow Semi Condensed',
    fontWeight: 400,
    color: '#A7AEC1',
    fontSize: '14px'
  },
  logout_btn: {
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: "none",
    marginRight: '30px',
    backgroundColor: '#DC2626',
    color: '#FFFFFF'
  } as const,
  cancel_btn: {
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 700,
    textTransform: "none",
    margin: '20px',
    backgroundColor: '#FFFFFF',
    color: '#32569A',
    borderColor: '#32569A'
  } as const,
  menu_item: {
    color: "#32569A",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    borderRadius: "8px",
    display: "flex",
    height: "36px",
    padding: "6px 49px 6px 16px",
    alignItems: "center",
    alignSelf: "stretch",
    "&:hover": {
      backgroundColor: "#E1F0E6"
    }
  },
  account_text: {
    color: '#32569A',
    fontFamily: 'Barlow Semi Condensed',
    fontWeight: 500,
    fontSize: '16px',
    borderRadius: '10px'
  },
  logout_text: {
    color: '#DC2626',
    fontFamily: 'Barlow Semi Condensed',
    fontWeight: 500,
    fontSize: '16px',
    borderRadius: '10px'
  },
  welcomeBoxContainer: {
    width: '50%',
    backgroundColor: '#fff',
  },

  marginLeft: {
    left: '-10rem'
  },

  notificationDot: {
    position: 'absolute',
    left: '25px',
    top: '14px'
  }

};

export default withStyles(styles)(Navbar);