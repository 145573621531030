import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import * as Yup from "yup";
import { createRef } from "react";
import { create } from "domain";
import moment from "moment";
export const baseURL = require("../../../framework/src/config.js").baseURL;

interface Error {
  status: boolean | null;
  message: string;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  btnDisableFlag: boolean;
  error: Error;
  token: string;
  wasteCatagories: any[];
  clientList: any[];
  branchNames: any[];
  successModal: boolean;
  editId: number;
  viewMode: boolean;
  attachments_to_remove: any;
  // Customizable Area End
}
interface SS {}

export default class AddNewWasteBillController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getClientNamesApiId: any;
  getWasteCatagoryApiId: any;
  formSchema: any;
  formRef: any;
  fileInputRef: any;
  wasteCatagories: any = [];
  getImpactFactorAPIId: any;
  debounceTimer: any;
  getBranchAPIId: any;
  createWasteBillAPIId: any;
  getWastebillDataAPIId: any;
  initialValues: any = {};
  defaultValues = {
    id: "",
    type: "",
    attributes: {
      name: "",
    },
  };
  scrollRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      btnDisableFlag: false,
      error: { status: null, message: "" },
      token: "",
      wasteCatagories: [],
      clientList: [],
      branchNames: [],
      successModal: false,
      editId: -1,
      viewMode: false,
      attachments_to_remove: [],
    };

    this.formSchema = Yup.object().shape({
      wasteCatagory: Yup.string().required("Waste Catagory Required"),
      branchName: Yup.string().required("Branch Required"),
      recyclableNetWeight: Yup.number()
        .positive()
        .required(),
      reportDate: Yup.string().required("Report Date Required"),
      comment: Yup.string().notRequired(),
      attachments: Yup.array().notRequired(),
      treeSaved: Yup.number().notRequired(),
      energySaved: Yup.number(),
      co2Reduction: Yup.number(),
      landfillSpaceSaved: Yup.number(),
      waterSaved: Yup.number(),
      reductionPetroleumUsed: Yup.number(),
    });
    this.formRef = createRef();
    this.scrollRef = createRef();
    this.fileInputRef = createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    let token = await localStorage.getItem("token");
    let userDetails: any = await localStorage.getItem("userDetail");
    userDetails = JSON.parse(userDetails);
    let userRole = userDetails?.account_role;
    if (token && (userRole === "Super Admin" || userRole === "Admin")) {
      this.setState({ token });
      this.getWasteCatagoryList(token);
      this.getClientNamesListing(token);
      let editId = this.props.navigation.history.location?.state?.id;
      let viewMode = this.props.navigation.history.location?.state?.viewMode;
      if (editId) {
        this.setState({ editId: parseInt(editId), viewMode });
        this.getWasteBillData(editId, token);
      }
    } else {
      this.navigationAdapter("EmailAccountLoginBlock");
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (
      this.getClientNamesApiId !== null &&
      this.getClientNamesApiId === dataMessage
    ) {
      this.handleAPIResponse(
        successMessage,
        errorMessage,
        this.getClientListCallback
      );
    } else if (
      this.getWasteCatagoryApiId !== null &&
      this.getWasteCatagoryApiId === dataMessage
    ) {
      this.handleAPIResponse(
        successMessage,
        errorMessage,
        this.getWasteCatagoryCallback
      );
    } else if (
      this.getImpactFactorAPIId !== null &&
      this.getImpactFactorAPIId === dataMessage
    ) {
      this.handleAPIResponse(
        successMessage,
        errorMessage,
        this.getImpactFactorCallback
      );
    } else if (
      this.getBranchAPIId !== null &&
      this.getBranchAPIId === dataMessage
    ) {
      this.handleAPIResponse(
        successMessage,
        errorMessage,
        this.getBranchCallback
      );
    } else if (
      this.createWasteBillAPIId !== null &&
      this.createWasteBillAPIId === dataMessage
    ) {
      this.handleAPIResponse(
        successMessage,
        errorMessage,
        this.createWasteBillCallback
      );
    } else if (
      this.getWastebillDataAPIId !== null &&
      this.getWastebillDataAPIId === dataMessage
    ) {
      this.handleAPIResponse(
        successMessage,
        errorMessage,
        this.getWasteBillDataCallback
      );
    }
    // Customizable Area End
  }
  // Customizable Area Start

  navigationAdapter = (screen: string, params: any = {}) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screen); // name of the screen where we want to navigate
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), params); // from here we can passing params
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  getWasteBillData = (editId: number, token: string) => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWastebillDataAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createWastebill}/${editId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onModalCrossPress = () => {
    this.setState({ successModal: false });
    this.navigationAdapter("WasteBill");
  };

  getWasteCatagoryList = (token: string) => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWasteCatagoryApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWasteCatagory
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getClientNamesListing = (token: string) => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClientNamesApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointGetCompanyList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBranchNameListing = (id: any) => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBranchAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getBranchEndpoint}?account_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setClientName = (name: string, item: any) => {
    this.formRef.current.setFieldValue(name, item);
    this.formRef.current.setFieldValue("branchName", this.defaultValues);

    // API Call for Branches for this Client
    if (item.id !== "") {
      this.getBranchNameListing(item.id);
    }
  };

  handleClientNameChange = (event: any) => {
    let temp = { id: "", attributes: { name: event.target.value } };
    this.formRef.current.setFieldValue("clientName", temp);
  };

  handleRecyclableNetWeight = (event: any, wasteId: any) => {
    this.formRef.current.handleChange(event);
    const newSearchTerm = event.target.value;

    // Clear the previous debounce timer
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    // Set a new debounce timer
    this.debounceTimer = setTimeout(() => {
      this.getImpactFactor(newSearchTerm, wasteId); // Pass the debounced searchTerm
    }, 1000); // Adjust the debounce delay as needed
  };

  getImpactFactor = (value: string, wasteId: string) => {
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getImpactFactorAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getImpactFactorEndpoint}?recyclable_net_weight=${value}&waste_type_category_id=${wasteId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFileUploadClick = () => {
    this.fileInputRef.current.click();
  };

  formateDate(date: string) {
    let formated = moment(date).format("DD/MM/YYYY");
    return formated.toString();
  }

  handleDateChange = (event: any) => {
    this.formRef.current.setFieldValue(
      "reportDate",
      moment(event.target.value).isValid()
        ? this.formateDate(event.target.value)
        : ""
    );
  };

  handleDownload = async (item: any) => {
    let response = await fetch(`${baseURL}${item?.attachment_url}`);
    let blobImage = await response.blob();
    let href = URL.createObjectURL(blobImage);
    let anchorElement = document.createElement("a");
    anchorElement.href = href;
    anchorElement.download = item?.name;
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  };
  handleFileDrop = (event: any) => {
    event.preventDefault();
    console.log("event target", event.dataTransfer.files);
    this.formRef.current.setFieldValue("attachments", [
      ...this.formRef.current.values.attachments,
      ...event.dataTransfer.files,
    ]);
  };

  handleDragEnter = (e: any) => {
    e.preventDefault();
  };

  handleDragLeave = (e: any) => {
    e.preventDefault();
  };

  handleDragOver = (e: any) => {
    e.preventDefault();
  };

  handleDeleteFile = (index: number) => {
    let temp = this.formRef.current.values.attachments;
    if (temp[index].blob_id) {
      this.setState({
        attachments_to_remove: [
          ...this.state.attachments_to_remove,
          temp[index].blob_id,
        ],
      });
    }
    temp.splice(index, 1);
    this.formRef.current.setFieldValue("attachments", temp);
  };

  updateWastebillData = (data: any) => {
    this.state.attachments_to_remove.forEach((item: any) => {
      data.append("waste_bill[attachments_to_remove][]", item);
    });

    const header = {
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createWasteBillAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createWastebill}/${this.state.editId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editUserApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleReset = () => {
    if (Object.keys(this.initialValues).length === 0) {
      this.formRef.current?.handleReset();
    } else {
      this.updateForm(this.initialValues);
    }
  };

  updateForm = (form: any) => {
    const {
      waste_type_category_id,
      branch_name_id,
      report_date,
      comment,
      tree_saved,
      kilo_watt_of_energy_saved,
      co2_eq_reduction,
      landfill_space_saved,
      water_saved,
      reduction_petroleum_use,
      waste_type_category_name,
      branch_name,
      account_company_name,
      account_id,
      attachments,
      recyclable_net_weight,
    } = form;
    let attachment = [];
    if (attachments !== null && attachments.length > 0) {
      attachment = attachments.map((item: any) => {
        return {
          name: item?.file_name,
          blob_id: item?.blob_id,
          attachment_url: item?.download_url,
        };
      });
    }
    this.formRef.current.setFieldValue("wasteCatagory", {
      id: waste_type_category_id,
      attributes: {
        name: waste_type_category_name,
      },
    });
    this.formRef.current.setFieldValue("clientName", {
      id: account_id,
      attributes: {
        name: account_company_name,
      },
    });
    this.getBranchNameListing(account_id);
    this.formRef.current.setFieldValue("branchName", {
      id: branch_name_id,
      attributes: {
        name: branch_name,
      },
    });
    this.formRef.current.setFieldValue(
      "recyclableNetWeight",
      recyclable_net_weight
    );
    this.formRef.current.setFieldValue("reportDate", report_date);
    this.formRef.current.setFieldValue("comment", comment);
    this.formRef.current.setFieldValue("treeSaved", tree_saved);
    this.formRef.current.setFieldValue(
      "energySaved",
      kilo_watt_of_energy_saved
    );
    this.formRef.current.setFieldValue("co2Reduction", co2_eq_reduction);
    this.formRef.current.setFieldValue(
      "landfillSpaceSaved",
      landfill_space_saved
    );
    this.formRef.current.setFieldValue("waterSaved", water_saved);
    this.formRef.current.setFieldValue(
      "reductionPetroleumUsed",
      reduction_petroleum_use
    );
    this.formRef.current.setFieldValue("attachments", attachment);
  };

  handleSubmit = (value: any) => {
    this.scrollRef.current.scrollIntoView();
    let data = new FormData();
    if (value.attachments.length !== 0) {
      this.setState({
        btnDisableFlag: true,
        error: { status: false, message: "Uploading Files" },
      });
      value.attachments.map((item: any) => {
        if (!item?.blob_id) {
          data.append("waste_bill[attachments][]", item);
        }
      });
    } else {
      this.setState({
        btnDisableFlag: true,
        error: { status: null, message: "" },
      });
    }
    data.append("waste_bill[waste_type_category_id]", value.wasteCatagory.id);
    data.append("waste_bill[account_id]", value.clientName.id);
    data.append("waste_bill[branch_name_id]", value.branchName.id);
    data.append("waste_bill[recyclable_net_weight]", value.recyclableNetWeight);
    data.append("waste_bill[report_date]", value.reportDate);
    data.append("waste_bill[comment]", value.comment);

    if (this.state.editId === -1) {
      const header = {
        token: this.state.token,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.createWasteBillAPIId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createWastebill
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.addUserApiMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        data
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      this.updateWastebillData(data);
    }
  };

  setWasteCategory=(name:string,item:any)=>{
    this.formRef.current.setFieldValue(name,item)
    if(this.formRef.current.values.recyclableNetWeight!==''){
    this.getImpactFactor(this.formRef.current.values.recyclableNetWeight,item?.id)
    }
  }

  handleAPIResponse = (successMsg: any, errorMsg: any, callback: Function) => {
    if (errorMsg) {
      console.log("error---------", errorMsg);
    } else if (successMsg) {
      if (successMsg.errors) {
        if (successMsg.errors[0].token) {
          this.navigationAdapter("EmailAccountLoginBlock");
        } else {
          this.setState({
            btnDisableFlag:false,
            error: { status: false, message: successMsg.errors[0] },
          });
        }
      } else {
        callback(successMsg);
      }
    }
  };

  getClientListCallback = (successMessage: any) => {
    if (successMessage.company_names) {
      let list = successMessage.company_names.map((item: any) => {
        return {
          id: item[0],
          attributes: {
            name: item[1],
          },
        };
      });
      this.setState({ clientList: list });
    }
  };

  getWasteCatagoryCallback = (successMessage: any) => {
    if (successMessage.data) {
      this.setState({ wasteCatagories: successMessage.data });
    }
  };

  getImpactFactorCallback = (successMessage: any) => {
    console.log('inside=====',successMessage)
    const {
      tree_saved,
      co2_eq_reduction,
      water_saved,
      kilo_watt_of_energy_saved,
      landfill_space_saved,
      reduction_petroleum_use,
    } = successMessage.impact_factors;
    this.formRef.current.setFieldValue("treeSaved", tree_saved);
    this.formRef.current.setFieldValue("co2Reduction", co2_eq_reduction);
    this.formRef.current.setFieldValue("waterSaved", water_saved);
    this.formRef.current.setFieldValue(
      "energySaved",
      kilo_watt_of_energy_saved
    );
    this.formRef.current.setFieldValue(
      "landfillSpaceSaved",
      landfill_space_saved
    );
    this.formRef.current.setFieldValue(
      "reductionPetroleumUsed",
      reduction_petroleum_use
    );
  };

  getBranchCallback = (successMessage: any) => {
    if (successMessage.branch_names) {
      let branches = successMessage.branch_names.map((item: any) => {
        return {
          id: item[0],
          attributes: {
            name: item[1],
          },
        };
      });
      this.setState({ branchNames: branches });
    }
  };

  createWasteBillCallback = (successMessage: any) => {
    this.scrollRef.current.scrollIntoView();
    if (this.state.editId === -1) {
      this.setState({
        successModal: true,
        error: { status: null, message: "" },
        btnDisableFlag: false,
      });
    } else {
      this.setState({
        error: { status: false, message: "Your changes have been saved!" },
        btnDisableFlag: false,
      });
    }
  };

  getWasteBillDataCallback = (successMessage: any) => {
    console.log("successsss", successMessage.data.attributes);
    if (successMessage.data) {
      const {
        waste_type_category_id,
        branch_name_id,
        report_date,
        comment,
        tree_saved,
        kilo_watt_of_energy_saved,
        co2_eq_reduction,
        landfill_space_saved,
        water_saved,
        reduction_petroleum_use,
        waste_type_category_name,
        branch_name,
        account_company_name,
        account_id,
        attachments,
        recyclable_net_weight,
      } = successMessage.data.attributes;
      let attachment = [];
      if (attachments !== null && attachments.length > 0) {
        attachment = attachments.map((item: any) => {
          return {
            name: item?.file_name,
            blob_id: item?.blob_id,
            attachment_url: item?.download_url,
          };
        });
      }
      let temp = {
        waste_type_category_id,
        branch_name_id,
        report_date,
        comment,
        tree_saved,
        kilo_watt_of_energy_saved,
        co2_eq_reduction,
        landfill_space_saved,
        water_saved,
        reduction_petroleum_use,
        waste_type_category_name,
        branch_name,
        account_company_name,
        account_id,
        attachments,
        recyclable_net_weight,
      };
      this.initialValues = { ...temp };
      this.updateForm(temp);
    }
  };

  editButton = () => {
    this.setState({ viewMode: false });
  };

  goBack = () => {
    this.props.navigation.goBack();
  };
  // Customizable Area End
}
