import LocalizedStrings from 'react-localization'
const configJSON = require("./config");
  
 const str = new LocalizedStrings({
      english: configJSON.english,
      arabic: configJSON.arabic,
    });
  
      let curr =  localStorage.getItem("language");
      if (curr) {
        str.setLanguage(curr);
      } else {
        str.setLanguage("english");
      }

  
  export default str