import React from "react";
import "./clientListSort.css"
import ClientListSortController, {
  Props
} from "./ClientListSortController.web";
import { arrowRightICon, checkRadioImage, unCheckRadioImage } from "../assets";
import Strings from "../Strings";

export default class ClientListSort extends ClientListSortController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    let {searchQueryState:{sort_by}} = this.props 
    return (
      //Merge Engine DefaultContainer
     <div className="sort_modal_wrapper">
      
      <div className="sort_menu_container sort_menu_title_container">
        <p className="sort_menu_text sort_menu_title">Sort</p>
      </div>

      <div className="sort_menu_container">
        <div data-test-id = "sort_created_at" className="sort_menu_image_container" onClick={this.setSortType('created_at')}>
        {sort_by === 'created_at' && <img src={checkRadioImage} alt="checkradioIcon" className="checkRadioImage" />} 
          <img src={unCheckRadioImage} alt="uncheckradioIcon" className="unCheckRadioImage" />
        </div>
        <p className="sort_menu_text">{Strings.newest}</p>
      </div>

      <div className="sort_menu_container">
      <div data-test-id = "sort_asc" className="sort_menu_image_container" onClick={this.setSortType('asc')}>
          {sort_by === 'asc' && <img src={checkRadioImage} alt="checkradioIcon" className="checkRadioImage" />} 
          <img src={unCheckRadioImage} alt="uncheckradioIcon" className="unCheckRadioImage" />
        </div>
        <p className="sort_menu_text">{Strings.ascendingA}<img src={arrowRightICon} alt="righticon" className="sort_menu_text_icon" />{Strings.z}</p>
      </div>

      <div className="sort_menu_container" style={{marginBottom:'2px'}}>
      <div data-test-id = "sort_desc" className="sort_menu_image_container" onClick={this.setSortType('desc')}>
          {sort_by === 'desc' && <img src={checkRadioImage} alt="checkradioIcon" className="checkRadioImage" />} 
          <img src={unCheckRadioImage} alt="uncheckradioIcon" className="unCheckRadioImage" />
        </div>
        <p className="sort_menu_text">{Strings.descendingZ}<img src={arrowRightICon} alt="righticon" className="sort_menu_text_icon" />{Strings.a}</p>
      </div>
      
     </div>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start


// Customizable Area End
