import React from "react";

// Customizable Area Start
// Customizable Area End

import EditAdminStaffController, {
  Props,
} from "./EditAdminStaffController.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
import Navbar from "../../settings2/src/Navbar.web";
import CustomInputField from "../../user-profile-basic/src/InputField.web";
import DropDown from "../../customform/src/DropDown.web";
import SuccessModal from "../../customform/src/sucessModal.web";
import Strings from './Strings'

export default class EditAdminStaff extends EditAdminStaffController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getErrorPromptStyle = () => {
    if (this.state.error.status === null) {
      return "";
    } else if (!this.state.error.status) {
      return "prompt-container-success";
    } else {
      return "prompt-container-failure";
    }
  };

  getEditScreen = () => {
    return (
      <div style={webStyle.editProfileInputContainer}>
        <div className="send-invite-top-container">
          <CustomInputField
            data-testid={"firstNameInput"}
            value={this.state.first_name}
            name={"first_name"}
            labelName={Strings.firstName}
            type={"text"}
            placeholder={Strings.enterFirstName}
            onChangeText={this.onInputChange}
            error={this.state.first_name_validate}
            customStyles="top-field-input"
            customContainerStyle="top-field-input-name"
          />
          <CustomInputField
            data-testid={"lastNameInput"}
            value={this.state.last_name}
            labelName={Strings.lastName}
            name={"last_name"}
            type={"text"}
            placeholder={Strings.enterLastName}
            onChangeText={this.onInputChange}
            error={this.state.last_name_validate}
            customStyles="top-field-input"
            customContainerStyle="top-field-input-name"
          />
        </div>
        <CustomInputField
          data-testid={"emailInput"}
          value={this.state.email}
          labelName={Strings.emailAddress}
          name={"email"}
          type={"text"}
          placeholder={Strings.enterEmailAddress}
          onChangeText={this.onInputChange}
          error={this.state.email_validate}
          customStyles="top-field-input"
        />
        <CustomInputField
          data-testid={"mobileInput"}
          value={this.state.full_phone_number}
          labelName={Strings.mobileNumberLabel}
          name={"full_phone_number"}
          type={"text"}
          placeholder={Strings.enterMobileNumber}
          onChangeText={this.onInputChange}
          error={this.state.phone_number_validate}
          customStyles="top-field-input"
        />
        <DropDown
          name={"role_id"}
          data-testid={"roleId"}
          value={this.state.role_id?.attributes?.name}
          showEndEndornment
          disable={true}
          labelName={Strings.role}
          placeholder={Strings.selectRole}
          dropDownList={this.roleList}
          currentSelected={this.state.role_id?.attributes?.name}
          customStyles="addwastebill-inputbox"
          setCurrentSelected={this.setCurrentDropDown}
          customContainerStyle="margin-zero"
          error={this.state.role_id?.attributes?.name === "" ? null : false}
        />
      </div>
    );
  };

  getChangePasswordScreen = () => {
    return (
      <div style={webStyle.editProfileInputContainer}>
        <CustomInputField
          data-testid={"createPassword"}
          value={this.state.password}
          labelName={Strings.changePassword}
          name={"password"}
          type={"password"}
          placeholder={Strings.createAPassword}
          onChangeText={this.onInputChange}
          error={this.state.password_validate}
          customStyles="top-field-input"
        />
        <CustomInputField
          data-testid={"confirmPassword"}
          value={this.state.password_confirmation}
          labelName={Strings.confirmPassword}
          name={"password_confirmation"}
          type={"password"}
          placeholder={Strings.confirmPassword}
          onChangeText={this.onInputChange}
          error={this.state.password_confirmation_validate}
          customStyles="top-field-input"
        />
      </div>
    );
  };

  getCurrentScreen = () => {
    switch (this.state.currentScreen) {
      case Strings.editProfile:
        return this.getEditScreen();
      case Strings.changePassword:
        return this.getChangePasswordScreen();
    }
  };

  // Customizable Area End

  render() {
    const { navigation, id } = this.props;
    const screenTitle: any = [Strings.editProfile, Strings.changePassword];
    return (
      // Customizable Area Start
      <div
        className={`account-creation-outer-container`}
        style={this.state.showSuccessModal ? { overflow: "hidden" } : {}}
      >
        {this.state.showSuccessModal && (
          <SuccessModal
            data-testid={'successModal'}
            successText={Strings.yourInvitationHasBeenSuccessfullySent}
            onModalCrossPress={this.onModalCrossPress}
          />
        )}
        <div
          className={`main-wrapper account-creation-inner-cont`}
          ref={this.scrollRef}
        >
          <NavigationMenu navigation={navigation} id={"AdminStaffListing"} />
          <div className="account-creation-container">
            <Navbar navigation={navigation} id={""} />
            <div className="header-container" style={{ width: "70%" }}>
              <div
                className="header-top-container"
                style={{ marginBottom: "2.8rem" }}
              >
                <p className="header-title">{Strings.editAdminStaff}</p>
                {this.state.error.status !== null && (
                  <span
                    className={`prompt-container header-prompt-container ${this.getErrorPromptStyle()}`}
                  >
                    {this.state.error.message}
                  </span>
                )}
              </div>
              <div
                style={{backgroundColor:'transparent',display:'flex'}}
                key={"editAdminStaffContainer"}
              >
                {screenTitle.map((item: any, index: number) => (
                  <div
                    data-testid={`changeScreenButton${index}`}
                    key={`editHeaderIcon${index}`}
                    onClick={() => this.onHeaderScreenClick(item)}
                    style={{marginRight:"4.5rem"}}
                    className={`client-titles-inner-container ${
                      item === this.state.currentScreen
                        ? "current-client-screen"
                        : ""
                    }`}
                  >
                    <p
                      className={`client-titles ${
                        item === this.state.currentScreen
                          ? "current-client-screen-title"
                          : ""
                      }`}
                    >
                      {item}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            {this.getCurrentScreen()}
            <div className="sendInvite-button-container">
              <button
                data-testid={"discardButton"}
                className="add-button"
                key={"editDiscardButton"}
                onClick={this.handleDiscardChanges}
              >
                <p className="add-button-text">{Strings.discardChanges}</p>
              </button>
              <button
                data-testid={"saveButton"}
                style={{ width: "7.5rem" }}
                key={"editSaveButton"}
                className="add-button button-fill send-invite-button"
                onClick={this.handlleSaveChange}
              >
                <p className="add-button-text">{Strings.save}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  editProfileInputContainer: {
    width: "79.3%",
    boxSizing: "border-box",
    paddingLeft: "2rem",
  },
} as const;

// Customizable Area End
