import React from "react";
import CloseIcon from '@material-ui/icons/Close';
import { COLOR_LIST, COLOR_TYPE, EventType, ItemEventType, configJSON } from "./CalenderController.web";
import CustomInputField from "../../../components/src/InputField.web";
import moment from "moment";
import { calendarIcon } from "../../dashboard/src/assets";
import { vectorDown, vectorUp } from "../../adminconsole2/src/assets";
import { ClickAwayListener } from "@material-ui/core";
import Strings from "./Strings";
import { successIcon } from "./assets";

export interface PopUpProps {
    testId?: string
    isOpen: boolean
    onPressClose: () => void
    onSaveHandle: (data: EventType, id?: string) => void
    currentItem?: ItemEventType
    isDelete?: boolean
    onDeleteHandle: (id: string) => void
}

interface PopUpState {
    title: string
    date: string
    description: string
    color: string
    colorTitle: string
    title_validate: boolean | null;
    isShowModal: boolean
    isSaved: boolean
}

export default class AddEventModal extends React.Component<PopUpProps, PopUpState> {
    constructor(props: PopUpProps) {
        super(props);
        this.state = {
            title: this.props.currentItem?.attributes?.title ?? "",
            color: this.props.currentItem?.attributes?.color ?? COLOR_TYPE.BLUE,
            colorTitle: this.props.currentItem?.attributes?.color_title ?? "",
            date: this.props.currentItem?.attributes?.event_date ? moment(new Date(this.props.currentItem?.attributes?.event_date)).format("DD/MM/YYYY") : "",
            description: this.props.currentItem?.attributes?.description ?? "",
            title_validate: true,
            isShowModal: false,
            isSaved: false,
        }
    }

    // istanbul ignore next
    componentDidUpdate(prevProps: PopUpProps) {
        if ((this.props.currentItem && this.props.currentItem.id !== prevProps.currentItem?.id) || this.props.isOpen !== prevProps.isOpen) {
            this.setState({
                title: this.props.currentItem?.attributes?.title ?? "",
                color: this.props.currentItem?.attributes?.color ?? COLOR_TYPE.BLUE,
                colorTitle: this.props.currentItem?.attributes?.color_title ?? "",
                date: this.props.currentItem?.attributes?.event_date ? moment(new Date(this.props.currentItem?.attributes?.event_date)).format("DD/MM/YYYY") : "",
                description: this.props.currentItem?.attributes?.description ?? "",
                title_validate: !configJSON.addEventValidation.test(this.props.currentItem?.attributes?.title),
            });
        }
    }

    validateInput = (name: string, value: string) => {
        if(name === "title") {
            this.setState({
                title_validate: !configJSON.addEventValidation.test(value),
            });
        }
    };

    onInputChange = (event: any) => {
        const { name, value } = event.target;
        let newValue = { ...this.state, [name]: value };
        this.setState({ ...newValue });
        this.validateInput(name, value);
    };

    onclose() {
        this.setState({
            title_validate: true,
            isShowModal: false,
            isSaved: false
        })
        this.props.onPressClose()
    }

    handleShowModal() {
        this.setState({ isShowModal: !this.state.isShowModal })
    }

    onSave() {
        const data = {
            title: this.state.title,
            color: this.state.color,
            color_title: this.state.colorTitle,
            event_date: moment(this.state.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
            description: this.state.description,
        }
        if (this.props.currentItem) {
            if (this.props.isDelete) {
                this.props.onDeleteHandle(this.props.currentItem.id)
            } else {
                this.props.onSaveHandle(data, this.props.currentItem.id)
            }
            setTimeout(() => {
                this.onclose()
            }, 500)
        } else {
            this.props.onSaveHandle(data)
            setTimeout(() => {
                this.setState({ isSaved: true })
            }, 500)
        }
    }

    render() {
        const { isOpen, } = this.props
        if (isOpen) {
            if (this.state.isSaved) {
                return (
                    <div style={webPopUpStyle.viewPopUp}>
                        <div
                            onClick={() => this.onclose()}
                            style={webPopUpStyle.viewBackground}
                            data-test-id="backgroundClose"
                        />
                        <div style={webPopUpStyle.viewContentSave}>
                            <div data-test-id="iconClose" onClick={() => this.onclose()} style={webPopUpStyle.closeIconAbsolute}>
                                <CloseIcon style={{ color: "#334155" }} />
                            </div>
                            <img src={successIcon} style={webPopUpStyle.successIcon} />
                            <p style={webPopUpStyle.savedText}>{Strings.entrySavedText}</p>
                        </div>
                    </div>
                )
            } else if (this.props.isDelete) {
                return (
                    <div style={webPopUpStyle.viewPopUp}>
                        <div
                            onClick={() => this.onclose()}
                            style={webPopUpStyle.viewBackground}
                            data-test-id="backgroundClose"
                        />
                        <div style={webPopUpStyle.viewContentDelete}>
                            <div data-test-id="iconClose" onClick={() => this.onclose()} style={webPopUpStyle.closeIconAbsolute}>
                                <CloseIcon style={{ color: "#334155" }} />
                            </div>
                            <p style={webPopUpStyle.confirmDeleteText}>{Strings.confirmDeleteText}</p>
                            <p style={webPopUpStyle.notifyDeleteText}>{Strings.notifyDeleteText}</p>
                            <div style={webPopUpStyle.viewButton}>
                                <button
                                    style={webPopUpStyle.buttonCancel}
                                    onClick={() => this.onclose()}
                                    data-test-id="cancelButton"
                                >
                                    {Strings.cancelText}
                                </button>
                                <button
                                    style={webPopUpStyle.buttonDelete}
                                    data-test-id="deleteButton"
                                    onClick={() => this.onSave()}
                                >
                                    {Strings.deleteText}
                                </button>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div style={webPopUpStyle.viewPopUp}>
                        <div
                            onClick={() => this.onclose()}
                            style={webPopUpStyle.viewBackground}
                            data-test-id="backgroundClose"
                        />
                        <div style={webPopUpStyle.viewContentPopUp}>
                            <div style={webPopUpStyle.viewHeaderPopUp}>
                                <p style={webPopUpStyle.headerText}>{Strings.addText}</p>
                                <div data-test-id="iconClose" onClick={() => this.onclose()} style={webPopUpStyle.closeIcon}>
                                    <CloseIcon style={{ color: "#334155" }} />
                                </div>
                            </div>

                            <CustomInputField
                                data-testid={"titleInput"}
                                labelName={Strings.titleLabel}
                                name={"title"}
                                type={"text"}
                                placeholder={"PWC Report"}
                                value={this.state.title}
                                onChangeText={this.onInputChange}
                                error={this.state.title_validate}
                                customStyles={"input_event"}
                            />
                            <ClickAwayListener data-test-id={"click_away_listener"} onClickAway={() => this.setState({ isShowModal: false })}>
                            <div className="date_input_event"
                            data-test-id={'colorTitleInput'}
                            onClick={()=>this.handleShowModal()}
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                }}>
                                    <p style={webPopUpStyle.input}>{Strings.titleColorPlaceholder}</p>
                                <div> 
                                    
                                        <div
                                            style={webPopUpStyle.viewChooseColor}
                                            onClick={() => this.handleShowModal()}
                                            data-test-id={"modalItem"}
                                        >
                                            <div style={{
                                                width: "20px",
                                                height: "20px",
                                                background: this.state.color,
                                                borderRadius: "50%",
                                                margin: "10px 6px 10px 0"
                                            }}></div>
                                            <img src={this.state.isShowModal ? vectorUp : vectorDown} />
                                        </div>
                                    <ModalColor
                                        testId="colorModal"
                                        isShow={this.state.isShowModal}
                                        currentColor={this.state.color}
                                        onChooseColor={(c: string) => this.setState({ color: c })}
                                    />
                                </div>
                            </div>
                            </ClickAwayListener>
                            <p style={webPopUpStyle.labelText}>{Strings.dateLabel}</p>
                            <div className="date_input_event" style={{width : '-webkit-fill-available'}}>
                                <input
                                    className="input_date_event"
                                    data-test-id="custom-start-date"
                                    id="date_input"
                                    type="date"
                                    value={moment(this.state.date,'DD-MM-yyyy').format('yyyy-MM-DD') }
                                    onChange={(v) => {
                                        this.setState({ date: moment(v.target.value).format("DD/MM/YYYY") })
                                    }}
                                    max={moment(new Date()).format('DD/MM/YYYY')}
                                />
                                <p style={webPopUpStyle.titleDate}>{this.state.date === "" ? "DD/MM/YYYY" : this.state.date}</p>
                                <img src={calendarIcon} alt="calander_icon" />
                            </div>
                            <div style={{ height: "20px" }} />
                            <CustomInputField
                                data-testid={"descriptionInput"}
                                labelName={Strings.descriptionLabel}
                                name={"description"}
                                type={"text"}
                                placeholder={Strings.descriptionPlaceholder}
                                value={this.state.description}
                                onChangeText={this.onInputChange}
                                customStyles={"input_event"}
                                multiline
                            />
                            <button
                                disabled={!this.state.title || !this.state.title.trim() || !this.state.date}
                                style={{ ...webPopUpStyle.buttonSave, ...{ backgroundColor: this.state.title_validate ? "rgba(141, 151, 162, 0.30)" : "#32569A" } }}
                                onClick={() => this.onSave()}
                                data-test-id="saveButton"
                            >
                                {Strings.saveText}
                            </button>
                        </div>
                    </div>
                )
            }
        } else return null
    }
}

export const ModalColor = ({ testId, isShow, currentColor, onChooseColor }: {
    testId: string;
    isShow: boolean;
    currentColor: string;
    onChooseColor: (c: string) => void
}) => {
    if (isShow) {
        return (
            <div style={webPopUpStyle.modalColor} data-test-id={testId}>
                {
                    COLOR_LIST.map((item: string) => (
                        <p style={{
                            fontSize: "40px",
                            marginLeft: "8px",
                            color: item,
                        }}
                            onClick={() => onChooseColor(item)}
                            key={item}
                            data-test-id={item}
                        >•</p>
                    ))
                }
            </div>
        )
    } else return null
}

export const webPopUpStyle = {
    titleDate: {
        color: "#858D9D",
        fontFamily: 'Barlow Semi Condensed',
        fontSize: "14px",
        lineHeight: "24px",
        width : "100%"
    } as any,
    buttonCancel: {
        borderRadius: "8px",
        border: "1px solid #32569A",
        background: "#FFF",
        color: "#32569A",
        fontFamily: 'Barlow Semi Condensed',
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 700,
        padding: "10px 16px"
    },
    buttonDelete: {
        borderRadius: "8px",
        background: "#DC2626",
        color: "#fff",
        fontFamily: 'Barlow Semi Condensed',
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 700,
        padding: "10px 16px",
        marginRight: "24px",
    },
    viewButton: {
        marginTop: "24px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        paddingTop: "24px",
        paddingBottom: "24px",
        justifyContent: "flex-end",
        gap: "24px",
        borderTop: "1px solid #E2E8F0"
    } as any,
    viewContentPopUp: {
        position: "absolute",
        background: "white",
        width: "40%",
        margin: "auto",
        padding: "24px",
        borderRadius: "12px",
        marginTop: "100px",
        paddingBottom:"50px",
    } as any,
    viewContentSave: {
        position: "absolute",
        background: "white",
        height: "50%",
        width: "40%",
        margin: "auto",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        marginTop: "100px",
        alignItems: "center",
        justifyContent: "center"
    } as any,
    viewContentDelete: {
        position: "absolute",
        background: "white",
        width: "40%",
        margin: "auto",
        borderRadius: "0.5rem 0.5rem 2rem 0.5rem",
        boxShadow: "1px solid black",
        marginTop: "100px",
        display: "flex",
        flexDirection: "column",
    } as any,
    confirmDeleteText: {
        color: "#0F172A",
        fontFamily: "Barlow Semi Condensed",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "600",
        marginBottom: "16px",
        marginLeft: "40px",
        marginTop: "40px"
    } as any,
    notifyDeleteText: {
        color: "#0F172A",
        fontFamily: "Barlow Semi Condensed",
        fontSize: "16px",
        marginLeft: "40px",
        lineHeight: "24px",
        fontWeight: "400",
    } as any,
    input: {
        color: "#858D9D",
        fontFamily: "Barlow Semi Condensed",
        fontSize: "14px",
        lineHeight: "24px",
        width: "100%",
        backgroundColor:"transparent"
    },
    viewBackground: {
        width: "100%",
        height: "100%",
        background: 'rgba(28, 48, 69, 0.25)',
    },
    modalColor: {
        display: "grid",
        gridTemplateColumns: "36px 36px",
        padding: "8px",
        width: "70px",
        position: "absolute",
        right: 0,
        top: "56px",
        borderRadius: "8px",
        zIndex: 2,
        backgroundColor: "#fff",
        border: "1px solid #E0E2E7"
    } as any,
    viewPopUp: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0)",
        display: "flex",
        justifyContent: "center",
        zIndex: 100000,
    } as any,
    closeIcon: {
        width: "16px",
        height: "16px",
    } as any,
    closeIconAbsolute: {
        width: "16px",
        height: "16px",
        position: "absolute",
        top: "24px",
        right: "24px",
    } as any,
    labelText: {
        color: "#777980",
        fontFamily: "Barlow Semi Condensed",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        marginBottom: "4px",
        marginTop: "20px"
    },
    successIcon: {
        width: "107px",
        height: "107px",
        marginBottom: "24px"
    },
    savedText: {
        color: "#000",
        fontFamily: "Barlow Semi Condensed",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
    },
    viewChooseColor: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: '4px'
    } as any,
    viewHeaderPopUp: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px"
    } as any,
    headerText: {
        color: "#1C3045",
        fontFamily: "Barlow Semi Condensed",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "28px",
        letterSpacing: "0.16px"
    },
    buttonSave: {
        padding: "8px 16px",
        borderRadius: "8px",
        color: "#FFF",
        fontFamily: "Public Sans",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "20px",
        letterSpacing: "0.07px",
        width: "55px",
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        right: "20px"
    } as any,
}
