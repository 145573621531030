import React from "react";
// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Navbar from "../../settings2/src/Navbar.web";
import TableComponent from './TableComponent.web'
import { ExportIcon, filterICon, imageEmpty } from "./assets";
import EnvironmentalCard from './EnvironmentalCard'
import CustomFilter from "./CustomFilter.web";
import "./Dashboard.css"
import ChartComponent, { ChartType } from "./ChartComponent.web";
import { TimeChoice } from "./DashboardController.web";
import { webStyle as styles } from "./Dashboard.web";

// Customizable Area End
import ReportClientController, {
  Props,
} from "./ReportClientController.web"
import { ClickAwayListener } from "@material-ui/core";
import Strings from "./Strings";

export default class ReportClient extends ReportClientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  getClearFiltersButton=()=>{
    const {branches,categories,sort_by}= this.state.searchQueryState
    if(!(branches.length===0 && categories.length===0 && sort_by==='created_at')){
    return(
      <button data-testid={'clearFiltersButton'} onClick={this.handleClearFilters} className="report_clear_button">{Strings.clearFilters}</button>
    )
    }
  }

  // Customizable Area End

  render() {
    if (this.state.listData.length === 0 && this.state.tableData.length === 0) {
      return (
        <div id="element" className="main-wrapper">
          <NavigationMenu navigation={this.props.navigation} id={""} />
          <div style={styles.container} className="dashboard_container_wrapper">
            <div className="dashboard_navbar_container">
              <Navbar navigation={this.props.navigation} id={""} />
            </div>
            <div className="dashboard_body_container">
              <div style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img src={imageEmpty} style={styles.imageEmpty} />
                <div style={styles.emptyText}>{Strings.emptyText}</div>
              </div>
              {this.getClearFiltersButton()}
            </div>
          </div>
        </div>
      );
    }
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <div id="element" className="main-wrapper">
        <NavigationMenu navigation={this.props.navigation} id={""} />
        <div style={webStyles.container} className="dashboard_container_wrapper">
          <div className="dashboard_navbar_container">
            <Navbar navigation={this.props.navigation} id={""} />
          </div>
          <div className="dashboard_body_container">
            <div style={webStyles.pageHeadingContainer}>
              <h2 style={webStyles.pageheading}>{Strings.ReportPageTitle}</h2>
              <div style={webStyles.pageTopFilters}>
                <div style={{ position: 'relative' }}>
                  <button data-test-id="filterIcon" onClick={() => this.handleShowFilter()} style={webStyles.filter}><img src={filterICon} /><p>{Strings.filterTitle}</p></button>
                  {this.state.showFilterModal &&
                    <ClickAwayListener onClickAway={() => this.hideFilter()}>
                      <div style={webStyles.viewFilter}>
                        <CustomFilter
                          testId="CustomFilter"
                          navigation={this.props.navigation}
                          searchQueryState={this.state.searchQueryState}
                          setSearchQueryState={(e) => { this.setState({ searchQueryState: e }) }}
                          showFilterModal={() => this.hideFilter()}
                          categoryList={this.state.categoryList}
                          branchList={this.state.branchList}
                        />
                      </div>
                    </ClickAwayListener>
                  }
                </div>
                <button data-test-id="exportIcon" onClick={() => this.exportReport()} style={webStyles.exportReport}><img src={ExportIcon} />{Strings.ReportTitle}</button>
              </div>
            </div>
            {/* View Chart */}
            <div style={styles.viewSegment}>
              <div style={styles.viewHeader}>
                <div style={styles.headerText}>{Strings.recyclingCollectionHeader}</div>
                <div style={styles.viewTime}>
                  <div
                    data-test-id={"contractTime"}
                    onClick={() => this.handleChangeTimeChart(TimeChoice.CONTRACT_YEAR)}
                    style={this.checkCurrentStyle(TimeChoice.CONTRACT_YEAR)}>{Strings.contractYear}</div>
                  <div style={{ width: "14px" }} />
                  <div
                    data-test-id={"yearTime"}
                    onClick={() => this.handleChangeTimeChart(TimeChoice.YEAR)}
                    style={this.checkCurrentStyle(TimeChoice.YEAR)}
                  >{Strings.yearlyText}</div>
                  <div style={{ width: "14px" }} />
                  <div
                    data-test-id={"monthTime"}
                    onClick={() => this.handleChangeTimeChart(TimeChoice.MONTH)}
                    style={this.checkCurrentStyle(TimeChoice.MONTH)}
                  >{Strings.monthlyText}</div>
                </div>
              </div>
              <ChartComponent
                chartType={this.state.chosenTime === TimeChoice.MONTH ? ChartType.LINE : ChartType.BAR}
                aspectRatio={3.5}
                label={this.state.listLabel}
                dataArray={this.state.listData}
                suggestedMax={this.state.maxChart}
              />
            </div>
            <EnvironmentalCard
              data={this.state.enviromentalCardsData} />

            {this.state.tableData.length>0&&
            <TableComponent
              tableHeaders={Strings.tableHeaders}
              data={this.state.tableData}
              showSortModal={this.state.showSortModal}
              handleShowSortModal={this.handleShowSort.bind(this)}
              hideSortModal={() => {
                this.setState({ showSortModal: false })
              }}
              showViewMore={false}
              onClickViewMore={() => { }}
              totalPagination={this.state.totalPagination}
              total={this.state.totalNetWeightCount}
              currentPage={this.state.page}
              onChangePage={(p: number) => {
                this.setState({ page: p })
              }}
              onChangeSortByValue={(v: string) => {
                this.setState({ netWeightSortBy: v })
              }}
            />}
            <div style={{ height: 50 }} />
          </div>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    backgroundColor: "#F6F7FA"
  },
  viewFilter: {
    top: "56px",
    right: "0px",
    width: "18.5625rem",
    zIndex: 100000,
    position: "absolute"
  } as any,
  pageHeadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '35px 0 20px 0'
  },
  pageheading: {
    color: "#1C3045",
    fontSize: "24px",
    fontStyle: "normal",
    lineHeight: "30px",
    letterSpacing: "0.24px",
    fontFamily: "Barlow Semi Condensed",
  },
  pageTopFilters: {
    display: 'flex',
    justifyContent: "space-between",
    gap: '10px',
    fontSize: "0.875rem"
  },
  filter: {
    fontFamily: "Barlow Semi Condensed",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "0.07px",
    padding: "10px 16px",
    color: '#667085',
    background: '#fff',
    border: '1px solid #e0e2e7',
    borderRadius: '8px',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    gap: '5px',
    alignItem: "center",
  } as any,
  exportReport: {
    padding: "10px 16px",
    color: '#fff',
    background: '#32569a',
    borderRadius: '8px',
    alignItem: "center",
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    fontFamily: "Barlow Semi Condensed",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "0.07px",
    gap: '5px',
    whiteSpace:"nowrap",
    cursor:"pointer"
  } as any,
  viewTime: {
    display: "flex",
    flexDirection: "row",
    padding: "2px 12px 2px 12px",
    borderRadius: "10px",
    border: "1px solid #E0E2E7"
  },
}


// Customizable Area End
