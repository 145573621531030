export const backgroundClientDashboard = require('../assets/background_client_dashboard.svg')
export const backgroundImage = require("../assets/background_image.png");
export const iconUserPlus = require("../assets/ic_user_plus.png");
export const iconUser = require("../assets/ic_user.png");
export const iconTrash = require("../assets/ic_trash.png");
export const imageEmpty = require("../assets/image_empty.png");
export const TreeSaved = require("../assets/TreeSaved.svg");
export const CarbonEmission = require("../assets/CarbonEmission.svg");
export const WaterSaved = require("../assets/WaterSaved.svg");
export const EnergySaved = require("../assets/EnergySaved.svg");
export const LandFillSaved = require("../assets/LandfillSaved.svg");
export const FuelSaved = require("../assets/FuelSaved.svg");
export const vectorDown = require("../assets/vector_down.png");
export const vectorUp = require("../assets/vector_up.png");
export const iconFilter = require("../assets/icon_filter.png");
export const iconDelete = require("../assets/icon_delete.png");
export const checkIcon = require("../assets/check_icon.svg");
export const uncheckIcon = require("../assets/uncheck_icon.svg");
export const ExportIcon = require("../assets/ExportIcon.svg");
export const filterICon = require("../assets/filter.svg");
export const calendarIcon = require("../assets/calendar.svg");
export const arrowRightICon = require("../assets/arrow-right.png");
export const checkRadioImage = require("../assets/checRadioIcon.svg");
export const unCheckRadioImage = require("../assets/uncheckRadioIcon.svg");
export const nextIcon = require("../assets/next_icon.svg");
export const prevIcon = require("../assets/prev_icon.svg");
