import React from "react";
import { imgPasswordInVisible, imgPasswordVisible, searchIcon } from "./assets";

export interface InputProps {
  name?: string;
  labelName?: string;
  value: string;
  placeholder?: string;
  onChangeText?: (arg: any) => void;
  customStyles?: string; // className
  error?: boolean | null;
  type?: string;
  errorMessage?: string;
  errorMessageStyle?: any;
  multiline?: boolean;
  rows?: number;
  customContainerStyle?: string;
  disable?: boolean;
  startAdornment?: boolean;
  customInputStyles?: string;
  startEndornmentIconStyles?: string;
  onInputPress?: () => void;
}

interface State {
  showPassword: boolean;
  focusInput: boolean;
}

class InputField extends React.PureComponent<InputProps, State> {
  state = {
    showPassword: false,
    focusInput: false,
  };

  endAdornment = () => {
    if (this.props.type == "password") {
      return (
        <button
          data-testid={"iconButton"}
          aria-label="toggle password visibility"
          onClick={this.handleClickShowPassword}
          onMouseDown={this.handleMouseDownPassword}
          className="client-endornment-icon"
        >
          {!this.state.showPassword ? (
            <img src={imgPasswordInVisible} className="img-input-icon" />
          ) : (
            <img src={imgPasswordVisible} className="img-input-icon" />
          )}
        </button>
      );
    }
  };

  startAdornment = () => {
    if (this.props.startAdornment) {
      return (
        <button
          data-testid={"iconButton"}
          aria-label="toggle password visibility"
          onClick={this.handleClickShowPassword}
          onMouseDown={this.handleMouseDownPassword}
          className={`start-endornment-icon ${this.props.startEndornmentIconStyles}`}
        >
          <img src={searchIcon} className="admin-listing-img-input-icon" />
        </button>
      );
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  getErrorStyle = () => {
    const { error = null } = this.props;
    if (error !== null) {
      if (error) {
        return "error-input";
      }
      return "success-input";
    }
    return "";
  };

  render(): React.ReactNode {
    const {
      name,
      value,
      placeholder,
      onChangeText,
      labelName = "",
      customStyles,
      customContainerStyle,
      disable = false,
      customInputStyles,
      onInputPress = () => {},
    } = this.props;

    return (
      <div
        className={`account-creation-input-container ${customContainerStyle}`}
      >
        {labelName && <p className={"input-name"}>{labelName}</p>}
        <div
          onClick={onInputPress}
          className={`input-container-client admin-listing-input-container ${customStyles} ${this.getErrorStyle()}`}
        >
          {this.startAdornment()}
          <input
            name={name}
            data-testid={"textInput"}
            value={value}
            className={`input-field-client admin-listing-input-field ${customInputStyles}`}
            placeholder={placeholder}
            onChange={onChangeText}
            type={this.props.type}
            disabled={disable}
            autoComplete="off"
          />
          {this.endAdornment()}
        </div>
      </div>
    );
  }
}

const styles = {
  errorMessage: {
    height: 19,
    width: "95%",
    marginLeft: 5,
  },
  marginCorrection: {
    borderColor: "E7EDEF",
    marginBottom: 10,
    width: "100%",
  },
};

export default InputField;
