import React from "react";

// Customizable Area Start
import { Container, Box, Typography, Tab } from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import { camera, eye, eyeSlash} from "./assets";
import QualificationBlock from "./QualificationBlock";
import Strings from "./Strings";
import Projects from "./Projects";
import Awards from "./Awards";
import Patents from "./Patents";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import "./userProfile.css";

const webStyle = {
  imageStyle: {
    width: 100,
  },
  qualificationBlock: {
    marginTop: 40,
  },
};

import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Navbar from "../../settings2/src/Navbar.web";

const imageStyles = {
  client : { height: '3.71rem', width: '3.71rem' , borderRadius : '6.5rem'},
  admin : { height: 'inherit', width: 'inherit' , borderRadius : '6.5rem'}
}
// Customizable Area End

import EducationalUserProfileController, {
  Props,
} from "./EducationalUserProfileController";

export default class EducationalUserProfile extends EducationalUserProfileController {
  // Customizable Area Start
  getUserName()
  {
    return this.state.initialState.firstName + " " + this.state.initialState.lastName
  }


  getScreen = () =>
  {
    if(this.state.selectedScreen === 'profile')
    {
      return this.getProfile()
    }
    else{ 
      return this.getPassowordChange()
    }
  }

  getProfile = () => 
  {
    return           <div className="detail-box">
    <div className="box-title">
     {Strings.personalInformationHeading}
    </div>
    <div className="img-and-email-section">
      <div className="image-container">
        <img src={this.getImage()} style={this.state.role === 'Client' ? imageStyles.client : imageStyles.admin}></img>
        <label  className="overlay-button">
        <input data-testid="image-input"type="file" accept="image/*" style={{visibility : 'hidden'}}onChange={(event) => this.handleImage(event)}/><span style={{marginTop : '-1rem'}}><img src={camera} ></img></span>
        <span className="overlay-text">{Strings.editPicture}</span>
       </label>
      </div>
      <div className="email-container">
        <div className="user-name">
          { this.getUserName() }
        </div>
        <div className="user-email">
          {this.state.email}
        </div>
      </div>
    </div>

    <div className="line"></div>
  

  <div className="fields-container">
    <div className="profile-row">
      <div className="field-wrapper">
        <div className="label-text">{Strings.firstName}</div>
        <div className={this.getInputTextClassForFirstName()}>
          <input type="text" className="profile-input profile-bg" data-testid = "first-name" value={this.state.firstName} onChange={(event : any) => { this.handleFirstNameLastName(event,'first-name')}}></input>
        </div>
      </div>
      <div className="field-wrapper">
    <div className="label-text">{Strings.lastName}</div>
  <div className={this.getInputTextClassForLastName()}>
    <input type="text" className="profile-input profile-bg" data-testid="last-name"value={this.state.lastName} onChange={(event : any) => { this.handleFirstNameLastName(event,'last-name')}}></input>
  </div>
  </div>
    </div>

    <div className="profile-row">
      { this.state.role != 'Client' ? <div className="field-wrapper">
        <div className="label-text">{Strings.role}</div>
        <div className="input-text disable-input">
          <input type="text" className="profile-input disable-input" value={this.state.role}></input>
        </div>
      </div>
      : 
      null }
      <div className="field-wrapper">
    <div className="label-text">{Strings.email}</div>
  <div className="input-text disable-input">
    <input type="text" className="profile-input disable-input" value={this.state.email}></input>
  </div>
  </div>
    </div>
  </div>

  </div>
  }

  getPassowordChange()
  {
    return <div className="detail-box">
    <div className="box-title">
     {Strings.changePassHead}
      </div>

      <div className="fields-container no-gap" style={{marginTop : '1.5rem'}}>
        <div className="profile-row row-align">
        <div className="field-wrapper no-gap">
        <div className="label-text">{Strings.currentPass}</div>
        <div className={this.getControlClass('current')}>
          <input type={this.getType('current')} data-testid="current-password" className="profile-input profile-bg" value={this.state.currentPassword} onChange={(event) => this.handleCurrentPassword(event)} style={{width : '-moz-available'}}></input>
          <span className="profileadornment" data-testid="current-toggle" onClick={() => this.toggleVisibility('current')}>
          <img className="icon-img" src={this.state.currentVisibilitiy ? eye : eyeSlash} ></img>
        </span>
        </div>
        <span className="error-msg">{this.state.currentErrorText}</span>
      </div>
        </div>

        <div className="profile-row row-align">
        <div className="field-wrapper no-gap">
        <div className="label-text">{Strings.newPass}</div>
        <div className={this.getControlClass('new')}>
          <input type={this.getType('new')} data-testid="new-password" className="profile-input profile-bg" onBlur={() => {this.setState({focusInput : false})}} style={{width : '-moz-available'}} onFocus={() => {this.setState({focusInput : true})}}value={this.state.newPassword} onChange={(event) => this.handleNewPassword(event)}></input>
          <span className="profileadornment" data-testid="new-toggle" onClick={() => this.toggleVisibility('new')}>
          <img className="icon-img"src={this.state.newVisibility ? eye : eyeSlash} ></img>
        </span>
        {!this.state.newPasswrodError || !this.state.focusInput ? null : this.customPasswordValidationModalProfile()}
        </div>
        
      </div>
        </div>

        <div className="profile-row row-align">
        <div className="field-wrapper no-gap">
        <div className="label-text">{Strings.confirmPass}</div>
        <div className={this.getControlClass('confirm')}>
          <input type={this.getType('confirm')} data-testid="confirm-password"className="profile-input profile-bg"  value={this.state.confirmPassword} onChange={(event) => this.handleConfirmPassword(event)} style={{width : '-moz-available'}}></input>
          <span className="profileadornment" data-testid="confirm-toggle" onClick={() => this.toggleVisibility('confirm')}>
          <img className="icon-img"src={this.state.confirmVisibility ? eye : eyeSlash} ></img>
        </span>
        </div>
        <span className="error-msg">{this.state.confirmErrorText}</span>
      </div>
        </div>

      </div>
    </div>
  }
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <div className={`main-wrapper pro-account-creation-inner-cont`}>
        <NavigationMenu navigation={this.props.navigation} id={"1"} />
        <div className="pro-account-creation-container">
          <Navbar navigation={this.props.navigation} id={""} />
          <div className="header-container-pro-page">
            <div className="profile-header-container">
              <p className="profile-heading-title">{"Account"}</p>
              {this.state.headerText ? <div className="header-message"><span className="message-text">{this.state.headerText} </span></div> : '' }        
            </div>
            <div className="profile-screen-title-container">
              <div data-testid="profile" className={`client-titles-inner-container`}  onClick={() => this.setSelected('profile')}>
                <p className={this.getHeadingClass('profile')}>
                 {Strings.editProfile}
                </p>
                <div className={`bottom-line ${this.state.selectedScreen === 'profile' ? 'opacity' : ''}`}></div>
              </div>

              <div data-testid="password"className={`client-titles-inner-container`} onClick={() => this.setSelected('password')}>
                <p className={this.getHeadingClass('password')} >
                {Strings.changePassHead}
                </p>
                <div className={`bottom-line ${this.state.selectedScreen === 'password' ? 'opacity' : ''}`} style={{width : '7.0625rem'}}></div>

              </div>

            </div>
          </div>

        {this.getScreen()}

          <div className="profile-button-container">
          <button
            data-testid={"discard-btn"}
            className="add-button"
            onClick={() => this.getDiscardFunction()}
            style={{fontWeight : 700}}
            disabled={this.isViewAsClient()}
          >
            <p className="add-button-text">{Strings.discard}</p>
          </button>
          <button
            data-testid={"saveButton"}
            className="add-button button-fill save-btn"
            onClick={() => this.save()}
            style={{fontWeight : 700}}
            disabled={this.isViewAsClient()}
          >
            <p className="add-button-text">{Strings.save}</p>
          </button>
        </div>

         

        </div>
      </div>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
