import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { createRef } from "react";
import Strings from "./Strings";

// Customizable Area Start

export type Selected = "Client Details" | "Contract" | "Report" | "Send Invite";

interface Error {
  status: boolean | null;
  message: string;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentScreen: string;
  data: any;
  error: Error;
  showSuccessModal: boolean;
  editId: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AccountCreationHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  scrollRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      currentScreen: Strings.clientDetails,
      data: {},
      error: {
        status: null,
        message: "",
      },
      showSuccessModal: false,
      editId: -1,
    };
    this.scrollRef = createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    // Customizable Area End
  }

  //Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    let token = localStorage.getItem("token");
    if (!token) {
      this.props.navigation.navigate("EmailAccountLoginBlock");
    } else {
      let editId = this.props.navigation.history.location?.state?.id;
      if (editId) {
        this.setState({ editId: parseInt(editId) });
      }
    }
  }

  setError = (error: Error) => {
    this.setState({ error: error });
  };

  setShowSuccessModal = (value: boolean) => {
    this.setState({
      showSuccessModal: value,
      error: { status: null, message: "" },
    });
  };

  setCurrentScreen = (value: string) => {
    this.setState({
      currentScreen: value,
      error: { status: null, message: "" },
    });
  };

  onHeaderScreenClick = (item: string) => {
    if (this.state.editId !== -1) {
      this.setCurrentScreen(item);
    }
  };

  scrollToTop = () => {
    this.scrollRef.current.scrollIntoView();
  };
  // Customizable Area End
}
