import LocalizedStrings from 'react-localization';
const configJSON = require("./config");
  
    const Strings = new LocalizedStrings({
      english: configJSON.englishLang,
      arabic: configJSON.arabicLang,
    });
  
    
      let lang = localStorage.getItem("language");
      if (lang) {
       Strings.setLanguage(lang);
      } else {
       Strings.setLanguage("english");
      }
  
  export default Strings;