Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetTotalRecycleWasteUrl = "/total_recyclable_net_weight";
exports.categoryListUrl = "/get_waste_type_category_for_report_page_filters";
exports.branchListUrl = "/account_block/branch_names";
exports.accountsUrl = "/account_block/accounts";
exports.dashboardGetRecycleCollectionUrl ="/recycling_collections"
exports.dashboardGetRecycleEnvironmentUrl = "/recycling_environmental_impact"
exports.dashboardGetServiceMaintainceUrl = "/dashboard/service_maintenance_logs"
exports.dashboardGetTotalClientUrl ="/total_account_details"
exports.dashboardGetcityListUrl = "/admin/branch_names/cities"
exports.dashboardDeleteUrl = "/dashboard/service_maintenance_logs/bulk_destroy"
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalClientText = "Total Clients";
exports.newClient = "New Clients";
exports.totalWasteNetWeight = "Total Waste Net Weight";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.emptyText = "Nothing to show yet!";
exports.recyclingCollectionHeader = "Recycling Collections in KG";
exports.totalRecyclableNetWeight = "Total Recyclable Net Weight";
exports.serviceHeader = "Service Maintenance";
exports.environmentalHeader = "Recycling Environmental Impact";
exports.baseUrl = ""
exports.deleteAll = "Delete all";
exports.filter = "Filter";
exports.labelBodyText = "dashboard Body";
exports.viewMoreText = "View More";
exports.yearlyText = "Yearly";
exports.monthlyText = "Monthly";
exports.recyclingCollectionEndPoint = "recycling_collections/"
exports.environmentalImpactEndPoint = "environmental_impact/";
exports.getTotalNetWeightEndPoint = "total_net_weight/"
exports.EnvironmentalSectionHeading = "Environmental Impact"
exports.ReportPageTitle = "Report"
exports.filterTitle = "Filters"
exports.exportReportTitle = "Export Report"
exports.graphHeading = "Recycling Collections in KG";
exports.tableHeaders = ["Branch", "Pickup Date", "Recyclable Category", "Recyclable Net Weight"];
exports.contractYear = "Contract Year"
exports.SortTitle = "Sort";
exports.NewToOld = "Newest to Oldest";
exports.oldToNew = "Oldest to Newest";
exports.AscendingTitle ="Ascending A → Z";
exports.checkRadioAlt = "checkradioIcon"
exports.unCheckRadioAlt="uncheckradioIcon"
exports.rightIconAlt = "righticon"
exports.DescendingTitle = "Descending Z → A";
exports.clearAll = "Clear All";
exports.clients = "Clients";
exports.pickUpDate = "Pick Up Date";
exports.customDate = "custom_date";

exports.english={
  totalClientText:"Total Clients",
  pickUpDate:"Pick Up Date",
  clients:"Clients",
  clearAll:"Clear All",
  descending:"Descending Z",
  A:"A",
  AscendingTitle:"Ascending A",
  Z:"Z",
  AscendingTitleAZ :"Ascending A → Z",
  DescendingTitleZA : "Descending Z → A",
  NewToOld:"Newest to Oldest",
  SortTitle:"Sort",
  oldToNew:"Oldest to Newest",
  deleteAll : "Delete all",
  viewMoreText : "View More",
  yearlyText : "Yearly",
  monthlyText : "Monthly",
  EnvironmentalSectionHeading: "Environmental Impact",
  ReportPageTitle : "Report",
  filterTitle : "Filters",
  filter:"Filter",
  ReportTitle : "Export Report",
  graphHeading : "Recycling Collections in KG",
  branch:"Branch",
  tableHeaders : ["Branch", "Pick Up Date", "Recyclable Category","Recyclable Net Weight" ],
  contractYear : "Contract Year",
  recyclableCategory:"Recyclable Category",
  recyclableNetWeight:"Recyclable Net Weight",
  newClient :"New Clients",
  totalWasteNetWeight :"Total Waste Net Weight",
  viewDetailsBtn :"View Details",
  candidateslabelTitleText :"Candidates",
  emptyText :"Nothing to show yet!",
  recyclingCollectionHeader :"Recycling Collections in KG",
  serviceHeader :"Service Maintenance",
  environmentalHeader :"Recycling Environmental Impact",
  city:"City",
  lastMonth:"Last Month",
  thisMonth:"This Month",
  custom:"Custom",
  apply:"Apply",
  totalNetWeight:"Total Net Weight",
  cancel:"Cancel",
  totalRecyclableNetWeight:"Total Recyclable Net Weight",
  last7Days:"Last 7 days",
  last30Days:"Last 30 Days",
  currentContractPeriod:"Current Contract Period",
  from:"From",
  to:"To",
  contractText:"Contract Year",
  treeSaved:"Tree Saved",
  trees:"Trees",
  co2EqReduction:"Co2 Reduction",
  waterSaved: "Water Saved",
  energySaved:"Energy Saved",
  showing:"Showing",
  lastwasteCollection:"Last waste Collection",
  landfillSpaceSaved:"Landfill Space Saved",
  reducedPetroleunUse:"Petroleum Saved",
  timePeriod:"Time Period",
  totalNetWeightText : "Total Net Weight",
  cubicMeter:"Cubic meter",
  Kg : 'KG',
  gallons:"Gallons",
  kWh:"KWh",
  clearFilters:"Clear Filter",
  barrels:"Barrels",
}

exports.arabic={
  totalClientText:"جميع العملاء",
  pickUpDate:"تاريخ التفريغ",
  clients:"العملاء",
  clearAll:"مسح الكل",
  barrels:"برميل",
  kWh:"كيلو واط",
  clearFilters:"مسح التصفية",
  Kg : "كيلوجرام",
  cubicMeter:"متر مكعب",
  trees:"شجرة",
  gallons:"جالون",
  A:"أ",
  descending:"ي تنازلي",
  filter:"تصفية",
  AscendingTitle:"أ تصاعدي",
  totalNetWeight:"إجمالي الوزن الصافي",
  Z:"ي",
  NewToOld:"الأجدد إلى الأقدم",
  oldToNew:"الأقدم إالى الأجدد",
  SortTitle:"ترتيب",
  AscendingTitleAZ :"تصاعدي أ ← ي",
  DescendingTitleZA : "تنازلي ي ← أ",
  deleteAll : "حذف الكل",
  viewMoreText : "المزيد",
  yearlyText : "سنوي",
  monthlyText : "شهري",
  EnvironmentalSectionHeading: "الأثر البيئي",
  ReportPageTitle : "التقارير",
  filterTitle : "تصفية",
  contractText:"سنة تعاقدية",
  ReportTitle : "تصدّير التقرير",
  graphHeading : "إجمالي المعاد تدويره بالكيلو",
  branch:"الفرع",
  showing:"عرض",
  treeSaved:"عدد الأشجار التي تم الحفاظ عليها",
  co2EqReduction:"تقليل إنبعاثات ثاني أكسيد الكربون",
  waterSaved: "الماء الذي تم ترشيده",
  energySaved:"تقليل الطاقة المستخدمة",
  landfillSpaceSaved:"مساحة أراضي المكبّات التي تم إنقاذها",
  reducedPetroleunUse:"تقليل إستخدام البترول",
  tableHeaders : ["الفرع", "تاريخ التفريغ", "الفئة المعاد تدويرها","الوزن الصافي" ],
  contractYear : "سنة تعاقدية",
  recyclableCategory:"الفئة المعاد تدويرها",
  recyclableNetWeight:"الوزن الصافي",
  newClient :"العملاء الجدد",
  totalWasteNetWeight : "إجمالي الوزن الصافي للنفايات",
  viewDetailsBtn :"View Details",
  candidateslabelTitleText :"Candidates",
  emptyText :"لا توجد بيانات للآن",
  recyclingCollectionHeader :"إجمالي المعاد تدويره بالكيلو",
  serviceHeader :"خدمات الصيانة",
  environmentalHeader :"الأثر البيئي لإعادة التدوير",
  city:"المدينة",
  timePeriod:"حدود الوقت",
  lastMonth:"الشهر الماضي",
  thisMonth:"هذا الشهر",
  custom:"تخصيص",
  apply:"تطبيق",
  cancel:"إلغاء",
  last7Days:"أخر 7 أيام",
  last30Days:"أخر 30 يوم",
  totalRecyclableNetWeight:"إجمالي الوزن الصافي للنفايات",
  currentContractPeriod:"السنة التعاقدية الحالية",
  from:"من",
  to:"إالى",
  totalNetWeightText : "إجمالي الوزن الصافي",
  lastwasteCollection:"آخر جمع النفايات",
}
exports.customDate = "custom_date"
exports.totalNetWeightText = "Total Net Weight"
exports.exportPDFURL = "/generate_pdf"
exports.branchText = "Branch"
exports.timeText = "Time Period"
exports.recyclableText = "Recyclable Category"
// Customizable Area End