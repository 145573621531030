Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.putApiMethod = 'PUT';

exports.getTermsCondsApiEndPoint = 'bx_block_terms_and_conditions/terms_and_conditions/';
exports.getAllTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.createTermsCondsApiEndPoint = 'terms_and_conditions/terms_and_conditions';
exports.getAccountGroupsApiEndPoint = 'account_groups/groups';
exports.setTermsCondsAcceptanceApiEndPoint = 'terms_and_conditions/accept_and_reject';

exports.createTermsConds = 'Create New'
exports.created = 'Created'
exports.tickAccept = 'Please Tick To Accept';
exports.updateTermsConds = 'Update';
exports.acceptedUsers = 'Accepted Users';
exports.saveTermsConds = 'Save';
exports.termsCondsUpdatedMassage = 'Terms and Conditions Updated Successfully';
exports.termsCondsList = 'Terms & Conditions List';
exports.termsConds = 'Terms & Conditions';
exports.termsCondsUserList = 'Users That Accepted List';
exports.token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MiwiZXhwIjoxNjkyNzgzMjM5LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.oWDAc-0CpHy7hCIg1QW7kJ3jvRWgyvfnk-9nQSI0dPuTJUCYjC48KsvKqswS0dIO2rSZD31RfxlA5-HKq-Oogw"

exports.dateFormat = "MM-DD-YYYY";
exports.buttonText = "Edit";
exports.title = "Terms and Conditions";
exports.englishLang = {
  heading : 'Terms and Conditions',
  editButton : '',
  edit : 'Edit',
  discardChanges : 'Discard Changes',
  tandcEditHeading : 'Terms & Conditions',
  save : 'Save',
  discard : 'Discard Changes',
  english : 'English',
  arabic : 'Arabic'
}
exports.arabicLang = {
  heading : 'الشروط والأحكام',
  edit : 'تعدّيل',
  discard : 'تجاهل التغييرات',
  tandcEditHeading : 'تعدّيل',
  save : 'حفظ',
  english : 'الإنجليزية',
  arabic : 'العربية'
}
  // Customizable Area End