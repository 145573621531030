import React, { ChangeEvent } from "react";
import "./ClientDetailsStyles.css";
import InputField, { InputProps } from "./InputField.web";
import ClientDetailsController from "./ClientDetailsController";
import { addIcon, trashCan } from "./assets";
import Strings from "./Strings";

export default class ClientDetails extends ClientDetailsController {
  getErrorBoxStyles = () => {
    if (this.state.error.status === null) {
      return "";
    } else if (!this.state.error.status) {
      return "prompt-container-success";
    } else {
      return "prompt-container-failure";
    }
  };

  render() {
    const UIFields: Array<InputProps> = [
      {
        labelName: Strings.companyNameLabel,
        name: 'companyName',
        value: this.state.companyName,
        placeholder: Strings.enterCompanyName,
        onChangeText: this.onChangeCompanyName,
        type: 'text',
        error: this.state.companyValidate,
      },
      {
        labelName: Strings.emailAddressLabel,
        name: 'emailAddress',
        value: this.state.emailAddress,
        placeholder: Strings.enterEmailAddress,
        onChangeText: this.onChangeEmailAddress,
        type: 'text',
        error: this.state.emailValidate,
      },
      {
        labelName: Strings.mobileNumberLabel,
        name: 'mobileNumber',
        value: this.state.mobileNumber,
        placeholder: Strings.enterMobileNumber,
        onChangeText: this.onChangeMobile,
        type: 'tel',
        error: this.state.mobileValidate,
      },
      {
        labelName: Strings.addressLabel,
        name: 'address',
        value: this.state.address,
        placeholder: Strings.enterClientAddress,
        onChangeText: this.onChangeAddress,
        customStyles: "address-field",
        rows: 4,
        multiline: true,
        error: this.state.addressValidate,
      },
      {
        labelName: Strings.cityLabel,
        name: 'city',
        value: this.state.city,
        placeholder: Strings.enterCity,
        onChangeText: this.onChangeCity,
        type: 'text',
        error: this.state.cityValidate,
      },
    ];

    return (
      <div className="client-details-container">
        {window.innerWidth<=800&&this.state.error.status!==null&&<span className={`prompt-container ${this.getErrorBoxStyles()}`}>
              {this.state.error.message}
        </span>}
        <div className="top-field-container">
          <p className="top-field-label">{Strings.clientDetails}</p>
          <div className="top-field-right-container">
            {window.innerWidth>=800&&<span className={`prompt-container ${this.getErrorBoxStyles()}`}>
              {this.state.error.message}
            </span>}
            <div
              className={`active-inactive-button-container ${this.state
                .userActive && "selected-green"}`}
            >
              <button
                name="Active"
                data-testid={"activeButton"}
                onClick={this.onActivePress}
                className={`button-text active-button ${this.state.userActive &&
                  "selected-text"}`}
              >
                {Strings.active}
              </button>
              <button
                name="Inactive"
                data-testid={"inactiveButton"}
                onClick={this.onInactivePress}
                className={`button-text inactive-button ${!this.state
                  .userActive && "selected-red selected-button selected-text"}`}
              >
                {Strings.inactive}
              </button>
            </div>
          </div>
        </div>
        <form className="client-details-form">
          {UIFields.map((item, index) => {
            const {
              name,
              value,
              placeholder,
              onChangeText,
              customStyles = "",
              error = false,
              type,
              labelName,
              multiline,
              rows,
            } = item;

            return (
              <InputField
                data-testid={"clientDetailsInput"}
                key={index}
                value={value}
                name={name}
                labelName={labelName}
                placeholder={placeholder}
                onChangeText={onChangeText}
                customStyles={customStyles}
                error={error}
                type={type}
                multiline={multiline}
                rows={rows}
              />
            );
          })}
        </form>
        <p className="top-field-label sub-header">{Strings.contactPerson}</p>
        {this.state.contactPersons.map((item, index) => {
          if (item._destroy){
          return null
          }
            return (
              <div className="contact-person-item-container" key={index}>
                <div className="top-field-container contact-person-item-header">
                  <p className="top-field-label">{`${Strings.person} ${index + 1}`}</p>
                  <button
                    className="icon-button"
                    data-testid={`contactDeleteButton${index}`}
                    onClick={() => this.handleContactsDelete(index)}
                  >
                    <img className="icon" src={trashCan} alt="Delete" />
                  </button>
                </div>
                <InputField
                  data-testid={"contactPersonInputName"}
                  value={item.name}
                  name={'name'}
                  labelName={Strings.nameLabel}
                  placeholder={Strings.enterPersonName}
                  type={'text'}
                  customStyles="person-contact"
                  error={item.nameValidate}
                  onChangeText={(e: ChangeEvent<HTMLInputElement>) =>
                    this.handleContactsPersonDetails(e, index)
                  }
                />
                <InputField
                  data-testid={"contactPersonInputEmail"}
                  value={item.email}
                  name={'email'}
                  labelName={Strings.emailAddressLabel}
                  placeholder={Strings.enterEmailAddress}
                  type={'text'}
                  error={item.emailValidate}
                  customStyles="person-contact"
                  onChangeText={(e: ChangeEvent<HTMLInputElement>) =>
                    this.handleContactsPersonDetails(e, index)
                  }
                />
                <InputField
                  data-testid={"contactPersonInputMobile"}
                  value={item.mobile}
                  name={'mobile'}
                  labelName={Strings.mobileNumberLabel}
                  type={'tel'}
                  error={item.mobileValidate}
                  placeholder={Strings.enterMobileNumber}
                  customStyles="person-contact"
                  onChangeText={(e: ChangeEvent<HTMLInputElement>) =>
                    this.handleContactsPersonDetails(e, index)
                  }
                />
              </div>
            );
        })}
        <button
          className="add-button"
          type="submit"
          onClick={this.handleAddContactPerson}
        >
          <img src={addIcon} className="icon" />
          <p className="add-button-text">{Strings.addContactPerson}</p>
        </button>
        <p className="top-field-label branch-header">{Strings.branchName}</p>
        <div className="bottom-list-container">
          {this.state.branchNames.map((item, index) => {
            if (!item._destroy){
              return (
                <div className="contact-person-item-container" key={index}>
                  <div className="top-field-container contact-person-item-header">
                    <p className="top-field-label">{`${Strings.branch} ${index +
                      1}`}</p>
                    <button
                      className="icon-button"
                      data-testid={`branchDeleteButton${index}`}
                      onClick={() => this.handleBranchDelete(index)}
                    >
                      <img className="icon" src={trashCan} alt="Delete" />
                    </button>
                  </div>
                  <InputField
                    data-testid={"branchInputName"}
                    value={item.name}
                    name={'name'}
                    labelName={Strings.nameLabel}
                    placeholder={Strings.enterBranchName}
                    type={'text'}
                    customStyles="person-contact"
                    error={item.nameValidate}
                    onChangeText={(e: ChangeEvent<HTMLInputElement>) =>
                      this.handleBranchDetails(e, index)
                    }
                  />
                  <InputField
                    data-testid={"branchInputAddress"}
                    value={item.address}
                    name={'address'}
                    labelName={Strings.addressLabel}
                    placeholder={Strings.enterClientAddress}
                    type={'text'}
                    multiline={true}
                    error={item.addressValidate}
                    customStyles="person-contact address-field"
                    onChangeText={(e: ChangeEvent<HTMLInputElement>) =>
                      this.handleBranchDetails(e, index)
                    }
                  />
                  <InputField
                    data-testid={"branchInputCity"}
                    value={item.city}
                    name={'city'}
                    labelName={Strings.cityLabel}
                    type={'text'}
                    placeholder={Strings.enterCity}
                    error={item.cityValidate}
                    customStyles="person-contact"
                    onChangeText={(e: ChangeEvent<HTMLInputElement>) =>
                      this.handleBranchDetails(e, index)
                    }
                  />
                </div>
              );}
          })}
          <button
            className="add-button"
            type="submit"
            onClick={this.handleAddBranchPerson}
          >
            <img src={addIcon} className="icon" />
            <p className="add-button-text">{Strings.addBranch}</p>
          </button>
        </div>
        <div className="bottom-button-container">
          <button
            data-testid={"discardButton"}
            className="add-button"
            onClick={this.handleDiscardChanges}
          >
            <p className="add-button-text">{Strings.discardChanges}</p>
          </button>
          <button
            data-testid={"saveButton"}
            className="add-button button-fill"
            onClick={this.handlleSaveChange}
          >
            <p className="add-button-text">{Strings.save}</p>
          </button>
        </div>
      </div>
    );
  }
}
