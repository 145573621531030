import LocalizedStrings from "react-localization";
const configJSON = require("./config");

const Strings = new LocalizedStrings({
  english: configJSON.englishLanguage,
  arabic: configJSON.arabicLanguage,
});

  let lang =  localStorage.getItem("language");
  if (lang) {
    Strings.setLanguage(lang);
  } else {
    Strings.setLanguage("english");
  }

export default Strings
