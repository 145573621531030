import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRequest, handleResponseDataObject } from "./requestHelper";
import { TimeChoice } from "./DashboardController.web";
import Strings from './Strings';
import { CarbonEmission, EnergySaved, FuelSaved, LandFillSaved, TreeSaved, WaterSaved } from "./assets";

export const defaultDataEnvironmentImpact = [
  {
    "title": Strings.treeSaved,
    "value": 0,
    "icon": TreeSaved,
    "unit":Strings.trees
  },
  {
    "title": Strings.co2EqReduction,
    "value": 0,
    "icon": CarbonEmission,
    "unit":Strings.Kg
  },
  {
    "title": Strings.waterSaved,
    "value": 0,
    "icon": WaterSaved,
    "unit":Strings.gallons
  },
  {
    "title":Strings.energySaved,
    "value": 0,
    "icon": EnergySaved,
    "unit":Strings.kWh
  },
  {
    "title": Strings.landfillSpaceSaved,
    "value": 0,
    "icon": LandFillSaved,
    "unit":Strings.cubicMeter
  },
  {
    "title": Strings.reducedPetroleunUse,
    "value": 0,
    "icon": FuelSaved,
    "unit":Strings.barrels
  }
]

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
}
interface S {
  totalNumber: number
  totalChange: number
  chosenTime: TimeChoice
  listData: Array<number>
  listLabel: Array<string>
  maxChart: number
  tableData: Array<any>
  showSortModal: boolean
  enviromentalCardsData: Array<{
    title: string;
    value: number;
    icon: any;
    unit:string;
  }>
}
interface SS { }

export default class DashboardClientController extends BlockComponent<Props, S, SS> {
  getEnvironmentalImpactApi: string = "";
  getRecycleCollectionApi: string = "";
  getTotalRecyclableWeightApi: string = "";
  getTotalNetWeightApi: string = ""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      totalNumber: 0,
      totalChange: 0,
      chosenTime: TimeChoice.MONTH,
      listData: [],
      listLabel: [],
      maxChart: 0,
      tableData: [],
      showSortModal: false,
      enviromentalCardsData: defaultDataEnvironmentImpact
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getTotalRecyclableWeight();
    this.getRecycleCollection();
    this.getEnvironmentalImpact();
    this.getTotalNetWeight();
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getTotalRecyclableWeightApi:
            this.setState({ totalNumber: responseJson.total_recyclable_net_weight })
            break;
          case this.getRecycleCollectionApi:
            const dataList: any = handleResponseDataObject({ inputData: responseJson.recycling_collection, timeChoice: this.state.chosenTime, isLineChart: this.state.chosenTime !== TimeChoice.YEAR })
            this.setState({
              listData: dataList.data,
              listLabel: dataList.label,
              maxChart: Math.max(dataList.data),
            })
            break;
          case this.getEnvironmentalImpactApi:
            const response = [
              responseJson.tree_saved,
              responseJson.co2_eq_reduction,
              responseJson.water_saved,
              responseJson.kilo_watt_of_energy_saved,
              responseJson.landfill_space_saved,
              responseJson.reduction_petroleum_use
            ]

            const newData = [...defaultDataEnvironmentImpact].map((item: {
              title: string;
              value: number;
              icon: any;
              unit:string;
            }, index: number) => ({
              ...item,
              value: response[index]
            }))

            this.setState({ enviromentalCardsData: newData })
            break;
          case this.getTotalNetWeightApi:
            this.setState({ tableData: responseJson.data })
            break;
        }
      }
    }
  }

  async getTotalRecyclableWeight() {
    const token = this.getTokenForClientDashboard();
    const detail: any = localStorage.getItem('userDetail')
    const userDetailObj: any = JSON.parse(detail)
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getTotalRecyclableWeightApi = request.messageId;

    createRequest({
      endPoint: `${webConfigJSON.dashboardGetTotalRecycleWasteUrl}?account_id=${this.getAccountId(userDetailObj)}`,
      requestMsg: request,
      method: "GET",
      token,
    });
  }

  getFilterTime() {
    if (this.state.chosenTime === TimeChoice.YEAR) return "&recycling_collection_filter=yearly"
    else if (this.state.chosenTime === TimeChoice.MONTH) return "&recycling_collection_filter=monthly"
    else return ""
  }

  handleChangeTimeChart(time: TimeChoice) {
    this.setState({
      chosenTime: time,
      listData: [],
      listLabel: [],
      maxChart: 0
    })
  }

  async getRecycleCollection() {
    const token = this.getTokenForClientDashboard();
    const detail: any = localStorage.getItem('userDetail')
    const userDetailObj: any = JSON.parse(detail)
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getRecycleCollectionApi = request.messageId;

    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: `${webConfigJSON.dashboardGetRecycleCollectionUrl}?account_id=${this.getAccountId(userDetailObj)}${this.getFilterTime()}`
    });
  }

  async getEnvironmentalImpact() {
    const token = this.getTokenForClientDashboard();
    const detail: any = localStorage.getItem('userDetail')
    const userDetailObj: any = JSON.parse(detail)
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getEnvironmentalImpactApi = request.messageId;

    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: `${webConfigJSON.environmentalImpactEndPoint}?account_id=${this.getAccountId(userDetailObj)}&per_page=5`
    });
  }

  onClickViewMore() {
    let element: any = document.querySelector('#root div');
    element.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    setTimeout(() => {
      this.props.navigation.navigate("ReportClient")
    }, 500)
  }

  async getTotalNetWeight() {
    const token = this.getTokenForClientDashboard();
    const detail: any = localStorage.getItem('userDetail')
    const userDetailObj: any = JSON.parse(detail)
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getTotalNetWeightApi = request.messageId;

    createRequest({
      token,
      requestMsg: request,
      method: "GET",
      endPoint: `${webConfigJSON.getTotalNetWeightEndPoint}?account_id=${this.getAccountId(userDetailObj)}`
    });
  }

  handleShowSort() {
    this.setState({ showSortModal: true })
  }

  getTokenForClientDashboard = () => {
    const clientDetails: any = sessionStorage.getItem("clientDetails");
    const token = localStorage.getItem("token");
    return (JSON.parse(clientDetails) || {}).token || token;
  }

  getAccountId = (userDetailObj: any) => {
    const clientDetails: any = sessionStorage.getItem("clientDetails");
    const userAccountId = userDetailObj.account.id;
    return clientDetails ? JSON.parse(clientDetails).id : userAccountId;
  }

  componentDidUpdate(prevProps: any, prevState: S) {
    if (prevState.chosenTime !== this.state.chosenTime) {
      this.getRecycleCollection()
    }
  }

  handleBeforeUnload = () => {
    localStorage.removeItem("clientDetails");
  }

  componentWillUnmount(): any {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }
}
