Object.defineProperty(exports, "__esModule", {
    value: true
  });



  exports.englishLanguage = {
    heading : 'Terms and Conditions',
  editButton : '',
  edit : 'Edit',
  discardChanges : 'Discard Changes',
  tandcEditHeading : 'Terms & Conditions',
  discard : 'Discard Changes',
  english : 'English',
  arabic : 'Arabic', 
    categoriesHeading : 'Categories',
    noSelections : 'No selections',
    changesSaved : 'Your changes have been saved!',
    addCategory : ' Add new category',
    name : 'Name',
    save : 'Save',
    action : 'Action',
    confirmDelete : 'Are you sure you want to delete?',
    deleteWarning : 'Once deleted, it can’t be undone.',
    cancel : 'Cancel',
    delete : 'Delete',
    deleteAll : 'Delete All',
    editCategory : 'Edit category',
    added : 'New Category has been successfully added!',
    searchText : 'Search Category',
    filters : 'Filters',
    sort : 'Sort'
    }
exports.arabicLanguage = { 
  categoriesHeading : 'الفئات',
  noSelections : 'لم يتم حديد أي فئة',
  changesSaved : 'تم حفظ التغييرات بنجاح',
  addCategory : ' إضافة فئة جديدة',
  name : 'الاسم',
  action : 'الإجراء',
  confirmDelete : 'هل أنت متأكد من حذف الفئة',
  deleteWarning : 'لا يمكن إستعادة الفئة بعد حذفها',
  cancel : 'إلغاء',
  delete : 'حذف',
  deleteAll : 'حذف الكل',
  editCategory : 'تعدّيل الفئة',
  added : '!تمت إضافة الفئة بنجاح',
  searchText : 'إبحث عنا لفئات ',
  filters : 'تصفية',
  heading : 'الشروط والأحكام',
  edit : 'تعدّيل',
  discard : 'تجاهل التغييرات',
  tandcEditHeading : 'تعدّيل',
  save : 'حفظ',
  english : 'الإنجليزية',
  arabic : 'العربية',
  sort : 'ترتيب'
  }