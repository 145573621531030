import LocalizedStrings from 'react-localization'
const configJSON = require("./config");
  
const LocalizedString = new LocalizedStrings({
      english: configJSON.englishLanguage,
      arabic: configJSON.arabicLanguage,
    });
  
      let curr =  localStorage.getItem("language");
      if (curr) {
        LocalizedString.setLanguage(curr);
      } else {
        LocalizedString.setLanguage("english");
      }

  
export default LocalizedString