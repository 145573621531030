import Strings from "./Strings";

export const imgPasswordVisible = require("../assets/visible.png");
export const imgPasswordInVisible = require("../assets/eye-slash.png");
export const logoImg = require("../assets/logoImg.png");
export const ticked = require("../assets/tick-circle.png");
export const unticked = require("../assets/unticked.png");
export const forgotPasswordStrings = {
  headerText: Strings.forgotPassword,
  subHeaderText:Strings.forgotPasswordSubHeader,
  buttonText: Strings.resetPassword,
};
export const resetPasswordStrings = {
  headerText: Strings.resetPassword,
  subHeaderText: Strings.resetPasswordSubheader,
  buttonText: Strings.resetPassword,
};
export const resetSuccessfulStrings = {
  buttonText: Strings.backToLogin,
  subHeaderImg: ticked,
  subHeaderText: Strings.passwordResetSuccessful,
};

export const constantPasswordValidation = [
  Strings.passwordMustBe8Character,
  Strings.passwordContainSpecialCharacter,
  Strings.passwordContainNumeric,
  Strings.passwordContainUpperAndLowerCharacter,
];
