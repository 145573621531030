import React from "react";

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    IconButton,
    Typography,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from "@material-ui/lab";
import Strings from './Strings'
import "./RegisterNewAdminStyles.css"

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import RegisterNewAdminController, {
    Props,
} from "./RegisterNewAdminController.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
import Navbar from "../../settings2/src/Navbar.web";
import { eyeImg, successImg, vectorDown, vectorUp, yesImg } from "./assets";

export default class RegisterNewAdmin extends RegisterNewAdminController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start

            <div className="main-wrapper contactus--inner-cont">
                {/* Navigation Menu */}
                    <NavigationMenu navigation={this.props.navigation} id={""} />
                <Box flex={3} minWidth={0} style={{ backgroundColor: 'rgba(245, 245, 245, 1)' }}>
                        <Navbar navigation={this.props.navigation} id={""} />
                    <Box style={webStyle.second_section}>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '50px' }}>
                            <Typography style={webStyle.register_text}>
                               {Strings.registerNewAdmin}
                            </Typography>
                            {
                                this.state.isRequired && <Alert icon={false} style={webStyle.alert_style} >
                                    {Strings.oneOfTheFieldIsInvalidOrEmpty}
                                </Alert>
                            }

                        </div>
                        <Box width="80%" style={webStyle.form_div}>
                            <div className="register-admin-top-input-container">
                                <Box style={webStyle.elt_wrapper}>
                                    <Typography style={webStyle.text_style}>{Strings.firstName}</Typography>
                                    <input type="text"
                                        data-test-id="firstNameInput"
                                        autoComplete="new-password"
                                        style={{
                                            height: '44px',
                                            backgroundColor:'transparent',
                                            borderRadius: '8px',
                                            width: '100%',
                                            border: '1px solid #E0E2E7',
                                            paddingLeft: '10px',
                                            fontSize: '14px',
                                            color: '#1C3045',
                                            fontFamily: 'Barlow Semi Condensed',
                                            borderColor: `${this.state.fnameFocused ? '#BFCC33' : '#E0E2E7'}`
                                        }}
                                        onFocus={() => {
                                            this.setState({ fnameFocused: true });
                                        }}
                                        placeholder={Strings.enterFirstName}
                                        value={this.state.firstName}
                                        onChange={(e: any) => {
                                            this.setState({ firstName: e.target.value })
                                        }}
                                    />
                                </Box>
                                <Box style={webStyle.elt_wrapper}>
                                    <Typography style={{ ...webStyle.text_style, color: 'rgba(28, 48, 69, 0.7)' }}>{Strings.lastName}</Typography>
                                    <input type="text"
                                        data-test-id="lastNameInput"
                                        style={{
                                            height: '44px',
                                            borderRadius: '8px',
                                            backgroundColor:'transparent',
                                            width: '100%',
                                            border: '1px solid #E0E2E7',
                                            paddingLeft: '10px',
                                            fontSize: '14px',
                                            color: '#1C3045',
                                            fontFamily: 'Barlow Semi Condensed',
                                            borderColor: `${this.state.lnameFocused ? '#BFCC33' : '#E0E2E7'}`
                                        }}
                                        onFocus={() => {
                                            this.setState({ lnameFocused: true });
                                        }}
                                        autoComplete="new-password"
                                        placeholder={Strings.enterLastName}
                                        value={this.state.lastName}
                                        onChange={(e: any) => {
                                            this.setState({ lastName: e.target.value })
                                        }}
                                    />
                                </Box>
                            </div>
                            <Box style={webStyle.wrapper_div}>
                                <Typography style={webStyle.text_style}>{Strings.emailAddress}</Typography>
                                <input type="text"
                                    data-test-id="emailInput"
                                    style={{ ...webStyle.input_style, backgroundColor:'transparent',color: '#1C3045', borderColor: `${this.state.isFocused ? (this.isValidEmail(this.state.email) ? '#BFCC33' : '#DC2626') : '#E0E2E7'}` }}
                                    placeholder={Strings.enterEmailAddress}
                                    value={this.state.email}
                                    onChange={(e: any) => {
                                        this.setState({ email: e.target.value })
                                    }}
                                    autoComplete="new-password"
                                    onFocus={() => {
                                        this.setState({ isFocused: true });
                                    }}
                                    onBlur={() => {
                                        this.setState({ isFocused: this.state.email !== '' });
                                    }}
                                />
                            </Box>
                            <Box style={webStyle.wrapper_div}>
                                <Typography style={webStyle.text_style}>{Strings.mobileNumberLabel}</Typography>
                                <input type="text"
                                    data-test-id="mobileNumberInput"
                                    style={{ ...webStyle.input_style,backgroundColor:'transparent', color: '#1C3045', borderColor: `${this.state.mobileFocused ? '#BFCC33' : '#E0E2E7'}` }}
                                    placeholder={Strings.enterMobileNumber}
                                    value={this.state.mobileNum}
                                    onChange={(e: any) => {
                                        this.setState({ mobileNum: e.target.value })
                                    }}
                                    autoComplete="new-password"
                                    onFocus={() => {
                                        this.setState({ mobileFocused: true })
                                    }}
                                />
                            </Box>
                            <Box style={webStyle.wrapper_div}>
                                <Typography style={webStyle.text_style}>{Strings.role}</Typography>
                               
                                    <div
                                        style={{
                                            position: 'relative',
                                            display: 'inline-block',
                                            width: '100%',
                                            height: '44px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: 'relative',
                                                padding: '10px',
                                                backgroundColor:'transparent',
                                                border: '1px solid #E0E2E7',
                                                borderRadius: '8px',
                                                cursor: 'pointer',
                                                ...(this.state.isOpen ? { marginBottom: '10px' } : {}),
                                                borderColor: `${this.state.roleFocused ? '#BFCC33' : '#E0E2E7'}`
                                            }}
                                            onClick={this.toggleDropdown}
                                            onFocus={() => {
                                                this.setState({ roleFocused: true });
                                            }}
                                            onBlur={() => {
                                                this.setState({ roleFocused: false });
                                            }}
                                            tabIndex={0}
                                        >
                                            <p style={{
                                                fontFamily: 'Barlow Semi Condensed',
                                                color: '#1C3045',
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                height: '25px',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}>{this.state.role || Strings.selectRole}</p>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    right: '10px',
                                                    transform: 'translateY(-50%)',
                                                }}
                                            >
                                              {this.state.isOpen ?  <img src={vectorUp} /> :
                                                <img src={vectorDown} /> }
                                            </div>
                                        </div>
                                        {this.state.isOpen && (
                                            <div
                                                style={{
                                                    
                                                    left: '0',
                                                    zIndex: 1000,
                                                    position: 'relative',
                                                    backgroundColor: '#fff',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '8px',
                                                    padding: '12px',
                                                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
                                                    marginTop: '10px',
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    fontFamily: 'Barlow Semi Condensed',
                                                }}
                                            >
                                                {options.map((option, index) => (
                                                    <li
                                                        key={index}
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '5px 0',
                                                        }}
                                                        onClick={() => this.selectOption(option)}
                                                    >
                                                        <p>{option}</p>
                                                    </li>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                            </Box>
                            <Box style={webStyle.wrapper_div}>
                                <Typography style={webStyle.text_style}>{Strings.createPassword}</Typography>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <input
                                        data-test-id="createPassInput"
                                        type={this.state.seePassword ? 'text' : 'password'}
                                        style={{ ...webStyle.input_style, backgroundColor:'transparent', color: '#1C3045', borderColor: `${this.state.createFocused ? '#BFCC33' : '#E0E2E7'}` }}
                                        placeholder={Strings.createAPassword}
                                        value={this.state.createPass}
                                        onChange={(e: any) => {
                                            this.setState({ createPass: e.target.value })
                                        }}
                                        autoComplete="new-password"
                                        onFocus={() => {
                                            this.setState({ createFocused: true });
                                        }}
                                    />
                                    <div style={{ position: 'relative' }}>
                                        <img src={eyeImg}
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                right: '10px',
                                                transform: 'translateY(-50%)',
                                                width: '18px',
                                                height: '18px'
                                            }}
                                            onClick={this.handleShowPassword}
                                        />
                                    </div>
                                </div>
                            </Box>
                            <Box style={webStyle.wrapper_div}>
                                <Typography style={webStyle.text_style}>{Strings.confirmPassword}</Typography>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <input
                                        data-test-id="confirmPass"
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        style={{ ...webStyle.input_style, backgroundColor:'transparent', color: '#1C3045', borderColor: `${this.state.confirmFocused ? '#BFCC33' : '#E0E2E7'}` }}
                                        placeholder={Strings.confirmPassword}
                                        value={this.state.confirmPass}
                                        onChange={(e: any) => {
                                            this.setState({ confirmPass: e.target.value })
                                        }}
                                        autoComplete="new-password"
                                        onFocus={() => {
                                            this.setState({ confirmFocused: true });
                                        }}
                                    />
                                    <div style={{ position: 'relative' }}>
                                        <img src={eyeImg}
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                right: '10px',
                                                transform: 'translateY(-50%)',
                                                width: '18px',
                                                height: '18px'
                                            }}
                                            onClick={this.showConfirmPassword}
                                        />
                                    </div>
                                </div>
                            </Box>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '20px', marginTop: '40px', marginRight: '30px',marginBottom:"10px" }}>
                            <Button
                                variant="outlined"
                                style={webStyle.discard_button}
                                onClick={this.handleCancel}
                            >{Strings.discardChanges}</Button>
                            <Button
                                data-test-id="sendBtn"
                                variant='contained'
                                onClick={this.handleSendInvite}
                                style={{
                                    color: 'white',
                                    backgroundColor: '#32569A',
                                    textTransform: "none",
                                    borderRadius:"0.5rem"
                                }}
                            >{Strings.sendInvite}</Button>
                        </Box>
                    </Box>
                    <Box>
                    </Box>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleCloseDilog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth
                        maxWidth="xs"
                        PaperProps={{
                            style: {
                                overflow: 'hidden',
                                borderRadius: '12px',
                                width: '640px',
                                height: '308px',
                                maxWidth: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            },
                        }}
                    >
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={this.handleCloseDilog}
                            aria-label="close"
                            style={{
                                position: 'absolute',
                                top: '16px',
                                right: '16px',
                                padding: '8px',
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" >
                                <div>
                                    <img src={successImg} alt="Success Image" style={{ position: 'absolute', top: 100, left: 260 }} />
                                    <img src={yesImg} alt="Yes Image" style={{ position: 'absolute', top: 100, left: 269 }} />
                                    <p style={{ 
                                        textAlign: 'center', 
                                        marginTop: '190px',
                                        color:'black',
                                        fontFamily:'Barlow Semi Condensed', 
                                        fontSize:'16',
                                        fontWeight:500
                                        }}>{Strings.yourInviteHasBeenSentSuccessfully}</p> 
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </Box>
            </div>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    second_section: {
        paddingTop: '20px',
        paddingLeft: '20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        boxSizing:"border-box"
    } as const,
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    navigationMenu: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    } as const,
    register_text: {
        fontFamily: 'Barlow Semi Condensed',
        fontSize: '24px',
        fontWeight: 700,
        color: '#1C3045'
    },
    form_div: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px'
    } as const,
    elt_wrapper: {
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px'
    } as const,
    wrapper_div: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px'
    } as const,
    text_style: {
        fontFamily: 'Barlow Semi Condensed',
        fontWeight: 500,
        fontSize: '16px',
        color: 'rgba(28, 48, 69, 0.7)'
    } as const,
    input_style: {
        height: '44px',
        borderRadius: '8px',
        width: '100%',
        border: '1px solid #E0E2E7',
        paddingLeft: '10px',
        fontSize: '14px',
        color: '#858D9D',
        fontFamily: 'Barlow Semi Condensed',
        position: 'relative',
    } as const,
    alert_style: {
        color: '#DC2626',
        fontFamily: 'Barlow Semi Condensed',
        fontSize: '16px',
        fontWeight: 400,
        backgroundColor: '#FEDCE0',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        marginRight: '10px'
    } as const,
    discard_button: {
        color: '#32569A',
        textTransform: 'none',
        fontFamily: 'Barlow Semi Condensed',
        fontSize: '16px',
        fontWeight: 700,
        borderRadius: '8px'
    } as const,

};
const options = [Strings.admin, Strings.superAdmin]

// Customizable Area End
