import React from "react";

//Customizable Area Start
import { logoImg } from "./assets";
import CustomForgotResetController, {
  Props,
} from "./CustomForgotResetController";
import InputFieldWeb from "./InputFieldWeb";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import  './ForgotPassword.css'

//Customizable Area End

export default class CustomForgotResetPassword extends CustomForgotResetController {
  render() {
    const {
      headerText,
      subHeaderText,
      buttonText,
      promptMessage,
      inputArray = [],
      onSubmitButtonPress = () => {},
      showPrompt,
      validateField = false,
      belowSubmitButtonUI,
      subHeaderImg,
      showBackButton = false,
      onBackButtonPress,
      subHeaderCustomStyle,
      customContainerStyle,
      subHeaderTextCustonStyle,
    }:Props = this.props;

    return (
      <div
        data-testid="customInputContainer"
        className={`custom-forgot-reset-password-container ${customContainerStyle}`}
      >
        {showBackButton && (
          <IconButton
            data-testid={"backButton"}
            style={styles.backButton}
            onClick={onBackButtonPress}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <img data-testid="logoImg" src={logoImg} className="logoImg" />
        {showPrompt !== null && (
          <span className={showPrompt ? "success-message" : "error-message"}>
            {promptMessage}
          </span>
        )}
        {headerText && <span className={"headerText"}>{headerText}</span>}
        {subHeaderText && (
          <div className={`sub-header ${subHeaderCustomStyle}`}>
            {subHeaderImg && (
              <img src={subHeaderImg} className="subheader-image" />
            )}
            {subHeaderText && (
              <h3 className={`subHeaderText ${subHeaderTextCustonStyle}`}>{subHeaderText}</h3>
            )}
          </div>
        )}
        <div className={'input-container'}>
        {inputArray.length > 0 &&
          inputArray.map((item: any, index: number) => {
            return (
              <InputFieldWeb
                data-testid={"inputField"}
                key={index}
                name={item?.name}
                placeholder={item?.placeholder}
                value={item?.value}
                onChange={item?.onChange}
                className={item?.className}
                type={item?.type}
                error={item?.error}
                errorMessage={item?.errorMessage}
                showPasswordValidationModal={item?.showPasswordValidationModal}
              />
            );
          })}
          </div>
        {buttonText && (
          <button
            className={`button ${!belowSubmitButtonUI &&
              "button-bottom-margin"}`}
            data-testid="submitButton"
            disabled={validateField}
            style={styles.submitButton}
            onClick={() => onSubmitButtonPress()}
          >
            {buttonText}
          </button>
        )}
        {belowSubmitButtonUI && belowSubmitButtonUI()}
      </div>
    );
  }
}

const styles = {
  submitButton: {
    marginTop: "1.37rem",
  },
  errorMessage: {
    width: "100%",
    marginLeft: 5,
  },
  backButton: {
    position: "absolute",
    top: '4rem',
    left: '5rem',
  } as const,
};
