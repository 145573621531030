import React from "react";

import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  Filler
} from 'chart.js'
import Strings from './Strings'



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

export enum ChartType {
    BAR,
    LINE
}

export interface ChartComponentProps { 
    label: Array<string>; 
    dataArray: Array<number>; 
    suggestedMax: number; 
    aspectRatio: number;
    chartType?: ChartType;
    typeIndex?:number;
}

export default class ChartComponent extends React.Component<ChartComponentProps> {
    constructor(props: ChartComponentProps) {
        super(props);
    }

    getUnit=()=>{
        switch(this.props.typeIndex){
            case 0 : return Strings.trees;
            case 2 : return Strings.gallons;
            case 3 : return Strings.kWh;
            case 4 : return Strings.cubicMeter;
            case 5 : return Strings.barrels
            default : return Strings.Kg
        }
    }

    // istanbul ignore next
    renderCustomTooltip=(context: any)=> {
        // Tooltip Element
        let tooltipEl:any = document.getElementById('chartjs-tooltip');
        let unit = this.getUnit()

        // Create element on first render
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        const tooltipModel = context.tooltip;
        if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
            tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem:any) {
            return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
            const bodyLines = tooltipModel.body.map(getBody);

            let innerHtml = '<thead>';

            innerHtml += '</thead><tbody>';

            bodyLines.forEach(function(body:any, i:any) {
                let style = 'background: #fff';
                style += '; border-width: 0px';
                const span = '<span style="' + style + '">' + body + ' '+ unit+'</span>';
                innerHtml += '<tr><td>' + span + '</td></tr>';
                
            });
            innerHtml += '</tbody>';

            let tableRoot:any = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
        }

        const position = context.chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipEl.style.font = "Barlow Semi Condensed";
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.scrollY - 40 + tooltipModel.caretY + 'px';
        tooltipEl.style.padding = '10px'
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.border = '1px solid #fff';
        tooltipEl.style.borderRadius = '5px';
        tooltipEl.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
        tooltipEl.style.backgroundColor = '#fff';
        tooltipEl.style.transform = "translate(-50%,-50%)"
    }

    // istanbul ignore next
    getRadientBackground(context: any) {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        let gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, "#E1F0E6");
        gradient.addColorStop(1, "#EAEEBA");
        if (!chartArea) {
            // This case happens on initial chart load
            return;
        }
        return gradient;
    }

    getTicks(value: number) {
        return `${value / 1000}K`;
    }

    renderBarChart() {
        const { label, dataArray, aspectRatio, suggestedMax } = this.props
        const data = {
            labels: label,
            datasets: [
                {
                    backgroundColor: '#E1F0E6',
                    borderWidth: 0,
                    hoverBackgroundColor: '#32569A',
                    hoverBorderColor: '#32569A',
                    borderRadius: 8,
                    barThickness: aspectRatio * 14,
                    data: dataArray,
                },
            ],
        };
        // istanbul ignore next
        const options: any = {
            responsive: true,
            maintainAspectRatio: false,
            // aspectRatio: aspectRatio,
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false,
                    external: (context: any) => this.renderCustomTooltip(context),
                }
            },
            scales: {
                yAxis: {
                    offset: true,
                    grid: {
                        display: false,
                        borderWidth: 0
                    },
                    beginAtZero: true,
                    backgroundColor: "#fff",
                    suggestedMax: suggestedMax,
                   
                    // ticks: {
                    //     callback: (value: number) => this.getTicks(value)
                    // }
                },
                xAxis: {
                    offset: true,
                    grid: {
                        display: false,
                        borderWidth: 0
                    },
                }
            },
        };
        return <Bar data={data} options={options} />
    }

    renderLineChart() {
        const { label, dataArray, aspectRatio, suggestedMax } = this.props
        const data = {
            labels: label,
            datasets: [
                {
                    tension: 0.2,
                    borderColor: '#E1F0E6',
                    backgroundColor: (context: any) => this.getRadientBackground(context),
                    fill: true,
                    data: dataArray,
                    pointBackgroundColor: "rgba(255, 255, 255, 0)",
                    pointBorderColor: "rgba(255, 255, 255, 0)",
                    pointHoverBackgroundColor: "rgba(255, 255, 255, 1)",
                    pointHoverBorderColor: "#32569A",
                    pointBorderWidth: 0.01,
                    pointStyle: 'circle',
                    pointRadius: 0.01,
                    pointHoverRadius: 10,
                    pointHoverBorderWidth: 2,
                    pointHitRadius: 10
                },
            ],
        };

        // istanbul ignore next
        const options: any = {
            responsive: true,
            maintainAspectRatio: false,
            // aspectRatio: aspectRatio,
            layout: {
                margin: 20
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false,
                    external: (context: any) => this.renderCustomTooltip(context)
                } as any
            },
            scales: {
                offset: true,
                yAxis: {
                    grid: {
                        display: false,
                        borderWidth: 0,
                    },
                    beginAtZero: true,
                    backgroundColor: "#fff",
                    suggestedMax: suggestedMax,
                },
                xAxis: {
                    grid: {
                        display: false,
                        borderWidth: 0
                    },
                }
            },
        };
        return <Line data={data} options={options} />
    }

    render() {
        if(this.props.chartType === ChartType.BAR) {
            return (
                <div>
                    {this.renderBarChart()}
                </div>
            )
        } else {
            return (
                <div>
                    {this.renderLineChart()}
                </div>
            )
        }
    }
}
