// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";

export interface Props {
  navigation : any
  id:any
}

interface S {
 
}

interface SS {
}

export default class PickupRequestClientController extends BlockComponent<
  Props,
  S,
  SS
> {
  getApicallData: string = "";

  constructor(props: Props) {
    super(props);
    

    this.state = {
    };
  }
}

// Customizable Area End
