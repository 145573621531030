Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "account_block/accounts";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "account_block/accounts";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.contractDetailSendEndPoint = "account_block/accounts/";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.clientDetailsEndpoint = 'account_block/accounts';
exports.accountCreationUpdate = 'account_block/accounts/';

exports.endPointApiGetClientAccountDetail = "account_block/accounts/";
exports.contentTypeApiGetClientAccountDetail = "application/json";
exports.methodTypeApiGetClientAccountDetail = "GET";


exports.endPointApiGetClientAccountList = "account_block/accounts";
exports.contentTypeApiGetClientAccountList = "application/json";
exports.methodTypeApiGetClientAccountList = "GET";

exports.endPointApiGetCompanyList = "account_block/account_company_names";
exports.contentTypeApiGetCompanyList = "application/json";
exports.methodTypeApiGetCompanyList = "GET";

exports.endPointApiGetCityList = "account_block/account_cities";
exports.contentTypeApiGetCityList = "application/json";
exports.methodTypeApiGetCityList = "GET";

exports.endPointApiDeleteClientList = "account_block/accounts/destroy_all";
exports.contentTypeApiDeleteClientList = "application/json";
exports.methodTypeApiDeleteClientList = "DELETE";

exports.englishLanguage = {
  companyNameLabel: "Company Name",
  companyName: "companyName",
  enterCompanyName: "Enter company name",
  city: "city",
  cityLabel: "City",
  enterCity: "Enter city",
  enterClientAddress: "Enter client address",
  address: "address",
  addressLabel: "Address",
  tel: "tel",
  email: "email",
  mobile: "mobile",
  mobileNumber: "mobileNumber",
  mobileNumberLabel: "Mobile Number",
  enterMobileNumber: "Enter mobile number",
  enterEmailAddress: "Enter email address",
  emailAddress: "emailAddress",
  emailAddressLabel: "Email Address",
  active: "Active",
  inactive: "Inactive",
  name: "name",
  nameLabel: "Name",
  enterPersonName: "Enter person name",
  addContactPerson: "Add Contact Person",
  addBranch: "Add Branch",
  branchName: "Branch Name",
  branch: "Branch",
  enterBranchName: "Enter branch name",
  discardChanges: "Discard Changes",
  save: "Save",
  firstName: "first_name",
  lastName: "last_name",
  firstNameLabel: "First Name",
  lastNameLabel: "Last Name",
  enterFirstName: "Enter first name",
  enterLastName: "Enter last name",
  enterClientEmailAddress: "Enter client email address",
  createPasswordLabel: "Create Password",
  confirmPasswordLabel: "Confirm Password",
  createPassword: "create_password",
  confirmPassword: "confirm_password",
  password: "password",
  createPasswordForClient: "Create password for client",
  confirmPasswordForClient: "Confirm password for client",
  passwordDoesntMatch: "Your password doens’t match!",
  sendInvite: "Send Invite",
  clientDetails: "Client Details",
  contract: "Contract",
  report: "Report",
  inviteSent: "Your invitation is successfully sent!",
  registerNewClient:"Register New Client",
  editClient:'Edit Client',
  contactPerson:'Contact Person',
  person:"Person",
  changesSaved:"Your changes have been saved!",
  monthly:'Monthly',
  biannual:'Bi-Annual',
  quarterly:"Quarterly",
  endOfyear:"End of year",
  dragAndDropFile:"Drag and Drop files or ",
  browse:"Browse",
  maxFileSize:"Max 100 Kb File Size",
  reportFrequency:"Report Frequency",
  wasteTypeCatagory:"Waste Type Category",
  awarenessGiven:"Awareness Given",
  attachments:"Attachments",
  currentContractDetails:"Current Contract Details",
  startDate:"Start Date",
  endDate:"End Date",
  addNewContract:"Add New Contract",
  paymentTerms:"Payment Terms",
  frequency:"Frequency",
  firstPickupDate:"First Pickup Date",
  occurences:"Occurences",
  dueDatePayment:"Due Date Payment",
  dueDate:"Due Date",
  recurringPayment:"Recurring Payment",
  repeakEvery:"Repeat every",
  week:"Week",
  month:"Month",
  year:"Year",
  daily:"Daily",
  weekly:"Weekly",
  repeatOn:"Repeat on",
  ends:"Ends",
  on:"On",
  after:"After",
  addAdditionalDueDates:"Add Additional Due Dates",
  outdoorRecycleBins:"Outdoor Recycle Bins",
  indoorRecycleBins:"Indoor Recycle Bins",
  numberofRecyleBins:"Number of Recyle Bins",
  number:"Number",
  frequencyofPickup:"Frequency of Pickup",
  selectADates:"Select a date...",
  selectFrequency:"Select frequency",
  details:"Details",
  view:"View",
  account:"Account",
  emailLabel:"Email",
  lastPickup:'Last Pick Up',
  action:"Action",
  edit:"Edit",
  delete:"Delete",
  viewAsClient:"View as client",
  noResultsFound:"No Results Found!",
  noResultSubHeader:"No results match the  filter criteria. Clear filter to show results!",
  clearFilters:"Clear Filter",
  areYouSureDeleteHeader:"Are you sure you want to delete?",
  areYouSureDeleteSubheader1:"This action can’t be undone and all the data stored in this account won’t be",
  areYouSureDeleteSubheader2:"accessible anymore.",
  cancel:"Cancel",
  clearAll:"Clear All",
  clients:"Clients",
  pickupDate:'Pick Up Date',
  today:"Today",
  thisWeek:"This Week",
  lastWeek:"Last Week",
  lastMonth:"Last Month",
  thisMonth:"This Month",
  custom:"Custom",
  apply:"Apply",
  activeClients:"Active Clients",
  inactiveClients:"Inactive Clients",
  deleteAll:"Delete All",
  searchClient:"Search client" ,
  filters:"Filters",
  sort:'Sort',
  newest:"Newest",
  ascendingA:"Ascending A",
  z:'Z',
  a:"A",
  descendingZ:"Descending Z",
  from:"From",
  showing:"Showing",
  select:"Select...",
  monday : 'Monday',
  tuesday : 'Tuesday',
  wednesday : 'Wednesday',
  thursday : 'Thursday',
  friday : 'Friday',
  saturday : 'Saturday',
  sunday : 'Sunday'
}

exports.arabicLanguage = {
  companyNameLabel: "اسم الشركة",
  companyName: "companyName",
  enterCompanyName: "أدخل اسم الشركة",
  city: "city",
  monday:"الاثنين",
tuesday:"يوم الثلاثاء",
wednesday:"الأربعاء",
thursday:"يوم الخميس",
friday:"جمعة",
saturday:"السبت",
sunday:"الأحد",
  cityLabel: "المدينة",
  enterCity: "المدينة",
  enterClientAddress: "أدخل عنوان العميل",
  address: "address",
  addressLabel: "العنوان",
  tel: "tel",
  email: "email",
  startDate:"تاريخ البداية",
  mobile: "mobile",
  mobileNumber: "mobileNumber",
  mobileNumberLabel: "رقم الهاتف",
  enterMobileNumber: "أدخل رقم الهاتف",
  enterEmailAddress: "أدخل البريد الإلكتروني",
  emailAddress: "emailAddress",
  emailAddressLabel: "البريد الإلكتروني",
  active: "نشط",
  showing:"عرض",
  inactive: "غير نشط",
  endDate:"تاريخ النهاية",
  name: "name",
  nameLabel: "الاسم",
  enterPersonName: "أدخل اسم المسؤول",
  addContactPerson: "إضافة مسؤول تواصل",
  addBranch: "إضافة فرع",
  number:"رقم",
  branchName: "اسم الفرع",
  branch: "فرع",
  enterBranchName: "أدخل اسم الفرع",
  discardChanges: "تجاهل التغييرات",
  save: "حفظ",
  firstName: "first_name",
  lastName: "last_name",
  firstNameLabel: "الاسم الأول",
  lastNameLabel: "الاسم الأخير",
  enterFirstName: "أدخل الاسم الأول",
  enterLastName: "أدخل الاسم الأخير",
  enterClientEmailAddress: "أدخل البريد الإلكتروني الخاص بالعميل",
  createPasswordLabel: "إنشاء كلمة المرور",
  confirmPasswordLabel: "تأكيد كلمة المرور",
  createPassword: "create_password",
  confirmPassword: "confirm_password",
  password: "password",
  random:"randommm",
  createPasswordForClient: "إنشاء كلمة المرور للعميل",
  confirmPasswordForClient: "تأكيد كلمة مرور للعميل ",
  passwordDoesntMatch: "Your password doens’t match!",
  sendInvite: "إرسال الدعوة",
  clientDetails: "بيانات العميل",
  contract: "العقد",
  report: "التقرير",
  inviteSent: "!تم إرسال الدعوة للعميل بنجاح",
  registerNewClient:"إضافة عميل جديد",
  editClient:'تعديل العميل',
  contactPerson:'مسؤول التواصل',
  person:"مسؤول التواصل الأول",
  changesSaved:'تم حفظ التغييرات بنجاح',
  monthly:'شهري',
  biannual:'نصف سنوي',
  quarterly:"ربع سنوي",
  endOfyear:"نهاية السنة",
  dragAndDropFile:' إسحب و أفلت الملف أو',
  browse:"تصّفح",
  maxFileSize:'100 كيلو بايت هو الحد الأقصى لحجم الملف',
  reportFrequency:"معدّل التقارير",
  wasteTypeCatagory:"فئات النفايات",
  awarenessGiven:"التوعية",
  attachments:"مرفقات",
  currentContractDetails:"تفاصيل العقود",
  startData:"تاريخ البداية",
  endData:"تاريخ النهاية",
  addNewContract:"لإضافة عقد جديد",
  paymentTerms:"شروط السداد",
  frequency:"معدّل السداد",
  firstPickupDate:"تاريخ أول تفريغ",
  occurences:"تكرارات",
  dueDatePayment:"تاريخ  السداد",
  dueDate:"تاريخ السداد",
  recurringPayment:"دفعات متعددة",
  repeakEvery:"عدد مرات تكرار السداد",
  week:"أسبوع",
  from:"من",
  month:"شهر",
  year:"سنة",
  daily:"يومي",
  weekly:"أسبوعي",
  repeatOn:"التكرار في يوم",
  ends:"ينتهي",
  on:"في",
  after:"بعد",
  addAdditionalDueDates:"إضافة تاريخ سداد",
  outdoorRecycleBins:"حاويات إعادة التدوير الخارجية",
  indoorRecycleBins:"حاويات إعادة التدوير الداخلية",
  numberofRecyleBins:"عدد حاويات إعادة التدوير",
  frequencyofPickup:"معدّل التفريغ",
  selectADates:"إختيار التاريخ",
  selectFrequency:"أختيار معدّل التحميل",
  details:"التفاصيل",
  view:"معاينة",
  account:"الحساب",
  select:"...يختار",
  emailLabel:"البريد الإلكتروني",
  lastPickup:"آخر تفريغ",
  action:"الإجراء",
  edit:"تعديل",
  delete:"حذف",
  viewAsClient:"المعاينة كعميل",
  noResultsFound:"لا توجد بيانات",
  noResultSubHeader:"لا توجد نتائج تطابق معايير التصفية. إمسح التصفية لإظهار النتائج!",
  clearFilters:"مسح التصفية",
  areYouSureDeleteHeader:"هل أنت متأكد أنك تريد الحذف؟",
  areYouSureDeleteSubheader1:"لا يمكن التراجع عن هذا الإجراء ولن يمكن الوصول إلى جميع البيانات المخزنة في هذا",
  areYouSureDeleteSubheader2:"الحساب بعد الآن.",
  cancel:"إلغاء",
  clearAll:'مسح الكل',
  clients:'العملاء',
  pickupDate:"تاريخ التحمّيل",
  today:"اليوم",
  thisWeek:"الأسبوع الحالي",
  lastWeek:"الأسبوع الماضي",
  lastMonth:"الشهر الماضي",
  thisMonth:"الشهر الحالي",
  custom:"تخصيص",
  apply:"تطبيق",
  activeClients:"العملاء النشطين",
  inactiveClients:"العملاء الغير نشطين",
  deleteAll:"حذف الكل",
  searchClient:"إبحث عن عميل",
  filters:'تصفية',
  sort:"ترتيب",
  newest:"الأحدث",
  ascendingA:"أ تصاعدي",
  z:'ي',
  a:"أ",
  descendingZ:"ي تنازلي"
}

// Customizable Area End

