import React from "react";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Navbar from "../../settings2/src/Navbar.web";
import "./PickupRequest.css"
import PickupRequestClientController, {Props} from "./PickupRequestClientController.web";



export default class PickupRequestClient   extends PickupRequestClientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <div id="element" className="main-wrapper">
        <NavigationMenu navigation={this.props.navigation} id={""} />
        <div className="pickup_container_wrapper">
          <div className="pickup_navbar_container">
            <Navbar navigation={this.props.navigation} id={""} />
          </div>
          <div className="pickup_body_container">
              <h1>Client pickup page comming soon</h1>
          </div>
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start


// Customizable Area End
