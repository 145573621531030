import React from "react";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import Strings from "./Strings";
import { runEngine } from "../../../framework/src/RunEngine";
import { constantPasswordValidation, ticked, unticked, userAlter, userPic } from "./assets";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isVisible: boolean;
  apiToken: any;
  newValue: string;
  educationQualification: string[];
  projectList: string[];
  modalProject: any;
  awardList: any;
  patentList: any;
  loadingEQ: boolean;
  activeTab: number;
  loadingProject: boolean;
  loadingAwards: boolean;
  loadingPub: boolean;
  isModalOpen: boolean;
  modalItem: any;
  initialState: any;
  image: any;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  firstNameEdited: boolean;
  lastNameEdited: boolean;
  imageEdited: boolean;
  imagePreview: any;
  isSaved: boolean;
  selectedScreen: string;
  currentPassword: string;
  currentPasswordError: boolean;
  newPassword: string;
  newPasswrodError: boolean;
  confirmPassword: string;
  confirmPasswordError: boolean;
  headerText: string;
  focusInput: boolean;
  currentErrorText: string;
  confirmErrorText: string;
  currentVisibilitiy: boolean;
  newVisibility: boolean;
  confirmVisibility: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  navigation: any
  // Customizable Area End
}

export default class EducationalUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  labelTitle: string = "";
  getEducationCallId: string = "";
  getProjectCallId: string = "";
  getAwardsCallId: string = "";
  sendDataId: any = ""
  getPatentCallId: string = "";

  regex = [
    configJSON.paswordContains8Character,
    configJSON.passwordContainsSpecialCharacter,
    configJSON.passwordContainsDigit,
    configJSON.passwordContainsUpperLowerCharacter,
  ];

  constantPasswordValidation = [
    Strings.charcterLength,
    Strings.specialCharacter,
    Strings.numericDigit,
    Strings.lowerAndUpperCaseCharacter,
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isVisible: false,
      apiToken: null,
      newValue: "1",
      educationQualification: [],
      projectList: [],
      modalProject: [],
      awardList: [],
      patentList: [],
      loadingEQ: true,
      activeTab: 1,
      loadingProject: true,
      loadingAwards: true,
      loadingPub: true,
      isModalOpen: false,
      modalItem: null,
      firstName: "",
      lastName: "",
      role: "",
      email: "",
      firstNameEdited: false,
      lastNameEdited: false,
      imageEdited: false,
      initialState: "",
      image: "",
      imagePreview: "",
      isSaved: false,
      selectedScreen: 'profile',
      currentPassword: '',
      currentPasswordError: false,
      newPassword: '',
      newPasswrodError: false,
      confirmPassword: '',
      confirmPasswordError: false,
      headerText: '',
      confirmErrorText: '',
      focusInput: false,
      currentErrorText: '',
      currentVisibilitiy: false,
      newVisibility: false,
      confirmVisibility: false,
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getToken();
    this.getEducationQualification();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (this.getEducationCallId !== null && this.getEducationCallId === dataMessage) {
      console.log(successMessage)
      if (successMessage) {
        this.initializeState(successMessage.data)
      }
    }

    if (this.sendDataId !== null && this.sendDataId === dataMessage) {

      this.handleClientAPIResponse(
        successMessage,
        errorMessage,
        this.clientSuccessCallback
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start

  initializeState(response: any) {
    this.setState({
      firstName: response['attributes'].first_name,
      lastName: response['attributes'].last_name,
      email: response['attributes'].email,
      role: response['attributes'].role['attributes'].name,
      image: response['attributes'].profile_picture,
      imagePreview: response['attributes'].profile_picture
    })

    this.setState({
      initialState: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        image: this.state.image
      }
    })
  }

  discardChanges() {
    if (this.state.firstNameEdited) {
      this.setState({ firstName: this.state.initialState.firstName })
    }

    if (this.state.lastNameEdited) {
      this.setState({ lastName: this.state.initialState.lastName })
    }

    if (this.state.imageEdited) {
      this.setState({ imageEdited: this.state.initialState.image })
    }
  }


  customPasswordValidationModalProfile = () => {
    let list = new Array(4).fill({ ticked: ticked, unticked: unticked });
    list = list.map((row, index) => {
      return {
        ...row,
        text: this.constantPasswordValidation[index],
        regex: this.regex[index],
      };
    });

    return (
      <div className="modal-container" id="modal">
        {list.map((item: any, index: number) => {
          return (
            <div key={index} className="modal-item-container">
              <img
                src={
                  item.regex.test(this.state.newPassword)
                    ? item.ticked
                    : item.unticked
                }
                className="item-icon"
              />
              <p className="item-text">{item.text}</p>
            </div>
          );
        })}
      </div>
    );
  };

  handleFirstNameLastName(event: any, type: string) {
    switch (type) {
      case 'first-name':
        this.setState({ firstName: event.target.value, firstNameEdited: true })
        break;

      case 'last-name':
        this.setState({ lastName: event.target.value, lastNameEdited: true })
    }
  }
  async getEducationQualification() {

    let token = this.getTokenForProfile();
    if (token) {
      const header = {
        token: token,
        "Content-Type": configJSON.getEducationContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getEducationCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.profileEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getEducationApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  }

  getTokenForProfile = () => {
    const data: any = sessionStorage.getItem("clientDetails");
    const path = this.props.navigation.history.location.pathname.slice(1);

    if(data && path === "EducationalUserProfileClient") {
      const clientDetails: any = JSON.parse(data);
      return clientDetails.token;
    } else {
      return localStorage.getItem("token");
    }
  }

  toggleVisibility(type: string) {
    switch (type) {
      case 'current':
        this.setState({ currentVisibilitiy: !this.state.currentVisibilitiy })
        break;

      case 'new':
        this.setState({ newVisibility: !this.state.newVisibility })
        break;

      case 'confirm':
        this.setState({ confirmVisibility: !this.state.confirmVisibility })
        break;
    }
  }

  getType(type: string) {
    switch (type) {
      case 'current':
        if (this.state.currentVisibilitiy) {
          return 'text'
        }
        else {
          return 'password'
        }

      case 'new':
        if (this.state.newVisibility) {
          return 'text'
        }
        else {
          return 'password'
        }

      case 'confirm':
        if (this.state.confirmVisibility) {
          return 'text'
        }
        else {
          return 'password'
        }
    }
  }

  handleImage(event: any) {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ imagePreview: reader.result, imageEdited: true, image: file })

      };

      reader.readAsDataURL(file);
    }
  }

  getImage() {
    if (this.state.imagePreview) {
      return this.state.imagePreview
    }
    else {
      return this.getPlaceholderImage()
    }
  }

  getPlaceholderImage() {
    if (this.state.role != 'Client') {
      return userPic
    }
    else {
      return userAlter
    }
  }

  getInputTextClassForLastName() {
    if (this.state.lastName == '' || this.state.lastName == undefined) {
      return 'input-text err'
    }
    else {
      return 'input-text'
    }
  }
  getInputTextClassForFirstName() {
    if (this.state.firstName == '' || this.state.firstName == undefined) {
      return 'input-text err'
    }
    else {
      return 'input-text'
    }
  }

  checkValidation(): boolean {
    if (this.state.firstName == '' || this.state.firstName == undefined) {
      return true;
    }
    if (this.state.lastName == '' || this.state.lastName == undefined) {
      return true;
    }
    return false
  }

  checkCurrentPassword(): boolean {
    if (!this.state.currentPassword.trim()) {
      this.setState({
        currentPasswordError: true
      })
      return false
    }
    else {
      return true;
    }
  }

  checknewPassword(): boolean {
    if (!this.state.newPassword.trim()) {
      this.setState({
        newPasswrodError: true
      })
      return false
    }
    else if (!this.checkPasswordValidations(this.state.newPassword)) {
      this.setState({
        newPasswrodError: true
      })
      return false
    }
    else {
      return true
    }
  }

  checkConfirmPassword(): boolean {
    if (!this.state.confirmPassword.trim()) {
      this.setState({
        confirmPasswordError: true,
        confirmErrorText: ''
      })
      return false
    }
    else if (this.state.newPassword.trim() && !this.checkConfirm(this.state.confirmPassword)) {
      this.setState({
        confirmPasswordError: true,
        confirmErrorText: 'Your password doesn’t match!'
      })
      return false
    }
    else {
      return true
    }
  }

  checkPasswordValidation() {
    let invalid = false;
    invalid = invalid || this.checkCurrentPassword()
    invalid = invalid || this.checknewPassword()
    invalid = invalid || this.checkConfirmPassword()

    return invalid;
  }

  save() {
    switch (this.state.selectedScreen) {
      case 'profile':
        if (!this.checkValidation()) {
          this.sendData('profile')
        }
        break;

      case 'password':
        if (this.checkPasswordValidation()) {
          this.sendData('password')
        }
    }

  }

  async sendData(type: string) {
    const data = new FormData()
    if (type === 'profile') {
      data.append("data[first_name]", this.state.firstName)
      data.append("data[last_name]", this.state.lastName)
      if (this.state.imageEdited) {
        data.append('data[profile_picture]', this.state.image, 'image.png')
      }
    }
    else {
      data.append('data[current_password]', this.state.currentPassword)
      data.append('data[new_password]', this.state.newPassword)
      data.append('data[confirm_password]', this.state.confirmPassword)
    }


    let token = await localStorage.getItem('token')
    if (token) {
      const header = {

        token,
      };

      this.setState({ isSaved: true })

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.sendDataId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.profileUpdateEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        data
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  getHeadingClass(param: string) {
    if (this.state.selectedScreen === param) {
      return 'profile-page-heading selected-page'
    }
    else {
      return 'profile-page-heading'
    }
  }

  setSelected(param: string) {
    this.setState({ selectedScreen: param , headerText : ''})
  }

  handleCurrentPassword(event: any) {
    this.setState({
      currentPassword: event.target.value,
      currentErrorText: '',
    })
    if (event.target.value == '') {
      this.setState({
        currentPasswordError: true
      })
    }
    this.handleCurrentOnBlur(event.target.value)
  }

  handleNewPassword(event: any) {

    this.setState({
      newPassword: event.target.value
    })
    this.handleOnBlur(event.target.value)
    if (this.state.confirmPassword.trim()) {
      this.handleConfirmOnBlur(this.state.confirmPassword)
    }
  }

  handleConfirmPassword(event: any) {

    this.setState({
      confirmPassword: event.target.value
    })

    this.handleConfirmOnBlur(event.target.value)
  }

  checkConfirm(confirmPassword: string) {
    if (this.state.newPassword === confirmPassword) {
      return true;
    }
    else {
      return false;
    }
  }

  checkPasswordValidations(value: string): boolean {
    if (configJSON.paswordContains8Character.test(value) && configJSON.passwordContainsDigit.test(value)
      && configJSON.passwordContainsSpecialCharacter.test(value) && configJSON.passwordContainsUpperLowerCharacter.test(value)) {
      return true;
    }
    else {
      return false;
    }
  }

  handleOnBlur(newPassword: string) {
    if (newPassword.trim() && !this.checkPasswordValidations(newPassword)) {
      this.setState({ newPasswrodError: true })
    }
    else {
      this.setState({ newPasswrodError: false })
    }
  }

  handleConfirmOnBlur(value: string) {
    if (this.state.newPassword != "" && value.trim() && !this.checkConfirm(value)) {
      this.setState({
        confirmPasswordError: true,
        confirmErrorText: Strings.errorPass
      })
    }
    else {
      this.setState({
        confirmPasswordError: false,
        confirmErrorText: ''
      })
    }
  }

  handleCurrentOnBlur(value: string) {
    if (value != "") {
      this.setState({
        currentPasswordError: false,
      })
    }
    else {
      this.setState({
        currentPasswordError: true
      })
    }
  }

  getControlClass(type: string) {
    switch (type) {
      case 'current':
        if (this.state.currentPasswordError) {
          return 'input-text err'
        }
        else {
          return 'input-text'
        }

      case 'new':
        if (this.state.newPasswrodError) {
          return 'input-text err'
        }
        else {
          return 'input-text'
        }

      case 'confirm':
        if (this.state.confirmPasswordError) {
          return 'input-text err'
        }
        else {
          return 'input-text'
        }
    }
  }


  discardPasswordChanges() {
    this.setState({
      currentPassword: '',
      currentPasswordError: false,
      currentErrorText: '',
      newPassword: '',
      newPasswrodError: false,
      confirmPassword: '',
      confirmPasswordError: false,
      confirmErrorText: ''
    })
  }

  getDiscardFunction() {
    if (this.state.selectedScreen == 'profile') {
      return this.discardChanges()
    }
    else {
      return this.discardPasswordChanges()
    }
  }


  handleClientAPIResponse(
    successMessage: any,
    errorMessage: any,
    callback: Function
  ) {
    if (successMessage) {
      if (successMessage.errors) {
        if (successMessage.errors[0].password) {
          this.setState({
            currentPasswordError: true,
            currentErrorText: Strings.newPasswordCantBeOld
          });
        }
      } else {
        callback(successMessage);
      }
    }
  }

  clientSuccessCallback = async (successMessage: any) => {
    if (this.state.selectedScreen == 'password') {
      this.setState({
        headerText: Strings.infoHeader
      });
      this.discardPasswordChanges()
    }
    else{
      this.setState({headerText:Strings.yourChangesHaveBeenSaved})
      this.setLocalStore(successMessage)
      let fullName :  any = document.getElementById("full-name")
      let loginImage :  any = document.getElementById("login-image")
      fullName.innerHTML = successMessage.data.attributes.full_name;
      loginImage.src = successMessage.data.attributes.profile_picture;
    }
    this.getEducationQualification()
  };

  setLocalStore(success : any)
  {
    let detail:any = localStorage.getItem('userDetail')
      let userDetailObj = JSON.parse(detail)
      if(userDetailObj.token && userDetailObj.account_role){
          userDetailObj.account.firt_name = success.data.attributes.first_name
          userDetailObj.account.last_name = success.data.attributes.last_name
          userDetailObj.account.full_name = success.data.attributes.full_name
          userDetailObj.profile_picture = success.data.attributes.profile_picture
          localStorage.setItem("userDetail", JSON.stringify(userDetailObj));
        }
    
  }

  isViewAsClient = () => {
    const clientDetails = sessionStorage.getItem("clientDetails");
    const path = this.props.navigation.history.location.pathname.slice(1);
    return !!clientDetails && path === "EducationalUserProfileClient";
  }

  handleBeforeUnload = () => {
    localStorage.removeItem("clientDetails");
  }

  componentWillUnmount(): any {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }


  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
