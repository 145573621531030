import React from 'react'
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from 'moment';
import { createRequest } from '../../dashboard/src/requestHelper';
import Strings from './Strings'

export interface EventType {
  title: string;
  color: string;
  color_title: string;
  event_date: string;
  description: string;
  creation_type?: string
}

export interface ItemEventType {
  id: string;
  attributes: EventType
}

export const TYPE_CALENDAR = {
  MONTH: "dayGridMonth",
  WEEK: "timeGridWeek",
  DAY: "timeGridDay"
}

export enum BUTTON_DAY_PRESSED {
  TODAY,
  PREV,
  NEXT
}

export const COLOR_TYPE = {
  GREEN_BFCC33: "#BFCC33",
  BLUE: "#32569A",
  RED: "#DC2626",
  PURPLE: "#8833FF",
  GREEN_288700: "#288700",
  COOL_GRAY: "#334155",
  RED_400: "#F87171",
  AMBER: "#F59E0B",
}

export const COLOR_LIST = [
  COLOR_TYPE.GREEN_BFCC33,
  COLOR_TYPE.BLUE,
  COLOR_TYPE.RED,
  COLOR_TYPE.PURPLE,
  COLOR_TYPE.GREEN_288700,
  COLOR_TYPE.COOL_GRAY,
  COLOR_TYPE.RED_400,
  COLOR_TYPE.AMBER,
]

export const configJSON = require("./config.js");

export enum CalendarType {
  MONTH,
  WEEK,
  DAY
}

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  todayEvent: Array<ItemEventType>
  tomorrowEvent: Array<ItemEventType>
  upcomingEvent: Array<ItemEventType>
  events: Array<any>
  eventList: Array<ItemEventType>
  currentCalendarType: CalendarType
  currentDate: Date
  modalVisible: boolean
  isDeleteItem: boolean
  modalItemVisible: boolean
  top: any
  left: any
  currentItem?: ItemEventType
  isEventChanged: boolean
  language: "en" | "ar"
}
interface SS { }

export default class CalenderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  calendarRef: any;
  getUpcomingEventApi: string = "";
  getCalendarEventApi: string = "";
  postAddEventApi: string = ""
  putEditEventApi: string = ""
  deleteEventApi: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      isEventChanged: false,
      todayEvent: [],
      tomorrowEvent: [],
      upcomingEvent: [],
      events: [],
      eventList: [],
      currentDate: new Date(),
      currentCalendarType: CalendarType.MONTH,
      modalVisible: false,
      modalItemVisible: false,
      isDeleteItem: false,
      top: 0,
      left: 0,
      currentItem: undefined,
      language:'en',
    };
    this.calendarRef = React.createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleClickTypeCalendar(CalendarType.WEEK)
    this.getCalendarEvent()
    this.getUpcomingEvent()
    let language=localStorage.getItem('language');
    if(language){
      if(language==='english'){
        this.setState({language:'en'})
      }
      else{
        this.setState({language:'ar'})
      }
    }
    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: S) {
    if (prevState.currentCalendarType !== this.state.currentCalendarType || prevState.currentDate !== this.state.currentDate) {
      this.getCalendarEvent()
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getCalendarEventApi:
            this.handleAddEventArray(responseJson.data)
            this.setState({ eventList: responseJson.data })
            break;
          case this.getUpcomingEventApi:
            this.setState({
              todayEvent: responseJson.todays_events,
              tomorrowEvent: responseJson.tomorrows_events,
              upcomingEvent: responseJson.upcoming_events,
            })
            break;
          case this.postAddEventApi:
            this.getCalendarEvent()
            this.setState({ currentItem: undefined })
            break;
          case this.putEditEventApi:
            this.getCalendarEvent()
            this.setState({ currentItem: undefined, isEventChanged: true })
            break;
          case this.deleteEventApi:
            this.getCalendarEvent()
            this.setState({ currentItem: undefined, isDeleteItem: false, isEventChanged: true })
            break;
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleAddEventArray(arrayData: Array<ItemEventType>) {
    const arrayDayWithEvent = arrayData.map((item: ItemEventType) => {
      const startDate = new Date(item.attributes.event_date)
      startDate.setHours(7)
      const endDate = new Date(item.attributes.event_date)
      endDate.setHours(18)
      return {
        title: item.attributes.title,
        end: endDate,
        start: startDate,
        textColor: item.attributes.color,
        id: item.id,
        className: "custom_event"
      }
    })
    this.setState({ events: arrayDayWithEvent })
  }

  getEventsOfDay(date: Date) {
    return this.state.eventList.filter((item) => item.attributes.event_date === moment(date).format("YYYY-MM-DD"))
  }

  async getCalendarEvent() {
    const token = localStorage.getItem("token");
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getCalendarEventApi = request.messageId;
    let startDate = ""
    let endDate = ""
    if (this.state.currentCalendarType === CalendarType.MONTH) {
      startDate = moment(this.state.currentDate).startOf('month').format('YYYY-MM-DD');
      endDate = moment(this.state.currentDate).endOf('month').format('YYYY-MM-DD');
    } else if (this.state.currentCalendarType === CalendarType.WEEK) {
      startDate = moment(this.state.currentDate).startOf('week').format('YYYY-MM-DD');
      endDate = moment(this.state.currentDate).endOf('week').format('YYYY-MM-DD');
    } else {
      startDate = moment(this.state.currentDate).format('YYYY-MM-DD');
      endDate = moment(this.state.currentDate).format('YYYY-MM-DD');
    }

    createRequest({
      endPoint: `${configJSON.calendarEventUrl}?start_date=${startDate}&end_date=${endDate}`,
      requestMsg: request,
      method: "GET",
      token,
    });
  }

  async getUpcomingEvent() {
    const token = localStorage.getItem("token");
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getUpcomingEventApi = request.messageId;
    const todayDate = moment(this.state.currentDate).format("YYYY-MM-DD")

    createRequest({
      endPoint: `${configJSON.upcomingEventUrl}?todays_date=${todayDate}`,
      requestMsg: request,
      method: "GET",
      token,
    });
  }

  onSaveHandle(data: EventType, id?: string) {
    if (id) {
      this.putEditEvent(data, id)
    } else if (this.state.isDeleteItem) {
      this.deleteEvent(id || "")
    } else {
      this.postAddEvent(data)
    }
  }

  async postAddEvent(data: EventType) {
    let token = await localStorage.getItem("token");
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.postAddEventApi = request.messageId;

    createRequest({
      token,
      requestMsg: request,
      method: "POST",
      endPoint: `${configJSON.calendarEventUrl}`,
      header: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: {
          ...data
        }
      })
    });
  }

  async putEditEvent(data: EventType, id: string) {
    let token = await localStorage.getItem("token");
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.putEditEventApi = request.messageId;

    createRequest({
      token,
      requestMsg: request,
      method: "PUT",
      endPoint: `${configJSON.calendarEventUrl}/${id}`,
      header: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        data: {
          ...data
        }
      })
    });
  }

  async deleteEvent(id: string) {
    let token = await localStorage.getItem("token");
    const request = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.deleteEventApi = request.messageId;

    createRequest({
      token,
      requestMsg: request,
      method: "DELETE",
      header: {
        "Content-Type": "application/json"
      },
      endPoint: `${configJSON.calendarEventUrl}/${id}`
    });
  }

  returnTitleHeader() {
    if (this.state.currentCalendarType === CalendarType.MONTH) {
      return Strings.thisMonthText
    } else if (this.state.currentCalendarType === CalendarType.WEEK) {
      return Strings.thisWeekText
    } else if (this.state.currentCalendarType === CalendarType.DAY) {
      return Strings.todayText
    } else return ""
  }

  handleClickTypeCalendar(typeCalendar: CalendarType) {
    this.setState({ currentCalendarType: typeCalendar, isEventChanged: false })
    if (typeCalendar === CalendarType.MONTH) {
      this.calendarRef?.calendar?.changeView(TYPE_CALENDAR.MONTH)
    } else if (typeCalendar === CalendarType.WEEK) {
      this.calendarRef?.calendar?.changeView(TYPE_CALENDAR.WEEK)
    } else {
      this.calendarRef?.calendar?.changeView(TYPE_CALENDAR.DAY)
    }
  }

  handleChangeDay(choice: BUTTON_DAY_PRESSED) {
    if (choice === BUTTON_DAY_PRESSED.TODAY) {
      this.calendarRef?.calendar?.today()
    } else if (choice === BUTTON_DAY_PRESSED.PREV) {
      this.calendarRef?.calendar?.prev()
    } else {
      this.calendarRef?.calendar?.next()
    }
    this.setState({ currentDate: this.calendarRef?.calendar?.getDate(), isEventChanged: false })
  }

  getEventTypeExistOnDay(date: Date) {
    const arrayType: any = []
    this.getEventsOfDay(date).forEach((item) => {
      if (new Date(item.attributes.event_date).getDate() === date.getDate() && new Date(item.attributes.event_date).getMonth() === date.getMonth() && new Date(item.attributes.event_date).getFullYear() === date.getFullYear()) {
        if (arrayType.indexOf(item.attributes.color) < 0) {
          arrayType.push(item.attributes.color)
        }
      }
    })
    return arrayType
  }

  checkEventsDay(date: Date) {
    const dateIndex = this.state.events.findIndex((item: any) => {
      return new Date(item.start).getDate() === date.getDate() && new Date(item.start).getMonth() === date.getMonth() && new Date(item.start).getFullYear() === date.getFullYear()
    })
    if (dateIndex >= 0) return true
    else return false
  }
  // Customizable Area End
}
