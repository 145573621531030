export const callIcon = require('../assets/call-calling.png');
export const checkIcon = require('../assets/check.png')
export const facebookIcon = require('../assets/Facebook.png')
export const instagramIcon = require('../assets/Instagram.png')
export const linkedInIcon = require('../assets/LinkedIn.png')
export const locationIcon = require('../assets/location.png')
export const sendIcon = require('../assets/send.png')
export const chatIcon = require ('../assets/sms.png');
export const twitterIcon = require('../assets/Twitter.png')
export const uncheckIcon = require('../assets/unCheck.png')
export const youtubeIcon = require('../assets/YouTube.png')
export const tickIcon = require('../assets/tick.png');
export const cross = require('../assets/cross.png');

