import React from 'react';
import  './ForgotPassword.css'

interface TimerProps {
    timeRemaining: number;
    setTimeRemaining:any
}

class Timer extends React.PureComponent<TimerProps> {
    interval: any
  
    componentDidMount() {
      const { timeRemaining, setTimeRemaining } = this.props;
      if (timeRemaining > 0) {
        this.interval = setInterval(() => {
          setTimeRemaining();
        }, 1000);
      }
    }
  
    componentWillUnmount() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    }
  
    formatTime(time: number) {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    }
  
    render() {
      const { timeRemaining } = this.props;
  
      return (
        <div className="timer-container">
          <p className="timer">{this.formatTime(timeRemaining)}</p>
        </div>
      );
    }
  }

export default Timer;
