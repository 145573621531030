// Customizable Area Start
import { createRef } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import Strings from './Strings'
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";

export const configJSON = require("./config");

export interface Props {
  navigation: any
  id: any
}

interface SearchQuery {
  page: number;
  per_page: number;
  status: string[] | string;
  search_term: string;
  clients: number[];
  time_period: string;
  branches: string[];
  sort: string;
}

interface Error {
  status: boolean | null;
  message: string;
}

interface S {
  token: any;
  error: Error;
  activeTabIndex: number;
  filterModal: boolean;
  sortModal: boolean;
  expanded: string | boolean;
  sortList: Array<any>;
  clients: any[];
  pickupDates: any[];
  branches: any[];
  searchQuery: SearchQuery;
  pickupRequestData: any;
  totalPages: number;
  totalItems: number;
  pageBatch: number;
  selectedPickupRequests: number[];
  allPickupRequestsSelected: boolean;
  menuIndex: number;
  showDeclineModal: boolean;
  showRescheduleModal: boolean;
  showDeclineAllModal: boolean;
  showReasonModal: boolean;
  actionReason: string;
  alertMessage: string | null;
  rescheduleDate: string;
  reasonToRejectOrReschedule: string | null;
  selectedPickup: { requestId: string; clientName: string; pickUpDate: string }
}

interface SS {
}

export default class PickupRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  getBranchListAPIId: any;
  getClientsListAPIId: any;
  getPickupRequestDataAPIId: any;
  acceptPickupRequestAPIId: any;
  declinePickupRequestAPIId: any;
  reschedulePickupRequestAPIId: any;
  acceptAllPickupRequestsAPIId: any;
  declineAllPickupRequestsAPIId: any;
  getApicallData: string = "";
  filterButtonRef: any;
  filterModalRef: any;
  sortButtonRef: any;
  sortModalRef: any;
  actionsButtonRef: any;
  actionsModalRef: any;
  debouncedSearch: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ]


    this.state = {
      token: "",
      error: { status: null, message: "" },
      activeTabIndex: 0,
      filterModal: false,
      sortModal: false,
      expanded: "panel2",
      sortList: [
        { desc: "pick_up_date_desc", before: Strings.newest, after: "" },
        { desc: "client_name_asc", before: Strings.ascending+" A", after: "Z" },
        { desc: "client_name_desc", before: Strings.descending + " Z", after: "A" },
      ],
      clients: [],
      pickupDates: [
        { title: Strings.lastSevenDays, value: "last_7_days" },
        { title: Strings.lastThirtyDays, value: "last_30_days" }
      ],
      branches: [],
      searchQuery: {
        page: 1,
        per_page: 10,
        status: "pending",
        search_term: "",
        clients: [],
        time_period: "",
        branches: [],
        sort: "pick_up_date_desc",
      },
      pickupRequestData: [],
      totalPages: 1,
      totalItems: 0,
      pageBatch: 1,
      selectedPickupRequests: [],
      allPickupRequestsSelected: false,
      menuIndex: -1,
      showDeclineModal: false,
      showRescheduleModal: false,
      showDeclineAllModal: false,
      showReasonModal: false,
      actionReason: "",
      alertMessage: null,
      rescheduleDate: "",
      reasonToRejectOrReschedule: "",
      selectedPickup: {
        requestId: "",
        clientName: "",
        pickUpDate: ""
      },
    };

    this.debouncedSearch = this.debounce(this.fetchPickupRequestData, 300);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.filterButtonRef = createRef();
    this.filterModalRef = createRef();
    this.sortButtonRef = createRef();
    this.sortModalRef = createRef();
    this.actionsButtonRef = createRef();
    this.actionsModalRef = createRef();
  }

  async componentDidMount() {
    super.componentDidMount();
    let token = await localStorage.getItem("token");
    let userDetails: any = await localStorage.getItem("userDetail");
    userDetails = JSON.parse(userDetails);
    let userRole = userDetails?.account_role;
    if (token && (userRole === "Super Admin" || userRole === "Admin")) {
      this.setState({ token: token });
      this.fetchClientsList();
      this.fetchBranchList();
      this.fetchPickupRequestData();
      document.addEventListener("mousedown", this.handleClickOutside);
    } else {
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (this.getBranchListAPIId !== null && this.getBranchListAPIId === dataMessage) {
      this.handleGetDataAPIResponse(
        successMessage,
        errorMessage,
        this.getBranchesSuccessCallback
      );
    }
    if (this.getClientsListAPIId !== null && this.getClientsListAPIId === dataMessage) {
      this.handleGetDataAPIResponse(
        successMessage,
        errorMessage,
        this.getClientsSuccessCallback
      );
    }
    if (this.getPickupRequestDataAPIId !== null && this.getPickupRequestDataAPIId === dataMessage) {
      this.handleGetDataAPIResponse(
        successMessage,
        errorMessage,
        this.getPickupRequestDataSuccessCallback
      );
    }
    if (this.acceptPickupRequestAPIId !== null && this.acceptPickupRequestAPIId === dataMessage) {
      this.handleGetDataAPIResponse(
        successMessage,
        errorMessage,
        this.acceptRequestSuccessCallback
      );
    }
    if (this.declinePickupRequestAPIId !== null && this.declinePickupRequestAPIId === dataMessage) {
      this.handleGetDataAPIResponse(
        successMessage,
        errorMessage,
        this.declineRequestSuccessCallback
      );
    }
    if (this.acceptAllPickupRequestsAPIId !== null && this.acceptAllPickupRequestsAPIId === dataMessage) {
      this.handleGetDataAPIResponse(
        successMessage,
        errorMessage,
        this.acceptAllRequestSuccessCallback
      );
    }
    if (this.declineAllPickupRequestsAPIId !== null && this.declineAllPickupRequestsAPIId === dataMessage) {
      this.handleGetDataAPIResponse(
        successMessage,
        errorMessage,
        this.declineAllRequestSuccessCallback
      );
    }
    if (this.reschedulePickupRequestAPIId !== null && this.reschedulePickupRequestAPIId === dataMessage) {
      this.handleGetDataAPIResponse(
        successMessage,
        errorMessage,
        this.rescheduleRequestSuccessCallback
      );
    }
  }

  handleGetDataAPIResponse = (
    successMsg: any,
    errorMsg: any,
    callback: Function
  ) => {
    if (errorMsg) {
      console.log("error-------------", errorMsg);
    } else if (successMsg) {
      if (successMsg.errors) {
        if (successMsg.errors[0].token) {
          this.props.navigation.navigate("EmailAccountLoginBlock");
        } else {
          this.setState({
            error: { status: false, message: successMsg.errors[0] },
          });
        }
      } else {
        callback(successMsg);
      }
    }
  };

  fetchClientsList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClientsListAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClientsList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getClientsSuccessCallback = (successMessage: any) => {
    const clientList = successMessage.company_names.map((company: any) => {
      return {
        id: company[0],
        name: company[1]
      }
    })
    this.setState({
      clients: clientList
    });
  }

  fetchBranchList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBranchListAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBranchesList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBranchesSuccessCallback = (successMessage: any) => {
    this.setState({
      branches: [...successMessage.data]
    });
  };

  fetchPickupRequestData = async () => {
    const apiEndpoint = this.buildPickupRequestGetDataUrl();

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPickupRequestDataAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPickupRequestDataSuccessCallback = (successMessage: any) => {
    this.setState({
      pickupRequestData: [...successMessage?.data],
      totalPages: successMessage?.meta.pagy.pages,
      totalItems: successMessage?.meta.pagy.count,
    });
  };

  acceptPickupRequest = (requestId: string) => {
    const apiEndpoint = `${configJSON.actionAPIEndpoint}/${requestId}`;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const data = {
      action: "accept"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acceptPickupRequestAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  acceptRequestSuccessCallback = (successMessage: any) => {
    this.setState({
      alertMessage: successMessage.meta.message
    }, () => {
      this.fetchPickupRequestData();
      this.setState({ menuIndex: -1 });
      setTimeout(() => {
        this.setState({ alertMessage: null });
      }, 3000);
    });
  };

  declinePickupRequest = (requestId: string) => {
    const apiEndpoint = `${configJSON.actionAPIEndpoint}/${requestId}`;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const data = {
      action: "decline",
      rejection_reason: this.state.reasonToRejectOrReschedule
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.declinePickupRequestAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  declineRequestSuccessCallback = (successMessage: any) => {
    this.setState({
      alertMessage: successMessage.meta.message
    }, () => {
      this.fetchPickupRequestData();
      this.resetStateAfterAction();
      setTimeout(() => {
        this.setState({ alertMessage: null });
      }, 3000);
    });
  };

  reschedulePickupRequest = (requestId: string) => {
    const apiEndpoint = `${configJSON.actionAPIEndpoint}/${requestId}`;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const data = {
      action: "reschedule",
      pick_up_date: this.state.rescheduleDate,
      reschedule_reason: this.state.reasonToRejectOrReschedule
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reschedulePickupRequestAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  rescheduleRequestSuccessCallback = (successMessage: any) => {
    this.setState({
      alertMessage: successMessage.meta.message
    }, () => {
      this.fetchPickupRequestData();
      this.resetStateAfterAction();
      setTimeout(() => {
        this.setState({ alertMessage: null });
      }, 3000);
    });
  };

  resetStateAfterAction = () => {
    const { selectedPickup } = this.state;
    const updatedPickup = {
      ...selectedPickup,
      requestId: "",
      clientName: "",
      pickUpDate: "",
    }
    this.setState({
      menuIndex: -1,
      selectedPickup: updatedPickup,
      rescheduleDate: "",
      reasonToRejectOrReschedule: null
    });
  }

  acceptAllPickupRequests = () => {
    if (this.state.selectedPickupRequests.length > 0) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
      };
  
      const data = {
        ids: [...this.state.selectedPickupRequests] 
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.acceptAllPickupRequestsAPIId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.acceptAllEndpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ data })
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  acceptAllRequestSuccessCallback = (successMessage: any) => {
    this.setState({
      alertMessage: successMessage.message
    }, () => {
      this.fetchPickupRequestData();
      this.setState({ selectedPickupRequests: [], allPickupRequestsSelected: false });
      setTimeout(() => {
        this.setState({ alertMessage: null });
      }, 3000);
    });
  };

  declineAllPickupRequests = () => {
    if (this.state.selectedPickupRequests.length > 0) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token,
      };
  
      const data = {
        ids: [...this.state.selectedPickupRequests],
        rejection_reason: this.state.reasonToRejectOrReschedule
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.declineAllPickupRequestsAPIId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.declineAllEndpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ data })
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateAPIMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  declineAllRequestSuccessCallback = (successMessage: any) => {
    this.setState({
      alertMessage: successMessage.message
    }, () => {
      this.fetchPickupRequestData();
      this.setState({
        selectedPickupRequests: [],
        allPickupRequestsSelected: false,
        rescheduleDate: "",
        reasonToRejectOrReschedule: null
      }, () => console.log(this.state));
      setTimeout(() => {
        this.setState({ alertMessage: null });
      }, 3000);
    });
  };

  onFilterClick = () => {
    this.setState({
      filterModal: !this.state.filterModal,
      sortModal: false
    });
  }

  onSortClick = () => {
    this.setState({
      sortModal: !this.state.sortModal,
      filterModal: false
    });
  }

  handleAccordianChange = (panel: string) => (event: any, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel : false });
  }

  onChangeScreen = (tabIndex: number) => {
    this.setState({ activeTabIndex: tabIndex }, () => this.setPickupRequestStatus());
  }

  setPickupRequestStatus = () => {
    const { searchQuery } = this.state;
    let updatedSearchQuery;
    if (this.state.activeTabIndex === 0) {
      updatedSearchQuery = {
        ...searchQuery,
        page: 1,
        per_page: 10,
        status: "pending"
      };
    } else if (this.state.activeTabIndex === 1) {
      updatedSearchQuery = {
        ...searchQuery,
        page: 1,
        per_page: 10,
        status: ["accepted", "rescheduled"]
      };
    } else {
      updatedSearchQuery = {
        ...searchQuery,
        page: 1,
        per_page: 10,
        status: "rejected"
      };
    }
    this.setState({
      totalPages: 1,
      totalItems: 0,
      pageBatch: 1,
      selectedPickupRequests: [],
      allPickupRequestsSelected: false,
      menuIndex: -1,
      searchQuery: updatedSearchQuery
    }, () => this.fetchPickupRequestData());
  }

  onChangeSearch = (newValue: string) => {
    const { searchQuery } = this.state;
    const updatedSearchQuery = {
      ...searchQuery,
      page: 1,
      per_page: 10,
      search_term: newValue
    };
    this.setState({
      totalPages: 1,
      totalItems: 0,
      pageBatch: 1,
      selectedPickupRequests: [],
      allPickupRequestsSelected: false,
      menuIndex: -1,
      searchQuery: updatedSearchQuery
    });
    this.debouncedSearch();
  }

  debounce = (func: any, delay: number) => {
    let timer: any;
    let self = this
    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        self.setState({ searchQuery: { ...self.state.searchQuery, page:1, per_page: 10 } },()=>func())
      }, delay);
    };
  }

  clearFilters = () => {
    const { searchQuery } = this.state;
    const updatedSearchQuery = {
      ...searchQuery,
      page: 1,
      per_page: 10,
      clients: [],
      time_period: "",
      branches: [],
    };
    this.setState({
      totalPages: 1,
      totalItems: 0,
      pageBatch: 1,
      filterModal:false,
      selectedPickupRequests: [],
      allPickupRequestsSelected: false,
      menuIndex: -1,
      searchQuery: updatedSearchQuery
    }, () => this.fetchPickupRequestData());
  }

  onSelectClient = (selectedClient: number) => {
    const { searchQuery } = this.state;
    let updatedSearchQuery;
    if (!this.isClientSelected(selectedClient)) {
      updatedSearchQuery = {
        ...searchQuery,
        page: 1,
        per_page: 10,
        clients: [...this.state.searchQuery.clients, selectedClient]
      };
    } else {
      updatedSearchQuery = {
        ...searchQuery,
        page: 1,
        per_page: 10,
        clients: [...this.state.searchQuery.clients.filter((client: number) => client !== selectedClient)]
      };
    }
    this.setState({
      totalPages: 1,
      totalItems: 0,
      pageBatch: 1,
      selectedPickupRequests: [],
      allPickupRequestsSelected: false,
      menuIndex: -1,
      searchQuery: updatedSearchQuery
    }, () => this.fetchPickupRequestData());
  }

  isClientSelected = (selectedClient: number) => {
    return this.state.searchQuery.clients.includes(selectedClient);
  }

  onSelectPickupDate = (pickupDate: string) => {
    const { searchQuery } = this.state;
    const updatedSearchQuery = {
      ...searchQuery,
      page: 1,
      per_page: 10,
      time_period: pickupDate
    };
    this.setState({
      totalPages: 1,
      totalItems: 0,
      pageBatch: 1,
      selectedPickupRequests: [],
      allPickupRequestsSelected: false,
      menuIndex: -1,
      searchQuery: updatedSearchQuery
    }, () => this.fetchPickupRequestData());
  }

  isPickupDateSelected = (pickupDate: string) => {
    return this.state.searchQuery.time_period === pickupDate;
  }

  onSelectBranch = (selectedBranch: string) => {
    const { searchQuery } = this.state;
    let updatedSearchQuery;
    if (!this.isBranchSelected(selectedBranch)) {
      updatedSearchQuery = {
        ...searchQuery,
        page: 1,
        per_page: 10,
        branches: [...this.state.searchQuery.branches, selectedBranch]
      };
    } else {
      updatedSearchQuery = {
        ...searchQuery,
        page: 1,
        per_page: 10,
        branches: [...this.state.searchQuery.branches.filter((branch: string) => branch !== selectedBranch)]
      };
    }
    this.setState({
      totalPages: 1,
      totalItems: 0,
      pageBatch: 1,
      selectedPickupRequests: [],
      allPickupRequestsSelected: false,
      menuIndex: -1,
      searchQuery: updatedSearchQuery
    }, () => this.fetchPickupRequestData());
  }

  isBranchSelected = (selectedBranch: string) => {
    return this.state.searchQuery.branches.includes(selectedBranch);
  }

  onSortItemClick = (selectedSort: string) => {
    const { searchQuery } = this.state;
    const updatedSearchQuery = {
      ...searchQuery,
      page: 1,
      per_page: 10,
      sort: selectedSort
    };
    this.setState({
      totalPages: 1,
      totalItems: 0,
      pageBatch: 1,
      selectedPickupRequests: [],
      allPickupRequestsSelected: false,
      menuIndex: -1,
      searchQuery: updatedSearchQuery
    }, () => this.fetchPickupRequestData());
  }

  handleClickOutside = (event: any) => {
    if(
      this.actionsButtonRef.current &&
      !this.actionsButtonRef.current.contains(event.target) &&
      this.actionsModalRef.current &&
      !this.actionsModalRef.current.contains(event.target)
    ) {
      this.setState({ menuIndex: -1 });
    }
    if (
      this.filterModalRef.current &&
      !this.filterModalRef.current.contains(event.target) &&
      this.filterButtonRef.current &&
      !this.filterButtonRef.current.contains(event.target)
    ) {
      this.setState({ filterModal: false });
    }
    if (
      this.sortButtonRef.current &&
      !this.sortButtonRef.current.contains(event.target) &&
      this.sortModalRef.current &&
      !this.sortModalRef.current.contains(event.target)
    ) {
      this.setState({ sortModal: false });
    }
  };

  buildPickupRequestGetDataUrl = () => {
    const parametersMap = {
      page: "page",
      per_page: "per_page",
      status: "filter[status][]",
      search_term: "search_term",
      clients: "filter[account_id][]",
      time_period: "filter[time_period][name]",
      branches: "filter[branch_name][]",
      sort: "sort"
    };

    const endPoint = `${configJSON.getPickupRequestData}?`;
    let searchParams = "";

    Object.keys(this.state.searchQuery).forEach((query) => {
      // @ts-ignore
      if (Array.isArray(this.state.searchQuery[query])) {
        // @ts-ignore
        if (this.state.searchQuery[query]) {
          // @ts-ignore
          this.state.searchQuery[query].forEach((value: any) => {
            // @ts-ignore
            searchParams += `&${parametersMap[query]}=${value}`
          });
        }
      } else {
        // @ts-ignore
        if (this.state.searchQuery[query]) {
          // @ts-ignore
          searchParams += `&${parametersMap[query]}=${this.state.searchQuery[query]}`;
        }
      }
    });

    return endPoint + searchParams.substring(1);
  }

  onPreviousPageClick = () => {
    if (this.state.pageBatch !== 1) {
      this.setState({ pageBatch: this.state.pageBatch - 1 });
    }
  }

  onPageClick = (item: number) => {
    if (this.state.searchQuery.page !== item) {
      const { searchQuery } = this.state;
      const updatedSearchQuery = {
        ...searchQuery,
        page: item
      };
      this.setState({ searchQuery: updatedSearchQuery }, () => this.fetchPickupRequestData());
    }
  }

  onNextPageClick = () => {
    if (this.state.pageBatch < Math.ceil(this.state.totalPages / 5)) {
      this.setState({ pageBatch: this.state.pageBatch + 1 });
    }
  }

  formatDate = (inputDateStr: string) => {
    const inputDate = moment(inputDateStr);
    return inputDate.format("DD MMM YYYY");
  };

  selectPickupRequest = (requestId: string) => {
    const { selectedPickupRequests } = this.state;
    let newSelectedRequests;
    if (!this.isPickupRequestSelected(requestId)) {
      newSelectedRequests = [...selectedPickupRequests, parseInt(requestId)];
    } else {
      newSelectedRequests = [...selectedPickupRequests.filter((id: number) => id !== parseInt(requestId))];
    }
    this.setState({ selectedPickupRequests: newSelectedRequests });
  }

  isPickupRequestSelected = (requestId: string) => {
    return this.state.selectedPickupRequests.includes(parseInt(requestId));
  };

  selectDeselectAllPickupRequests = () => {
    this.setState({ allPickupRequestsSelected: !this.state.allPickupRequestsSelected }, () => {
      if (!this.state.allPickupRequestsSelected) {
        this.deselectAllPickupRequests();
      } else {
        this.selectAllPickupRequests();
      }
    });
  };
  
  deselectAllPickupRequests = () => {
    this.setState({ selectedPickupRequests: [] });
  };

  selectAllPickupRequests = () => {
    const newlySelected: number[] = [];
    this.state.pickupRequestData.forEach((pickupRequest: any) => {
      const requestId = parseInt(pickupRequest.id)
      if (!this.state.selectedPickupRequests.includes(requestId)) {
        newlySelected.push(requestId);
      }
    });
    const { selectedPickupRequests } = this.state;
    const newSelectedRequests = [...selectedPickupRequests, ...newlySelected];
    this.setState({ selectedPickupRequests: newSelectedRequests });
  };

  openActionMenu = (index: number) => {
    this.actionsButtonRef = createRef();
    this.actionsModalRef = createRef();
    if (this.state.menuIndex !== index) this.setState({ menuIndex: index });
    else this.setState({ menuIndex: -1 });
  }

  openDeclineModal = (requestId: string, clientName: string) => {
    const { selectedPickup } = this.state;
    const updatedPickup = {
      ...selectedPickup,
      requestId,
      clientName
    };
    this.setState({
      showRescheduleModal: false,
      showDeclineModal: true,
      selectedPickup: updatedPickup
    });
  }

  closeDeclineModal = () => {
    this.setState({ showDeclineModal: false });
  }

  openRescheduleModal = (requestId: string, clientName: string, pickUpDate: string) => {
    const { selectedPickup } = this.state;
    const updatedPickup = {
      ...selectedPickup,
      requestId,
      clientName,
      pickUpDate
    };
    this.setState({
      rescheduleDate: "",
      showRescheduleModal: true,
      showDeclineModal: false,
      selectedPickup: updatedPickup
    });
  }

  closeRescheduleModal = () => {
    this.setState({ showRescheduleModal: false });
  }

  openDeclineAllModal = () => {
    if (this.state.selectedPickupRequests.length > 0) {
      this.setState({ showDeclineAllModal: true });
    }
  }

  closeDeclineAllModal = () => {
    this.setState({ showDeclineAllModal: false });
  }

  openReasonModal = (reason: string) => {
    this.setState({ showReasonModal: true, actionReason: reason });
  };

  closeReasonModal = () => {
    this.setState({ showReasonModal: false });
  };

  handlePickupDateChange = (event: any) => {
    this.setState({ rescheduleDate: event.target.value });
  }

  handleReasonChange = (event: any) => {
    this.setState({ reasonToRejectOrReschedule: event.target.value });
  }

  formatDateText = (dateString: string) => {
    const [year, month, date] = dateString.split("-");
    return `${date}/${month}/${year}`
  }

  isValidRescheduleDate = () => {
    return this.state.rescheduleDate !== this.state.selectedPickup.pickUpDate;
  }

  shouldDisableRescheduleSaveButton = () => {
    return !this.isValidRescheduleDate() || this.state.rescheduleDate.length <= 0;
  }

  componentWillUnmount(): any {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

}

// Customizable Area End
