import React from "react";
import "./clientListFilter.css"
import CustomFilterController, { Props } from './CustomFilterController.web'
import { withStyles } from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { calendarIcon, checkIcon, uncheckIcon } from "./assets";
import { Formik, Form } from "formik";
import moment from "moment";
import Strings from './Strings'
const configJSON = require("../src/config.js")

export const VALUE_FILTER_REPORT = {
  LAST_WEEK: 'last_7_days',
  LAST_MONTH: 'last_30_days',
  CURRENT_CONTRACT: 'current_contract_period',
  CUSTOM: 'custom_date',
}

const LIST_TIME_CHOICE = [
  { title: Strings.last7Days, value: VALUE_FILTER_REPORT.LAST_WEEK },
  { title: Strings.last30Days, value: VALUE_FILTER_REPORT.LAST_MONTH },
  { title: Strings.currentContractPeriod, value: VALUE_FILTER_REPORT.CURRENT_CONTRACT },
  { title: Strings.custom, value: VALUE_FILTER_REPORT.CUSTOM },
]

export default class CustomFilter extends CustomFilterController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Formik
          enableReinitialize={true}
          innerRef={this.formRef}
          initialValues={this.state.initialValues}
          onSubmit={(values) => { this.handleSubmit(values) }}
          data-test-id="formik"
          validationSchema={this.formSchema}
        >

          {(props) => {
            const {
              values,
              setFieldValue,
              resetForm
            } = props;
            return (
              <Form translate={undefined}
                style={FilterStyles.formContainer}
              >
                <div style={FilterStyles.filterModalWrapper}>
                  <div style={FilterStyles.clearAll}>
                    <p
                      data-test-id="reset-form"
                      onClick={this.clearFormValue(resetForm)}
                      style={FilterStyles.ClearAllTitle}
                    >
                      {Strings.clearAll}
                    </p>
                  </div>
                  <div style={FilterStyles.filterAccordianWrapper}>

                    <Accordion
                      onChange={this.handleAcordionChange('panel1')}
                      square expanded={this.state.expanded === 'panel1'}
                      data-test-id="clientaccordian"
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: '#8D97A2' }} />}
                        id="panel1d-header"
                        aria-controls="panel1d-content"
                      >
                        <p
                          className={`client_accordian_header ${this.state.expanded === 'panel1' && 'filter_accordian_active_header_color'}`}
                        >
                          {Strings.branch}
                        </p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          style={FilterStyles.AccordianDetailsWrapper}
                        >
                          {this.props.branchList.map((branchObj: any, comIndex: number) => {
                            return (
                              <div
                                className="checkbbox_wrapper"
                                key={comIndex}
                                onClick={this.setBranchId(branchObj.id, values, setFieldValue)}
                                data-test-id={`branch-checkbox-${comIndex}`}
                              >
                                <img
                                  className="checkbox_img"
                                  src={values.branches.includes(branchObj.id) ? checkIcon : uncheckIcon}
                                  alt="checkIcon"
                                />
                                <p style={FilterStyles.checkBoxTitle}>{branchObj.attributes.name}</p>
                              </div>
                            )
                          })}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      data-test-id="dateaccordian"
                      onChange={this.handleAcordionChange('panel2')}
                      square expanded={this.state.expanded === 'panel2'}
                    >
                      <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                        expandIcon={<ExpandMoreIcon style={{ color: '#8D97A2' }} />}
                      >
                        <p
                          className={`client_accordian_header ${this.state.expanded === 'panel2' && 'filter_accordian_active_header_color'}`}
                        >
                          {Strings.timePeriod}
                        </p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={FilterStyles.AccordianDetailsWrapper}>
                          {LIST_TIME_CHOICE.map((pick_obj: any, pickIndex: number) => {
                            return (
                              <div
                                data-test-id={`sortBy${pickIndex}`}
                                className="checkbbox_wrapper"
                                key={pickIndex}
                              >
                                <img
                                  data-test-id={`pickup-checkbox-${pickIndex}`}
                                  src={values.sort_by === pick_obj.value ? checkIcon : uncheckIcon}
                                  alt="checkIcon" className="checkbox_img"
                                  onClick={this.setPickValue(pick_obj.value, values, setFieldValue)}
                                />
                                <p style={FilterStyles.checkBoxTitle}>{pick_obj.title}</p>
                              </div>
                            )
                          })}
                          {values.sort_by === configJSON.customDate &&
                            <div
                              // className="custom_date_wrapper "
                              style={FilterStyles.customDateWrapper}
                            >
                              <div style={FilterStyles.customDateInputWarpper}
                              >
                                <p style={FilterStyles.customDateInputTitle}>{Strings.from}</p>

                                <div style={FilterStyles.customDateInputContainer} >
                                  <input style={FilterStyles.inputDateHide}
                                    data-test-id="custom-start-date"
                                    id="from_date_type"
                                    type="date"
                                    value={moment(values.custom_start_date, 'DD-MM-yyyy').format('yyyy-MM-DD')}
                                    onChange={this.setCustomStartDate(values, setFieldValue)}
                                  />
                                  <div style={FilterStyles.viewTime}>
                                    <p style={{...FilterStyles.customDateInputText, ...this.getPlaceholderStyle(values.custom_start_date)}}>
                                      {this.formatDateValue(values.custom_start_date)}
                                    </p>
                                    <img src={calendarIcon} style={FilterStyles.icon} />
                                  </div>
                                </div>
                              </div>

                              <div style={FilterStyles.customDateInputWarpper}>
                                <p style={FilterStyles.customDateInputTitle}>{Strings.to}</p>
                                <div style={FilterStyles.customDateInputContainer} >
                                  <input style={FilterStyles.inputDateHide}
                                    data-test-id="custom-end-date"
                                    id="from_date_type"
                                    type="date"
                                    value={moment(values.custom_end_date, 'DD-MM-yyyy').format('yyyy-MM-DD')}
                                    onChange={this.setCustomEndDate(setFieldValue)}
                                    min={moment(values.custom_start_date, 'DD-MM-yyyy').format('yyyy-MM-DD')}
                                  />
                                  <div style={FilterStyles.viewTime}>
                                    <p style={{...FilterStyles.customDateInputText, ...this.getPlaceholderStyle(values.custom_end_date)}}>
                                      {this.formatDateValue(values.custom_end_date)}
                                    </p>
                                    <img src={calendarIcon} style={FilterStyles.icon} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          }


                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* accordian 3 */}
                    <Accordion
                      data-test-id='cityaccordian'
                      square
                      expanded={this.state.expanded === 'panel3'}
                      onChange={this.handleAcordionChange('panel3')}
                    >
                      <AccordionSummary
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                        expandIcon={<ExpandMoreIcon style={{ color: '#8D97A2' }} />}
                      >
                        <p
                          className={`client_accordian_header ${this.state.expanded === 'panel3' && 'filter_accordian_active_header_color'}`}
                        >{Strings.recyclableCategory}</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div style={FilterStyles.AccordianDetailsWrapper}>
                          {this.props.categoryList.map((categoryObj: any, categoryIndex: number) => {
                            return (
                              <div
                                className="checkbbox_wrapper"
                                key={categoryIndex}
                                data-test-id={`category-checkbox-${categoryIndex}`}
                                onClick={this.setCategoryName(categoryObj.id, values, setFieldValue)}
                              >
                                <img
                                  src={values.categories.includes(categoryObj.id) ? checkIcon : uncheckIcon}
                                  alt="checkIcon" className="checkbox_img"
                                />
                                <p style={FilterStyles.checkBoxTitle}>{categoryObj.name}</p>
                              </div>
                            )
                          })}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div style={FilterStyles.filterBottomWrapper}
                  >
                    <button data-test-id="cancle-btn"
                      style={FilterStyles.cancelButtonStyles}
                      onClick={this.cancelFilterBtn}>{Strings.cancel}</button>
                    <button style={FilterStyles.applyButtonStyles} type="submit">{Strings.apply}</button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </React.Fragment >
    );
    // Customizable Area End
  }
}

// Customizable Area Start


const FilterStyles = {
  checkBoxTitle: {
    fontFamily: "Barlow Semi Condensed",
    fontSize: "14px",
    fontWeight: 400,
    color: "#0F172A",
    overflow: "hidden" as "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as "nowrap",
    width: "100%"
  },
  viewTime: {
    display: 'flex',
    flexDirection: 'row',
    gap: "8px"
  } as any,
  customDateInputText: {
    fontFamily: "Barlow Semi Condensed",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#1C3045",
  },
  inputDateHide: {
    top: "0",
    left: "0",
    position: "absolute",
    width: "100%",
    height: "100%",
    opacity: "0",
    zIndex: 10000000,
    borderRadius: "8px",
    cursor: "pointer",
  } as any,
  icon: {
    width: "20px",
    height: "20px",
  },
  customDateInputContainer: {
    width: "127px",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #BFCC33",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    position: "relative" as "relative",
  },
  customDateInputTitle: {
    fontFamily: "Barlow Semi Condensed",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left" as "left",
    color: "#94A3B8",
    marginBottom: "8px",
  },
  customDateInputWarpper: {
    display: "flex",
    flexDirection: "column" as "column"
  },
  customDateWrapper: {
    marginTop: "-16px",
    display: "flex",
    gap: "6px"
  },
  checkBoxWrapper: {
    width: "95%",
    height: "36px",
    display: "flex",
    marginBottom: "8px",
  },
  AccordianDetailsWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    width: "100%",
  },
  inActiveAccordianHeader: {
    fontFamily: "Barlow Semi Condensed",
    fontSize: "14px",
    fontWeight: 400,
    color: "#8D97A2",
  },
  ActiveAccordianHeader: {
    fontFamily: "Barlow Semi Condensed",
    fontSize: "14px",
    fontWeight: 400,
    color: "#0F172A !important",
  },
  applyButtonStyles: {
    width: "64px",
    height: "40px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#32569A",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFFFFF",
    marginRight: "6px",
    cursor: "pointer",
  },
  cancelButtonStyles: {
    width: "71px",
    height: "40px",
    borderRadius: "8px",
    border: "1px solid #32569A",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#32569A",
    cursor: "pointer",
    backgroundColor: "#FFFFFF",
  },
  filterBottomWrapper: {
    width: "100%",
    height: "71px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "32px"
  },
  filterAccordianWrapper: {
    width: "95%",
    maxHeight: "calc(100% - 115px)",
    overflowY: "auto" as "auto",
    overflowX: "hidden" as "hidden",
  },
  clearAll: {
    width: "100%",
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  ClearAllTitle: {
    width: "44px",
    height: "13px",
    marginRight: "23px",
    color: "#8D97A2",
    fontSize: "14px",
    fontFamily: "Barlow Semi Condensed",
    backgroundColor: "unset",
    cursor: "pointer",
  },
  formContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  filterModalWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    width: "100%",
    flexShrink: 0,
    borderRadius: "0.5rem",
    border: "1px solid var(--neutrals-cool-gray-200, #e2e8f0)",
    background: "var(--basic-white, #fff)",
    maxHeight: "80vh",
    alignItems: "center",
  }
}



const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails);


const AccordionSummary = withStyles({
  root: {
    padding: '0px 7px',
    marginBottom: -1,
    minHeight: 54,
    '&$expanded': {
      minHeight: 36,
    },
  },
  content: {
    '&$expanded': {
      margin: '14px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const Accordion = withStyles({
  root: {
    borderBottom: '1px solid rgba(141, 151, 162, 0.4)',
    boxShadow: 'none',

    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

// Customizable Area End
