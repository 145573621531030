import React from "react";
import ActiveClientTableController, { Props } from "./ActiveClientTableController.web";
import "./activeClientTable.css"
import { actionIcon, chcekIcon, checkallICon, closeIcon, noResultsIcon, paginationLeftIcon, paginationRightIcon, unChcekBoxIcon } from "../assets";
import ClientTableSkeletonLoader from "./ClientTableSkeletonLoader"
import moment from "moment";
import Strings from "../Strings";

export default class ActiveClientTable extends ActiveClientTableController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start


  headerComponent = () => {
    let { total_count, total_page, page, activePagiantionStep, per_page } = this.props.searchQueryState

    return (
      <div className="client_table_wrapper">
        <div className="client_table_conttainer" id="table_container" style={{borderRadius: "12px"}}>
          {/* header */}
          <div className="client_table_header_container">
            {/* name header */}
            <div className="client_table_header_field_container header_field_name_container">
              <img
                src={this.props.selectUserDelete.length ? checkallICon : unChcekBoxIcon}
                alt="Checkbox"
                data-test-id="select-all-chekbox"
                className="client_table_name_filed_checkbox_img"
                onClick={this.selectAll}
              />
              <p className="client_table_field_text">{Strings.nameLabel}</p>
            </div>
            {/* email header */}
            <div className="client_table_header_field_container header_field_email_container">
              <p className="client_table_field_text">{Strings.emailLabel}</p>
            </div>
            {/* last pickup */}
            <div className="client_table_header_field_container header_field_lastpickup_container">
              <p className="client_table_field_text">{Strings.lastPickup}</p>
            </div>
            {/* city */}
            <div className="client_table_header_field_container header_field_city_container">
              <p className="client_table_field_text">{Strings.cityLabel}</p>
            </div>
            {/* action */}
            <div className="client_table_header_field_container header_field_action_container">
              <p className="client_table_field_text">{Strings.action}</p>
            </div>
          </div>
          {this.props.loader ? <ClientTableSkeletonLoader /> : this.tableRenderComponent()} 
        </div>
        <div className="client_pagination_container">
          {/* pagination text  */}
          <div className="client_pagination_count_text">{Strings.showing} {(page - 1) * (per_page) + 1}-{page === total_page ? total_count : page * per_page} {Strings.from} {total_count}</div>
          {/* pagination button  */}
          <div className="client_pagination_button_container">
            <div data-test-id="previewPaginationButton" className="client_pagination_button" onClick={this.downPage}><img src={paginationLeftIcon} alt="left_icon" /></div>
            {Array.from(Array(total_page).keys()).slice(((activePagiantionStep - 1) * 5), ((activePagiantionStep - 1) * 5) + 5).map((pageNumber: any, pageIndex: number) => {
              return <div data-test-id={`page-button-${pageIndex + 1}`} className={`client_pagination_button ${page === ((activePagiantionStep - 1) * 5) + (pageIndex + 1) && 'client_pagination_active_button'} `} key={pageIndex} onClick={this.pageChange(((activePagiantionStep - 1) * 5) + pageIndex + 1)}>{((activePagiantionStep - 1) * 5) + pageIndex + 1}</div>
            })}
            {Math.ceil(total_page / 5) > activePagiantionStep && <div className="client_pagination_button more_button_page">...</div>}
            <div data-test-id="nextPaginationButton" className="client_pagination_button" onClick={this.upPage}><img src={paginationRightIcon} alt="left_icon" /></div>
          </div>
        </div>
      </div>
    )
  }

  tableRenderComponent = () => {
    return (
      <React.Fragment>
        {this.props.clientListigData.map((client: any, index: number) => {
          return (
            <div className={`client_table_tr_container ${this.props.selectUserDelete.includes(+client.id) && 'select_tr'}`} key={index}>
              {/* name header */}
              <div className={`client_table_header_field_container header_field_name_container`}>
                <img
                  src={this.props.selectUserDelete.includes(+client.id) ? chcekIcon : unChcekBoxIcon}
                  alt="Checkbox"
                  className="client_table_name_filed_checkbox_img"
                  data-test-id={`select-client-${client.id}`}
                  onClick={this.selectClient(+client.id)}
                />
                <p className="client_table_field_text client_table_field_text_name">{client.attributes.company_name}</p>
              </div>
              {/* email */}
              <div className="client_table_header_field_container header_field_email_container">
                <p className="client_table_field_text_td">{client.attributes.client_email}</p>
              </div>
              {/* last pick up */}
              <div className="client_table_header_field_container header_field_lastpickup_container">
                <p className="client_table_field_text_td">{client.attributes.last_pick_up_date ? moment(client.attributes.last_pick_up_date, "yyyy-MM-DD").format('DD MMM yyyy') : '-'}</p>
              </div>
              {/* City */}
              <div className="client_table_header_field_container header_field_city_container">
                <p className="client_table_field_text_td">{client.attributes.city}</p>
              </div>
              {/* action */}
              <div className="client_table_header_field_container header_field_action_container">
                <img
                  src={actionIcon}
                  alt="Action"
                  className="client_listing_action_img"
                  data-test-id={`action-${index}`}
                  onClick={(e) => this.onActionClick(e, client.id)}

                />
                {this.state.menuIndex === client.id && (
                  <div className="client_listing_action_menu_container" data-test-id={`actionConsole-${index}`}>
                    <p className="client_listing_action_menu_item" data-test-id={`viewMenu-${index}`} onClick={this.viewClient(client.id)}>{Strings.view}</p>
                    <p className="client_listing_action_menu_item" data-test-id={`editMenu-${index}`} onClick={this.editClient(client.id)}>{Strings.edit}</p>
                    <p className="client_listing_action_menu_item" data-test-id={`delete-client-${index}`} onClick={this.deleteClient(+client.id)}>{Strings.delete}</p>
                    <p className="client_listing_action_menu_item" data-test-id={`viewAsClient-${index}`} onClick={this.viewAsClient(client.id)}>{Strings.viewAsClient}</p>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )
  }

  getRenderComponent = () => {
    if (this.props.loader || this.props.clientListigData.length) {
      return this.headerComponent()
    }
    return (
      <div className="noResultContainer">
        <img src={noResultsIcon} alt="no_con" className="no_result_image" />
        <p className="no_result_text">{Strings.noResultsFound}</p>
        <p className="no_result_text_sub">{Strings.noResultSubHeader}</p>
        <button data-test-id="clear_filter" className="clear_filter_btn" onClick={this.clearFilter}>{Strings.clearFilters}</button>
      </div>
    )
  }

  // Customizable Area End

  render() {

    // Customizable Area Start
    return (
      <React.Fragment>
        {this.getRenderComponent()}
        {this.state.deleteModalFlag &&
          <div className="delete_modal_popup_wrapper" data-test-id="logoutPopupBox">
            <div className="delete_modal_popup_container">
              <div className="delete_modal_modal_close"><img src={closeIcon} alt="cross" data-test-id="closeModalImg" onClick={this.hideDeleteModalFlag} /></div>
              <p className="delete_modal_text_message">{Strings.areYouSureDeleteHeader}</p>
              <p className="delete_modal_sub_text_message">{Strings.areYouSureDeleteSubheader1} <br /> {Strings.areYouSureDeleteSubheader2}</p>

              <div className="delete_modal_button_container">
                <button data-test-id="closeModal" className="delete_modal_cancel_button" onClick={this.hideDeleteModalFlag}>{Strings.cancel}</button>
                <button data-test-id="handleDelete" className="delete_modal_logout_button" onClick={this.handleDeleteClient}>{Strings.delete}</button>
              </div>
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}   