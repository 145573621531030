import React from "react";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Navbar from "../../settings2/src/Navbar.web";
import "./Dashboard.css"
import DashboardClientController, {
  Props,
  webConfigJSON
} from "./DashboardClientController.web";
import { iconTrash, imageEmpty } from "./assets";
import { TimeChoice } from "./DashboardController.web";
import { webStyle } from "./Dashboard.web";
import ChartComponent, { ChartType } from "./ChartComponent.web";
import EnvironmentalCard from "./EnvironmentalCard";
import TableComponent from "./TableComponent.web";
import Strings from './Strings'

export default class DashboardClient extends DashboardClientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  checkCurrentStyle(choice: TimeChoice) {
    if (this.state.chosenTime === choice) return webStyle.chosenTimeText
    else return webStyle.unchosenTimeText
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    if (this.state.totalNumber !== 0) {
      return (
        //Merge Engine DefaultContainer
        <div id="element" className="main-wrapper">
          <NavigationMenu navigation={this.props.navigation} id={""} />
          <div style={webStyle.container} className="dashboard_container_wrapper">
            <div className="dashboard_navbar_container">
              <Navbar navigation={this.props.navigation} id={""} />
            </div>
            <div className="dashboard_body_container">
              {/* View statistic */}
              <div style={webStyle.itemStatisticClient}>
                <img src={iconTrash} style={webStyle.icon} />
                <div style={webStyle.headerTextStatistic}>{Strings.totalRecyclableNetWeight}</div>
                <div style={webStyle.textNumber}>{this.state.totalNumber?.toLocaleString()}</div>
              </div>
  
              {/* View Chart */}
              <div style={webStyle.viewSegment}>
                <div style={webStyle.viewHeader}>
                  <div style={webStyle.headerText}>{Strings.recyclingCollectionHeader}</div>
                  <div style={webStyle.viewTime}>
                    <div
                      data-test-id={"contractTime"}
                      onClick={() => this.setState({ chosenTime: TimeChoice.CONTRACT_YEAR })}
                      style={this.checkCurrentStyle(TimeChoice.CONTRACT_YEAR)}>{Strings.contractText}</div>
                    <div style={{ width: "14px" }} />
                    <div
                      data-test-id={"yearTime"}
                      onClick={() => this.handleChangeTimeChart(TimeChoice.YEAR)}
                      style={this.checkCurrentStyle(TimeChoice.YEAR)}
                    >{Strings.yearlyText}</div>
                    <div style={{ width: "14px" }} />
                    <div
                      data-test-id={"monthTime"}
                      onClick={() => this.handleChangeTimeChart(TimeChoice.MONTH)}
                      style={this.checkCurrentStyle(TimeChoice.MONTH)}
                    >{Strings.monthlyText}</div>
                  </div>
                </div>
                <ChartComponent
                  chartType={this.state.chosenTime !== TimeChoice.YEAR ? ChartType.LINE : ChartType.BAR}
                  aspectRatio={3.5}
                  dataArray={this.state.listData}
                  label={this.state.listLabel}
                  suggestedMax={this.state.maxChart}
                />
              </div>

              <EnvironmentalCard data={this.state.enviromentalCardsData} />
              <TableComponent
                tableHeaders={Strings.tableHeaders}
                data={this.state.tableData}
                showSortModal={this.state.showSortModal}
                handleShowSortModal={() => this.handleShowSort()}
                showViewMore={true}
                onClickViewMore={() => this.onClickViewMore()}
                hideSortModal={() => { }}
                total={0}
              />
            </div>
          </div>
        </div>
        //Merge Engine End DefaultContainer
      );
    }
    return (
      <div id="element" className="main-wrapper">
        <NavigationMenu navigation={this.props.navigation} id={""} />
        <div style={webStyle.container} className="dashboard_container_wrapper">
          <div className="dashboard_navbar_container">
            <Navbar navigation={this.props.navigation} id={""} />
          </div>
          <div className="dashboard_body_container">
            {/* List 3 View Statistic */}
            <div style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <img src={imageEmpty} style={webStyle.imageEmpty} />
              <div style={webStyle.emptyText}>{webConfigJSON.emptyText}</div>
            </div>
          </div>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
