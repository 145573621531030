import React from "react";
import { cross, successTick } from "./assets";

interface Props {
  onModalCrossPress: () => void;
  successText:string;
}

export default class SuccessModal extends React.PureComponent<Props> {
  render() {
    return (
      <div className="wastebill-success-outer-container">
        <div className="wastebill-success-modal">
          <div
            className="addwastebill-cross-modal-img-container"
            data-testid={"successModal"}
          >
            <img
              data-testid={"successModalCloseButton"}
              src={cross}
              className="addwastebill-cross-modal-img"
              onClick={this.props.onModalCrossPress}
            />
          </div>
          <div className="addwastebill-success-modal-inner-container">
            <img src={successTick} className="addwastebill-success-tick-img" />
            <p className="addwastebill-success-text">
              {this.props.successText}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
