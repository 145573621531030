// Customizable Area Start
import React from "react";

// Customizable Area Start
import PickupClientListingController, {
  Props,
} from "./PickupClientListingController.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Navbar from "../../settings2/src/Navbar.web";
import "./pickupClientListingStyles.css";
import {
  actionIcon,
  addIcon,
  arrowRightICon,
  calendar,
  caretLeft,
  caretRight,
  circleFill,
  cross,
  filterIcon,
  noResult,
  noResultBackground,
  sortIcon,
  tickIcon,
  vectorDown,
  vectorUp,
} from "./assets";
import InputField from "../../adminconsole2/src/InputField.web";
import { crossIcon } from "../../adminconsole2/src/assets";
import moment from "moment";
import Strings from "./Strings";
// Customizable Area End

export default class PickupClientListing extends PickupClientListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getErrorBoxStyles = () => {
    if (this.state.error.status !== null) {
      if (!this.state.error.status) {
        return "prompt-container-success";
      } else {
        return "prompt-container-failure";
      }
    }
  };

  getStatusStyle: any = (status: string) => {
    let baseStyle = {
      boxSizing: "border-box",
      padding: "4px 8px",
      borderRadius: 8,
    };
    switch (status) {
      case Strings.pending:
        return { ...baseStyle, backgroundColor: "#E1CCFF", color: "#6200EA" };
      case Strings.reScheduled:
        return { ...baseStyle, backgroundColor: "#FEF3C7", color: "#D97706" };
      case Strings.accepted:
        return { ...baseStyle, backgroundColor: "#DCFBCF", color: "#288700" };
      case Strings.rejected:
        return { ...baseStyle, backgroundColor: "#FEDCE0", color: "#DC2626" };
    }
  };

  getTableListing = () => {
    return this.state.listData.map((item: any, index: number) => {
      const {
        branch,
        pick_up_date,
        status,
        rejection_reason = "",
        reschedule_reason = "",
      } = item.attributes;

      let reason: string =
        status === Strings.rejected ? rejection_reason : reschedule_reason;
      reason = reason ? reason : "";

      let trimReason: string =
        reason.length > 20 ? `${reason.substring(0, 15)}...` : reason;
      return (
        <tr key={index} className={`admin-listing-table-item-container`}>
          <td className="pickup-listing-table-field-branch-container">
            <p className="admin-listing-email-item-text">
              {branch.attributes.name}
            </p>
          </td>
          <td className="pickup-listing-table-field-address-container">
            <p className="admin-listing-email-item-text">
              {branch.attributes.address}
            </p>
          </td>
          <td className="pickup-listing-table-field-city-container">
            <p className="admin-listing-email-item-text">
              {branch.attributes.city}
            </p>
          </td>
          <td className="pickup-listing-table-field-pickup-date-container">
            <p className="admin-listing-email-item-text">
              {moment(pick_up_date).isValid()
                ? this.formateListDate(pick_up_date)
                : ""}
            </p>
          </td>
          <td className="pickup-listing-table-header pickup-status-header">
            <p className="admin-listing-email-item-text">
              {reason.length > 20 ? trimReason : reason}
              {reason.length > 20 && (
                <p
                  data-testid={"seeMoreButton"}
                  style={webStyle.seeMoreText}
                  onClick={() => this.onSeeMoreClick(reason)}
                >
                  {Strings.seeMore}
                </p>
              )}
            </p>
          </td>
          <td className="pickup-listing-table-header pickup-status-header">
            <p
              style={this.getStatusStyle(status)}
              className="admin-listing-email-item-text"
            >
              {status}
            </p>
          </td>
          <td className="pickup-listing-table-field-action-container">
            <img
              src={actionIcon}
              alt="Action"
              data-testid={`actionButton${index}`}
              className="admin-listing-action-img"
              onClick={() => this.onActionClick(index)}
              ref={this.actionButtonRef}
            />
            {this.state.menuIndex === index && (
              <div
                className="admin-listing-action-menu-container pickup-request-action-menu-container"
                data-testid={`actionConsole`}
                ref={this.actionModalRef}
              >
                <p
                  className="admin-listing-action-menu-item"
                  data-testid={"editPickup"}
                  onClick={() => this.onEditPickupRequest(item.id, status)}
                >
                  {Strings.edit}
                </p>
                <p
                  data-testid={"deleteActionButton"}
                  className="admin-listing-action-menu-item"
                  onClick={() => this.onDeleteModalOpen(item.id, status)}
                >
                  {Strings.delete}
                </p>
              </div>
            )}
          </td>
        </tr>
      );
    });
  };

  getPagination = () => {
    let numbers = [];
    let currentPage = this.state.page;
    for (
      let i = this.state.pageBatch * 5 - 4;
      i <=
      (this.state.totalPages > this.state.pageBatch * 5
        ? this.state.pageBatch * 5
        : this.state.totalPages);
      i++
    )
      numbers.push(i);
    return (
      <div className="admin-listing-page-container">
        <div
          className="admin-listing-page-inner-container"
          title="Previous Page"
          key={"Previous Page Container"}
          data-testid={"previousPage"}
          onClick={this.onPreviousPageClick}
        >
          <img
            src={caretLeft}
            key={"Previous Page Button"}
            className="admin-listing-page-icon"
            alt="Previous Page"
          />
        </div>
        {numbers.map((item, index) => {
          return (
            <div
              title={`Page ${item}`}
              data-testid={`pageButton${index}`}
              key={`page button${index}`}
              onClick={() => this.onPageClick(item)}
              className={`admin-listing-page-inner-container ${
                currentPage === item
                  ? "admin-listing-page-inner-container-selected"
                  : ""
              }`}
            >
              <p
                className={`admin-listing-page-number-text ${
                  currentPage === item
                    ? "admin-listing-page-number-text-selected"
                    : ""
                }`}
              >
                {item}
              </p>
            </div>
          );
        })}
        {this.state.pageBatch < Math.ceil(this.state.totalPages / 5) && (
          <div
            className="admin-listing-page-inner-container"
            key={"next page batch indicator"}
          >
            <p className="admin-listing-page-number-text ">{"..."}</p>
          </div>
        )}
        <div
          key={"next page button"}
          className="admin-listing-page-inner-container"
          title="Next Page"
          data-testid={"nextPage"}
          onClick={this.onNextPageClick}
        >
          <img
            src={caretRight}
            className="admin-listing-page-icon"
            alt="Next Page"
            key={"Next page Img"}
          />
        </div>
      </div>
    );
  };

  showDeleteModal = () => {
    return (
      <div
        key={"PickupRequest Delete Modal"}
        data-testid={"pickupDeleteModal"}
        className="admin-listing-delete-modal-container"
      >
        <div className="admin-listing-delete-modal-inner-container">
          <img
            src={crossIcon}
            key={"PickupRequest Modal Close Img"}
            className="admin-listing-close-modal-img"
            onClick={this.onModalCloseClick}
          />
          <p
            className="admin-listing-delete-modal-header-text"
            style={{ marginBottom: "1.5rem" }}
          >
            {Strings.areYouSureYouWantToDelete}
          </p>
          <hr className="admin-listing-modal-separator admin-listing-delete-modal-separator" />
          <div
            className="admin-listing-filter-menu-button-container admin-listing-delete-buttons-container"
            style={{ marginRight: "2.5rem" }}
          >
            <button
              className="admin-listing-delete-button admin-listing-modal-buttons admin-listing-delete-button-text admin-listing-delete-cancel-button "
              onClick={this.onModalCloseClick}
              data-testid={"modalcancelButton"}
            >
              {Strings.cancel}
            </button>
            <button
              data-testid={"modalDeleteButton"}
              className="admin-listing-delete-button admin-listing-delete-button-text"
              onClick={this.onModalDeleteClick}
            >
              {Strings.delete}
            </button>
          </div>
        </div>
      </div>
    );
  };

  showReasonPopup = () => {
    return (
      <div
        data-testid={"reasonPopup"}
        key={"PickupRequest Reason Modal"}
        className="admin-listing-delete-modal-container"
      >
        <div
          className="admin-listing-delete-modal-inner-container"
          style={{
            padding: "1rem",
            alignItems: "flex-start",
            boxSizing: "border-box",
          }}
        >
          <div
            className="addwastebill-cross-modal-img-container"
            data-testid={"crossModalIcon"}
            key={"crossIcon"}
          >
            <img
              data-testid={"reasonModalCrossIcon"}
              src={cross}
              style={webStyle.crossImg}
              onClick={this.onSeeMoreCloseClick}
            />
          </div>
          <div
            style={{
              boxSizing: "border-box",
              padding: "0rem 1.2rem 1rem 1.2rem",
            }}
          >
            <p style={{ ...webStyle.reasonHeader, marginBottom: "1rem" }}>
              {Strings.reason}
            </p>
            <p
              style={{
                ...webStyle.reasonHeader,
                ...webStyle.reasonBody,
              }}
            >
              {this.state.reason}
            </p>
          </div>
        </div>
      </div>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div
        id="element"
        className={`main-wrapper contactus--inner-cont`}
        ref={this.scrollRef}
      >
        {this.state.showDeleteModal && this.showDeleteModal()}
        {this.state.showReasonPopup && this.showReasonPopup()}
        <NavigationMenu navigation={this.props.navigation} id={""} />
        <div className="dashboard_container_wrapper">
          <div className="dashboard_navbar_container">
            <Navbar navigation={this.props.navigation} id={""} />
          </div>
          <div style={{ width: "97%" }} className="contactus-outer-container">
            {this.state.error.status !== null && (
              <span
                style={{ marginRight: 0, width: "42.4%" }}
                data-testid={"prompMessage pickup-listing-prompt"}
                className={`prompt-container header-prompt-container mobile-prompt-container ${this.getErrorBoxStyles()}`}
              >
                {this.state.error.message}
              </span>
            )}
            <div className="admin-listing-inner-container">
              <div className="contactus-header-container">
                <div className="admin-listing-header-container">
                  <p className="contactus-header-title">
                    {Strings.pickupRequest}
                  </p>
                  {this.state.error.status !== null && (
                    <span
                      data-testid={"prompMessage pickup-listing-prompt"}
                      className={`prompt-container header-prompt-container pickup-listing-web-container ${this.getErrorBoxStyles()}`}
                    >
                      {this.state.error.message}
                    </span>
                  )}
                  <button
                    data-testid={"AddnewPickupRequest"}
                    className="add-button admin-listing-register-button"
                    onClick={this.onNewPickupClick}
                    disabled={this.shouldDisableNewPickupRequestButton()}
                  >
                    <img
                      src={addIcon}
                      className="icon"
                      style={webStyle.cursorPointer}
                    />
                    <p className="add-button-text admin-listing-register-button-text">
                      {Strings.newPickupRequest}
                    </p>
                  </button>
                </div>
                {this.state.listData.length === 0 && this.state.search.length===0&&
                this.state.selectedFilterBranch.length === 0 &&
                this.state.selectedFilterCity.length === 0 &&
                this.state.selectedFilterTime.id === "" ? (
                  <div
                    style={webStyle.noResultContainer}
                    data-testid={"noResultFound"}
                  >
                    <img
                      src={noResult}
                      style={{
                        ...webStyle.noResultImg,
                      }}
                    />
                    <p
                      style={{
                        ...webStyle.filterHeaderTitle,
                        color: "#000",
                        fontSize: "1.25rem",
                        textAlign: "center",
                      }}
                    >
                      {Strings.nothingToShowYet}
                    </p>
                  </div>
                ) : (
                  <div>
                    <div className="contactus-screen-title-container admin-listing-sub-header-container">
                      <div
                        style={{ width: "100%" }}
                        className="subheader-right-container"
                      >
                        <InputField
                          value={this.state.search}
                          name="Search"
                          customStyles={"admin-staff-listing-input-container"}
                          customInputStyles={"admin-listing-search-input"}
                          startEndornmentIconStyles={`admin-listing-search-start-endornment ${window.innerWidth <=
                            414 && "cursor-pointer"}`}
                          data-testid={"searchBar"}
                          onInputPress={this.searchIconPress}
                          onChangeText={this.onChangeSearch}
                          startAdornment={true}
                          placeholder={Strings.search}
                          customContainerStyle={"input-container-admin-listing"}
                        />
                        <div className="admin-listing-filter-container admin-listing-margin-right admin-listing-margin-left">
                          <button
                            className="admin-listing-button admin-listing-filter-button"
                            onClick={this.onFilterClick}
                            data-testid={"filterButton"}
                            ref={this.filterButtonRef}
                          >
                            <img
                              src={filterIcon}
                              className="icon admin-listing-icon"
                              style={webStyle.cursorPointer}
                            />
                            <p className="admin-listing-button-font admin-listing-filter-button-text">
                              {Strings.filters}
                            </p>
                          </button>
                          {this.state.showFilterModal && (
                            <div
                              key={"filter Modal"}
                              className="admin-listing-filter-menu"
                              data-testid="filterModal"
                              ref={this.filterModalRef}
                            >
                              <p
                                className="admin-listing-filter-menu-clear-button"
                                data-testid={"clearAllFilters"}
                                onClick={this.onFilterClearClick}
                              >
                                {Strings.clearAll}
                              </p>
                              <hr className="admin-listing-modal-separator" />
                              <div
                                className="admin-listing-filter-menu-header"
                                onClick={() =>
                                  this.onFilterRoleClick("Branches")
                                }
                              >
                                <p
                                  className="admin-listing-filter-menu-header-text"
                                  style={{
                                    ...webStyle.filterHeaderTitle,
                                    color:
                                      this.state.showFilterType === "Branches"
                                        ? "#0F172A"
                                        : "#8D97A2",
                                  }}
                                >
                                  {Strings.branch}
                                </p>
                                <img
                                  src={
                                    this.state.showFilterType === "Branches"
                                      ? vectorUp
                                      : vectorDown
                                  }
                                  data-testid={"branchFilterExpand"}
                                  onClick={() =>
                                    this.onFilterRoleClick("Branches")
                                  }
                                />
                              </div>
                              {this.state.showFilterType === "Branches" &&
                                this.state.filters.branches.map(
                                  (item, index) => (
                                    <div
                                      key={`branchFilter${index}`}
                                      style={{
                                        width: "100%",
                                        height: "2.25rem",
                                      }}
                                      className="admin-listing-filter-menu-item"
                                      data-testid={"branchFilters"}
                                    >
                                      {this.state.filters.selectedBranch.findIndex(
                                        (element: any) =>
                                          JSON.stringify(item) ===
                                          JSON.stringify(element)
                                      ) !== -1 ? (
                                        <img
                                          src={tickIcon}
                                          className="admin-listing-filter-menu-item-tick-img"
                                          data-testid={`filter${index}`}
                                          style={webStyle.cursorPointer}
                                          onClick={() =>
                                            this.onFilterRoleItemClick(item)
                                          }
                                        />
                                      ) : (
                                        <div
                                          className="admin-listing-filter-menu-item-img"
                                          data-testid={`filter${index}`}
                                          onClick={() =>
                                            this.onFilterRoleItemClick(item)
                                          }
                                        />
                                      )}
                                      <p
                                        className="admin-listing-filter-menu-item-text"
                                        style={{ width: "100%" }}
                                      >
                                        {item?.attributes?.name}
                                      </p>
                                    </div>
                                  )
                                )}
                              <hr
                                className="admin-listing-modal-separator"
                                style={{ marginTop: "0.5rem" }}
                              />
                              <div
                                onClick={() =>
                                  this.onFilterRoleClick("Time Period")
                                }
                                className="admin-listing-filter-menu-header"
                              >
                                <p
                                  style={{
                                    ...webStyle.filterHeaderTitle,
                                    color:
                                      this.state.showFilterType ===
                                      "Time Period"
                                        ? "#0F172A"
                                        : "#8D97A2",
                                  }}
                                  className="admin-listing-filter-menu-header-text"
                                >
                                  {Strings.timePeriod}
                                </p>
                                <img
                                  src={
                                    this.state.showFilterType === "Time Period"
                                      ? vectorUp
                                      : vectorDown
                                  }
                                  data-testid={"timeFilterExpand"}
                                  onClick={() =>
                                    this.onFilterRoleClick("Time Period")
                                  }
                                />
                              </div>
                              {this.state.showFilterType === "Time Period" &&
                                this.state.filters.time.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        width: "100%",
                                        height: "2.25rem",
                                      }}
                                      onClick={() =>
                                        this.onFilterTimeItemClick(item)
                                      }
                                      className="admin-listing-filter-menu-item"
                                    >
                                      {this.state.filters.selectedTime.id ===
                                      item.id ? (
                                        <img
                                          src={tickIcon}
                                          className="admin-listing-filter-menu-item-tick-img"
                                          data-testid={`timefilter${index}`}
                                          onClick={() =>
                                            this.onFilterTimeItemClick(item)
                                          }
                                        />
                                      ) : (
                                        <div
                                          className="admin-listing-filter-menu-item-img"
                                          data-testid={`timefilter${index}`}
                                          onClick={() =>
                                            this.onFilterTimeItemClick(item)
                                          }
                                        />
                                      )}
                                      <p
                                        style={{ width: "100%" }}
                                        className="admin-listing-filter-menu-item-text"
                                      >
                                        {item?.attributes?.name}
                                      </p>
                                    </div>
                                  );
                                })}
                              {this.state.showFilterType === "Time Period" && (
                                <div
                                  style={{
                                    ...webStyle.flexColumn,
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      height: "2.25rem",
                                    }}
                                    className="admin-listing-filter-menu-item"
                                  >
                                    {this.state.filters.selectedTime.id ===
                                    "custom" ? (
                                      <img
                                        src={tickIcon}
                                        className="admin-listing-filter-menu-item-tick-img"
                                        data-testid={`customFilterTimer`}
                                        onClick={() =>
                                          this.onFilterTimeItemClick({
                                            id: "custom",
                                            attributes: {
                                              name: Strings.custom,
                                              to: "",
                                              from: "",
                                            },
                                          })
                                        }
                                      />
                                    ) : (
                                      <div
                                        className="admin-listing-filter-menu-item-img"
                                        data-testid={`customFilterTimer`}
                                        onClick={() =>
                                          this.onFilterTimeItemClick({
                                            id: "custom",
                                            attributes: {
                                              name: "Custom",
                                              to: "",
                                              from: "",
                                            },
                                          })
                                        }
                                      />
                                    )}
                                    <p
                                      style={{ width: "100%" }}
                                      className="admin-listing-filter-menu-item-text"
                                    >
                                      {Strings.custom}
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      ...webStyle.flex,
                                      padding: "0rem 1rem 0rem 1rem",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      style={{
                                        ...webStyle.flexColumn,
                                        width: "45%",
                                      }}
                                    >
                                      <p style={webStyle.filterHeaderTitle}>
                                        {Strings.From}
                                      </p>
                                      <span
                                        style={{ marginTop: 0, width: "100%" }}
                                        className={`addNewWasteBill-date-input`}
                                      >
                                        <input
                                          type="date"
                                          name="from"
                                          data-testid="FromDateCustom"
                                          className="date-opacity text-field-input"
                                          value={moment( this.state.filters.selectedTime
                                            .attributes.from,'DD-MM-yyyy').format('yyyy-MM-DD')
                                          }
                                          disabled={
                                            this.state.filters.selectedTime
                                              .id !== "custom"
                                          }
                                          onChange={this.onFilterDateChange}
                                        />
                                        <p
                                          className={`addwastebill-date-text ${Boolean(
                                            this.state.filters.selectedTime
                                              .attributes.from
                                          ) && "addwastebill-text-color"}`}
                                        >
                                          {this.state.filters.selectedTime
                                            .attributes.from
                                            ? this.state.filters.selectedTime
                                                .attributes.from
                                            : "DD/MM/YYYY"}
                                        </p>
                                        <span className="adornment">
                                          <img src={calendar} />
                                        </span>
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        ...webStyle.flexColumn,
                                        width: "45%",
                                      }}
                                    >
                                      <p style={webStyle.filterHeaderTitle}>
                                        {Strings.to}
                                      </p>
                                      <span
                                        style={{ marginTop: 0, width: "100%" }}
                                        className={`addNewWasteBill-date-input`}
                                      >
                                        <input
                                          type="date"
                                          name="to"
                                          data-testid="toDateCustom"
                                          className="date-opacity text-field-input"
                                          value={moment(this.state.filters.selectedTime
                                            .attributes.to,'DD-MM-yyyy').format('yyyy-MM-DD')
                                            
                                          }
                                          disabled={
                                            this.state.filters.selectedTime
                                              .id !== "custom"
                                          }
                                          onChange={this.onFilterDateChange}
                                        />
                                        <p
                                          className={`addwastebill-date-text ${Boolean(
                                            this.state.filters.selectedTime
                                              .attributes.to
                                          ) && "addwastebill-text-color"}`}
                                        >
                                          {this.state.filters.selectedTime
                                            .attributes.to
                                            ? this.state.filters.selectedTime
                                                .attributes.to
                                            : "DD/MM/YYYY"}
                                        </p>
                                        <span className="adornment">
                                          <img src={calendar} />
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <hr
                                className="admin-listing-modal-separator"
                                style={{ marginTop: "0.5rem" }}
                              />
                              <div
                                className="admin-listing-filter-menu-header"
                                onClick={() => this.onFilterRoleClick("City")}
                              >
                                <p
                                  style={{
                                    ...webStyle.filterHeaderTitle,
                                    color:
                                      this.state.showFilterType === "City"
                                        ? "#0F172A"
                                        : "#8D97A2",
                                  }}
                                  className="admin-listing-filter-menu-header-text"
                                >
                                  {Strings.city}
                                </p>
                                <img
                                  src={
                                    this.state.showFilterType === "City"
                                      ? vectorUp
                                      : vectorDown
                                  }
                                  data-testid={"cityFilterExpand"}
                                  onClick={() => this.onFilterRoleClick("City")}
                                />
                              </div>
                              {this.state.showFilterType === "City" &&
                                this.state.filters.city.map((item, index) => (
                                  <div
                                    key={`cityFilter${index}`}
                                    style={{ height: "2.25rem" }}
                                    data-testid={"cityFilter"}
                                    className="admin-listing-filter-menu-item"
                                  >
                                    {this.state.filters.selectedCity.findIndex(
                                      (element: any) =>
                                        JSON.stringify(item) ===
                                        JSON.stringify(element)
                                    ) !== -1 ? (
                                      <img
                                        src={tickIcon}
                                        className="admin-listing-filter-menu-item-tick-img"
                                        style={{ cursor: "pointer" }}
                                        data-testid={`cityFilter${index}`}
                                        onClick={() =>
                                          this.onFilterCityItemClick(item)
                                        }
                                      />
                                    ) : (
                                      <div
                                        className="admin-listing-filter-menu-item-img"
                                        data-testid={`cityFilter${index}`}
                                        onClick={() =>
                                          this.onFilterCityItemClick(item)
                                        }
                                      />
                                    )}
                                    <p className="admin-listing-filter-menu-item-text">
                                      {item?.city}
                                    </p>
                                  </div>
                                ))}

                              <div
                                className="admin-listing-filter-menu-button-container"
                                style={{ marginTop: "0.5rem" }}
                              >
                                <button
                                  className="admin-listing-filter-menu-button"
                                  data-testid="cancelFilters"
                                  onClick={this.onCancelFilterClick}
                                >
                                  {Strings.cancel}
                                </button>
                                <button
                                  className="admin-listing-filter-menu-button admin-listing-filter-menu-button-blue"
                                  onClick={this.onApplyFilterClick}
                                  data-testid="applyFilters"
                                >
                                  {Strings.apply}
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="admin-listing-sort-container">
                          <button
                            key={"sort Button"}
                            className="admin-listing-button"
                            onClick={this.onSortClick}
                            data-testid={"sortButton"}
                            ref={this.sortButtonRef}
                          >
                            <img
                              src={sortIcon}
                              className="icon"
                              style={webStyle.cursorPointer}
                            />
                            <p className="admin-listing-sort-button-text admin-listing-button-font admin-listing-filter-button-text">
                              {Strings.sort}
                            </p>
                          </button>
                          {this.state.showSortModal && (
                            <div
                              key={"sort_Modal"}
                              className="admin-listing-sort-menu"
                              data-testid={"sortModal"}
                              ref={this.sortModalRef}
                            >
                              <div
                                className="admin-listing-filter-menu-header"
                                key={"sort_title"}
                              >
                                <p className="admin-listing-filter-menu-header-text">
                                  {Strings.sort}
                                </p>
                              </div>
                              {this.state.sortList.map(
                                (item: any, index: number) => (
                                  <div
                                    key={`sortList${index}`}
                                    className="admin-listing-sort-menu-item"
                                  >
                                    {JSON.stringify(this.state.selectedSort) ===
                                    JSON.stringify(item) ? (
                                      <div
                                        key={`sortOption${index + 1}`}
                                        className="admin-listing-sort-menu-item-img-div"
                                        data-testid={`sortOption${index + 1}`}
                                        onClick={() =>
                                          this.onSortItemClick(item)
                                        }
                                      >
                                        <img
                                          src={circleFill}
                                          key={"tick_icon"}
                                          className="admin-listing-sort-menu-item-tick-img"
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="admin-listing-sort-menu-item-img-div"
                                        data-testid={`sortOption${index + 1}`}
                                        key={"tick_icon"}
                                        onClick={() =>
                                          this.onSortItemClick(item)
                                        }
                                      />
                                    )}
                                    <div
                                      className="admin-listing-sort-menu-item-text-container"
                                      key={`sort_option${index}`}
                                    >
                                      <p className="admin-listing-sort-menu-item-text">
                                        {item.before}
                                      </p>
                                      {item.after !== "" && (
                                        <img
                                          src={arrowRightICon}
                                          className="admin-listing-sort-menu-item-arrow"
                                        />
                                      )}
                                      {item.after !== "" && (
                                        <p className="admin-listing-sort-menu-item-text">
                                          {item.after}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {this.state.showSearchBar && (
                      <InputField
                        value={this.state.search}
                        name="Search"
                        data-testId={"alternateSearchBar"}
                        key={"Alternate-Search"}
                        onChangeText={this.onChangeSearch}
                        startAdornment={true}
                        placeholder={Strings.searchClient}
                        customContainerStyle={
                          "input-container-admin-listing pickup-listing-alternate-search-bar"
                        }
                      />
                    )}
                    <div className="admin-listing-table-container pickup-listing-table-container">
                      <div className="admin-listing-table-inner-container">
                        <table
                          style={{ width: "100%" }}
                          className="admin-listing-table"
                        >
                          <thead style={{ width: "100%" }}>
                            <tr className="admin-listing-table-header-container">
                              <th className="pickup-listing-table-field-branch-container">
                                <p className="admin-listing-name-text">
                                  {Strings.branch}
                                </p>
                              </th>
                              <th className="pickup-listing-table-field-address-container">
                                <p className="admin-listing-name-text">
                                  {Strings.address}
                                </p>
                              </th>
                              <th className="pickup-listing-table-field-city-container">
                                <p className="admin-listing-name-text">
                                  {Strings.city}
                                </p>
                              </th>
                              <th className="pickup-listing-table-field-pickup-date-container">
                                <p
                                  className="admin-listing-name-text"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {Strings.pickupDate}
                                </p>
                              </th>
                              <th className="pickup-listing-table-header pickup-status-header">
                                <p className="admin-listing-name-text">
                                  {Strings.reason}
                                </p>
                              </th>
                              <th className="pickup-listing-table-header pickup-status-header">
                                <p className="admin-listing-name-text">
                                  {Strings.status}
                                </p>
                              </th>
                              <th className="pickup-listing-table-field-action-container">
                                <p className="admin-listing-name-text">
                                  {Strings.action}
                                </p>
                              </th>
                            </tr>
                          </thead>
                          <tbody>{this.getTableListing()}</tbody>
                        </table>
                      </div>
                      <div className="admin-listing-table-footer-container">
                        <p className="admin-listing-table-footer-text">{`${
                          Strings.showing
                        } ${(this.state.page - 1) * 10 + 1} - ${
                          this.state.currentPageCount
                        } ${Strings.From} ${this.state.totalItems}`}</p>
                        {this.getPagination()}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

const webStyle = {
  filterHeaderTitle: {
    color: "#8D97A2",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "0.875rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "1.5rem",
  },
  flex: { display: "flex" },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  seeMoreText: {
    color: "#D97706",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "1.25rem",
    letterSpacing: "0.00375rem",
    cursor: "pointer",
  },
  reasonHeader: {
    color: "#0F172A",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "1.5rem",
  },
  reasonBody: {
    fontWeight: 400,
  },
  crossImg: {
    height: "1.5rem",
    width: "1.5rem",
  },
  noResultContainer: {
    alignSelf: "center",
    marginTop: "7.5rem",
  },
  noResultImg: {
    height: "24.625rem",
    width: "20.00788rem",
    backgroundImage: noResultBackground,
  },
  cursorPointer: {
    cursor: "pointer",
  },
} as const;
// Customizable Area End
