import React from 'react'
import ClientAccountDetailControllerweb from './ClientAccountDetailControllerweb'
import NavigationMenu from '../../navigationmenu/src/NavigationMenu'
import Navbar from '../../settings2/src/Navbar.web'
import { Tab, Tabs } from '@material-ui/core'
import "./clientAccountDetailStyle.css"
import { Skeleton, TabContext, TabPanel } from '@material-ui/lab'
import { DropIcon, attachmentIcon, calendar, documentDownloadIcon, tickDisable, untickDisable } from './assets'
import Strings from './Strings'
const { baseURL } = require("../../../framework/src/config");


export default class ClientAccountDetail extends ClientAccountDetailControllerweb {
    render() {

        let detailPart = [
            { title: Strings.companyNameLabel, key_value: 'company_name' },
            { title: Strings.emailAddressLabel, key_value: 'client_email' },
            { title: Strings.mobileNumberLabel, key_value: 'full_phone_number' },
            { title: Strings.addressLabel, key_value: 'address' },
            { title: Strings.cityLabel, key_value: 'city' },
        ]

        let contactPerson = [
            { title: Strings.nameLabel, key_value: 'name' },
            { title: Strings.emailAddressLabel, key_value: 'email' },
            { title: Strings.mobileNumberLabel, key_value: 'mobile_number' },
        ]

        let branchName = [
            { title: Strings.nameLabel, key_value: 'name' },
            { title: Strings.addressLabel, key_value: 'address' },
            { title: Strings.cityLabel, key_value: 'city' },
        ]

        let contractDetail = [
            { title: Strings.startDate, key_value: 'start_date' },
            { title: Strings.endDate, key_value: 'end_date' },
        ]

        return (
            <div id="element" className={`main-wrapper`}>
                <NavigationMenu navigation={this.props.navigation} id={""} />

                <div className="container_wrapper scroll_account">
                    <div className="navbar_container">
                        <Navbar navigation={this.props.navigation} id={""} />
                    </div>

                    <div className="body_container">
                        <div className="account_header_wrapper">
                            <div className="header_title_wrapper">
                                <h1 className="header_title_text">{Strings.account}</h1>
                            </div>
                        </div>
                        <div className='account_tabs_container'>
                            <TabContext value={this.state.tabIndexString}>
                                <Tabs value={this.state.tabIndex} data-test-id="accounttabs" onChange={this.handleTabChange} classes={{ root: 'account_tabs_root', indicator: 'account_tabs_indicator', scroller: 'account_tabs_scroller' }}>
                                    <Tab data-test-id="tabs-detail" label={Strings.details} classes={{ root: 'account_tab_root', selected: 'account_tab_selected' }} />
                                    <Tab data-test-id="tabs-contract" label={Strings.contract} classes={{ root: 'account_tab_root', selected: 'account_tab_selected' }} />
                                    <Tab data-test-id="tabs-report" label={Strings.report} classes={{ root: 'account_tab_root', selected: 'account_tab_selected' }} />
                                </Tabs>

                                {/* tabpanel 1 -------------- */}
                                <TabPanel value={'0'} classes={{ root: 'account_tabpanel_root' }}>
                                    {this.state.AccountApiResponse ?
                                        <div className='tabpanel_detail_wrapper' data-test-id="accountDetail">
                                            {/* detail part */}
                                            <div className="tabpanel_content_header" >
                                                <div className="tabpanel_header_text">{Strings.details}</div>
                                            </div>
                                            {detailPart.map((detailObj, dIndex) => {
                                                return (
                                                    <div className='detail_box' key={dIndex}>
                                                        <p className='tabpanel_sub_titlle'>{detailObj.title}</p>
                                                        <div className='input_container'>{this.state.ClientAccountDetail[detailObj.key_value]}</div>
                                                    </div>
                                                )
                                            })}
                                            {/* contact */}
                                            <div className="tabpanel_content_header" >
                                                <div className="tabpanel_header_text">{Strings.contactPerson}</div>
                                            </div>
                                            {this.state.ClientAccountDetail.contact_persons?.map((contact: any, cIndex: number) => {
                                                return (
                                                    <React.Fragment key={cIndex}>
                                                        <div className="tabpanel_content_header" >
                                                            <div className="tabpanel_header_text">{Strings.person} {cIndex + 1}</div>
                                                        </div>
                                                        {contactPerson.map((contactObj, coIndex) => {
                                                            return (
                                                                <div className='detail_box' key={coIndex}>
                                                                    <p className='tabpanel_sub_titlle'>{contactObj.title}</p>
                                                                    <div className='input_container'>{contact[contactObj.key_value]}</div>
                                                                </div>
                                                            )
                                                        })}
                                                    </React.Fragment>
                                                )
                                            })}

                                            {/* branch */}
                                            <div className="tabpanel_content_header" >
                                                <div className="tabpanel_header_text">{Strings.branchName}</div>
                                            </div>
                                            {this.state.ClientAccountDetail.branch_names?.map((branch: any, bIndex: number) => {
                                                return (
                                                    <React.Fragment key={bIndex}>
                                                        <div className="tabpanel_content_header" >
                                                            <div className="tabpanel_header_text">{Strings.branch} {bIndex + 1}</div>
                                                        </div>
                                                        {branchName.map((branchObj, brIndex) => {
                                                            return (
                                                                <div className='detail_box' key={brIndex}>
                                                                    <p className='tabpanel_sub_titlle'>{branchObj.title}</p>
                                                                    <div className='input_container'>{branch[branchObj.key_value]}</div>
                                                                </div>
                                                            )
                                                        })}
                                                    </React.Fragment>
                                                )
                                            })}
                                        <div style={{marginTop:'32px'}}></div>
                                        </div>
                                        : <SkeletonComponent />
                                    }
                                </TabPanel>




                                {/* tabpanel 2 ------------- */}
                                <TabPanel value={'1'} classes={{ root: 'account_tabpanel_root' }}>
                                    {this.state.AccountApiResponse ?
                                        <div className='tabpanel_contract_wrapper'>
                                            {/* curent contract detail */}

                                            <div className="tabpanel_content_header" >
                                                <div className="tabpanel_header_text">{Strings.currentContractDetails}</div>
                                            </div>

                                            {this.state.ClientAccountDetail.contracts?.map((contract: any, cIndex: number) => {
                                                return (
                                                    <React.Fragment key={cIndex}>
                                                        {contractDetail.map((contractObj, coIndex) => {
                                                            return (
                                                                <div className='detail_box width-50' key={coIndex}>
                                                                    <p className='tabpanel_sub_titlle'>{contractObj.title}</p>
                                                                    <div className='input_container'><img src={calendar} className='leftImage' /> {contract[contractObj.key_value]}</div>
                                                                </div>
                                                            )
                                                        })}
                                                    </React.Fragment>
                                                )
                                            })}

                                            {/* Payment Terms */}

                                            <div className="tabpanel_content_header" >
                                                <div className="tabpanel_header_text">{Strings.paymentTerms} </div>
                                            </div>
                                            <div className='detail_box width-50'>
                                                <p className='tabpanel_sub_titlle'>{Strings.frequency}</p>
                                                <div className='input_container'><img src={DropIcon} alt="dropIcon" className='rightImage'></img> {this.state.ClientAccountDetail.payment_term_frequency}</div>
                                            </div>
                                            <div className='detail_box width-50'>
                                                <p className='tabpanel_sub_titlle'>{Strings.firstPickupDate}</p>
                                                <div className='input_container'><img src={calendar} className='leftImage' /> {this.state.ClientAccountDetail.first_pickup_date}</div>
                                            </div>

                                            {/* due date pauyment */}
                                            <div className="tabpanel_content_header" >
                                                <div className="tabpanel_header_text">{Strings.dueDatePayment} </div>
                                            </div>
                                            {/*  */}
                                            {this.state.ClientAccountDetail.due_dates?.map((dueDates: any, dIndex: number) => {
                                                return (
                                                    <React.Fragment key={dIndex}>
                                                        <div className='detail_box width-50'>
                                                            <p className='tabpanel_sub_titlle'>{Strings.dueDate}</p>
                                                            <div className='input_container'><img src={calendar} className='leftImage' /> {dueDates.due_date}</div>
                                                        </div>

                                                        <div className="tabpanel_content_header" >
                                                            <div className="tabpanel_header_text"><img src={dueDates.recurring_payment ? tickDisable : untickDisable} alt="disableTick"></img>{Strings.recurringPayment}  </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })}

                                            {/* Frequency of Pickup */}
                                            <div className="tabpanel_content_header" >
                                                <div className="tabpanel_header_text">{Strings.frequencyofPickup} </div>
                                            </div>

                                            <div className='detail_box width-50'>
                                                <p className='tabpanel_sub_titlle'>{Strings.startDate}</p>
                                                <div className='input_container'><img src={calendar} className='leftImage' /> {this.state.ClientAccountDetail.frequency_of_pickup_start_date}</div>
                                            </div>
                                            <div className='detail_box width-50'>
                                                <p className='tabpanel_sub_titlle'>{Strings.frequency}</p>
                                                <div className='input_container'><img src={DropIcon} alt="dropIcon" className='rightImage'></img> {this.state.ClientAccountDetail.pick_up_frequency}</div>
                                            </div>

                                            {/* Number of Recyle Bins */}
                                            <div className="tabpanel_content_header" >
                                                <div className="tabpanel_header_text">{Strings.numberofRecyleBins} </div>
                                            </div>
                                            <div className='detail_box'>
                                                <div className='recyle_container'>
                                                    <p className='tabpanel_sub_titlle'>{Strings.indoorRecycleBins}</p>
                                                    <div className='input_container report_input_cotainer'>{this.state.ClientAccountDetail.indoor_recycle_bin}</div>
                                                </div>
                                            </div>
                                            <div className='detail_box'>
                                                <div className='recyle_container'>
                                                    <p className='tabpanel_sub_titlle'>{Strings.outdoorRecycleBins}</p>
                                                    <div className='input_container report_input_cotainer'>{this.state.ClientAccountDetail.outdoor_recycle_bin}</div>
                                                </div>
                                            </div>

                                        </div>
                                        : <SkeletonComponent />
                                    }
                                </TabPanel>
                                <TabPanel value={'2'} classes={{ root: 'account_tabpanel_root' }} >
                                    {this.state.AccountApiResponse ?
                                        <div className='tabpanel_detail_wrapper' data-test-id="accountDetail">
                                            {/* report */}
                                            <div className='detail_box' >
                                                <p className='tabpanel_sub_titlle'>{Strings.reportFrequency}</p>
                                                <div className='input_container report_input_cotainer frequency-text'><img src={DropIcon} alt="dropIcon" className='rightImage'></img>   {this.state.ClientAccountDetail.report_frequency}</div>
                                            </div>
                                            {/* waste type category */}
                                            <div className='detail_box' >
                                                <p className='tabpanel_sub_titlle'>{Strings.wasteTypeCatagory}</p>
                                                <div className='input_container report_input_cotainer'>
                                                    <img src={DropIcon} alt="dropIcon" className='rightImage'></img>
                                                    {this.state.ClientAccountDetail.waste_type_categories?.map((Category: any, cIndex: number) => {
                                                        return <div className='chip_box_container' key={cIndex} >{Category}</div>
                                                    })}
                                                </div>
                                            </div>
                                            {/* Awarness Given */}
                                            <div className='detail_box' >
                                                <p className='tabpanel_sub_titlle'>{Strings.awarenessGiven}</p>
                                                <div className='input_container report_input_cotainer'>
                                                    <img src={DropIcon} alt="dropIcon" className='rightImage'></img>
                                                    {this.state.ClientAccountDetail.awareness_given?.map((Category: any, cIndex: number) => {
                                                        return <div className='chip_box_container' key={cIndex} >{Category}</div>
                                                    })}
                                                </div>
                                            </div>
                                            {/* attechment */}

                                            <div className="tabpanel_content_header" style={{marginTop:'32px'}} >
                                                <div className="tabpanel_header_text">{Strings.attachment} </div>
                                            </div>

                                            {this.state.ClientAccountDetail.account_attachments?.map((attachment: any, aIndex: number) => {
                                                return (
                                                    <div className='attechment_container' key={aIndex}>
                                                        <div className='icon_box'><img src={attachmentIcon} alt="attachmenticon" /></div>
                                                        <img data-test-id='image_download' src={documentDownloadIcon} alt="dropIcon" className='rightImage' style={{ cursor: 'pointer' }} onClick={()=>this.downloadImage(`${baseURL}${attachment.attachment_url}`, attachment.attachment_url.split('/').pop())}></img>

                                                        <div className='detail_box'>
                                                            <p className='file_title'>{attachment.attachment_url.split('/').pop()}</p>
                                                            <p className='view_text' data-test-id='view_file' onClick={() => window.open(`${baseURL}${attachment.attachment_url}`)}>{Strings.view}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                        : <SkeletonComponent />
                                    }
                                </TabPanel>
                            </TabContext>

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}


export let SkeletonComponent = () => {
    return (
        <div className='tabpanel_detail_wrapper' data-testid="skeleton-component">
            <div className="tabpanel_content_header" data-testid="skeleton-animation">
                <Skeleton animation="wave" />
            </div>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number, dIndex) => {
                return (
                    <div className='detail_box' key={dIndex} data-testid="detail-box">
                        <Skeleton />
                        <Skeleton animation="wave" />
                    </div>
                )
            })}
        </div>
    )
}


