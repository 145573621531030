import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  mobileNum: string;
  role: any;
  createPass: any;
  confirmPass: any;
  seePassword: boolean;
  showPassword: boolean;
  isFocused: boolean;
  lnameFocused: boolean;
  fnameFocused: boolean;
  mobileFocused: boolean;
  roleFocused: boolean;
  createFocused: boolean;
  confirmFocused: boolean;
  borderColor: any;
  isRequired: boolean;
  isOpen: boolean;
  selectedValue: any;
  open:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RegisterNewAdminController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  registerNewAdminId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      firstName:"",
      lastName:"",
      email:"",
      mobileNum:'',
      role:'',
      createPass:'',
      confirmPass:"",
      seePassword: false,
      showPassword: false,
      isFocused: false,
      lnameFocused: false,
      fnameFocused: false,
      mobileFocused: false,
      roleFocused: false,
      createFocused: false,
      confirmFocused: false,
      borderColor:'',
      isRequired: false,
      isOpen:false,
      selectedValue:'',
      open: false,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    if(apiRequestCallId && responseJson){
      this.handleAllResponse(apiRequestCallId,responseJson)
    }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleCloseDilog = () => {
    this.setState({open:false});
    this.props.navigation.navigate('AdminStaffListing')
  };

  handleAllResponse=(apiRequestCallId: any,responseJson: any)=>{
      if(apiRequestCallId===this.registerNewAdminId && !responseJson.errors) { 
        this.setState({
          open: true
        })
      }
  }

  isValidEmail(email: any) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
}
updateBorderColor(email: any) {
  const newBorderColor = this.isValidEmail(email) ? '#BFCC33' : '#DC2626';
  this.setState({ borderColor: newBorderColor });
}
  handleShowPassword=()=>{
    this.setState({
      seePassword:!this.state.seePassword
    })
  }
  showConfirmPassword=()=>{
    this.setState({
      showPassword:!this.state.showPassword
    })
  }
  handleCancel=()=>{
    this.setState({
      firstName:"",
      lastName:"",
      mobileNum:"",
      email:"",
      createPass:"",
      confirmPass:""
    })
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  selectOption = (value :any) => {
    this.setState({
      role: value,
      isOpen: false,
      roleFocused: true, 
    });
  };

  handleSendInvite =  () => {
    if(this.state.firstName =='' ||
    this.state.lastName == '' ||
    this.state.email=='' || 
    this.state.mobileNum=='' || 
    this.state.role=='' || 
    this.state.createPass=='' ||
    this.state.confirmPass == ''){
this.setState({isRequired: true})
    }
     else {
      let token =  localStorage.getItem("token");

    const header = {
      "Content-Type":"application/json",
      token:token,
    };
    const data = {
      first_name:this.state.firstName,
      last_name:this.state.lastName,
      full_phone_number:this.state.mobileNum,
      role_id:this.state.role === 'Admin' ? 2 : 1,
      email: this.state.email,
      password: this.state.createPass,
      password_confirmation: this.state.confirmPass
    };
    
      const httpBody = {
        data: data,
      };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.registerNewAdminId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "admin/admin_staffs"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({isRequired: false})

    return true;


  }
    }
  // Customizable Area End
}
