// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import RegisterNewAdmin from "../../blocks/adminconsole2/src/RegisterNewAdmin.web";
import Notifications from "../../blocks/notifications/src/Notifications";
import FormApprovalWorkflow from "../../blocks/formapprovalworkflow/src/FormApprovalWorkflow";
import EditAdminStaff from '../../blocks/adminconsole2/src/EditAdminStaff.web.tsx'
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Cfmatchalgorithm39 from "../../blocks/cfmatchalgorithm39/src/Cfmatchalgorithm39";
import Sorting from "../../blocks/sorting/src/Sorting";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
// import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLogin.web";
// import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
// import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Analytics from "../../blocks/analytics/src/Analytics";
// import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Categories from "../../blocks/categoriessubcategories/src/Categories.web"
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web"
import DashboardClient from "../../blocks/dashboard/src/DashboardClient.web"
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import TermsConditionsEdit from "../../blocks/termsconditions/src/TermsConditionsEdit.web"
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web"
import ClientListing from "../../blocks/user-profile-basic/src/client-listing/ClientListing.Web"
import ResetPassword from '../../blocks/forgot-password/src/ResetPassword.web';
import ResetSuccessful from '../../blocks/forgot-password/src/ResetSuccessful.web';
import AccountCreation from "../../blocks/user-profile-basic/src/AccountCreationHeader.web";
import PickupRequest from "../../blocks/formapprovalworkflow/src/PickupRequest.web"
import PickupRequestClient from "../../blocks/formapprovalworkflow/src/PickupRequestClient.web"
import Calendar from "../../blocks/scheduling/src/Caleder.web"
import ReportClient from "../../blocks/dashboard/src/ReportClient.web"
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web.tsx';
import Contactus from '../../blocks/contactus/src/Contactus.web';
import AddNewWasteBill from '../../blocks/customform/src/AddNewWasteBill.web';
import AddPickupRequestClient from '../../blocks/customform/src/AddPickupRequestClient.web'
import PickupClientListing from '../../blocks/customform/src/PickupClientListing.web';
import WasteBill from "../../blocks/customform/src/WasteBill.web"
import ContactusClient from '../../blocks/contactus/src/ContactusUser.web';
import ContractDetails from "../../blocks/user-profile-basic/src/ContractDetails.web";
import ClientAccountDetail from "../../blocks/user-profile-basic/src/ClientAccountDetail.web"
import AdminStaffListing from '../../blocks/adminconsole2/src/AdminStaffListing.web';



const routeMap = {
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
EducationalUserProfileClient:{
  component:EducationalUserProfile,
 path:"/EducationalUserProfileClient"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
RegisterNewAdmin:{
  component:RegisterNewAdmin,
  path:"/RegisterNewAdmin"
},
Notifications:{
 component:Notifications,
path:"/Notifications"},
NotificationsClient:{
  component:Notifications,
 path:"/NotificationsClient"},
FormApprovalWorkflow:{
 component:FormApprovalWorkflow,
path:"/FormApprovalWorkflow"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
PickupClientListing:{
component:PickupClientListing,
path:"/PickupClientListing",
},
EditAdminStaff:{
  component:EditAdminStaff,
  path:'/EditAdminStaff'
},
PickupRequest:{
  component:PickupRequest,
 path:"/PickupRequest"},
 PickupRequestClient:{
  component:PickupRequestClient,
 path:"/PickupRequestClient"},
 Categories:{
  component:Categories,
 path:"/Categories"},
 ReportClient:{
  component:ReportClient,
 path:"/ReportClient"},
 AddPickupRequestClient:{
  component:AddPickupRequestClient,
  path:"/AddPickupRequestClient"
 },
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Cfmatchalgorithm39:{
 component:Cfmatchalgorithm39,
path:"/Cfmatchalgorithm39"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/",
exact: true
},
Dashboard:{
  component:Dashboard,
 path:"/Dashboard"
 },
 DashboardClient:{
  component:DashboardClient,
 path:"/DashboardClient"
 },
ForgotPassword: {
  component: ForgotPassword,
  path: '/ForgotPassword'
},
ResetPassword: {
  component: ResetPassword,
  path: '/ResetPassword'
},
ResetSuccessful: {
  component: ResetSuccessful,
  path: '/ResetSuccessful'
},
WasteBill: {
  component: WasteBill,
  path: '/WasteBill'
},
AddNewWasteBill:{
  component:AddNewWasteBill,
  path:'/AddNewWasteBill'
},
Contactus: {
  component: Contactus,
  path: '/Contactus'
},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
AccountCreation:{
  component:AccountCreation,
  path:'/AccountCreation'
},
TermsConditions:{
 component:TermsConditions,
 exact:true,
path:"/TermsConditions"
},
ClientListing:{
 component:ClientListing,
 exact:true,
path:"/ClientListing"
},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
 
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
TermsConditionsEdit : { 
  component : TermsConditionsEdit,
  path : "/TermsConditionsEdit"
},
ContactusClient:{
  component:ContactusClient,
  path:'/ContactusClient'
},
Calendar:{
  component:Calendar,
  path:'/Calendar'
},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Categories:{
 component:Categories,
path:"/Categories"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
ClientAccountDetail:{
 component:ClientAccountDetail,
path:"/ClientAccountDetail"},
AdminStaffListing:{
  component:AdminStaffListing,
  path:'/AdminStaffListing'
},
  // Home: {
  //   component: UserProfileBasicBlock,
  //   path: '/',
  //   exact: true
  // },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  Contract: { 
    component : ContractDetails,
    path : '/Contract'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "/AlertWeb",
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const language  = localStorage.getItem("language");

    return (
      <View style={{ height: '100vh', width: 'auto' }}>
        <div dir={language === 'arabic' ? 'rtl' : 'ltr'}>
          {/* <TopNav /> */}
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </div>
      </View>
    );
  }
}

export default App;
