import { TextField, Typography, withStyles } from "@material-ui/core";
import React from "react";
import {
  constantPasswordValidation,
  ticked,
  unticked,
  imgPasswordInVisible,
  imgPasswordVisible,
} from "./assets";

import "./ForgotPassword.css";

interface Props {
  name?: string;
  value: string;
  placeholder?: string;
  onChange?: any;
  className?: any;
  error?: boolean;
  type?: string;
  errorMessage?: string;
  showPasswordValidationModal?: boolean;
  errorMessageStyle?: any;
}

interface State {
  showPassword: boolean;
  focusInput: boolean;
}

const configJSON = require("./config");
class InputField extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    showPassword: false,
    focusInput: false,
  };

  regex = [
    configJSON.paswordContains8Character,
    configJSON.passwordContainsSpecialCharacter,
    configJSON.passwordContainsDigit,
    configJSON.passwordContainsUpperLowerCharacter,
  ];

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  endAdornment = () => {
    if (this.props.type == "password")
      return (
        // <InputAdornment position="end">
        <button
          data-testid={"iconButton"}
          aria-label="toggle password visibility"
          onClick={this.handleClickShowPassword}
          onMouseDown={this.handleMouseDownPassword}
          className="endornment-icon"
        >
          {!this.state.showPassword ? (
            <img src={imgPasswordInVisible} className="img-input-icon" />
          ) : (
            <img src={imgPasswordVisible} className="img-input-icon" />
          )}
        </button>
        // </InputAdornment>
      );
  };

  customPasswordValidationModal = () => {
    let arr = new Array(4).fill({ ticked: ticked, unticked: unticked });
    arr = arr.map((item, index) => {
      return {
        ...item,
        text: constantPasswordValidation[index],
        regex: this.regex[index],
      };
    });

    return (
      <div className="custom-validation-modal-container" data-testid={"validationModal"}>
        {arr.map((item: any, index: number) => {
          return (
            <div key={index} className="modal-item-container">
              <img
                src={
                  item.regex.test(this.props.value)
                    ? item.ticked
                    : item.unticked
                }
                className="item-icon"
              />
              <p className="item-text">{item.text}</p>
            </div>
          );
        })}
      </div>
    );
  };

  onInputFocus = () => {
    this.setState({ focusInput: true });
  };

  onInputBlur = () => {
    this.setState({ focusInput: false });
  };

  getType = () => {
    if (this.props.type === "password") {
      if (this.state.showPassword) return "text";
    }
    return this.props.type;
  };

  render(): React.ReactNode {
    const { focusInput } = this.state;
    const {
      name,
      value,
      placeholder,
      onChange,
      error = false,
      errorMessage = "",
      showPasswordValidationModal = false,
      errorMessageStyle = {},
    } = this.props;

    return (
      <div>
        <h6 className={"inputName"}>{name}</h6>
        {/* <CssTextField
          color="primary"
          value={value}
          data-testid={"textInput"}
          variant="outlined"
          placeholder={placeholder}
          onChange={onChange}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          error={value.length !== 0 && error}
          type={this.getType()}
          InputProps={{
            endAdornment: this.endAdornment(),
          }}
          className={`inputField`}
          style={this.getStyle()}
        /> */}
        <div
          className={`input-field-container ${value.length !== 0 &&
            !error &&
            "input-success"} ${value.length !== 0 && error && "inputError"}`}
        >
          <input
            name={name}
            data-testid={"textInput"}
            value={value}
            className={`input-field`}
            placeholder={placeholder}
            onChange={onChange}
            type={this.getType()}
            onFocus={this.onInputFocus}
            onBlur={this.onInputBlur}
          />
          {this.endAdornment()}
        </div>
        {showPasswordValidationModal &&
          focusInput &&
          this.customPasswordValidationModal()}
        <Typography
          color="error"
          align="left"
          variant="subtitle2"
          style={{ ...styles.errorMessage, ...errorMessageStyle }}
          className="input-error-text"
        >
          {error && errorMessage !== "" && value !== "" ? errorMessage : ""}
        </Typography>
      </div>
    );
  }
}

const styles = {
  errorMessage: {
    height: 19,
    width: "100%",
    marginTop:4,
  },
  marginCorrection: {
    marginBottom: 10,
  },
};

const styleInput = {
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#E7EDEF",
      },
      // "&.Mui-focused fieldset": {
      //   borderColor: '#E7EDEF',
      // },
    },
  },
};

const CssTextField = withStyles(styleInput)(TextField);

export default InputField;
