export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const arrow=require("../assets/arrow.svg");
export const dot=require("../assets/red-dot.svg");
export const crossIcon=require("../assets/cross-icon.svg");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const arrowImg=require("../assets/Vector.png");
export const globalImg=require("../assets/global.svg");
export const notification=require("../assets/notification.svg");
export const signout=require("../assets/logout@2x.png");
export const frame=require("../assets/frame@3x.png");
export const clock = require("../assets/clock.svg");
export const right = require("../assets/right.svg");
export const emptyNotification = require("../assets/No-Message@3x.png")
export const Naqaa = require("../assets/Naqaa.png")
export const MenuIcon = require("../assets/menu_icon.svg")
export const downArrow = require('../assets/downArrow.svg');
