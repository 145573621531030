import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {dot, minusIcon, notEmpty, tick, ticks } from "./assets";

import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Navbar from "../../settings2/src/Navbar.web";
import Strings from "./Strings";

// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getCheckBoxInput(elem:any, index : number)
  {
    if(elem.attributes.is_read)
    {
      return  <label className="container-check"> <input type="checkbox" checked={elem.isSelected} data-testid={`checkbox-${index}`} style={{visibility : 'hidden', pointerEvents : 'none'}}/> </label>

    }
    else{
      return    <label className="container-check">
                <input type="checkbox" checked={elem.isSelected} data-testid={`checkbox-${index}`} onChange={(event) => this.checkBoxHandle(event, elem)} />
                {elem.isSelected ? <span className='checkbox-custom' style={webStyle.checkbox}><img src={tick}></img> </span> : <span className="checkmark" style={webStyle.checkmark}></span>}

              </label>
    }
  }



  getNotificationList() {
    return (
      <div className="not-list-box">
        {this.state.notificationList.map((elem: any, index: number) => (
          <div className={`not-ele ${elem.isSelected ? 'not-bg' : ''}`}>
            <div className="checkbox-container">
           {this.getCheckBoxInput(elem,index)}
            </div>
            <div className="not-content"  onClick={() => this.itemClick(elem)} data-testid = {`notif-${index}`}>
              <div className="not-content-sub-lg-heading">
                {elem.attributes.contents}
              </div>
            </div>
            <div className="time"> {this.timeSince(elem.attributes.created_at)} {Strings.ago}
            { elem.attributes.is_read == false ? <img src={dot} style={{height : '1rem', width : '1rem'}}></img> : null }</div>
          </div>
        ))
        }

      </div>
    )
  }

  getNoNotificationImage()
  {
    return <div className="not-image-container">
      <div style={webStyle.imageBox}>
      <img src={notEmpty} style={{height : 'inherit', width : 'inherit'}}></img>
      </div>
      <p className="not-image-text">
     {Strings.noNotification}
      </p>
    </div>
  }

  getSection()
  {
    if(this.state.notificationList.length > 0)
    {
      return this.getNotificationList();
    }
    else{
      return this.state.loaded ? this.getNoNotificationImage() : null
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className="main-wrapper">

        <NavigationMenu navigation={this.props.navigation} id={"1"} />
        <div className="notif-container" style={webStyle.container}>
          <Navbar navigation={this.props.navigation} id={""} />

          <div className="notif-heading-box">
            <div className="not-left">
                <label className="container-check">
                  <input type="checkbox" checked={this.state.allSelected} data-testid="top-checkbox" onChange={(event) => this.globalCheckbox(event)} />
                  {this.state.allSelected ? <span className='checkbox-custom' style={webStyle.checkbox}><img src={minusIcon}></img> </span> : <span className="checkmark" style={webStyle.checkmark}></span>}

                </label>
              <div className="not-heading" style={{ marginLeft: "0.75rem" }}>
                {Strings.notificationHeading}
              </div>
            </div>
            <div className="not-right" data-testid="mark-read"onClick={() => this.markRead()}>
              <img src={ticks}></img>
              <span style={{ marginLeft: "0.25rem" }} >{Strings.markRead}</span>
            </div>
          </div>

          {this.getSection()}
        </div>
      </div>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  itemWrapper: {
    border: "1px solid #767676",
    display: "flex",
    width: "70%",
    flexDirection: "row" as "row",
    marginTop: 10,
    padding: 16,
    paddingTop: 10,
    cursor: "pointer",
  },
  itemHeadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row" as "row",
  },
  iconStyle: {
    width: 20,
    height: 26,
    marginTop: 6,
  },
  itemHeading: {
    color: "#000",
    flex: 1,
    fontWeight: 700,
  },
  itemHeadingRead: {
    color: "#6200EE",
    flex: 1,
    fontWeight: 700,
  },
  contents: {
    fontSize: 16,
    color: "#767676",
  },
  okButton: {
    backgroundColor: "#ccc",
    color: "#000",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },

  container: {
    backgroundColor: '#F5F5F5'
  },
  checkmark : { 
    height : '1.25rem',
    width : '1.31rem',
    border : '#1C3045B2 2px solid'
  },

  checkbox : { 
    height : '1.25rem',
    width : '1.31rem',
    border : '#BFCC33 2px solid'
  },

  imageBox : { 
    marginLeft : '-6rem',
    height : '22.39rem',
    width : '27.12rem'
  }
};
// Customizable Area End
