import LocalizedStrings from 'react-localization'
const configJSON = require("./config");
  
 const Strings = new LocalizedStrings({
      english: configJSON.englishLanguage,
      arabic: configJSON.arabicLanguage,
    });
  
      let curr =  localStorage.getItem("language");
      if (curr) {
        Strings.setLanguage(curr);
      } else {
        Strings.setLanguage("english");
      }

  
export default Strings