Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";
exports.reminderText = "Reminders"
exports.contractEndingText = "Contract ending"
exports.reportDueText = "Report due"
exports.paymentDueText = "Payment due"
exports.todayText = "Today"
exports.tomorrowText = "Tomorrow"
exports.upcomingText = "Upcoming Dates"
exports.addText = "Add New Entry"
exports.titleLabel = "Add Title"
exports.titleColorPlaceholder = "Enter Title for Color"
exports.dateLabel = "Choose Date"
exports.descriptionLabel = "Description"
exports.descriptionPlaceholder = "Enter your description"
exports.monthText = "Month"
exports.weekText = "Week"
exports.dayText = "Day"
exports.saveText = "Save"
exports.deleteText = "Delete"
exports.cancelText = "Cancel"
exports.thisMonthText = "This Month"
exports.thisWeekText = "This Week"
exports.entrySavedText = "Your entry is successfully saved!"
exports.confirmDeleteText = "Are you sure you want to delete entry?"
exports.eventChanged = "Your changes have been saved!"
exports.notifyDeleteText = 'Deleted entries can’t be undone.'
exports.calendarEventUrl = "/calendar/calendar_events"
exports.upcomingEventUrl = "/calendar/calendar_events/upcoming"

exports.english = {
  calendar: "Calendar",
  reminderText: "Reminders",
  contractEndingText: "Contract ending",
  reportDueText: "Report due",
  paymentDueText: "Payment due",
  todayText: "Today",
  tomorrowText: "Tomorrow",
  upcomingText: "Upcoming Dates",
  addText: "Add New Entry",
  titleLabel: "Add Title",
  titleColorPlaceholder: "Enter Title for Color",
  dateLabel: "Choose Date",
  descriptionLabel: "Description",
  descriptionPlaceholder: "Enter your description",
  monthText: "Month",
  weekText: "Week",
  dayText: "Day",
  saveText: "Save",
  deleteText: "Delete",
  cancelText: "Cancel",
  thisMonthText: "This Month",
  thisWeekText: "This Week",
  entrySavedText: "Your entry is successfully saved!",
  confirmDeleteText: "Are you sure you want to delete entry?",
  eventChanged: "Your changes have been saved!",
  notifyDeleteText: 'Deleted entries can’t be undone.'
}

exports.arabic = {
  reminderText: "تذكيرات",
  contractEndingText: "تعدّيل العقد",
  paymentDueText: "تاريخ السداد",
  calendar: "التقويم",
  tomorrowText: "غدًا",
  reportDueText: "تاريخ التقرير",
  upcomingText: "تواريخ قادمة",
  titleLabel: "العنوان",
  confirmDeleteText: "هل أنت متأكد من حذف التذكير",
  eventChanged: "!تم حفظ التغييرات بنجاح!",
  monthText: "شهر",
  dateLabel: "التاريخ",
  entrySavedText: "!تم حفظ التذكير بنجاح",
  weekText: "أسبوع",
  dayText: "يوم",
  notifyDeleteText: 'لا يمكن إستعادة التذكير بعد حذفه',
  saveText: "حفظ",
  addText: "إضافة تذكير",
  deleteText: "حذف",
  cancelText: "إلغاء",
  todayText: "اليوم",
  thisMonthText: "هذا الشهر",
  titleColorPlaceholder: "أدخل اسم للون",
  thisWeekText: "هذا الأسبوع",
  descriptionLabel: "الوصف",
  descriptionPlaceholder: "أدخل الوصف",
}

exports.addEventValidation = /^(?=.*[\w\d]).+/;

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
// Customizable Area End