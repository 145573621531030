import React from "react";
import "./SendInviteStyles.css";
import "./ClientDetailsStyles.css";
import SendInviteController from "./SendInviteController";
import InputField from "./InputField.web";
import Strings from "./Strings";

export default class SendInvite extends SendInviteController {
  render() {
    return (
      <div className="send-invite-outer-container">
        <div className="send-invite-container">
          <div className="send-invite-top-container">
            <InputField
              data-testid={"textInput"}
              value={this.state.first_name}
              name={Strings.firstName}
              labelName={Strings.firstNameLabel}
              type={Strings.text}
              placeholder={Strings.enterFirstName}
              onChangeText={this.onChangeInputField}
              error={this.state.first_name_validate}
              customStyles="top-field-input"
              customContainerStyle="top-field-input-name"
            />
            <InputField
              data-testid={"textInput"}
              value={this.state.last_name}
              labelName={Strings.lastNameLabel}
              name={Strings.lastName}
              type={Strings.text}
              placeholder={Strings.enterLastName}
              onChangeText={this.onChangeInputField}
              error={this.state.last_name_validate}
              customStyles="top-field-input"
              customContainerStyle="top-field-input-name"
            />
          </div>
          <InputField
            data-testid={"textInput"}
            value={this.state.emailAddress}
            labelName={Strings.emailAddressLabel}
            name={Strings.emailAddress}
            type={Strings.text}
            placeholder={Strings.enterClientEmailAddress}
            onChangeText={this.onChangeInputField}
            error={this.state.email_validate}
          />
          <InputField
            data-testid={"textInput"}
            value={this.state.create_password}
            labelName={Strings.createPasswordLabel}
            name={Strings.createPassword}
            type={Strings.password}
            placeholder={Strings.createPasswordForClient}
            onChangeText={this.onChangeInputField}
            error={this.state.create_password_validate}
          />
          <InputField
            data-testid={"textInput"}
            value={this.state.confirm_password}
            labelName={Strings.confirmPasswordLabel}
            name={Strings.confirmPassword}
            type={Strings.password}
            placeholder={Strings.confirmPasswordForClient}
            onChangeText={this.onChangeInputField}
            error={this.state.confirm_password_validate}
            errorMessage={Strings.passwordDoesntMatch}
          />
        </div>
        <div className="sendInvite-button-container">
          <button
            data-testid={"discardButton"}
            className="add-button"
            onClick={this.handleDiscardChanges}
          >
            <p className="add-button-text">{Strings.discardChanges}</p>
          </button>
          <button
            data-testid={"saveButton"}
            className="add-button button-fill send-invite-button"
            onClick={this.handlleSaveChange}
          >
            <p className="add-button-text">{Strings.sendInvite}</p>
          </button>
        </div>
      </div>
    );
  }
}
