export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const plusImg = require("../assets/plus.png");
export const searchIcon= require("../assets/Icon.png");
export const CheckBox= require("../assets/Checkbox.png");
export const notAllCheck= require("../assets/notAll.png");
export const unCheckedBox= require("../assets/unCheck.png");
export const breadCrum=require("../assets/breadCrumb.png");
export const Close=require("../assets/close.png");
