import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { calender, documentImg, documenttext, element, group, key, message, profile, categories, accountUserIcon } from "./assets";
import { Linking } from "react-native";
import Strings from './Strings'
import { createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // drawerContent?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  selectedItem: any;
  showSideBar: boolean;
  screenSize : any;
  userDetail : any;
  userRole : string;
  signOutModalFlag : boolean;
  navigationMenuArray : { icon: string, title: string, navigationName:string }[];
  navigationImageArray : any;
  activePath : any;
  currentLanguage:'english'|'arabic';

    // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  navigation : any
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  navigationMenuRef: any;

  navMenuForAdmin = [
    {icon : 'element', title : Strings.home, navigationName : 'Dashboard'},
    {icon : 'profile', title : Strings.clients, navigationName : 'ClientListing'},
    {icon : 'documenttext', title : Strings.wasteBill, navigationName : 'WasteBill'},
    {icon : 'calender', title : Strings.calendar, navigationName : 'Calendar'},
    {icon : 'group', title : Strings.pickupRequest, navigationName : 'PickupRequest'},
    {icon : 'message', title : Strings.contact, navigationName : 'Contactus'},
    {icon : 'documentImg', title : Strings.termsConditions, navigationName : 'TermsConditionsEdit'},
    {icon : 'key', title : Strings.adminStaff, navigationName : 'AdminStaffListing'},
    {icon : 'categories', title : Strings.categories, navigationName : 'Categories'},
  ]
  
  navMenuForClient = [
    {icon : 'element', title : Strings.home, navigationName : 'DashboardClient'},
    {icon : 'accountUserIcon', title : Strings.account, navigationName : 'ClientAccountDetail'},
    {icon : 'documenttext', title : Strings.report, navigationName : 'ReportClient'},
    {icon : 'group', title : Strings.pickupClient, navigationName : 'PickupClientListing'},
    {icon : 'message', title : Strings.contact, navigationName : 'ContactusClient'},
  ]
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      selectedItem: this.props.id,
      showSideBar: false,
      screenSize : window.innerWidth,
      userDetail : null,
      signOutModalFlag: false,
      userRole : '',
      navigationMenuArray: [],
      navigationImageArray :{calender, documentImg, documenttext, element, group, key, message, profile, categories, accountUserIcon},
      activePath:'',
      currentLanguage:'english',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.navigationMenuRef = createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token }, () => {
      });
    } 
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.setTokenToSessionStorage();
    this.getUserDetail();   
    let language:any = localStorage.getItem('language');
        if(language){
        this.setState({currentLanguage: language});
        }
  }

  setTokenToSessionStorage() {
    const data = localStorage.getItem("clientDetails");
    if(data) {
      sessionStorage.setItem("clientDetails", data);
    }
  }

  getUserDetail = ()=>{
      let detail:any = localStorage.getItem('userDetail')
      try{
        let userDetailObj = JSON.parse(detail)
        if(userDetailObj.token && userDetailObj.account_role){
              this.setState({
                userDetail:userDetailObj,
                userRole:userDetailObj.account_role,
                navigationMenuArray: (userDetailObj.account_role === "Client" || this.isViewAsClient())
                  ? this.navMenuForClient
                  : this.navMenuForAdmin,
                activePath:window.location.pathname.split('/')[1]
              });
              this.CheckUserAndClient(userDetailObj.account_role, window.location.pathname.split('/')[1])
          }else{
            this.navigateLoginBlock()    
          }
      }catch(e){
        this.navigateLoginBlock()
      }
  }

  isViewAsClient = () => {
    const path = this.props.navigation.history.location.pathname.slice(1);
    const clientDetails = sessionStorage.getItem("clientDetails");
    return clientDetails && configJSON.RouteClient.includes(path);
  }


  toggle = (e:any) => {
    e.stopPropagation()
    this.navigationMenuRef.current.classList.toggle("hide-tab-element");
  }


  ShowSignOutPopup = ()=>{
    let element: any = document.querySelector('#root div')!;
    if (element){element.style.overflow = 'hidden'}
    this.setState({signOutModalFlag : true}) 
  }

  HideSignOutPopup = async()=>{
    let element :any = document.querySelector('#root div')!;
    if(element){element.style.overflow = 'unset'}
    this.setState({signOutModalFlag : false}) 
  }

  handleLogout=()=>{
    this.HideSignOutPopup();
    let language = localStorage.getItem('language');
    localStorage.clear();
    if(language)
    {
      localStorage.setItem('language',language)
    }
    this.navigateLoginBlock();
     return true
  }

  onLogoutClick = () => {
    if(this.state.userRole !== "Client" && this.isViewAsClient()) {
      this.handleLogoutAsClient();
    } else {
      this.handleLogout();
    }
  }

  handleLogoutAsClient = () => {
    this.HideSignOutPopup();
    localStorage.removeItem("clientDetails");
    sessionStorage.removeItem("clientDetails");
    window.close();
  }

  navigateLoginBlock=()=>{
    this.props.navigation.navigate("EmailAccountLoginBlock");

  }

  CheckUserAndClient=(accountRole:string, pathname:string)=>{
    const RouteClient = configJSON.RouteClient.map((word:string) => word.toLowerCase());
    const RouteAdmin = configJSON.RouteAdmin.map((word:string) => word.toLowerCase());

    let rediretPathClientKeys = Object.keys(configJSON.redirectForClient).map((word:string) => word.toLowerCase());
    let rediretPathAdminKeys = Object.keys(configJSON.redirectForAdmin).map((word:string) => word.toLowerCase());
    if(accountRole === 'Client' && !RouteClient.includes(pathname.toLowerCase())){
      this.goNavigationPage(rediretPathClientKeys.includes(pathname.toLowerCase())?configJSON.redirectForClient[pathname]:'DashboardClient')
    } else if((accountRole === 'Super Admin' || accountRole === 'Admin' ) && !RouteAdmin.includes(pathname.toLowerCase())){
      this.goNavigationPage(rediretPathAdminKeys.includes(pathname.toLowerCase())?configJSON.redirectForAdmin[pathname]:'Dashboard')
    }
    
  }

  goNavigationPage = (path:string)=>{
    this.props.navigation.navigate(path)
  }

  activePath = (navigationName:string)=>{
    if(this.state.activePath.toLowerCase() === navigationName.toLowerCase()) return true
    const activeRoutesLists = configJSON.activeRoutesLists
    if(activeRoutesLists[this.state.activePath.toLowerCase()]?.includes(navigationName))return true
    return false
  }

  setLanguageOption = (option:string)=>{
    if(option!==this.state.currentLanguage){
    localStorage.setItem('language',option)
    window.location.reload()  
    }
  }
  // Customizable Area End
}
