
 // Customizable Area Start
import React from "react";
import InputField from "../../user-profile-basic/src/InputField.web";
import "./contactusStyle.css";

import ContactusController, { Props } from "./ContactusWebController";
import {
  callIcon,
  chatIcon,
  checkIcon,
  facebookIcon,
  instagramIcon,
  linkedInIcon,
  locationIcon,
  sendIcon,
  twitterIcon,
  uncheckIcon,
  youtubeIcon,
} from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Navbar from "../../settings2/src/Navbar.web";
import Strings from "./Strings";

 // Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }
 // Customizable Area Start
  getErrorBoxStyles = () => {
    if (this.state.error.status === null) {
      return "";
    } else if (!this.state.error.status) {
      return "prompt-container-success";
    } else {
      return "prompt-container-failure";
    }
  };

  getFields = (item: any, index: number) => {
    if (item.type === "header")
      return (
        <div className="contactus-chat-container" key={index}>
          {item.icon && <img src={item.icon} className="subheader-icon" />}
          <div className="chat-text">{item.text}</div>
        </div>
      );
    else if (item.type === "inputField") {
      const {
        value,
        name,
        labelName,
        placeholder,
        onChangeText,
        customStyles,
        error,
        multiline = false,
        fieldType,
      } = item;
      return (
        <div className="contactus-input-container" key={index}>
          <InputField
            data-testid={"clientDetailsInput"}
            key={index}
            value={value}
            name={name}
            labelName={labelName}
            placeholder={placeholder}
            onChangeText={onChangeText}
            customStyles={customStyles}
            error={error}
            type={fieldType}
            multiline={multiline}
          />
        </div>
      );
    }
    const { headerText, fields } = item;
    return (
      <div className="contactus-upload-url-container" key={index}>
        <div className="contactus-upload-header">{headerText}</div>
        {fields.map(
          (
            item: {
              status: boolean;
              name: string;
              url: string;
              validate: boolean | null;
              icon: any;
              onChangeText: any;
            },
            index: number
          ) => {
            return (
              <div key={index} className="contactus-upload-url-item-container">
                <img
                  data-testid={`checkButton${item.name}`}
                  onClick={() => this.onURLTickPress(item.name)}
                  src={item.status ? checkIcon : uncheckIcon}
                  className={`check-uncheck-icon ${item.status &&
                    "check-background"}`}
                />
                <img
                  src={item.icon}
                  className="social-img"
                />
                <InputField
                  data-testid={`socialURL${item.name}`}
                  key={index}
                  value={item.url}
                  name={item.name}
                  placeholder={Strings.enterWebsiteURL}
                  onChangeText={item.onChangeText}
                  error={item.validate}
                  type={"text"}
                  disable={!item.status}
                />
              </div>
            );
          }
        )}
      </div>
    );
  };
// Customizable Area End
  render() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    const UIFields = [
      { type: "header", icon: chatIcon, text: Strings.chat },
      {
        type: "inputField",
        value: this.state.chatHeading,
        name: "chatHeading",
        labelName: Strings.heading1,
        placeholder: Strings.enterHeading,
        onChangeText: this.onChangeText,
        customStyles: "",
        error: this.state.chatHeadingValidate,
        fieldType: "text",
      },
      {
        type: "inputField",
        value: this.state.chatSubHeading,
        name: "chatSubHeading",
        labelName: Strings.subHeading1,
        placeholder: Strings.enterSubHeading,
        onChangeText: this.onChangeText,
        customStyles: "",
        error: this.state.chatSubHeadingValidate,
        fieldType: "text",
      },
      {
        type: "inputField",
        value: this.state.chatEmail,
        name: "chatEmail",
        labelName: Strings.emailAddress,
        placeholder: Strings.enterEmailAddress,
        onChangeText: this.onChangeText,
        customStyles: "",
        error: this.state.chatEmailValidate,
        fieldType: "text",
      },
      { type: "header", icon: locationIcon, text: Strings.office },
      {
        type: "inputField",
        value: this.state.officeHeading,
        name: "officeHeading",
        labelName: Strings.heading1,
        placeholder: Strings.enterHeading,
        onChangeText: this.onChangeText,
        customStyles: "",
        error: this.state.officeHeadingValidate,
        fieldType: "text",
      },
      {
        type: "inputField",
        value: this.state.officeSubHeading,
        name: "officeSubHeading",
        labelName: Strings.subHeading1,
        placeholder: Strings.enterSubHeading,
        onChangeText: this.onChangeText,
        customStyles: "",
        error: this.state.officeSubHeadingValidate,
        fieldType: "text",
      },
      {
        type: "inputField",
        value: this.state.officeAddress,
        name: "officeAddress",
        labelName: Strings.address,
        placeholder: Strings.enterAddress,
        onChangeText: this.onChangeText,
        customStyles: "",
        error: this.state.officeAddressValidate,
        fieldType: "text",
      },
      { type: "header", icon: callIcon, text: Strings.contact },
      {
        type: "inputField",
        value: this.state.contactHeading,
        name: "contactHeading",
        labelName: Strings.heading1,
        placeholder: Strings.enterHeading,
        onChangeText: this.onChangeText,
        customStyles: "",
        error: this.state.contactHeadingValidate,
        fieldType: "text",
      },
      {
        type: "inputField",
        value: this.state.contactSubHeading,
        name: "contactSubHeading",
        labelName: Strings.subHeading1,
        placeholder: Strings.enterSubHeading,
        onChangeText: this.onChangeText,
        customStyles: "",
        error: this.state.contactSubHeadingValidate,
        fieldType: "text",
      },
      {
        type: "inputField",
        value: this.state.contactNumber1,
        name: "contactNumber1",
        labelName: Strings.contactNumbers,
        placeholder: Strings.enterContactNumber1,
        onChangeText: this.onChangeText,
        customStyles: "",
        error: this.state.contactNumber1Validate,
        fieldType: "text",
      },
      {
        type: "inputField",
        value: this.state.contactNumber2,
        name: "contactNumber2",
        labelName: Strings.contactNumbers,
        placeholder: Strings.enterContactNumber2,
        onChangeText: this.onChangeText,
        customStyles: "",
        error: this.state.contactNumber2Validate,
        fieldType: "text",
      },
      {
        type: "inputField",
        value: this.state.contactNumber3,
        name: "contactNumber3",
        labelName: Strings.contactNumbers,
        placeholder: Strings.enterContactNumber3,
        onChangeText: this.onChangeText,
        customStyles: "",
        error: this.state.contactNumber3Validate,
        fieldType: "text",
      },
      { type: "header", icon: sendIcon, text: Strings.follow },
      {
        type: "inputField",
        value: this.state.followHeading,
        name: "followHeading",
        labelName: Strings.heading1,
        placeholder: Strings.enterHeading,
        onChangeText: this.onChangeText,
        customStyles: "",
        error: this.state.followHeadingValidate,
        fieldType: "text",
      },
      {
        type: "inputField",
        value: this.state.followSubHeading,
        name: "followSubHeading",
        labelName: Strings.subHeading1,
        placeholder: Strings.enterSubHeading,
        onChangeText: this.onChangeText,
        customStyles: "",
        error: this.state.followSubHeadingValidate,
        fieldType: "text",
      },
      {
        type: "uploadUrl",
        headerText: Strings.uploadURL,
        fields: [
          {
            status: this.state.facebookUrl.status,
            url: this.state.facebookUrl.url,
            name: "facebookUrl",
            onChangeText: this.onChangeUrlText,
            icon: facebookIcon,
            validate: this.state.facebookUrl.validate,
          },
          {
            status: this.state.instagramUrl.status,
            url: this.state.instagramUrl.url,
            name: "instagramUrl",
            onChangeText: this.onChangeUrlText,
            icon: instagramIcon,
            validate: this.state.instagramUrl.validate,
          },
          {
            status: this.state.twitterUrl.status,
            url: this.state.twitterUrl.url,
            name: "twitterUrl",
            onChangeText: this.onChangeUrlText,
            icon: twitterIcon,
            validate: this.state.twitterUrl.validate,
          },
          {
            status: this.state.youtubeUrl.status,
            url: this.state.youtubeUrl.url,
            name: "youtubeUrl",
            onChangeText: this.onChangeUrlText,
            icon: youtubeIcon,
            validate: this.state.youtubeUrl.validate,
          },
          {
            status: this.state.linkedinUrl.status,
            url: this.state.linkedinUrl.url,
            name: "linkedinUrl",
            onChangeText: this.onChangeUrlText,
            icon: linkedInIcon,
            validate: this.state.linkedinUrl.validate,
          },
        ],
      },
    ];
    return (
      <div className="main-wrapper contactus--inner-cont">
        <NavigationMenu navigation={this.props.navigation} id={"6"} />
        <div className="contactus-right-container">
          <Navbar navigation={this.props.navigation} id={""} />
          <div className="contactus-outer-container">
            <div className="contactus-inner-container">
              <div className="contactus-header-container">
                <div className="contactus-header-top-container">
                  <p className="contactus-header-title">{Strings.contact}</p>
                  {this.state.error.status !== null && (
                    <span
                      data-testid={"prompMessage"}
                      className={`contactus-prompt-container header-prompt-container ${this.getErrorBoxStyles()}`}
                    >
                      {this.state.error.message}
                    </span>
                  )}
                </div>
                <div className="contactus-screen-title-container">
                  <div
                    data-testid={`changeScreenButton`}
                    className={`contactus-header-inner-container`}
                  >
                    <p className={`contactus-titles`}>{Strings.contactUs}</p>
                  </div>
                </div>
              </div>
              {UIFields.map((item, index) => {
                return this.getFields(item, index);
              })}
            </div>
            <div
              className="bottom-button-container"
              key={"bottomButtonContainer"}
            >
              <button
                data-testid={"discardButton"}
                className="add-button"
                onClick={this.handleDiscardChanges}
              >
                <p className="add-button-text">{Strings.discardChanges}</p>
              </button>
              <button
                data-testid={"saveButton"}
                className="add-button button-fill"
                onClick={this.handlleSaveChange}
              >
                <p className="add-button-text">{Strings.save}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
