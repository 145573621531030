// Customizable Area Start
import React from "react";
import Grid from '@material-ui/core/Grid';
import Strings from './Strings'

export interface Props {
  data: {
    title: string,
    value: number,
    icon: any,
    unit:string,
  }[]
}

export default class EnvironmentalCard extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }


  // Customizable Area Start
  render() {

    return (
      <React.Fragment>
        <div
          style={CustomWebStyles.container}
        >
          <h1
            style={CustomWebStyles.heading}
          >
            {Strings.EnvironmentalSectionHeading}
          </h1>
          <Grid container spacing={3}>
            {this.props.data.map((element, index) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <div
                    style={CustomWebStyles.cardBox}
                  >
                    <img style={{ height: index === 5 ? "85px" : "110px", width: index === 5 ? "85px" : "95px" }} src={element.icon} />
                    <div style={CustomWebStyles.cardContent}>
                      <h5 style={CustomWebStyles.cardSubHeading}>{`${element.title} (${element.unit})`}</h5>
                      <h2 style={CustomWebStyles.numberText}>{element.value?.toLocaleString()}</h2>
                    </div>
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </div>
      </React.Fragment>
    );
  }
  // Customizable Area End

}

const CustomWebStyles = {
  container: {
    background: "#fff",
    marginBottom: "20px",
    width: "calc(100% - 32px)",
    borderRadius: "12px",
    padding: 24
  },
  heading: {
    color: "#1C3045",
    fontSize: "24px",
    fontStyle: "normal",
    lineHeight: "30px",
    letterSpacing: "0.24px",
    fontFamily: "Barlow Semi Condensed",
    marginBottom: "24px",
  },
  cardBox: {
    background: "#E1F0E6",
    display: "flex",
    borderRadius: "10px",
    width: "92%",
    alignItems: "center",
    paddingLeft: "30px",
    paddingTop: "10px",
    height: "130px"
  },
  imgBox: {
    width: "83px",
    height: "83px",
  },
  cardContent: {
    alignSelf: "center",
    marginLeft: "16px"
  },
  cardSubHeading: {
    color: "#000",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "20.8px",
  },
  numberText: {
    color: "#32569A",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "28px",
    fontStyle: "normal",
    marginTop: "16px",
    lineHeight: "36.4px",
  }

}
// Customizable Area End
