import React from "react";
import "./AdminStaffListingStyle.css";
import Strings from "./Strings";

import // Customizable Area Start

// Customizable Area End
"@material-ui/core";

// Customizable Area Start

// Customizable Area End

import AdminStaffListingController, {
  Props,
} from "./AdminStaffListingController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import {
  actionIcon,
  addIcon,
  arrowRight,
  caretLeft,
  caretRight,
  circleFill,
  crossIcon,
  deleteIcon,
  filterIcon,
  minusIcon,
  sortIcon,
  tickIcon,
  untickIcon,
  vectorDown,
  vectorUp,
} from "./assets";
import Navbar from "../../settings2/src/Navbar.web";
import InputField from "./InputField.web";

export default class AdminStaffListing extends AdminStaffListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getErrorBoxStyles = () => {
    if (this.state.error.status === null) {
      return "";
    } else if (!this.state.error.status) {
      return "prompt-container-success";
    } else {
      return "prompt-container-failure";
    }
  };

  getListing = () => {
    return this.state.listData.map((item: any, index: number) => {
      const {
        complete_name = "",
        email = "",
        full_phone_number = "",
        role,
      } = item.attributes;
      return (
        <tr
          key={index}
          className={`admin-listing-table-item-container ${
            this.state.selected.indexOf(item.id) !== -1 &&
            "admin-listing-table-item-selected"
          }`}
        >
          <td className="admin-listing-table-field-name-container">
            <img
              src={
                this.state.selected.indexOf(item.id) === -1
                  ? untickIcon
                  : tickIcon
              }
              alt={
                this.state.selected.indexOf(item.id) === -1
                  ? "Unselect"
                  : "Select"
              }
              className="admin-listing-checkbox-img"
              data-testid={`selectButton${index}`}
              onClick={() => this.onSelectClick(item.id)}
            />
            <p className="admin-listing-name-item-text">{complete_name}</p>
          </td>
          <td className="admin-listing-table-field-email-container">
            <p className="admin-listing-email-item-text">{email}</p>
          </td>
          <td className="admin-listing-table-field-mobile-container">
            <p className="admin-listing-email-item-text">{full_phone_number}</p>
          </td>
          <td className="admin-listing-table-field-role-container">
            <p className="admin-listing-email-item-text">
              {role.attributes.name}
            </p>
          </td>
          <td className="admin-listing-table-field-action-container">
            <img
              src={actionIcon}
              alt="Action"
              data-testid={`actionButton${index}`}
              className="admin-listing-action-img"
              onClick={() => this.onActionClick(index)}
              ref={this.actionButtonRef}
            />
            {this.state.menuIndex === index && (
              <div
                className="admin-listing-action-menu-container"
                data-testid={`actionConsole`}
                ref={this.actionModalRef}
              >
                <p
                  className="admin-listing-action-menu-item"
                  onClick={() => this.onEditAdminClick(item.id)}
                >
                  {Strings.edit}
                </p>
                <p
                  className="admin-listing-action-menu-item"
                  onClick={() => this.onDeleteModalOpen(item.id)}
                >
                  {Strings.delete}
                </p>
              </div>
            )}
          </td>
        </tr>
      );
    });
  };

  getPages = () => {
    let numbers = [];
    let currentPage = this.state.page;
    for (
      let i = this.state.pageBatch * 5 - 4;
      i <=
      (this.state.totalPages > this.state.pageBatch * 5
        ? this.state.pageBatch * 5
        : this.state.totalPages);
      i++
    )
      numbers.push(i);
    return (
      <div className="admin-listing-page-container">
        <div
          className="admin-listing-page-inner-container"
          title="Previous Page"
          onClick={this.onPreviousPageClick}
        >
          <img
            src={caretLeft}
            className="admin-listing-page-icon"
            alt="Previous Page"
          />
        </div>
        {numbers.map((item, index) => {
          return (
            <div
              title={`Page ${item}`}
              data-testid={`pageButton${index}`}
              onClick={() => this.onPageClick(item)}
              className={`admin-listing-page-inner-container ${
                currentPage === item
                  ? "admin-listing-page-inner-container-selected"
                  : ""
              }`}
              key={index}
            >
              <p
                className={`admin-listing-page-number-text ${
                  currentPage === item
                    ? "admin-listing-page-number-text-selected"
                    : ""
                }`}
              >
                {item}
              </p>
            </div>
          );
        })}
        {this.state.pageBatch < Math.ceil(this.state.totalPages / 5) && (
          <div className="admin-listing-page-inner-container">
            <p className="admin-listing-page-number-text ">{"..."}</p>
          </div>
        )}
        <div
          className="admin-listing-page-inner-container"
          title="Next Page"
          onClick={this.onNextPageClick}
        >
          <img
            src={caretRight}
            className="admin-listing-page-icon"
            alt="Next Page"
          />
        </div>
      </div>
    );
  };

  getHeaderTickIcon = () => {
    if (
      this.state.listData.length === this.state.selected.length &&
      this.state.listData.length !== 0
    )
      return tickIcon;
    if (this.state.selected.length === 0) return untickIcon;
    return minusIcon;
  };

  getInitialNumber = () => {
    let page = this.state.page;
    let totalData = this.state.listData.length;
    let temp = page * 10;
    if (totalData > temp) return temp;
    return totalData;
  };

  deleteModal = () => {
    return (
      <div className="admin-listing-delete-modal-container">
        <div className="admin-listing-delete-modal-inner-container">
          <img
            src={crossIcon}
            className="admin-listing-close-modal-img"
            onClick={this.onModalCloseClick}
          />
          <p className="admin-listing-delete-modal-header-text">
            {Strings.areYouSureYouWantToDelete}
          </p>
          <p className="admin-listing-delete-modal-subheader-text">
            {Strings.onceDoneCantBeUndone}
          </p>
          <hr className="admin-listing-modal-separator admin-listing-delete-modal-separator" />
          <div className="admin-listing-filter-menu-button-container admin-listing-delete-buttons-container">
            <button
              className="admin-listing-delete-button admin-listing-modal-buttons admin-listing-delete-button-text admin-listing-delete-cancel-button "
              onClick={this.onModalCloseClick}
            >
              {"Cancel"}
            </button>
            <button
              className="admin-listing-delete-button admin-listing-delete-button-text"
              onClick={this.onModalDeleteClick}
            >
              {"Delete"}
            </button>
          </div>
        </div>
      </div>
    );
  };
  // Customizable Area End

  render() {
    let currentItems = this.state.currentItemCount + (this.state.page - 1) * 10;
    return (
      // Customizable Area Start
      <div
        className="main-wrapper contactus--inner-cont"
        style={this.state.showDeleteModal ? { overflow: "hidden" } : {}}
        ref={this.scrollRef}
      >
        {this.state.showDeleteModal && this.deleteModal()}
        <NavigationMenu navigation={this.props.navigation} id={"8"} />
        <div className="contactus-right-container">
          <Navbar navigation={this.props.navigation} id={""} />
          <div className="contactus-outer-container">
            <div className="admin-listing-inner-container">
              <div className="contactus-header-container">
                <div className="admin-listing-header-container">
                  <div className="admin-listing-header">
                    <p className="contactus-header-title">
                      {Strings.adminStaff}
                    </p>
                    {this.state.error.status !== null && (
                      <span
                        data-testid={"prompMessage admin-listing-prompt"}
                        className={`prompt-container header-prompt-container ${this.getErrorBoxStyles()}`}
                      >
                        {this.state.error.message}
                      </span>
                    )}
                  </div>
                  <button
                    className="add-button admin-listing-register-button"
                    onClick={this.handleRegisterNewClient}
                  >
                    <img src={addIcon} className="icon" />
                    <p className="add-button-text admin-listing-register-button-text">
                      {Strings.registerNewAdmin}
                    </p>
                  </button>
                </div>
                <div className="contactus-screen-title-container admin-listing-sub-header-container">
                  <div
                    data-testid={`changeScreenButton`}
                    style={{ border: "0px" }}
                    className={`contactus-header-inner-container`}
                  >
                    <p className={`contactus-titles`}>{Strings.adminStaff}</p>
                    <div
                      style={{
                        width: "100%",
                        height: "2px",
                        borderRadius: "2px",
                        backgroundColor: "#BFCC33",
                      }}
                    />
                  </div>
                  <div className="subheader-right-container">
                    <button
                      className="admin-listing-delete-all-button admin-listing-delete-all admin-listing-margin-left "
                      onClick={() => this.onDeleteModalOpen()}
                    >
                      <img
                        src={deleteIcon}
                        className="icon admin-listing-icon"
                      />
                      <p className="admin-listing-delete-btn-text">
                        {Strings.deleteAll}
                      </p>
                    </button>
                    <InputField
                      value={this.state.search}
                      name="Search"
                      customStyles={"admin-staff-listing-input-container"}
                      customInputStyles={"admin-listing-search-input"}
                      startEndornmentIconStyles={`admin-listing-search-start-endornment ${
                        window.innerWidth <= 414 && "cursor-pointer"
                      }`}
                      onInputPress={this.searchIconPress}
                      onChangeText={this.onChangeSearch}
                      startAdornment={true}
                      placeholder={Strings.searchClient}
                      customContainerStyle={"input-container-admin-listing"}
                    />
                    <div className="admin-listing-filter-container admin-listing-margin-right admin-listing-margin-left">
                      <button
                        className="admin-listing-button admin-listing-filter-button"
                        onClick={this.onFilterClick}
                        data-testid={"filterButton"}
                        ref={this.filterButtonRef}
                      >
                        <img
                          src={filterIcon}
                          className="admin-listing-icon admin-listing-padding-right.5"
                        />
                        <p className="admin-listing-button-font admin-listing-filter-button-text">
                          {Strings.filters}
                        </p>
                      </button>
                      {this.state.filterModal && (
                        <div
                          key={"filterModal"}
                          className="admin-listing-filter-menu"
                          data-testid="filterModal"
                          ref={this.filterModalRef}
                        >
                          <p
                            className="admin-listing-filter-menu-clear-button"
                            data-testid={"clearAllFilters"}
                            onClick={this.onFilterClearClick}
                          >
                            {Strings.clearAll}
                          </p>
                          <hr className="admin-listing-modal-separator" />
                          <div className="admin-listing-filter-menu-header">
                            <p className="admin-listing-filter-menu-header-text">
                              {Strings.role}
                            </p>
                            <img
                              src={
                                this.state.showFilterRole
                                  ? vectorUp
                                  : vectorDown
                              }
                              onClick={this.onFilterRoleClick}
                            />
                          </div>
                          {this.state.showFilterRole &&
                            this.state.filters.roles.map((item, index) => (
                              <div
                                key={index}
                                className="admin-listing-filter-menu-item"
                              >
                                {JSON.stringify(
                                  this.state.filters.selectedRole
                                ) === JSON.stringify(item) ? (
                                  <img
                                    src={tickIcon}
                                    className="admin-listing-filter-menu-item-tick-img"
                                    data-testid={`filter${index}`}
                                    onClick={() =>
                                      this.onFilterRoleItemClick(item)
                                    }
                                  />
                                ) : (
                                  <div
                                    className="admin-listing-filter-menu-item-img"
                                    data-testid={`filter${index}`}
                                    onClick={() =>
                                      this.onFilterRoleItemClick(item)
                                    }
                                  />
                                )}
                                <p className="admin-listing-filter-menu-item-text">
                                  {item?.attributes?.name}
                                </p>
                              </div>
                            ))}
                          <div className="admin-listing-filter-menu-button-container">
                            <button
                              className="admin-listing-filter-menu-button"
                              data-testid="cancelFilters"
                              onClick={this.onCancelFilterClick}
                            >
                              {Strings.cancel}
                            </button>
                            <button
                              className="admin-listing-filter-menu-button admin-listing-filter-menu-button-blue"
                              onClick={this.onApplyFilterClick}
                              data-testid="applyFilters"
                            >
                              {Strings.apply}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="admin-listing-sort-container">
                      <button
                        className="admin-listing-button"
                        onClick={this.onSortClick}
                        data-testid={"sortButton"}
                        ref={this.sortButtonRef}
                      >
                        <img src={sortIcon} className="admin-listing-icon" />
                        <p className="admin-listing-sort-button-text admin-listing-button-font admin-listing-filter-button-text">
                          {Strings.sort}
                        </p>
                      </button>
                      {this.state.sortModal && (
                        <div
                          className="admin-listing-sort-menu"
                          data-testid={"sortModal"}
                          ref={this.sortModalRef}
                        >
                          <div className="admin-listing-filter-menu-header">
                            <p className="admin-listing-filter-menu-header-text">
                              {Strings.sort}
                            </p>
                          </div>
                          {this.state.sortList.map(
                            (item: any, index: number) => (
                              <div
                                key={index}
                                className="admin-listing-sort-menu-item"
                              >
                                {JSON.stringify(this.state.selectedSort) ===
                                JSON.stringify(item) ? (
                                  <div
                                    className="admin-listing-sort-menu-item-img-div"
                                    data-testid={`sortOption${index + 1}`}
                                    onClick={() => this.onSortItemClick(item)}
                                  >
                                    <img
                                      src={circleFill}
                                      className="admin-listing-sort-menu-item-tick-img"
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="admin-listing-sort-menu-item-img-div"
                                    data-testid={`sortOption${index + 1}`}
                                    onClick={() => this.onSortItemClick(item)}
                                  />
                                )}
                                <div className="admin-listing-sort-menu-item-text-container">
                                  <p className="admin-listing-sort-menu-item-text">
                                    {item.before}
                                  </p>
                                  {item.after !== "" && (
                                    <img
                                      src={arrowRight}
                                      className="admin-listing-sort-menu-item-arrow"
                                    />
                                  )}
                                  {item.after !== "" && (
                                    <p className="admin-listing-sort-menu-item-text">
                                      {item.after}
                                    </p>
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.showSearchBar && (
            <InputField
              value={this.state.search}
              name="Search"
              onChangeText={this.onChangeSearch}
              startAdornment={true}
              placeholder={Strings.searchClient}
              customContainerStyle={
                "input-container-admin-listing alternate-searchbar"
              }
            />
          )}
          <div className="admin-listing-table-container">
            <div className="admin-listing-table-inner-container">
              <table className="admin-listing-table">
                <thead>
                  <tr className="admin-listing-table-header-container">
                    <th className="admin-listing-table-field-name-container">
                      <img
                        src={this.getHeaderTickIcon()}
                        alt="Checkbox"
                        className="admin-listing-checkbox-img"
                        onClick={this.onSelectAllClick}
                      />
                      <p className="admin-listing-name-text">{Strings.name}</p>
                    </th>
                    <th className="admin-listing-table-field-email-container">
                      <p className="admin-listing-name-text">{Strings.email}</p>
                    </th>
                    <th className="admin-listing-table-field-mobile-container">
                      <p className="admin-listing-name-text">
                        {Strings.mobileNumber}
                      </p>
                    </th>
                    <th className="admin-listing-table-field-role-container">
                      <p className="admin-listing-name-text">{Strings.role}</p>
                    </th>
                    <th className="admin-listing-table-field-action-container">
                      <p className="admin-listing-name-text">
                        {Strings.action}
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>{this.getListing()}</tbody>
              </table>
            </div>
            <div className="admin-listing-table-footer-container">
              <p className="admin-listing-table-footer-text">{`${
                Strings.showing
              } ${(this.state.page - 1) * 10 + 1} - ${currentItems} ${
                Strings.from
              } ${this.state.totalItems}`}</p>
              {this.getPages()}
            </div>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {};
// Customizable Area End
