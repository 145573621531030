import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Strings from "./Strings";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  setError: (arg: any) => void;
  setCurrentScreen: (arg: string) => void;
  editId: number
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedFile: any;
  isAutocompleteOpen: boolean;
  isAutocompleteOpenWaste: boolean;
  isAutocompleteOpenAwar: boolean;
  report_frequency: any;
  waste_type_category_ids: any[];
  awareness_given: any;
  attachments: any;
  wasteType: string[];
  wasteCategory: any[];
  defaultOptionsawarnessGiven: any[];
  filesToRemoveFromUploaded: any[];
  newFilesToUpload: any[];
  submitButtonDisable: boolean;
  User_language: any,
  reportFreq: any[]

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ClientDetailsReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallIds: any = "";
  forValidationApiCallId: string = "";
  emailRegs: RegExp;
  reportId: any;
  caterogyCallId: any;
  getDetailsId: string = ""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      selectedFile: [],
      submitButtonDisable: false,
      isAutocompleteOpen: false,
      isAutocompleteOpenWaste: false,
      isAutocompleteOpenAwar: false,
      report_frequency: { title: 'Bi-Annual', arabic_text: 'نصف سنوي' },
      waste_type_category_ids: [],
      awareness_given: [],
      attachments: [],
      wasteType: [],
      wasteCategory: [],
      defaultOptionsawarnessGiven: [
        { title: 'Online', selected: false, arabic_text: 'عن بعد' },
        { title: 'Offline', selected: false, arabic_text: 'حضوري' },
        { title: 'Infographic Materials', selected: false, arabic_text: 'المواد المعلوماتية' }
      ],
      filesToRemoveFromUploaded: [],
      newFilesToUpload: [],
      User_language: null,
      reportFreq: [
        { title: 'Monthly', arabic_text: 'شهري', },
        { title: 'Bi-annual', arabic_text: 'نصف سنوي' },
        { title: 'Quarterly', arabic_text: 'ربع سنوي' },
        { title: 'End of year', arabic_text: 'نهاية السنة' }
      ]
    };

    this.emailRegs = /\w+/;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.category();
    let language = localStorage.getItem('language')
    this.setState({ User_language: language ? language : 'english' })
    
    // Customizable Area End
  }

  // Customizable Area Start
  getUserDetails() {

    let UserToken = localStorage.getItem('token')
    if (UserToken) {
      const header = {
        token: UserToken,
        "Content-Type": configJSON.contentTypeApiGetClientAccountDetail,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getDetailsId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodTypeApiGetClientAccountDetail
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiGetClientAccountDetail + this.props.editId
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }



  handleFileSelect = async (event: any) => {
    const selectedFiles = event.target.files;
    const file = selectedFiles
    // Array.from(selectedFiles).filter((file:any) => file.size <= 102400);
    const alreadyUploadedFiles = this.state.selectedFile;
    const FilesToUpload = this.state.newFilesToUpload;
    if (file) {
      this.setState(() => ({
        selectedFile: [...alreadyUploadedFiles, ...file],
        newFilesToUpload: [...FilesToUpload, ...file]
      }));

    } else {
      this.props.setError({
        status: true, message: "Error Uploading! File size too big."
      })
    }

  };
  handleDragOver = (event: any) => {
    event.preventDefault();
  };
  handleDrop = (event: any) => {
    event.preventDefault();
    const file = event.dataTransfer?.files;
    const FilesToUpload = this.state.newFilesToUpload;
    if (file) {
      this.setState({
        selectedFile: [...this.state.selectedFile, ...file],
        newFilesToUpload: [...FilesToUpload, ...file],
      })
    }
  };

  toggleAutocomplete = () => {
    this.setState((prevState) => ({
      isAutocompleteOpen: !prevState.isAutocompleteOpen,
    }));
  };

  handleAutocompleteOpen = () => {
    this.setState({ isAutocompleteOpen: true });
  };

  handleAutocompleteClose = () => {
    this.setState({ isAutocompleteOpen: false });
  };
  handleIconClick = () => {
    this.toggleAutocomplete();
  };
  toggleAutocompleteWaste = () => {
    this.setState((prevState) => ({
      isAutocompleteOpenWaste: !prevState.isAutocompleteOpenWaste,
    }));
  };
  handleAutocompleteOpenWaste = () => {
    this.setState({ isAutocompleteOpenWaste: true });
  };
  handleAutocompleteCloseWaste = () => {
    this.setState({ isAutocompleteOpenWaste: false });
  };
  handleIconClickWaste = () => {
    this.toggleAutocompleteWaste();
  };
  toggleAutocompleteAwar = () => {
    this.setState((prevState) => ({
      isAutocompleteOpenAwar: !prevState.isAutocompleteOpenAwar,
    }));
  };
  handleAutocompleteOpenAwar = () => {
    this.setState({ isAutocompleteOpenAwar: true });
  };
  handleAutocompleteCloseAwar = () => {
    this.setState({ isAutocompleteOpenAwar: false });
  };
  handleIconClickAwar = () => {
    this.toggleAutocompleteAwar();
  };

  handleWasteTypeCategoriesChange = (_event: any, selectedOptions: any[]) => {
    const selectedIds = selectedOptions.map((option) => option);
    this.setState({ waste_type_category_ids: selectedIds });
  };
  handleAwarnessChange = (_event: any, selectedOptions: any[]) => {
    let selectedIds: any[] = [];
    const updatedArr = this.state.defaultOptionsawarnessGiven;
    const selectedOptionArr = selectedOptions.map(option => option.title)

    updatedArr.forEach(option => {
      if (selectedOptionArr.includes(option.title)) {
        option.selected = true;
        selectedIds.push(option);
      } else {
        option.selected = false;
        selectedIds = selectedIds.filter(element => { return element.title !== option.title })
      }
    })

    this.setState({ awareness_given: selectedIds, defaultOptionsawarnessGiven: updatedArr });
  };


  handlefileClose = (id: any, file: any) => {
    const updatedFiles = this.state.selectedFile;
    const fileToDelete = this.state.filesToRemoveFromUploaded;
    if (file.blobId) {
      this.setState({ filesToRemoveFromUploaded: [...fileToDelete, file.blobId] })
    }
    updatedFiles.splice(id, 1);
    this.setState(
      { selectedFile: updatedFiles }
    );
  }

  handleSetSelectedAwareness = (selectedItems: any[]) => {
    const selectedIds: { title: any, selected: boolean, arabic_text: string }[] = [];
    const { defaultOptionsawarnessGiven } = this.state
    const updatedArr = defaultOptionsawarnessGiven
    updatedArr.forEach(option => {
      if (selectedItems.includes(option.title)) {
        option.selected = true
        selectedIds.push(option)
      }
    })
    this.setState({ defaultOptionsawarnessGiven: updatedArr, awareness_given: selectedIds })
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage))
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (this.reportId !== null && this.reportId === dataMessage) {
      this.handleClientAPIResponse(
        successMessage,
        errorMessage,
        this.clientSuccessCallback
      );
    }

    // response handling of waste type categories
    if (this.caterogyCallId === dataMessage) {
      if (successMessage) {
        if (this.props.editId && this.props.editId != -1) {
          this.getUserDetails()
        }
        this.setState({
          wasteCategory: successMessage?.data
        })
      }
    }

    // response handling reports pre filled values
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      apiRequestCallId === this.getDetailsId
    ) {
      if (!successMessage.errors) {

        this.updateDropDown(successMessage)

      }
    }

    // Customizable Area End

  }


  updateDropDown = (successMessage:any)=>{
         const { data } = successMessage;
        const selectedAwarenessGiven = data.attributes.awareness_given_value;
        const selectedWasteTypeCategories = data.attributes.waste_type_categories ?? ''
        const report_frequency_fromApi = data.attributes.report_frequency ?? Strings.biannual;
        const filesAttached = data.attributes.account_attachments?.map((item: any) => {
          const name = item.attachment_url.split("/").pop();
          const blobId = item.blob_id
          return { name, blobId }
        })
        const { wasteCategory } = this.state


        // setting up selected awareness given values
        this.handleSetSelectedAwareness(selectedAwarenessGiven);

        // setting up selected waste type values
        const selectedWasteTypes: { title: string, selected: boolean, id: number }[] = []
        wasteCategory.forEach(element => {
          if (selectedWasteTypeCategories.includes(element.attributes.name)) {
            selectedWasteTypes.push(element)
          }
        })

        

        let findIndex = this.state.reportFreq.findIndex(data => data.title === report_frequency_fromApi || data.arabic_text === report_frequency_fromApi)

        this.setState({
          waste_type_category_ids: selectedWasteTypes,
          selectedFile: filesAttached ?? [],
          report_frequency: { ...this.state.reportFreq[findIndex] }
        })

  }

  report = async () => {
    let token = await localStorage.getItem("token");
    this.setState({ submitButtonDisable: true })
    const header = {
      token: token,
    };

    let formData = new FormData();

    if (Array.isArray(this.state.newFilesToUpload)) {
      this.state.newFilesToUpload.forEach((imgs: any) => {
        formData.append("account[account_attachments][]", imgs);
      });
    }
    formData.append("account[step]", "Step3");
    formData.append("account[report_frequency]", this.state.report_frequency.title);
    this.state.waste_type_category_ids.forEach(item => {
      formData.append("account[waste_type_category_ids][]", item.id)
    })
    this.state.awareness_given.map((aware: { title: string, selected: boolean, arabic_text: string }) => {
      formData.append("account[awareness_given][]", aware.title);
    })

    this.state.filesToRemoveFromUploaded.forEach(item => {
      formData.append("account[attachments_to_remove][]", item)
    })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reportId = requestMessage.messageId;
    let id = this.props.editId && this.props.editId !== -1 ? this.props.editId : localStorage.getItem("clientAccountId")

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountCreationUpdate}${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleClientAPIResponse(
    successMessage: any,
    errorMessage: any,
    callback: Function
  ) {
    this.setState({ submitButtonDisable: false })
    if (successMessage) {
      if (successMessage.error) {
        this.props.setError({
          error: { status: true, message: successMessage.error[0] },
        });
      } else {
        callback(successMessage);
      }
    }
  }

  clientSuccessCallback = async (successMessage: any) => {
    this.props.setCurrentScreen(Strings.sendInvite);
  };
  handleDiscard = () => {
    this.setState({
      selectedFile: [],
      report_frequency: { title: 'Bi-Annual', arabic_text: 'نصف سنوي' },
      waste_type_category_ids: [],
      awareness_given: [],
      attachments: [],
      wasteType: [],
    });
  };

  handleChangeReport = async (event: any, selectedOptions: any) => {
    this.setState({
      report_frequency: { ...selectedOptions }
    })
  }
  // waste type categories api integration
  category = async () => {
    let token = localStorage.getItem("token");

    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.caterogyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/waste_type_categories`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
}
