import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Divider, IconButton, Toolbar, Tooltip, TableContainer, Box, makeStyles, styled, ClickAwayListener } from "@material-ui/core";
import { FilterList } from '@material-ui/icons'
import Pagination, { PaginationRenderItemParams } from '@material-ui/lab/Pagination';
import CustomSortModal from "./CustomSortModal.web";
import Strings from "./Strings";
import { PaginationItem } from "@material-ui/lab";
import { nextIcon, prevIcon } from "./assets";
import moment from "moment";


export interface Props {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      waste_type_category_id: number;
      branch_name_id: number;
      recyclable_net_weight: number;
      report_date: string;
      waste_type_category_name: string;
      branch_name: string
    }
  }[];
  tableHeaders: string[];
  showSortModal: boolean;
  handleShowSortModal: () => void;
  showViewMore: boolean;
  onClickViewMore: () => void
  totalPagination?: number
  total: number
  currentPage?: number
  onChangePage?: (value: number) => void
  onChangeSortByValue?: (v: string) => void
  hideSortModal: () => void
}


export default class TableComponent extends React.Component<Props>{
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  EnhancedTableToolbar(hideSort: boolean) {
    if (hideSort) {
      return (
        <Toolbar style={WebStyles.viewHeader}>
          <h3 style={WebStyles.tableHeading}>{Strings.totalNetWeightText}</h3>
        </Toolbar>
      );
    }
    return (
      <Toolbar style={WebStyles.viewHeader}>
        <h3 style={WebStyles.tableHeading}>{Strings.totalNetWeight}</h3>
        <div style={{position:'relative'}}>
          <Tooltip title="Filter list" onClick={() => this.props.handleShowSortModal()}>
            <IconButton style={WebStyles.viewIconFilter} >
              <FilterList />&nbsp; {Strings.SortTitle}
            </IconButton>
          </Tooltip>
          {this.props.showSortModal &&
            <ClickAwayListener onClickAway={() => this.props.hideSortModal()}>
              <div className="filter_modal_container" style={{ top: "24px" }}>
                <CustomSortModal
                  data-testid="sortModal"
                  onChangeSortByChoice={(v: string) => this.props.onChangeSortByValue?.(v)}
                />
              </div>
            </ClickAwayListener>
          }
        </div>
      </Toolbar>
    );
  }

  renderItemPagination(item: PaginationRenderItemParams) {
    if (item.type === "page") {
      return (
        <div onClick={item.onClick} style={this.props.currentPage === item.page ? WebStyles.pageSelected : WebStyles.pageNumber}>{item.page}</div>
      )
    } else if (item.type === "next") {
      return (
        <div onClick={item.onClick} style={WebStyles.pageNumber}>
          <img src={nextIcon} style={WebStyles.icon} />
        </div>
      )
    } else if (item.type === "previous") {
      return (
        <div onClick={item.onClick} style={WebStyles.pageNumber}>
          <img src={prevIcon} style={WebStyles.icon} />
        </div>
      )
    } else return <PaginationItem {...item} />
  }

  render() {
    const dataTable = this.props.showViewMore ? this.props.data.slice(0, 5) : this.props.data
    return (
      <div
        style={WebStyles.tableWrapper}
      >
        {this.EnhancedTableToolbar(this.props.showViewMore)}
        <Divider />
        <TableContainer>
          <Table aria-label="simple table" >
            <TableHead>
              <TableRow>
                {this.props.tableHeaders.map(
                  element => <TableCell key={element} align="left" style={WebStyles.tableHeader}>{element}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map(element => {
                const { branch_name, report_date, waste_type_category_name, recyclable_net_weight } = element.attributes
                return (
                  <TableRow key={element.id}>
                    <TableCell align="left" style={WebStyles.tableCell}>{branch_name}</TableCell>
                    <TableCell align="left" style={WebStyles.tableCell}>{moment(new Date(report_date)).format("DD/MM/YYYY")}</TableCell>
                    <TableCell align="left" style={WebStyles.tableCell}>{waste_type_category_name}</TableCell>
                    <TableCell align="left" style={WebStyles.tableCell}>{`${recyclable_net_weight} KG`}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {
          this.props.showViewMore ? (
            <Box style={WebStyles.viewMore}>
              <div data-testid='viewMoree' onClick={() => this.props.onClickViewMore()} style={WebStyles.textViewMore}>{String(Strings.viewMoreText).toLowerCase()}</div>
            </Box>
          ) : (
            <Box style={WebStyles.viewPagination}>
              <p data-testid='showingPagination' style={WebStyles.textHint}>{this.props.currentPage && `${Strings.showing} ${Math.max(1, this.props.currentPage * 10 - 9)}-${Math.min(this.props.total, this.props.currentPage * 10)} ${Strings.from} ${this.props.total}`}</p>
              <MyPagination
                onChange={(event: React.ChangeEvent<unknown>, value: number) => this.props.onChangePage?.(value)}
                count={this.props.totalPagination}
                page={this.props.currentPage}
                variant="outlined"
                shape="rounded"
                renderItem={(item) => this.renderItemPagination(item)}
              />
            </Box>
          )
        }
      </div>
    );
  }
}

const MyPagination = styled(Pagination)({
  "& .MuiPaginationItem-ellipsis": {
    border: "0px solid red",
    backgroundColor: 'rgba(141, 151, 162, 0.30)',
    borderRadius: 8,
    height: "32px",
    paddingBottom: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  //There has a lot of Global classes. you have to use it according to your requirement
  //Some classes are MuiPaginationItem-root, MuiPaginationItem-page and Mui-selected
});

const WebStyles = {
  viewIconFilter: {
    fontSize: '16px',
    width: '93px',
    height: '40px',
    border: '1px solid #e0e2e7', 
    borderRadius: '8px'
  },
  viewHeader: {
    justifyContent: "space-between",
    paddingLeft: "22px",
    paddingRightt: "22px",
  },
  tableCell: {
    color: "rgba(28, 48, 69, 0.70)",
    fontSize: "14px",
    fontFamily: "Barlow Semi Condensed",
    fontWeight: "500",
    lineHeight: "20px",
    width: "25%",
    paddingTop: "18px",
    paddingLeft: "22px",
    paddingBottom: "18px",
    letterSpacing: "0.07px"
  } as any,
  tableWrapper: {
    background: "#fff",
    marginBottom: "20px",
    width: "calc(100% + 16px)",
    borderRadius: "12px",
    paddingBottom: 24
  },
  tableHeader: {
    color: "#1C3045",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Barlow Semi Condensed",
    fontWeight: "600",
    lineHeight: "24px",
    paddingLeft: "22px"
  } as any,
  tableHeading: {
    color: "#1C3045",
    fontSize: "24px",
    fontStyle: "normal",
    fontFamily: "Barlow Semi Condensed",
    fontWeight: "700",
    lineHeight: "24px"
  } as any,
  textHint: {
    color: '#667085',
    fontSize: '14px',
    lineHeight: '20px',
    fontStyle: 'normal',
    fontFamily: ' Barlow Semi Condensed'
  },
  textViewMore: {
    color: '#32569A',
    fontSize: '14px',
    lineHeight: '20px',
    fontStyle: 'normal',
    fontFamily: ' Barlow Semi Condensed',
    fontWeight: 700,
    letterSpacing: "0.07px",
    alignItems: "flex-end"
  } as any,
  viewPagination: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: ' space-between',
    padding: ' 24px 24px 0 24px'
  },
  viewMore: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: ' 20px 20px 20px 0px',
    flexDirection: 'row',
  } as any,
  pageNumber: {
    backgroundColor: 'rgba(141, 151, 162, 0.30)',
    borderRadius: 8,
    color: '#32569A',
    fontFamily: "Public Sans",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "0.07px",
    padding: 6,
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
    width: "20px",
    marginRight: "3px",
    marginLeft: "3px"
  } as any,
  pageSelected: {
    backgroundColor: '#32569A',
    color: '#fff',
    borderRadius: 8,
    fontFamily: "Public Sans",
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    letterSpacing: "0.07px",
    padding: 6,
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
    width: "20px",
    marginRight: "3px",
    marginLeft: "3px"
  },
  icon: {
    width: "16px",
    height: "16px",
    padding: "2px",
  }
}
// Customizable Area End