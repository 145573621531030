import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import Strings from "./Strings";

// Customizable Area Start
interface SocialMedia {
  status: boolean;
  url: string;
  validate: boolean | null;
}
interface Error {
  status: boolean | null;
  message: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  error: Error;
  chatHeading: string;
  chatHeadingValidate: boolean | null;
  chatSubHeading: string;
  chatSubHeadingValidate: boolean | null;
  chatEmail: string;
  chatEmailValidate: boolean | null;
  officeHeading: string;
  officeHeadingValidate: boolean | null;
  officeSubHeading: string;
  officeSubHeadingValidate: boolean | null;
  officeAddress: string;
  officeAddressValidate: boolean | null;
  contactHeading: string;
  contactHeadingValidate: boolean | null;
  contactSubHeading: string;
  contactSubHeadingValidate: boolean | null;
  contactNumber1: string;
  contactNumber1Validate: boolean | null;
  contactNumber2: string;
  contactNumber2Validate: boolean | null;
  contactNumber3: string;
  contactNumber3Validate: boolean | null;
  followHeading: string;
  followHeadingValidate: boolean | null;
  followSubHeading: string;
  followSubHeadingValidate: boolean | null;
  facebookUrl: SocialMedia;
  instagramUrl: SocialMedia;
  twitterUrl: SocialMedia;
  youtubeUrl: SocialMedia;
  linkedinUrl: SocialMedia;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getContactUsDetails: any;
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      token: "",
      error: { status: null, message: "" },
      chatHeading: "",
      chatHeadingValidate: null,
      chatSubHeading: "",
      chatSubHeadingValidate: null,
      chatEmail: "",
      chatEmailValidate: null,
      officeHeading: "",
      officeHeadingValidate: null,
      officeSubHeading: "",
      officeSubHeadingValidate: null,
      officeAddress: "",
      officeAddressValidate: null,
      contactHeading: "",
      contactHeadingValidate: null,
      contactSubHeading: "",
      contactSubHeadingValidate: null,
      contactNumber1: "",
      contactNumber1Validate: null,
      contactNumber2: "",
      contactNumber2Validate: null,
      contactNumber3: "",
      contactNumber3Validate: null,
      followHeading: "",
      followHeadingValidate: null,
      followSubHeading: "",
      followSubHeadingValidate: null,
      facebookUrl: { status: false, url: "", validate: null },
      instagramUrl: { status: false, url: "", validate: null },
      twitterUrl: { status: false, url: "", validate: null },
      youtubeUrl: { status: false, url: "", validate: null },
      linkedinUrl: { status: false, url: "", validate: null },
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let temp = await localStorage.getItem("token");
    if (temp) {
      this.setState({ token: temp });
      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: temp,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getContactUsDetails = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.contactUsEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const successMessage = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (
      this.getContactUsDetails !== null &&
      this.getContactUsDetails === dataMessage
    ) {
      this.handleGetContactDetails(
        successMessage,
        errorMessage,
        this.getInitialContactDetails
      );
    } else if (
      this.contactUsApiCallId !== null &&
      this.contactUsApiCallId === dataMessage
    ) {
      this.handleSaveContactDetails(
        successMessage,
        errorMessage,
        this.saveContactDetailsSuccess
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start
  initialState: any = {};

  validateInput = (name: string, value: string) => {
    if (name === "chatEmail") {
      let validate = !configJSON.emailRegex.test(value);
      this.setState({
        chatEmailValidate: value !== "" ? validate : null,
      });
    } else if (name.includes("Number")) {
      let validate = !configJSON.mobileRegex.test(value);
      let temp = {
        ...this.state,
        [`${name}Validate`]: value !== "" ? validate : null,
        [`${name}`]: value,
      };
      this.setState({ ...temp });
    } else {
      let temp = {
        ...this.state,
        [`${name}Validate`]: value !== "" ? false : null,
        [`${name}`]: value,
      };
      this.setState({ ...temp });
    }
  };

  onChangeText = (event: any) => {
    const { name, value } = event.target;
    let newValue = { ...this.state, [name]: value };
    this.setState({ ...newValue });
    this.validateInput(name, value);
  };

  validateURL = (value: string) => {
    return value !== "" ? !configJSON.urlRegex.test(value) : null;
  };

  onChangeUrlText = (event: any) => {
    const { name, value }: { name: string; value: string } = event.target;
    switch (name) {
      case "facebookUrl":
        this.setState({
          facebookUrl: {
            ...this.state.facebookUrl,
            validate: this.validateURL(value),
            url: value,
          },
        });
        break;
      case "instagramUrl":
        this.setState({
          instagramUrl: {
            ...this.state.instagramUrl,
            validate: this.validateURL(value),
            url: value,
          },
        });
        break;
      case "twitterUrl":
        this.setState({
          twitterUrl: {
            ...this.state.twitterUrl,
            validate: this.validateURL(value),
            url: value,
          },
        });
        break;
      case "youtubeUrl":
        this.setState({
          youtubeUrl: {
            ...this.state.youtubeUrl,
            validate: this.validateURL(value),
            url: value,
          },
        });
        break;
      case "linkedinUrl":
        this.setState({
          linkedinUrl: {
            ...this.state.linkedinUrl,
            validate: this.validateURL(value),
            url: value,
          },
        });
        break;
    }
  };
  onURLTickPress = (name: string) => {
    switch (name) {
      case "facebookUrl":
        this.setState({
          facebookUrl: {
            ...this.state.facebookUrl,
            status: !this.state.facebookUrl.status,
            validate: !this.state.facebookUrl.status
              ? this.validateURL(this.state.facebookUrl.url)
              : null,
          },
        });
        break;
      case "instagramUrl":
        this.setState({
          instagramUrl: {
            ...this.state.instagramUrl,
            status: !this.state.instagramUrl.status,
            validate: !this.state.instagramUrl.status
              ? this.validateURL(this.state.instagramUrl.url)
              : null,
          },
        });
        break;
      case "twitterUrl":
        this.setState({
          twitterUrl: {
            ...this.state.twitterUrl,
            status: !this.state.twitterUrl.status,
            validate: !this.state.twitterUrl.status
              ? this.validateURL(this.state.twitterUrl.url)
              : null,
          },
        });
        break;
      case "youtubeUrl":
        this.setState({
          youtubeUrl: {
            ...this.state.youtubeUrl,
            status: !this.state.youtubeUrl.status,
            validate: !this.state.youtubeUrl.status
              ? this.validateURL(this.state.youtubeUrl.url)
              : null,
          },
        });
        break;
      case "linkedinUrl":
        this.setState({
          linkedinUrl: {
            ...this.state.linkedinUrl,
            status: !this.state.linkedinUrl.status,
            validate: !this.state.linkedinUrl.status
              ? this.validateURL(this.state.linkedinUrl.url)
              : null,
          },
        });
        break;
    }
  };

  checkValidation = () => {
    const {
      chatEmailValidate,
      contactNumber1Validate,
      contactNumber2Validate,
      contactNumber3Validate,
      facebookUrl,
      instagramUrl,
      twitterUrl,
      youtubeUrl,
      linkedinUrl,
    } = this.state;
    let fieldValid =
      !chatEmailValidate &&
      !contactNumber1Validate &&
      !contactNumber2Validate &&
      !contactNumber3Validate;

    let urlValid = [];
    let temp: any = {
      facebookUrl,
      instagramUrl,
      twitterUrl,
      youtubeUrl,
      linkedinUrl,
    };
    for (const key in temp) {
      let currentValue: SocialMedia = temp[key];
      if (currentValue.status) {
        currentValue.validate =
          currentValue.validate !== null ? currentValue.validate : true;
        urlValid.push(!currentValue.validate);
      }
    }

    let tempState = { ...this.state };
    this.setState({ ...tempState });

    let urlValidation = urlValid.every((value: any) => value);

    return urlValidation && fieldValid;
  };

  handlleSaveChange = () => {
    if (this.checkValidation()) {
      if (this.state.token) {
        const {
          chatHeading,
          chatSubHeading,
          chatEmail,
          officeHeading,
          officeSubHeading,
          officeAddress,
          contactHeading,
          contactSubHeading,
          contactNumber1,
          contactNumber2,
          contactNumber3,
          followHeading,
          followSubHeading,
          facebookUrl,
          instagramUrl,
          twitterUrl,
          youtubeUrl,
          linkedinUrl,
        } = this.state;

        const data = {
          chat_email_address: chatEmail,
          chat_heading: chatHeading,
          chat_sub_heading: chatSubHeading,
          contact_heading: contactHeading,
          contact_number1: contactNumber1,
          contact_number2: contactNumber2,
          contact_number3: contactNumber3,
          contact_sub_heading: contactSubHeading,
          facebook_url: facebookUrl.url,
          facebook_url_active: facebookUrl.status,
          follow_heading: followHeading,
          follow_sub_heading: followSubHeading,
          instagram_url: instagramUrl.url,
          instagram_url_active: instagramUrl.status,
          linkedin_url: linkedinUrl.url,
          linkedin_url_active: linkedinUrl.status,
          office_address: officeAddress,
          office_heading: officeHeading,
          office_sub_heading: officeSubHeading,
          twitter_url: twitterUrl.url,
          twitter_url_active: twitterUrl.status,
          youtube_url: youtubeUrl.url,
          youtube_url_active: youtubeUrl.status,
        };

        const header = {
          "Content-Type": configJSON.contactUsApiContentType,
          token: this.state.token,
        };

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.contactUsApiCallId = requestMessage.messageId;

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.contactUsEndpoint
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpPutMethod
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify({ data: data })
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
      } else {
        this.props.navigation.navigate("EmailAccountLoginBlock");
      }
    }
  };

  handleDiscardChanges = () => {
    this.setState({...this.initialState})
  };

  handleGetContactDetails = (
    successMessage: any,
    errorMessage: any,
    callback: Function
  ) => {
    if (errorMessage) {
    } else if (successMessage) {
      if (!successMessage.errors) {
        callback(successMessage);
      } else if (successMessage.errors[0].token) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
      }
    }
  };

  getInitialContactDetails = (successMessage: any) => {
    if (successMessage.data.attributes) {
      const {
        chat_email_address,
        chat_heading,
        chat_sub_heading,
        contact_heading,
        contact_number1,
        contact_number2,
        contact_number3,
        contact_sub_heading,
        facebook_url,
        facebook_url_active,
        follow_heading,
        follow_sub_heading,
        instagram_url,
        instagram_url_active,
        linkedin_url,
        linkedin_url_active,
        office_address,
        office_heading,
        office_sub_heading,
        twitter_url,
        twitter_url_active,
        youtube_url,
        youtube_url_active,
      } = successMessage.data.attributes;
      let data = {
        chatEmail: chat_email_address,
        chatEmailValidate: chat_email_address !== "" ? false : null,
        chatHeading: chat_heading,
        chatHeadingValidate: chat_heading !== "" ? false : null,
        chatSubHeading: chat_sub_heading,
        chatSubHeadingValidate: chat_sub_heading !== "" ? false : null,
        contactHeading: contact_heading,
        contactHeadingValidate: contact_heading !== "" ? false : null,
        contactNumber1: contact_number1,
        contactNumber1Validate: contact_number1 !== "" ? false : null,
        contactNumber2: contact_number2,
        contactNumber2Validate: contact_number2 !== "" ? false : null,
        contactNumber3: contact_number3,
        contactNumber3Validate: contact_number3 !== "" ? false : null,
        contactSubHeading: contact_sub_heading,
        contactSubHeadingValidate: contact_sub_heading !== "" ? false : null,
        officeAddress: office_address,
        officeAddressValidate: office_address !== "" ? false : null,
        officeHeading: office_heading,
        officeHeadingValidate: office_heading !== "" ? false : null,
        officeSubHeading: office_sub_heading,
        officeSubHeadingValidate: office_sub_heading !== "" ? false : null,
        followHeading: follow_heading,
        followHeadingValidate: follow_heading !== "" ? false : null,
        followSubHeading: follow_sub_heading,
        followSubHeadingValidate: follow_sub_heading !== "" ? false : null,
        facebookUrl: {
          status: facebook_url_active,
          url: facebook_url,
          validate: facebook_url !== "" ? false : null,
        },
        instagramUrl: {
          status: instagram_url_active,
          url: instagram_url,
          validate: instagram_url !== "" ? false : null,
        },
        twitterUrl: {
          status: twitter_url_active,
          url: twitter_url,
          validate: twitter_url !== "" ? false : null,
        },
        youtubeUrl: {
          status: youtube_url_active,
          url: youtube_url,
          validate: youtube_url !== "" ? false : null,
        },
        linkedinUrl: {
          status: linkedin_url_active,
          url: linkedin_url,
          validate: linkedin_url !== "" ? false : null,
        },
      };
      this.initialState = { ...data };
      this.setState({
        ...data,
      });
    }
  };

  handleSaveContactDetails = (
    successMessage: any,
    errorMessage: any,
    callback: Function
  ) => {
    if (errorMessage) {
    } else if (successMessage) {
      if (!successMessage.errors) {
        callback(successMessage);
      } else if (successMessage.errors[0].token) {
        this.props.navigation.navigate("EmailAccountLoginBlock");
      } else {
        this.setState({
          error: { status: true, message: successMessage.errors[0] },
        });
      }
    }
  };

  saveContactDetailsSuccess = () => {
    this.initialState={...this.state}
    this.setState({
      error: { status: false, message: Strings.yourChangesHaveBeenSaved },
    });
  };
  // Customizable Area End
}
