import React from "react";

//Customizable Area Start
import { resetPasswordStrings } from "./assets";
import ResetPasswordController, { Props } from "./ResetPasswordController";
import CustomForgotResetPassword from "./CustomForgotResetPassword.web";
import "./ForgotPassword.css";
import Strings from "./Strings";
//Customizable Area End

export default class ResetPassword extends ResetPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    return (
      <div className="forgotPasswordcontainer">
        <CustomForgotResetPassword
          data-testid={"customForgotReset"}
          headerText={resetPasswordStrings.headerText}
          subHeaderText={resetPasswordStrings.subHeaderText}
          buttonText={resetPasswordStrings.buttonText}
          onSubmitButtonPress={this.onResetPress}
          showPrompt={null}
          showBackButton={true}
          onBackButtonPress={this.onBackToLogin}
          validateField={
            this.state.newPasswordValidate || this.state.confirmPassValidate
          }
          inputArray={[
            {
              name: Strings.newPassword,
              placeholder: Strings.enterNewPassword,
              value: this.state.newPassword,
              type: "password",
              onChange: this.onNewPassChange,
              className: webStyles.marginBottom0,
              showPasswordValidationModal: true,
              error:this.state.newPasswordValidate,
              errorMessage: `${this.state.samePassError}`,
            },
            {
              name: Strings.confirmPassword,
              placeholder: Strings.confirmPassword,
              value: this.state.confirmPassword,
              type: "password",
              onChange: this.onConfirmPassChange,
              error: !(
                this.state.newPassword === this.state.confirmPassword &&
                this.state.confirmPassword.length !== 0
              ),
              errorMessage: Strings.yourPasswordDoesntMatch,
            },
          ]}
        />
      </div>
    );
  }
}

const webStyles = {
  marginCorrection: {
    marginBottom: 28,
  },
  marginBottom0: {
    marginBottom: 0,
  },
};
