Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";


exports.arabicLang = { 
  welcomeText : 'أهلًا بك في منصة نقاء للإستدامة ',
  loginText : 'تسجيل الدخول ',
  emailLabel : 'البريد الإلكتروني',
  passLabel : 'كلمة المرور',
  emailPlaceholder : 'أدخل البريد الإلكتروني',
  passPlaceholder : 'أدخل كلمة السر',
  sideScreenText : `بيئة أفضل لغد أفضل`,
  betterEnv : 'بيئة أفضل ',
  betterTom : 'لغد أفضل',
  loginBtn : 'Login',
  rememberMe : 'تذكرني',
  tandcText : `.بإستخدامك لمنصة نقاء للإستدامة أنت توافق على `,
  termsConditions : '.الشروط والأحكام',
  english : 'الإنجليزية',
  arabic : 'العربية',
  forgetPassword : 'نسيت كلمة المرور؟',
  incorrectUserCred : 'خطأ في البريد الإلكتروني أو كلمة المرور'
}
exports.englishLang = { 
  welcomeText : 'Welcome to Naqaa Solutions',
  loginText : 'Login ',
  emailLabel : 'Email address',
  passLabel : 'Password',
  emailPlaceholder : 'Enter email address',
  passPlaceholder : 'Enter password',
  sideScreenText : `Better Environment,Better Tomorrow`,
  betterEnv : 'Better Environment',
  betterTom : 'Better Tomorrow',
  loginBtn : 'Login',
  rememberMe : 'Remember me',
  tandcText : ` By using Naqaa Dashboard, you agree to our `,
  termsConditions : 'Terms and Conditions.',
  forgetPassword : 'Forgot password?',
  english : 'English',
  arabic : 'Arabic',
  incorrectUserCred : ' Username or password is incorrect!'
}
// Customizable Area End