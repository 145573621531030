import { Typography } from "@material-ui/core";
import React from "react";
import "./ClientDetailsStyles.css";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

export interface InputProps {
  name?: string;
  labelName?: string;
  value: string;
  placeholder?: string;
  onChangeText?: (arg: any) => void;
  customStyles?: string; // className
  customStyle?: any; // Object style
  error?: boolean | null;
  type?: string;
  errorMessage?: string;
  errorMessageStyle?: any;
  multiline?: boolean;
  rows?: number;
  customContainerStyle?: string;
  disable?: boolean;
  onBlur?: any;
  labelStyle?: string;
  inputStyle?: any; //input Style Object
}

interface S {
  showPassword: boolean;
  focusInput: boolean;
}

class CustomInputField extends React.PureComponent<InputProps, S> {
  state = {
    showPassword: false,
    focusInput: false,
  };

  getEndAdornment = () => {
    if (this.props.type == "password") {
      return (
        <button
          data-testid={"iconButton"}
          aria-label="toggle password visibility"
          onClick={this.handleClickShowPassword}
          onMouseDown={this.handleMouseDownPassword}
          className="client-endornment-icon"
        >
          {!this.state.showPassword ? (
            <img src={imgPasswordInVisible} className="img-input-icon" />
          ) : (
            <img src={imgPasswordVisible} className="img-input-icon" />
          )}
        </button>
      );
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  onInputFocus = () => {
    this.setState({ focusInput: true });
  };

  onInputBlur = () => {
    this.props?.onBlur && this.props?.onBlur();
    this.setState({ focusInput: false });
  };

  getInputType = () => {
    if (this.props.type === "password") {
      if (this.state.showPassword) {
        return "text";
      }
    }
    return this.props.type;
  };

  getErrorStyles = () => {
    if (this.props.error !== null) {
      if (this.props.error) {
        return { outline: "none", border: "1px solid rgba(220, 38, 38, 1)" };
      }
      return { outline: "none", border: "1px solid #bfcc33" };
    }
    return {};
  };

  render(): React.ReactNode {
    const {
      name,
      value,
      placeholder,
      onChangeText,
      multiline = false,
      error = null,
      errorMessage = "",
      errorMessageStyle = {},
      labelName = "",
      customStyles,
      customContainerStyle,
      disable = false,
      labelStyle,
      inputStyle,
      customStyle,
    } = this.props;

    return (
      <div
        className={`account-creation-input-container ${customContainerStyle}`}
      >
        <p className={`input-name ${labelStyle}`}>{labelName}</p>
        <div
          style={{
            ...this.getErrorStyles(),
            ...customStyle,
          }}
          className={`input-container-client ${customStyles} ${this.getErrorStyles()}`}
        >
          {!multiline ? (
            <input
              name={name}
              data-testid={"textInput"}
              value={value}
              style={inputStyle}
              className={`input-field-client`}
              placeholder={placeholder}
              onChange={onChangeText}
              type={this.getInputType()}
              onFocus={this.onInputFocus}
              onBlur={this.onInputBlur}
              disabled={disable}
              autoComplete="new-password"
            />
          ) : (
            <textarea
              name={name}
              data-testid={"textAreaInput"}
              value={value}
              className={`input-field-client`}
              placeholder={placeholder}
              onChange={onChangeText}
              onFocus={this.onInputFocus}
              onBlur={this.onInputBlur}
              disabled={disable}
              style={inputStyle}
              autoComplete="new-password"
            />
          )}
          {this.getEndAdornment()}
        </div>
        <Typography
          color="error"
          align="left"
          variant="subtitle2"
          style={{ ...styles.errorMessage, ...errorMessageStyle }}
          className="input-error-text"
        >
          {error && errorMessage !== "" && value !== "" ? errorMessage : ""}
        </Typography>
      </div>
    );
  }
}

const styles = {
  errorMessage: {
    height: 19,
    width: "95%",
    marginLeft: 5,
  },
  marginCorrection: {
    borderColor: "E7EDEF",
    marginBottom: 10,
    width: "100%",
  },
};

export default CustomInputField;
