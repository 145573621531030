import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
export interface SQS {
  page: number,
  per_page: number,
  searchText: string,
  total_page: number
  total_count : number,
  activePagiantionStep : number,
  pick_up_date : string,
  clients: number[],
  citys : string[],
  custom_start_date : string,
  custom_end_date : string,
  sort_by : string,
}
// Customizable Area End

export const webConfigJSON = require("../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  tabIndexString: string
  tabIndex: number,
  showFilterModal: boolean,
  searchQueryState: SQS,
  clientListigData: any[],
  apiloader: boolean,
  companyList : any[],
  cityList : any[],
  showSortrModal: boolean,
  select_user_delete : number[],
  deleteModalFlag : boolean,
  selectedClientToView: string,

  // Customizable Area End
}
interface SS { }

export default class ClientListingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getClientAccountListId: string = "";
  getCompanyListId : string = "";
  getCityListId : string = "";
  deleteClientId : string = "";
  debouncedSearch: any;
  getClientTokenId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      tabIndexString: '0',
      tabIndex: 0,
      showFilterModal: false,
      searchQueryState: {
        page: 1,
        per_page: 10,
        searchText: '',
        total_page: 1,
        total_count: 0,
        activePagiantionStep : 1,
        pick_up_date : '',
        clients : [],
        citys : [],
        custom_start_date:'01-11-2023',
        custom_end_date :'',
        sort_by:'created_at',
      },
      clientListigData: [],
      apiloader: false,
      companyList:[],
      cityList:[],
      showSortrModal: false,
      select_user_delete : [],
      deleteModalFlag : false,
      selectedClientToView: '',
    };

    this.debouncedSearch = this.debounce(this.fetchClientTableData, 300);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    let temp=JSON.parse(JSON.stringify(this.state))
    setTimeout(()=>{
      this.setState({...temp})
    },0)
    this.fetchCompanyData()
    this.fetchClientTableData()
    this.fetchCityData()
    // Customizable Area End
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        console.log('responseJson: ', responseJson);

      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data ) {
        if (apiRequestCallId === this.getClientAccountListId) {
          let {meta : {pagy}} = responseJson
          this.setState({ clientListigData: responseJson.data, apiloader: false, searchQueryState:{...this.state.searchQueryState, total_page:pagy.pages, total_count:pagy.count} });
        }
      } else {
        this.handleApiResponse(responseJson,apiRequestCallId)
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleApiResponse = (responseJson:any,apiRequestCallId:any)=>{
    if(responseJson && responseJson.company_names && apiRequestCallId === this.getCompanyListId ){
      let {company_names} = responseJson
      console.log('company_names: ', company_names);
      this.setState({companyList : company_names})
    }else if(responseJson && responseJson.client_token && apiRequestCallId === this.getClientTokenId){
      this.setClientDetails(responseJson).then(() => window.open("/DashboardClient", "_blank"));
    }else if(responseJson && responseJson.cities && apiRequestCallId === this.getCityListId){
      let {cities} = responseJson
      this.setState({cityList : cities})
    }else if(responseJson && responseJson.message && apiRequestCallId === this.deleteClientId){
      this.deletApiSuccessResponse()
    }else if (responseJson && responseJson.errors) {
      this.handleErrorMessage(responseJson.errors, apiRequestCallId)
    }
  }

  setClientDetails = (responseJson: any): Promise<void> => {
    return new Promise((resolve, reject) => {
      localStorage.removeItem("clientDetails");
      const { client_token } = responseJson;
      const clientDetails = {
        id: this.state.selectedClientToView,
        token: client_token
      };
      localStorage.setItem("clientDetails", JSON.stringify(clientDetails));
      resolve();
    })
  }

  handleErrorMessage = (errors: any, apiRequestCallId: any) => {
    if (errors[0].token) {
      localStorage.clear()
      this.props.navigation.navigate('EmailAccountLoginBlock')
    }
    if (errors.includes('Accounts data does not exist') && apiRequestCallId === this.getClientAccountListId) {
      this.setState({
        clientListigData: [],
        apiloader: false,
        searchQueryState:{
          ...this.state.searchQueryState,page:1,
          total_page: 1,
          total_count: 0,
          activePagiantionStep : 1
        } });
    }
  }


  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabIndex: newValue, tabIndexString: newValue.toString() },()=>this.fetchClientTableData())
  }

  showFilterModal = () => {
    this.setState({ showFilterModal: !this.state.showFilterModal })
  }
  
  showSortModal = () => {
    this.setState({ showSortrModal: !this.state.showSortrModal })
  }

  getClientActivated=()=>{
    return this.state.tabIndex ===1 ? false : true
  }

  fetchClientTableData = async() => {
    this.setState({ apiloader: true })
    let token = localStorage.getItem('token')
    let { searchQueryState } = this.state
    let apipayload = {searchText : 'search_query',pick_up_date:'filters[pick_up_date]',sort_by : 'sort_by',clients:'filters[clients][]',citys:'filters[cities][]'}
    let endPoint: string = `${webConfigJSON.endPointApiGetClientAccountList}?page=${searchQueryState.page}&per_page=${searchQueryState.per_page}&activated=${this.getClientActivated()}`
    
    Object.keys(apipayload).forEach((payloadtype) => {
        // @ts-ignore
        if(searchQueryState[payloadtype]){
          if(payloadtype === 'pick_up_date' && searchQueryState.pick_up_date === 'custom_date'){
            endPoint = `${endPoint}&filters[pick_up_date][custom_date][start_date]=${searchQueryState.custom_start_date}&filters[pick_up_date][custom_date][end_date]=${searchQueryState.custom_end_date}`
          }else{
            // @ts-ignore
            if(searchQueryState[payloadtype].length){
            // @ts-ignore
              if(Array.isArray(searchQueryState[payloadtype])){
            // @ts-ignore
                searchQueryState[payloadtype].map((value:any)=>{
            // @ts-ignore
                  endPoint = `${endPoint}&${apipayload[payloadtype]}=${value}`       
                })
              }
            // @ts-ignore
            endPoint = `${endPoint}&${apipayload[payloadtype]}=${searchQueryState[payloadtype]}` 
            }
          }
         } 
       })
    
    const header = {
      "Content-Type": webConfigJSON.contentTypeApiGetClientAccountList,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClientAccountListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.methodTypeApiGetClientAccountList
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  this.scrollUp()
   
  }

  scrollUp=()=>{
    let element: any = document.querySelector('#table_container');
    if(element){
      element.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }
  }


  fetchCompanyData = ()=>{
    let token = localStorage.getItem('token')

    const header = {
      "Content-Type": webConfigJSON.contentTypeApiGetCompanyList,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCompanyListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.endPointApiGetCompanyList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.methodTypeApiGetCompanyList
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchCityData = ()=>{
    let token = localStorage.getItem('token')

    const header = {
      "Content-Type": webConfigJSON.contentTypeApiGetCompanyList,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCityListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.endPointApiGetCityList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.methodTypeApiGetCityList
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQueryState: { ...this.state.searchQueryState, searchText: event.target.value } })
    this.debouncedSearch();
  }


  debounce = (func: any, delay: number) => {
    let timer: any;
    let self = this
    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        self.setState({ searchQueryState: { ...self.state.searchQueryState, page:1, activePagiantionStep:1 } },()=>func())
      }, delay);
    };
  }

  setSearchQueryState = (queryState:SQS,callApi:boolean)=>{
    this.setState({searchQueryState:{...queryState}},()=>{
      if(callApi){
        this.fetchClientTableData()
        this.setState({select_user_delete:[]})
      }
      this.handleClickAwaySort()
    })
  }

  handleClickAwayFilter=()=>{
    this.setState({ showFilterModal: false })
  }
 
  handleClickAwaySort=()=>{
    this.setState({ showSortrModal: false })
  }

  addClient=()=>{
    this.props.navigation.navigate("AccountCreation");
  }

  setSelectUserForDelete = (ids:number[])=>{
    this.setState({select_user_delete:ids})
  }

  showDeleteModalFlag = ()=>{
    if(!this.state.select_user_delete.length){
      return
    }
    this.setState({deleteModalFlag : true})
    let element: any = document.querySelector('#root div');
    if(element){
      element.scrollTo({
        top: 0,
        behavior: "smooth"
      })
      element.style.overflow = 'hidden'
    }
  }

  hideDeleteModalFlag = ()=>{
    this.setState({deleteModalFlag : false})
    let element: any = document.querySelector('#root div');
    if(element){element.style.overflow = 'unset'}
  }

  handleDelete=(ids:number[])=>{
    let token = localStorage.getItem('token')

    let data = {
      account_ids: ids,
    };

    const header = {
      "Content-Type": webConfigJSON.contentTypeApiGetCompanyList,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteClientId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.endPointApiDeleteClientList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.methodTypeApiDeleteClientList
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.hideDeleteModalFlag()
  }

  viewAsClient = (clientId: string) => {
    this.setState({selectedClientToView: clientId});
    this.fetchClientToken(clientId);
  }

  fetchClientToken = async (clientId: string) => {
    const token = localStorage.getItem("token");
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: token,
    };
    const endpoint = `${webConfigJSON.endPointApiGetClientAccountDetail}${clientId}/client_token`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getClientTokenId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deletApiSuccessResponse =()=>{
    let {total_page,page} = this.state.searchQueryState
    let {select_user_delete,clientListigData} = this.state
    if(page>1 && page ===total_page && clientListigData.length === select_user_delete.length){
      this.setSearchQueryState({...this.state.searchQueryState,page:page-1,activePagiantionStep:Math.ceil((total_page-1)/5)},true)
    }else{
      this.setSearchQueryState({...this.state.searchQueryState},true)
    }
  }

  // Customizable Area End
}
